import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button'
import CardMedia from "@material-ui/core/CardMedia";
import FormLabel from "@material-ui/core/FormLabel";
// import { saveAs } from 'file-saver'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

const EditImageField = (props) => {
  const classes = useStyles();
  let storeLogoUrl = `${
    props.searchDetails &&
    props.searchDetails.store &&
    props.searchDetails.store.storeLogoUrl
  }`;
  var tempLink = document.createElement('a');
  document.body.appendChild(tempLink);
  const download = async(e) => {
    // var fileIndex = fileUrls.length*1-1*1;
    var fileUrl = e;
    tempLink.setAttribute('href', fileUrl);
    tempLink.setAttribute('download', fileUrl.split('/')[fileUrl.split('/').length*1-1*1]);
    tempLink.click();
  };
  return (
    <>
      <FormLabel component="legend" style={{textAlign:'left'}}>Store Logo</FormLabel>
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={storeLogoUrl}
            title="Store Logo"
          />
           <CardActions>
      <Button size="small"  onClick={() => download(storeLogoUrl)}>Download</Button>
    </CardActions>
        </CardActionArea>
      </Card>
    </>
  );
};
export default EditImageField;
