import React from "react";
import MainCard from "../../ui-component/cards/MainCard";
import Divider from "@mui/material/Divider";
import { Container, Row, Col } from "react-bootstrap";
import StoreDetails from "./StoreDetails";
import UserDetails from "./UserDetails";
import Complaint from "./Complaint";
import ShopAssistantDetails from "./ShopAssistantDetails";
import DeliveryPartnerDetails from "./DeliveryPartnerDetails";
import PreviousComplaints from "./PreviousComplaints";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  card: {
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    padding: "15px",
    borderRadius: "14px",
  },
  col: {
    padding: "8px",
    color: "rgb(99, 115, 129)",
    fontWeight: 600,
    fontSize: "14px",
  },
}));

const Details = (props) => {
  const classes = useStyles();
  const getOrderDetails = (id) => {
    localStorage.setItem("orderID", id);
    props.history.push({
      pathname: "/home/Orderdetails",
      state: { orderid: id },
    });
  };
  React.useEffect(
    () => {
      props.Complaint.orderid &&
        props.Complaint.orderid.id &&
        props.orderComplaints(
          props.Complaint.orderid &&
            props.Complaint.orderid.id &&
            props.Complaint.orderid.id
        );
    },
    /*eslint-disable */
    []
  );
  return (
    <Container style={{ padding: "20px" }}>
      <Row>
        <Col sm={12} md={4}>
          <MainCard
            border={false}
            content={false}
            onClick={() => {
              getOrderDetails(
                props.Complaint.orderid &&
                  props.Complaint.orderid.id &&
                  props.Complaint.orderid.id
              );
            }}
            style={{
              backgroundColor: "white",
              padding: "15px",
              borderRadius: "14px",
              width: "90%",
              cursor: "pointer",
            }}
            boxShadow
            className={classes.card}
          >
            <Divider>
              <h4 style={{ textAlign: "center" }}>
                <b>Order Details</b>
              </h4>
            </Divider>
            <Row className={classes.col}>
              <Col sm={6}>Order#</Col>
              <Col sm={6}>
                {props.Complaint.orderid &&
                  props.Complaint.orderid.orderNumber &&
                  props.Complaint.orderid.orderNumber}
              </Col>
            </Row>
            <Row className={classes.col}>
              <Col sm={6}>Order Status</Col>
              <Col sm={6}>
                {props.Complaint.orderid &&
                  props.Complaint.orderid.lastStatus &&
                  props.Complaint.orderid.lastStatus}
              </Col>
            </Row>

            <Row className={classes.col}>
              <Col sm={6}>Delivery Time</Col>
              <Col sm={6}>
                {props.Complaint.orderid &&
                  props.Complaint.orderid.deliveryTime &&
                  props.Complaint.orderid.deliveryTime}
              </Col>
            </Row>
            <Row className={classes.col}>
              <Col sm={6}>Delivery Address</Col>
              <Col sm={6}>
                {props.Complaint.orderid &&
                  props.Complaint.orderid.deliveryAddress &&
                  props.Complaint.orderid.deliveryAddress}
              </Col>
            </Row>
            <Row className={classes.col}>
              <Col sm={6}>No. of Products</Col>
              <Col sm={6}>
                {props.Complaint.orderid &&
                  props.Complaint.orderid.productCount &&
                  props.Complaint.orderid.productCount}
              </Col>
            </Row>
            <Row className={classes.col}>
              <Col sm={6}>Total Price</Col>
              <Col sm={6}>
                ₹
                {props.Complaint.orderid &&
                  props.Complaint.orderid.totalPrice &&
                  parseFloat(props.Complaint.orderid.totalPrice).toFixed(2)}
              </Col>
            </Row>
            <Row className={classes.col}>
              <Col sm={6}>Delivery Charge</Col>
              <Col sm={6}>
                ₹
                {props.Complaint.orderid &&
                  props.Complaint.orderid.deliveryCharge &&
                  parseFloat(props.Complaint.orderid.deliveryCharge).toFixed(2)}
              </Col>
            </Row>
            <Row className={classes.col}>
              <Col sm={6}>Grand Total</Col>
              <Col sm={6}>
                ₹
                {props.Complaint.orderid &&
                  props.Complaint.orderid.totalPriceDelivery &&
                  parseFloat(
                    props.Complaint.orderid.totalPriceDelivery
                  ).toFixed(2)}
              </Col>
            </Row>
          </MainCard>
        </Col>
        <Col sm={12} md={8}>
          <MainCard
            border={false}
            content={false}
            style={{
              backgroundColor: "white",
              padding: "15px",
              borderRadius: "14px",
              width: "90%",
            }}
            boxShadow
            className={classes.card}
          >
            <Divider>
              <h4 style={{ textAlign: "center" }}>
                <b>Store</b>
              </h4>
            </Divider>
            <StoreDetails {...props} />
          </MainCard>
        </Col>
      </Row>
      <Row style={{ paddingTop: "10px" }}>
        <Col sm={12} md={4}>
          <MainCard
            border={false}
            content={false}
            style={{
              backgroundColor: "white",
              padding: "15px",
              borderRadius: "14px",
              width: "90%",
            }}
            boxShadow
            className={classes.card}
          >
            <Divider>
              <h4 style={{ textAlign: "center" }}>
                <b>User</b>
              </h4>
            </Divider>
            <UserDetails {...props} />
            <br />
            <Divider>
              <h4 style={{ textAlign: "center" }}>
                <b>Shop Assistant</b>
              </h4>
            </Divider>
            <ShopAssistantDetails {...props} />
            <br />
            <Divider>
              <h4 style={{ textAlign: "center" }}>
                <b>Delivery Partner</b>
              </h4>
            </Divider>
            <DeliveryPartnerDetails {...props} />
            {props.ordercomplaintsuccess &&
              props.OrderComplaint &&
              props.OrderComplaint !== null &&
              props.OrderComplaint !== undefined && (
                <>
                  <Divider>
                    <h4 style={{ textAlign: "center" }}>
                      <b>Previous Complaints</b>
                    </h4>
                  </Divider>
                  <PreviousComplaints {...props} />
                </>
              )}
          </MainCard>
        </Col>
        <Col sm={12} md={8}>
          <MainCard
            border={false}
            content={false}
            style={{
              backgroundColor: "white",
              padding: "15px",
              borderRadius: "14px",
              width: "90%",
            }}
            boxShadow
            className={classes.card}
          >
            <Divider>
              <h4 style={{ textAlign: "center" }}>
                <b>Complaint</b>
              </h4>
            </Divider>
            <Complaint {...props} />
          </MainCard>
        </Col>
      </Row>
    </Container>
  );
};

export default Details;
