import React from "react";
import Container from '@mui/material/Container';
import { Row, Col } from 'react-bootstrap';
import TextField from "@material-ui/core/TextField";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { useFormik } from "formik";
import { flatdiscountvalidationSchema } from "./Validation";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import MenuItem from '@mui/material/MenuItem';
import './index.css';



const Business = [

  {
    value: 1,
    label: 'Groceries & Essentials'
  },
  {
    value: 2,
    label: 'Restaurants & Bakery'
  },
  {
    value: 3,
    label: 'Fish or meat'
  },
  {
    value: 4,
    label: 'Fruits & Vegetables'
  },
  {
    value: 5,
    label: 'Medicines'
  },
  {
    value: 6,
    label: 'Electronics & Home Appliances'
  },
  {
    value: 7,
    label: 'Lifestyle & Fashion'
  },
  {
    value: 8,
    label: 'Sports & Fitness'
  },
  {
    value: 9,
    label: 'Pet Care'
  },
  {
    value: 10,
    label: 'Home decor & Furnishing'
  },
  {
    value: 11,
    label: 'Home Business'
  },
];

export default function FlatDiscount(props) {

  const [isUser, setIsUser] = React.useState(false);

  const [tovalue, setToValue] = React.useState();

  const [fromvalue, setFromValue] = React.useState();

  const [BuisnessType, setBusinessType] = React.useState(null);

  const [disablebutton,setDisableButton] = React.useState(false);


  const handleChange = (event) => {
    // console.log("handleChange() - value is", event.target.checked);
    setIsUser(event.target.checked);
    // Can't set form object values here as setState calls don't happen immediately.
  };

  const theme = createMuiTheme({
    palette: {
      primary: green,
    },
    overrides: {
      MuiInputBase: {
        input: {
          "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
        },
      },
    },
  });

  const formik = useFormik({
    initialValues: {
      couponCode: '',
      usesperCustomer: '',
      disAmt: '',
      minOrder: '',
      fromdate: '',
      todate: '',
      fromtime: new Date(),
      totime: new Date(),
    },
    validationSchema: flatdiscountvalidationSchema,

    onSubmit: (values) => {
      let { fromtime, totime } = values;
      let StartDate = new Date(fromtime);
      let ToDate = new Date(totime);
      if (StartDate >= ToDate) {
        formik.setFieldError("totime", "End Time must be greater than Start Time")
        // console.log(`${StartDate} is greater than ${ToDate}`);
        // false
      } 
      else if (StartDate<=new Date( Date.now() - 1000 * (60 * 5) )) {
        formik.setFieldError("fromtime", "Start Time must be greater than Today") // false
      }
      else if (ToDate < new Date()) {
        formik.setFieldError("totime", "End Time must be greater than Today") // false
      }

      else if (Number(values.disAmt) >= Number(values.minOrder)) {
        formik.setFieldError("disAmt", "Discount amount must be lesser than Minimum order value")
      }


      else {
        setDisableButton(true);
        let { couponCode, usesperCustomer, disAmt, minOrder, fromtime, totime } = values;
        let StartDate = new Date(fromtime);
        let ToDate = new Date(totime);
        props.CreateFlat({ couponCode, usesperCustomer, disAmt, minOrder, StartDate, ToDate, isUser, BuisnessType })
      }

    }
  })

  React.useEffect(()=>{
    formik.handleReset();
    setDisableButton(false);
  },[props.Flatcouponsuccess])
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <form
        // enableReinitialize
        // enableReinitialize={true}
        style={{ width: "100%", paddingTop: "15px" }}
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
      >
        <Container maxWidth='md'
          style={{
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.16)",
            borderRadius: "10px",
            //   Top: "90px",
            marginTop: '100px',
            padding: "30px",
            width: "100%",
          }}
        >
          <span>
            <h3 className="CouponType">
              {'Flat coupon'}
            </h3>
          </span>
          <span>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isUser}
                  onChange={handleChange}
                  name="primaryCheckbox"
                  color="primary"
                />
              }
              label="User Coupon"
            />
          </span>
        </Container>
        <Container maxWidth='md'
          style={{
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.16)",
            borderRadius: "10px",
            //   Top: "90px",
            marginTop: "20px",
            padding: "30px",
            width: "100%",
          }}
        >


          <Row
            className="CouponHeading">
            <p >
              Create coupon
            </p>
          </Row>
          <div className="formDiv">

            <Row>

              <Col md={6} sm={12} xs={12} style={{paddingBottom:'10px'}}>
                <ThemeProvider theme={theme}>
                  <TextField
                    InputProps={{ style: { height: 35 } }}
                    fullWidth
                    variant="outlined"
                    value={formik.values.couponCode}
                    onChange={formik.handleChange}
                    error={formik.touched.couponCode && Boolean(formik.errors.couponCode)}
                    helperText={formik.touched.couponCode && formik.errors.couponCode}
                    className="form-control"
                    name="couponCode"
                    label="Coupon code"
                    InputLabelProps={{
                      shrink: true,

                    }}
                  />
                </ThemeProvider>
              </Col>


              <Col md={6} sm={12} xs={12} style={{paddingBottom:'10px'}}>
                <ThemeProvider theme={theme}>
                  <TextField
                    InputProps={{ style: { height: 35 }, inputProps: { min: 1 } }}
                    type="number"
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+"){
                        event.preventDefault();
                      }
                    }}
                    fullWidth
                    variant="outlined"
                    value={formik.values.usesperCustomer}
                    onChange={formik.handleChange}
                    error={formik.touched.usesperCustomer && Boolean(formik.errors.usesperCustomer)}
                    helperText={formik.touched.usesperCustomer && formik.errors.usesperCustomer}
                    className="form-control"
                    name="usesperCustomer"
                    label="Uses per customer"
                    InputLabelProps={{
                      shrink: true,

                    }}
                  />
                </ThemeProvider>
              </Col>

            </Row>


          </div>
        </Container>

        <Container maxWidth='md'
          style={{
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.16)",
            borderRadius: "10px",
            marginTop: "20px",
            padding: "30px",
            width: "100%",
          }}
        >

          <Row
            className="CouponHeading">
            <p >
              Coupon details
            </p>
          </Row>
          <div className="formDiv">

            <Row>
              <Col md={6} sm={12} xs={12} style={{paddingBottom:'10px'}}>
                <ThemeProvider theme={theme}>
                  <TextField
                    InputProps={{ style: { height: 35 } }}
                    fullWidth
                    variant="outlined"
                    value={formik.values.disAmt}
                    onChange={formik.handleChange}
                    error={formik.touched.disAmt && Boolean(formik.errors.disAmt)}
                    helperText={formik.touched.disAmt && formik.errors.disAmt}
                    className="form-control"
                    name="disAmt"
                    label="Discount Amount"
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </ThemeProvider>
              </Col>

              <Col md={6} sm={12} xs={12} style={{paddingBottom:'10px'}}>
                <ThemeProvider theme={theme}>
                  <TextField
                    InputProps={{ style: { height: 35 } }}
                    fullWidth
                    variant="outlined"
                    value={formik.values.minOrder}
                    onChange={formik.handleChange}
                    error={formik.touched.minOrder && Boolean(formik.errors.minOrder)}
                    helperText={formik.touched.minOrder && formik.errors.minOrder}
                    className="form-control"
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    name="minOrder"
                    label="Minimum order value"
                    InputLabelProps={{
                      shrink: true,

                    }}
                  />
                </ThemeProvider>
              </Col>
            </Row>
            <Row style={{ paddingTop: '30px' }}>
              <Col md={6} style={{paddingBottom:'10px'}}>
                <TextField
                  InputProps={{
                    style: { height: 35, width: "100%" },
                    autoComplete: "off",
                  }}
                  id="outlined-select-currency"
                  select
                  variant="outlined"
                  label="Business Type"
                  value={BuisnessType}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {setBusinessType(e.target.value);console.log("BusinessType",e.target.value)}}
                  style={{ width: '100%' }}
                >
                  <MenuItem key={'none'} value={null}>
                    {'None'}
                  </MenuItem>
                  {Business.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                      {console.log("OPtion",option)}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>
            </Row>
          </div>
        </Container>

        <Container maxWidth='md'
          style={{
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.16)",
            borderRadius: "10px",
            marginTop: "20px",
            padding: "30px",
            width: "100%",
          }}
        >

          <Row
            className="CouponHeading">
            <p >
              Coupon Validity
            </p>
          </Row>
          <div className="formDiv">

            <Row>


              <Col md={6} sm={12} xs={12} style={{paddingBottom:'10px'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={fromvalue}
                    onChange={(newValue) => {
                      setFromValue(newValue);
                      formik.setFieldValue('fromtime', newValue)
                    }}
                    label="Start Time"
                    renderInput={(props) => <TextField
                      onKeyDown={onKeyDown}
                      {...props} value={formik.values.fromtime}
                      onChange={formik.handleChange}
                      error={formik.touched.fromtime && Boolean(formik.errors.fromtime)}
                      helperText={formik.touched.fromtime && formik.errors.fromtime}
                      className="form-control"
                      name="fromtime" />}

                  />
                </LocalizationProvider>
              </Col>
              <Col md={6} sm={12} xs={12} style={{paddingBottom:'10px'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={tovalue}
                    minDate={formik.values.fromtime}
                    onChange={(newValue) => {
                      setToValue(newValue);
                      formik.setFieldValue('totime', newValue)
                    }}
                    label="End Time"
                    renderInput={(props) => <TextField
                      onKeyDown={onKeyDown}
                      {...props} value={formik.values.totime}
                      onChange={formik.handleChange}
                      error={formik.touched.totime && Boolean(formik.errors.totime)}
                      helperText={formik.touched.totime && formik.errors.totime}
                      className="form-control"
                      name="totime" />}

                  />
                </LocalizationProvider>
              </Col>
            </Row>
          </div>
        </Container>
        <Row className="centerDiv">
       {   disablebutton===true?
        <button  className="disable-couponButton">
        Create Coupon
      </button>
      :
       <button type='submit' className="couponButton">
            Create Coupon
          </button>}
        </Row>
      </form>
    </>
  )
}