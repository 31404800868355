import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { InputAdornment } from "../../MUIComponents/index";

const CustomTextField = ({
  width,
  height,
  placeholder,
  label,
  required,
  variant,
  type,
  border,
  borderRadius,
  size,
  hiddenLabel,
  background,
  startAdornment,
  multiline,
  rows,
  endAdornment,
  opacity,
  value,
  onChange,
  name,
  padding,
  match,
  inputRef,
  disabled
  
}) => {
  // const [value, setValue] = React.useState("");

  // const handleChange = (event) => {
    
  //   setValue(event.target.value);
  // };

  return (
    <TextField
      style={{ width, height, border, borderRadius, background, opacity,padding }}
      name={name}
      value={value}
      hiddenLabel={hiddenLabel}
      multiline={multiline}
      rows={rows}
      label={label}
      placeholder={placeholder}
      variant={variant}
      type={type}
      size={size}
      inputRef={inputRef}
      onChange={onChange}
      disabled={disabled}
      InputProps={{
        sx: {
          fontSize: match && '13px', // Adjust font sizes accordingly
        },
        startAdornment: (
          <InputAdornment position="start">
            {startAdornment} {/* Render startAdornment if provided */}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {endAdornment} {/* Render startAdornment if provided */}
          </InputAdornment>
        ),
      }}
    />
  );
};

CustomTextField.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  hiddenLabel: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  required: PropTypes.bool,
  border: PropTypes.string.isRequired,
  borderRadius: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  startAdornment: PropTypes.element,
  endAdornment: PropTypes.element,
  opacity: PropTypes.string.isRequired,
  value:PropTypes.string,
  onChange:PropTypes.func,
  name:PropTypes.string,
  padding:PropTypes.string.isRequired,
  name:PropTypes.string.isRequired,
  value:PropTypes.string.isRequired,
  onChange:PropTypes.func,
  match:PropTypes.bool,
  disabled:PropTypes.bool,
  inputRef:PropTypes.string
};

export default CustomTextField;
