import React from "react";
import {

    DESCRIPTION,
    DESCRIPTION_2,
} from "./constants";
import LoginForm from "../LoginForm";
import { SHOP } from '../../utils/image'
import "./index.css";
import { Row, Col } from "react-bootstrap";

const HomeBanner = (props) => {


    return (
        <div className="banner">
            <Row className="banner-content">
            <Col md={2}/>
                <Col md={4} style={{width:'100%'}}>
                    <img src={SHOP} style={{ width: '250px', height: '55px',position: 'relative',left: '10px' }} alt="shop and deliver.png" />
                    <div className="description">
                        {DESCRIPTION}
                        <div>{DESCRIPTION_2}</div>
                    </div>
                </Col>
                <Col md={4} style={{width:'100%'}}>
                    <LoginForm {...props} />
                </Col>
                <Col md={2}/>
            </Row>

        </div>
    );
};
export default HomeBanner;
