import React from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import Customerdetails from "../OrderDetailsAssets/Customerdetails";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor:'#ebedef'
  }));

const CustomerDetails = (props) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={3}
        style={{ padding: "20px", justifyContent: "center" }}
      >
          <Grid item xs={12} md={12}>
          <Item>
              <Customerdetails {...props}/>
              </Item>
              </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerDetails;
