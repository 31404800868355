import client from "../../../Configurations/apollo";
import { ApolloClient } from "@apollo/client";
import { InMemoryCache } from "@apollo/client";

import {
	CHANGE_ORDER_STATUS_TO_COMPLETED,
	CHANGE_ORDER_STATUS_TO_DELIVERY,
	CHANGE_ORDER_STATUS_TO_READY,
	CHANGE_TO_ACTIVE_STATUS,
	NOTIFICATION_STATUS,
	ORDER_NEW_UPLOAD
} from "./gqlQuery";

// 
// Client for PUSH NOTIFICATION
const customClient = new ApolloClient({
	uri: `${process.env.REACT_APP_SCHEDULE_ALERT_URL}`,
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: "no-cache",
			errorPolicy: "ignore",
		},
		query: {
			fetchPolicy: "no-cache",
			errorPolicy: "all",
		},
	},
  });


const token = localStorage.getItem('token');

export const scheduleAlert = (params) => {
	return (dispatch) => {
		dispatch({
			type: "SCHEDULING_ALERT",
		});
		customClient
			.mutate({
				mutation: NOTIFICATION_STATUS,
				variables: {
					storeid:params.storeid,
					orderid:params.orderid,
					status:params.status,
					deliveryDate:`${params.deliveryDate}`,
					deliveryTime:`${params.deliveryTime}`,
					orderNumber:Number(params.orderNumber),
					shopassistantId:params.shopassistantId
		},	
			})
			.then((res) => {
				if (res) {	
					dispatch({
						type: "SCHEDULING_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "SCHEDULING_DATA_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "SCHEDULING_FAILURE",
					payload: err.message,
				});
			});
	};

}
export const ChangetoActiveStatus = (params1,params2) => { 
	return (dispatch) => {
		dispatch({
			type: "CHANGE_TO_ACTIVE",
		});
		client.mutate({
				mutation: CHANGE_TO_ACTIVE_STATUS,
				variables: {
					orderid:params1,
					preparationTime: Number(params2)
		},	
			})
			.then((res) => {
				if (res) {	
					dispatch({
						type: "CHANGE_TO_ACTIVE_STATUS_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "CHANGE_TO_ACTIVE_STATUS_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "CHANGE_TO_ACTIVE_STATUS_FAILURE",
					payload: err.message,
				});
			});
	};

}
export const ChangetoReadyStatus = (params1,params2) => {
	return (dispatch) => {
		dispatch({
			type: "CHANGE_TO_READY",
		});
		client
			.mutate({
				mutation: CHANGE_ORDER_STATUS_TO_READY,
				variables: {
					orderid:params1,
					products:params2
		},	
			})
			.then((res) => {
				if (res) {	
					dispatch({
						type: "CHANGE_ORDER_STATUS_TO_READY_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "CHANGE_ORDER_STATUS_TO_READY_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				console.log(err); 
				dispatch({
					type: "CHANGE_ORDER_STATUS_TO_READY_FAILURE",
					payload: err.message,
				});
			});
			
	};

}

export const changeToOutForDelivery = (params1,params2) => {
	return (dispatch) => {
		dispatch({
			type: "CHANGE_TO_DELIVERY",
		});
		client
			.mutate({
				mutation: CHANGE_ORDER_STATUS_TO_DELIVERY,
				variables: {
					orderid:params1,
					packingImages:params2
		},	
			})
			.then((res) => {
				if (res) {	
					dispatch({
						type: "CHANGE_ORDER_STATUS_TO_DELIVERY_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "CHANGE_ORDER_STATUS_TO_DELIVERY_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "CHANGE_ORDER_STATUS_TO_DELIVERY_FAILURE",
					payload: err.message,
				});
			});
	};

}
export const ChangetoCompletedStatus = (params) => {
	return (dispatch) => {
		dispatch({
			type: "CHANGE_TO_COMPLETED",
		});
		client
			.mutate({
				mutation: CHANGE_ORDER_STATUS_TO_COMPLETED,
				variables: {
					orderid:params.orderid,
					stats:params.stats
		},	
			})
			.then((res) => {
				if (res) {	
					dispatch({
						type: "CHANGE_ORDER_STATUS_TO_COMPLETED_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "CHANGE_ORDER_STATUS_TO_COMPLETED_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "CHANGE_ORDER_STATUS_TO_COMPLETED_FAILURE",
					payload: err.message,
				});
			});
	};

}
export const uploadOrderImage = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_ORDERIMAGE_UPLOAD",
		});
		client
			.mutate({
				mutation: ORDER_NEW_UPLOAD,
				variables: {
					file: Imgdata,
					type:3,
					size:512,
					filename:params.filename,
					orderid:localStorage.getItem('Rid')
				},
			})
			.then((res) => { 
				if (res.data) {
					// localStorage.clear('imageUrl')
					localStorage.setItem('imageUrl',res.data&&res.data.imageEditAndUpload
					&&res.data.imageEditAndUpload.Location)
					dispatch({
						type: "ORDERIMAGE_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "ORDERIMAGE_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "ORDERIMAGE_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};
