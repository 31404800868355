import {
  DialogContent,
  DialogTitle,
  Checkbox,
  Grid,
  Typography,
  DialogContentText,
  DialogActions,
  useTheme,
  useMediaQuery,
} from "../MUIComponents/index";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "./ActiveOrder.css";
import Direction from "../Utilities/Icon/Direction.svg";
import Phone from "../Utilities/Icon/Phone.svg";
import Pointer from "../Utilities/Icon/Pointer.svg";
import BlueClock from "../Utilities/Icon/BlueClock.svg";
import ActiveOrderLine from "../Utilities/Icon/ActiveOrderLine.svg";
import CustomButton from "../CommonComponents/button";

import CustomDialog from "../CommonComponents/dialog";
import LineInReadyForDel from "../Utilities/Icon/LineInReadyForDel.svg";
import Food from "../Utilities/Icon/Food.svg";
import LineInDialog from "../Utilities/Icon/LineInDialog.svg";
import CustomCard from "../CommonComponents/card";
import {
  contentFontSize_16,
  contentFontSize_20,
  contentFontsize_14,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_20_xs_18,
  contentFontsize_14_xs_10,
  contentFontsize_17,
  ButtonFontSize_18,
  contentFontSize_18,
} from "../Typography";
import ReadyOrder from "./ReadyOrder";

import LoaderActive from "./Loader/LoaderActive";
// import { CheckBox } from "@mui/icons-material";
function ActiveOrder(props) {
  const location = useLocation();
  // const orderid = location.state.orderid;

  // console.log("orderId:", orderid);
  const orderid = localStorage.getItem('orderID')
  // if (orderId) {
  //   // If orderId is truthy, call props.getSearchDetails(orderId)
  //   props.getSearchDetails(orderId);
  //   console.log("two", props.getSearchDetails(orderId)); // This line might be unnecessary
  // } else {
  //   // If orderId is falsy, set orderid to localStorage.getItem("id") and call props.getSearchDetails(orderid)
  //   const orderid = localStorage.getItem("id"); // Assuming orderid is a variable, not a prop
  //   props.getSearchDetails(orderid);
  // }
  useEffect(() => {
    props.getOrderDetails(orderid);
  }, []);
  const activeOrderDetails = props?.orderData;
  console.log("activeOrderDetails", activeOrderDetails);

  // const { orderId } = useParams();
  // const nav = useNavigate();
  function handleClickOne() {
    //   nav("/home/MyOrders/ActiveOrders");
    localStorage.setItem("orderCardDetails", JSON.stringify(ActiveOrderData));
  }
  function handleClickTwo() {
    //   nav("/home/MyOrders");
  }

  const ActiveOrderData =
    JSON.parse(localStorage.getItem("orderCardDetails")) || [];
  console.log("datas", JSON.parse(localStorage.getItem("orderCardDetails")));
  const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [uncheckedItems, setUncheckedItems] = useState([]);
  useEffect(() => {
    const products = activeOrderDetails?.products;
    setCheckedItems(products);
  }, [activeOrderDetails?.products]);
  console.log("hehe", checkedItems);
  console.log("huhu", uncheckedItems);
  const handleCheckboxChange = (item) => {
    const isChecked = checkedItems.some(
      (checkedItem) => checkedItem.productid.id === item.productid.id
    );
  
    if (isChecked) {
      // If the item is checked, uncheck it
      setCheckedItems(
        checkedItems.filter((checkedItem) => checkedItem.productid.id !== item.productid.id)
      );
      setUncheckedItems([...uncheckedItems, item]);
    } else {
      // If the item is unchecked, check it
      setUncheckedItems(
        uncheckedItems.filter((uncheckedItem) => uncheckedItem.productid.id !== item.productid.id)
      );
      setCheckedItems([...checkedItems, item]);
    }
  };
  
  const [active, setActive] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const handleQuantity = (item) => {
    setActive("");
    setSelectedItem(item); // Set the selected item for the dialog
    setOpen(true);
    setCounter(item.quantity);
    console.log("item", item.quantity);
  };

  function handleClose() {
    setOpen(false);
    // setActive("cancel")
    setSelectedItem(null);
  }

  // const increment = () => {
  //   setCounter((counter) => counter + 1);
  // };

  const decrement = () => {
    if (counter > 0) {
      setCounter((counter) => counter - 1);
    }
  };
  const [updatedOrder, setUpdatedOrder] = useState([]);

  const [updatedItems, setUpdatedItem] = useState([]);

  function handleChange(selectedItem) {
    setActive("change");
    setOpen(false);

    const updatedItemCopy = [...updatedItems]; // Make a copy of the current state

    // Check if the selected item already exists in updatedItem
    const index = updatedItemCopy.findIndex(
      (item) => item.productid.id === selectedItem.productid.id
    );

    if (index !== -1) {
      // If the counter is 0, remove the item from updatedItem
      if (counter === 0) {
        updatedItemCopy.splice(index, 1);
      } else {
        // If the item already exists in updatedItem, update its quantity
        updatedItemCopy[index].quantity = counter;
      }
    } else {
      // If the item doesn't exist in updatedItem, add it with the updated quantity
      updatedItemCopy.push({ ...selectedItem, quantity: counter });
    }

    // Update the state with the new value
    setUpdatedItem(updatedItemCopy);

    // Store the updated data in localStorage
    localStorage.setItem("updatedItem", JSON.stringify(updatedItemCopy));
  }

  // You can access updatedItem anywhere within the component
  console.log("Updated itemsss:", updatedItems);

  // Merge items and updatedItems arrays and filter out duplicate product details
  const mergedItems = [
    ...items.filter(
      (item) =>
        !updatedItems.some(
          (updatedItem) => updatedItem.productid.id === item.productid.id
        )
    ),
    ...updatedItems,
  ];

  console.log("m", mergedItems);

  const extractedProducts = activeOrderDetails?.products?.map((every) => {
    const productid = every.productid?.id;
    const productPrice = every?.productPrice;
    const shopAssistantQuantity = every?.quantity;
    const status = 2;

    return { productid, productPrice, shopAssistantQuantity, status };
  });

  console.log("extra", extractedProducts);
  const [updatedProducts, setUpdatedProducts] = useState([]);
  const [counter, setCounter] = useState(0); // Initialize counter with 0
  const [tick, setTick] = useState(false);
  const result = mergedItems?.map((item) => {
    const matchingOrderDetails = items?.find(
      (orderDetail) => orderDetail?.productid.id === item?.productid.id
    );

    const productid = item.productid.id;
    const productPrice = item.productPrice;

    console.log("matchingOrderDetail:", matchingOrderDetails);
    console.log(
      "matchingOrderDetail.quantity:",
      matchingOrderDetails?.quantity
    );
    console.log("item.quantity:", item?.quantity);

    //const status = item.quantity === (matchingOrderDetails?.quantity ?? item?.quantity) ? 2 : 1;
    if (
      item.quantity === 0 &&
      !uncheckedItems.some(
        (uncheckedItem) => uncheckedItem.productid.id === item.productid.id
      )
    ) {
      setTick(true);
      setUncheckedItems([
        ...uncheckedItems,
        {
          ...item,
          quantity: matchingOrderDetails?.quantity ?? 0,
        },
      ]);
      setCheckedItems(
        checkedItems.filter(
          (checkedItem) => checkedItem.productid.id !== item.productid.id
        )
      );
    }
    const shopAssistantQuantity =
      counter === (matchingOrderDetails?.quantity ?? item?.quantity)
        ? matchingOrderDetails?.quantity ?? item?.quantity
        : item?.quantity;
    const status = matchingOrderDetails?.quantity === item?.quantity ? 2 : 1;

    console.log("status:", status);

    return {
      productid: productid,
      productPrice: productPrice,
      status: status,
      shopAssistantQuantity: shopAssistantQuantity,
    };
  });

  const unselectedItems = uncheckedItems.map((item) => {
    const productid = item.productid.id;
    const productPrice = item.productPrice;
    const shopAssistantQuantity = 0;
    const status = 3;

    return {
      productid: productid,
      productPrice: productPrice,
      status: status,
      shopAssistantQuantity: shopAssistantQuantity,
    };
  });
  console.log("Un", unselectedItems);

  const combinedItems = [];

  result.forEach((resultItem) => {
    const matchingUnselectedItem = unselectedItems.find(
      (unselectedItem) => unselectedItem.productid === resultItem.productid
    );

    if (matchingUnselectedItem) {
      combinedItems.push({
        ...resultItem,
        shopAssistantQuantity: matchingUnselectedItem.shopAssistantQuantity,
        status: matchingUnselectedItem.status,
      });
    } else {
      combinedItems.push(resultItem);
    }
  });

  // Add unselectedItems that are not present in result
  unselectedItems.forEach((unselectedItem) => {
    const matchingResultItem = result.find(
      (resultItem) => resultItem.productid === unselectedItem.productid
    );

    if (!matchingResultItem) {
      combinedItems.push(unselectedItem);
    }
  });

  console.log("combined", combinedItems);

  const combinedProducts = [];

  extractedProducts?.forEach((extractedProduct) => {
    const matchingCombinedItem = combinedItems?.find(
      (combinedItem) => combinedItem.productid === extractedProduct.productid
    );

    if (matchingCombinedItem) {
      combinedProducts?.push({
        ...matchingCombinedItem,
      });
    } else {
      combinedProducts?.push(extractedProduct);
    }
  });

  // Add combinedItems that are not present in extractedProducts
  combinedItems?.forEach((combinedItem) => {
    const matchingExtractedProduct = extractedProducts.find(
      (extractedProduct) =>
        extractedProduct.productid === combinedItem.productid
    );

    if (!matchingExtractedProduct) {
      combinedProducts.push(combinedItem);
    }
  });

  console.log("c", combinedProducts);

  // const unselectedItems = extractedProducts?.filter((item) => {
  //   // Check if item.productid is defined before accessing its id property
  //   return !items?.some((mergedItem) => {
  //     return mergedItem.productid.id === item.productid;
  //   });
  // });

  // // Remove selected items from unselectedItems
  // const selectedIds = items.map((item) => item.productid);
  // const filteredUnselectedItems = unselectedItems?.filter(
  //   (item) => !selectedIds.includes(item.productid)
  // );

  // // Add remaining unselected items to the result with shopAssistantQuantity as 0 and status as 3
  // filteredUnselectedItems?.forEach((item) => {
  //   result.push({
  //     productid: item.productid, // Assuming item.id is correct here
  //     productPrice: item.productPrice,
  //     status: 3,
  //     shopAssistantQuantity: 0,
  //   });
  // });
  console.log("result:", result);

  const products = combinedProducts;
  console.log("p", products);

  //  useEffect(() => {
  //   if (mergedItems && counter !== null && extractedProducts) {
  //     const updatedProductsArray = mergedItems.map((each) => {
  //       const productPrice = each.productPrice;
  //       const productid = each.productid.id;

  //       // Find the corresponding product in the extractedProducts array
  //       const extractedProduct = extractedProducts.find(product => product.productid === productid);

  //       // Calculate shopAssistantQuantity and status based on the counter and extractedProduct
  //       const shopAssistantQuantity = counter === extractedProduct.quantity ? extractedProduct.quantity : each.quantity;
  //       const status = counter === extractedProduct.quantity ? 2 : 1;

  //       // Return an object with the desired properties
  //       return {
  //         productid: productid,
  //         productPrice: productPrice,
  //         status: status,
  //         shopAssistantQuantity: shopAssistantQuantity,
  //       };
  //     });

  //     // Update the state with the new array of objects
  //     setUpdatedProducts(updatedProductsArray);
  //   }
  // }, []); // Add mergedItems, counter, and extractedProducts to dependency array

  //   console.log("user",updatedProducts);
  //   const OrderReadybuttonStateRef = useRef(false);
  //   const [OrderReady, setOrderReady] = useState([]);
  //   // useEffect(()=>{
  //   //   props.ChangetoReadyStatus(orderId,items)
  //   // })
  //   const [products, setProducts] = useState([]);

  console.log("e", extractedProducts);

  console.log("id", products.productid);
  const [loading, setLoading] = useState(false);
  const [showOrderReady, setShowOrderReady] = useState(false);
  function handleOrderReady() {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      // nav("/home/MyOrders/ReadyForDeliveryDetails", {
      //   state: { orderid: orderid },
      // });
      setShowOrderReady(true);
    }, 2000);

    props.ChangetoReadyStatus(orderid, products);
    
  }
  {
    products.map((every) => console.log("e", typeof every.productPrice));
  }
  const detailsActive=( "props",props&&props.ChangetoActive&&props.ChangetoActive.changeOrderStatusToShopping)
    
    console.log("poda3",detailsActive)
  // const selectedProducts = ("props", props && props.ChangetoReadyStatus);
  // console.log("selectedProducts", selectedProducts);
  // console.log("id", orderid);
  
  
  // useEffect(() => {
  //   props.ChangetoReadyStatus(orderid,products)
  // }, []);
  //  const readyOrders= ("props",props&&props.ChangetoReady&&props.ChangetoReady.changeOrderStatusToReady);
  
  // console.log("zzzz",readyOrders)
  
  
   const totalProductPrice = combinedProducts.reduce((total, item) => {
    return active === "change" &&
      combinedProducts &&
      combinedProducts.some(
        (updatedItem) => updatedItem?.productid?.id === item?.productid?.id
      )
      ? total +
          (combinedProducts.find(
            (updatedItem) => updatedItem.productid === item?.productid
          )?.shopAssistantQuantity || 0) *
            (item?.productPrice || 0)
      : total + (item.shopAssistantQuantity || 0) * (item?.productPrice || 0);
  }, 0);

  const deliveryCharge = activeOrderDetails?.deliveryCharge;
  const packingCharge = activeOrderDetails?.packingCharge + activeOrderDetails?.handlingCharge;
  const discountPrice = activeOrderDetails?.discountPrice;

  // Calculate the total charges
  const totalCharges = deliveryCharge + packingCharge - discountPrice;

  // Calculate the grand total
  const grandTotal = totalProductPrice + totalCharges;

  console.log("i", items);
  console.log(counter);
  console.log("order", updatedOrder);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  console.log("item", selectedItem);
  return (
    <div>
      {!loading && !showOrderReady && (
        <>
          <div style={{ display: "flex", gap: "50px" }}>
            <div style={{ width: "55%" }}>
              <div style={{ marginTop: "0px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "30px",
                      }}
                    >
                      <div style={{ display: "flex", gap: "5px" }}>
                        <img
                          src={Direction}
                          style={{ width: "20px", height: "20px" }}
                          alt="Direction Icon"
                        />

                        <div>
                          <p
                            className="nameStyle"
                            style={{ marginBottom: "0px", color: "black" }}
                          >
                            {" "}
                            {activeOrderDetails &&
                              activeOrderDetails.customerName}
                          </p>
                        </div>
                      </div>

                      <div>
                        <p
                          style={{
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            textAlign: "left",
                            color: "black",
                            marginBottom: "0px",
                          }}
                        >
                          {activeOrderDetails &&
                            activeOrderDetails?.deliveryAddress}
                          ,
                          {activeOrderDetails &&
                            activeOrderDetails?.deliveryDate}
                          ,
                        </p>
                      </div>
                      <div>
                        <p
                          style={{
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            color: "black",
                            textAlign: "left",
                          }}
                        >
                          {activeOrderDetails &&
                            activeOrderDetails.deliveryTime}
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        justifyContent: smmatch ? "flex-start" : "flex-end",
                        height: "100%",
                        marginTop: "50px",
                      }}
                    >
                      <img
                        src={Phone}
                        style={{ width: "20px", height: "20px" }}
                        alt="Phone icon"
                      />
                      <span>
                        <p className="numberStyle1" style={{ color: "black" }}>
                          +91 {activeOrderDetails?.userid?.phoneNumber}
                        </p>
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="activeorderBox">
                <span>
                  <p fontSize={contentFontsize_17} className="textStyleActive">
                    #{activeOrderDetails && activeOrderDetails.orderNumber} -
                    Scheduled on{" "}
                    {activeOrderDetails && activeOrderDetails.deliveryDate},{" "}
                    {activeOrderDetails && activeOrderDetails.deliveryTime}
                  </p>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                  marginTop: "20px",
                  padding: smmatch ? "10px 10px" : "0px 50px 0px 0px",
                }}
              >
                {activeOrderDetails &&
                  activeOrderDetails?.products?.map((item) => (
                    <>
                      <div style={{ display: "flex" }}>
                        {console.log("item vannu", item)} 
                        <div style={{ flex: "1" }}>
                          <div style={{ display: "flex", gap: "40px" }}>
                            <div>
                            <Checkbox
    checked={
      uncheckedItems.some(
        (uncheckedItem) => uncheckedItem.productid.id === item.productid.id
      )
        ? false
        : true
    }
    onChange={() => handleCheckboxChange(item)}
    disabled={uncheckedItems.some(
      (uncheckedItem) => uncheckedItem.productid.id === item.productid.id
    )}
    sx={{
      color: "#659B1B",
      '&.Mui-checked': {
        color: "#659B1B",
      },
    }}
  />
                            </div>

                            <div>
                              <p
                                fontSize={contentFontsize_17}
                                className="productDetail"
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "0px",
                                  textAlign: "left",
                                  color: uncheckedItems.some(
                                    (selectedItem) =>
                                      selectedItem?.productid?.id ===
                                      item?.productid?.id
                                  )
                                    ? "rgba(0, 0, 0, 0.5)"
                                    : "black",
                                }}
                              >
                                {item.productid.productname} <br />
                                {/* Render updated quantity if it exists */}
                                {updatedItems &&
                                  updatedItems.map((updatedItem, index) => {
                                    if (
                                      item?.productid?.id ===
                                        updatedItem?.productid?.id &&
                                      item.quantity !== updatedItem.quantity
                                    ) {
                                      return (
                                        <p
                                          key={index}
                                          fontSize={contentFontsize_14}
                                          style={{
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            marginBottom: "0px",
                                            textAlign: "left",
                                            color: uncheckedItems.some(
                                              (selectedItem) =>
                                                selectedItem?.productid?.id ===
                                                item?.productid?.id
                                            )
                                              ? "rgba(0, 0, 0, 0.5)"
                                              : "black",
                                          }}
                                        >
                                          {updatedItem.quantity === 0 ? (
                                        <span
                                          style={{
                                            color: "rgba(0, 0, 0, 0.5)",
                                            fontFamily: "Roboto",
                                          }}
                                        >
                                          {`(${
                                            updatedItem.quantity
                                          } X ${updatedItem.productPrice.toFixed(
                                            2
                                          )})`}
                                        </span>
                                      ) : (
                                        <>
                                          <span
                                            style={{
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            {item.quantity} X{" "}
                                            {item.productPrice.toFixed(2)}
                                          </span>
                                          <span
                                            style={{
                                              color: "rgba(234, 67, 53, 1)",
                                              fontFamily: "Roboto",
                                            }}
                                          >
                                            {` (${
                                              updatedItem.quantity
                                            } X ${updatedItem.productPrice.toFixed(
                                              2
                                            )})`}
                                          </span>
                                        </>
                                      )}
                                        </p>
                                      );
                                    }

                                    return null;
                                  })}
                                {/* Render original quantity if updated quantity doesn't exist */}
                                {!updatedItems.some(
                                  (updatedItem) =>
                                    updatedItem?.productid?.id ===
                                    item.productid?.id
                                ) && (
                                  <p
                                    fontSize={contentFontsize_14}
                                    style={{
                                      fontWeight: "400",
                                      fontFamily: "Roboto",
                                      marginBottom: "0px",
                                      textAlign: "left",
                                      color: uncheckedItems.some(
                                        (selectedItem) =>
                                          selectedItem?.productid?.id ===
                                          item?.productid?.id
                                      )
                                        ? "rgba(0, 0, 0, 0.5)"
                                        : "black",
                                    }}
                                  >
                                    {" "}
                                    {item.quantity !== undefined
                                      ? `${
                                          item.quantity
                                        } X ${item.productPrice.toFixed(2)}`
                                      : `${counter} X ${item.productPrice.toFixed(
                                          2
                                        )}`}
                                  </p>
                                )}
                                {updatedItems &&
                                  updatedItems.map((updatedItem, index) => {
                                    if (
                                      item?.productid?.id ===
                                        updatedItem?.productid?.id &&
                                      item.quantity === updatedItem.quantity
                                    ) {
                                      return (
                                        <p
                                          key={index}
                                          fontSize={contentFontsize_14}
                                          style={{
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          {`${
                                            item.quantity
                                          } X ${item.productPrice.toFixed(2)}`}
                                        </p>
                                      );
                                    }

                                    return null;
                                  })}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div style={{ flex: "1" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1px",
                              }}
                            >
                              {/* Calculate price based on updated quantity if active is "change", otherwise use original quantity */}
                              <p
                                fontSize={contentFontsize_17}
                                className="productDetail"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginBottom: "0px",
                                  marginRight: "0px",
                                  marginTop: "0px",
                                  color: uncheckedItems.some(
                                    (selectedItem) =>
                                      selectedItem?.productid?.id ===
                                      item?.productid?.id
                                  )
                                    ? "rgba(0, 0, 0, 0.5)"
                                    : "black",
                                }}
                              >
                                ₹{" "}
                                {active === "change" &&
                                updatedItems &&
                                updatedItems.some(
                                  (updatedItem) =>
                                    updatedItem?.productid?.id ===
                                    item?.productid?.id
                                )
                                  ? (
                                      (updatedItems.find(
                                        (updatedItem) =>
                                          updatedItem?.productid
                                            ?.productname ===
                                          item.productid.productname
                                      )?.quantity || 0) *
                                      (item?.productPrice || 0)
                                    ).toFixed(2)
                                  : (
                                      (item.quantity || 0) *
                                      (item?.productPrice || 0)
                                    ).toFixed(2)}
                              </p>
                              <p
                                fontSize={contentFontsize_14_xs_10}
                                className="itemDetails"
                                onClick={() => handleQuantity(item)}
                                style={{
                                  cursor: "pointer",
                                  marginBottom: "0px",
                                  marginRight: "0px",

                                  color: uncheckedItems.some(
                                    (selectedItem) =>
                                      selectedItem?.productid?.id ===
                                      item?.productid?.id
                                  )
                                    ? "rgba(0, 0, 0, 0.5)"
                                    : "#85BE49",
                                }}
                              >
                                Change Qty
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}

                {/* {end} */}

                {open && (
                  <CustomDialog
                    open={open}
                    onClick={handleClose}
                    width="450px"
                    height="500px"
                    showCloseButton={false}
                    borderRadius="10px"
                  >
                    <DialogTitle
                      sx={{
                        color: "#85BE49",
                        display: "flex",
                        justifyContent: "center",
                        padding:"5px 24px"
                      }}
                    >
                      Change Quantity
                    </DialogTitle>
                    <img style={{ width: "300px" }} src={LineInDialog} />
                    <DialogContent
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <CustomCard
                        padding="2px"
                        width={"70px"}
                        height={"70px"}
                        background="#F2F2F2"
                        boxShadow="none"
                        borderRadius="10px"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // padding:"6px"
                          }}
                        >
                          <img
                            src={Food}
                            alt="Beverages"
                            style={{ marginTop: "9px" }}
                          />
                        </div>
                      </CustomCard>
                      <div style={{ marginLeft: "10px" }}>
                        <DialogContentText>
                          <p
                            style={{
                              fontWeight: "500",
                              fontFamily: "Roboto",
                              color: "black",
                              marginBottom: "0px",
                            }}
                          >
                            {selectedItem?.productid.productname}
                          </p>
                        </DialogContentText>
                      </div>

                      <>
                        <div className="counter-container">
                          <div className="counter-button" >
                            +
                          </div>
                          <span className="counter-value">{counter}</span>
                          <div className="counter-button" onClick={decrement}>
                            -
                          </div>
                        </div>
                      </>
                    </DialogContent>
                    
                      <div style={{display:"flex",justifyContent:"center",gap:"20px",alignItems:"center",marginTop:"20px"}}>
                        <div>
                      <CustomButton
                        onClick={handleClose}
                        width="160px"
                        height="42px"
                        variant={active === "cancel" ? "contained" : "outlined"}
                        background={
                          active === "cancel" ? "#659B1B" : "transparent"
                        }
                        color={active === "cancel" ? "white" : "#659B1B"}
                        borderRadius="25px"
                        borderColor="#659B1B"
                      >
                        <p style={{textTransform:"capitalize",marginBottom:"0px"}}>
                        Cancel
                        </p>
                      </CustomButton>
                      </div>
                      <div>
                      <CustomButton
                        type="submit"
                        onClick={() => handleChange(selectedItem)}
                        width="160px"
                        height="42px"
                        variant={active === "change" ? "contained" : "outlined"}
                        background={
                          active === "change" ? "#659B1B" : "transparent"
                        }
                        color={active === "change" ? "white" : "#659B1B"}
                        borderRadius="25px"
                        borderColor="#659B1B"
                      >
                        <p style={{textTransform:"capitalize",marginBottom:"0px"}}>
                        Change
                        </p>
                      </CustomButton>
                      </div>
                      </div>
                   
                  </CustomDialog>
                )}
              </div>

              {/* {end} */}

              <Grid container>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <img
                    style={{ width: "100%" }}
                    src={LineInReadyForDel}
                    alt="LineInReadyForDel"
                  />
                </Grid>
              </Grid>

              {/* {start} */}
              <div
                style={{ padding: smmatch ? "10px 10px" : "0px 50px 0px 75px" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p fontSize={contentFontSize_16} className="productDetails1">
                    Total Product price
                  </p>
                  {/* </Grid> */}
                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>  */}
                  <p className="productDetails1" fontSize={contentFontSize_16}>
                    ₹{" "}{totalProductPrice?.toFixed(2)}
                  </p>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p fontSize={contentFontSize_16} className="productDetails1">
                    Delivery Charge
                  </p>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
                  <p
                    className="productDetails1"
                    fontSize={contentFontSize_16}
                    // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                  >
                    ₹{" "} {activeOrderDetails?.deliveryCharge?.toFixed(2)}
                  </p>
                </div>

                {activeOrderDetails?.discountPrice ? ( 
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    fontSize={contentFontSize_16}
                    style={{ color: "rgba(45, 147, 251, 1)" }}
                    className="productDetails1"
                  >
                    Coupon - ({activeOrderDetails?.couponID?.couponCode})
                  </p>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                  <p
                    className="productDetails1"
                    style={{ color: "rgba(45, 147, 251, 1)" }}
                    fontSize={contentFontSize_16}
                  >
                    -₹{" "}{activeOrderDetails?.discountPrice?.toFixed(2)}
                  </p>
                </div>
                  ):(null)}

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    fontSize={contentFontSize_16}
                    className="productDetails1"
                    style={{ marginBottom: "10px" }}
                  >
                    Packing Charges
                  </p>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                  <p
                    className="productDetails1"
                    fontSize={contentFontSize_16}
                    // sx={{marginLeft:"22px"}}
                    style={{ marginBottom: "10px" }}
                  >
                    ₹{" "}{packingCharge?.toFixed(2)}
                  </p>
                </div>
              </div>
              {/* </Grid>
                </Grid> */}
              <Grid container>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <img
                    style={{ width: "100%" }}
                    src={LineInReadyForDel}
                    alt="LineInReadyForDel"
                  />
                </Grid>
              </Grid>

              <div
                style={{ padding: smmatch ? "10px 10px" : "0px 50px 0px 75px" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    fontSize={contentFontSize_20_xs_18}
                    style={{
                      fontWeight: "700",
                      fontFamily: "Roboto",
                      color: "black",
                      marginTop: "15px",
                    }}
                  >
                    Total
                  </p>
                  {/* </Grid>
  
                      <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                  <p
                    style={{
                      fontWeight: "700",
                      fontFamily: "Roboto",
                      color: "black",
                      marginTop: "15px",
                    }}
                    fontSize={contentFontSize_16}
                  >
                    ₹{" "}{grandTotal?.toFixed(2)}
                  </p>
                </div>
              </div>

              <Grid container>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <img
                    style={{ width: "100%" }}
                    src={LineInReadyForDel}
                    alt="LineInReadyForDel"
                  />
                </Grid>
              </Grid>
              {activeOrderDetails.paymentStatus === 0 ?(
              <div
                style={{
                  marginTop: "20px",
                  marginLeft: smmatch ? "0px" : "0px",
                  textAlign: "left",
                }}
              >
                <p style={{ color: "#1D89E1" }}>
                  <img
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "20px",
                    }}
                    src={BlueClock}
                  />
                  Waiting for Payment
                </p>
              </div>
              ):(
                <div
                style={{
                  marginTop: "20px",
                  marginLeft: smmatch ? "0px" : "0px",
                  textAlign: "left",
                }}
              >
                <p style={{ color: "#1D89E1" }}>
                  <img
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "20px",
                    }}
                    src={BlueClock}
                  />
                  Payment Completed
                </p>
              </div> 
              )}
            </div> 
                  
            <div style={{ marginTop: "200px" }}>
              <div className="timeline-containerActive">
              {(detailsActive
                    ? detailsActive.stats
                    : activeOrderDetails.stats
                  ).map((each, index) => (
                    <div key={index} className="timeline-entryActive">
                      <div className="timeline-markerOut">
                        <div className="dotActive"></div>
                        {index <
                          (detailsActive 
                            ? detailsActive.stats.length
                            : activeOrderDetails.stats.length) -
                            1 && <div className="connecting-lineActive"></div>}
                      </div>
                      <div className="timeline-contentActive">
                        <div>
                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "800",
                              fontFamily: "Roboto",
                              color: "#000000",
                            }}
                          >
                            {each.status}
                          </p>
                          {each?.status === "Order-Placed" && (
                            <p
                              style={{
                                color: "grey",
                                fontSize: "14px",
                                marginTop: "10px",
                              }}
                            >
                              Scheduled for {activeOrderDetails.deliveryDate},{" "}
                            {activeOrderDetails.deliveryTime}
                              {/* {each?.created}, {each?.createdTime} */}
                            </p>
                          )}
                        </div>
                        <div className="detailsActive">
                          <p
                            style={{
                              fontSize: "15px",
                              fontWeight: "400",
                              fontFamily: "Roboto",
                              color: "#000000",
                              textAlign: "left",
                              width: "100%",
                            }}
                          >
                              {each?.created}, {each?.createdTime}
                            
                          </p>
                        </div>
                        {each.status === "Order-Accepted" &&
                          detailsActive?.shopAssistantId?.firstName && (
                            <div
                              className="shop-assistant"
                              style={{
                                fontSize: "16px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                                color: "#000000",
                                textAlign: "left",
                                width: "100%",
                              }}
                            >
                              Shop Assistant:{" "}
                              {detailsActive?.shopAssistantId?.firstName}
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            
          </div>
          <div className="OrderReadyButton">
                <CustomButton
                  borderRadius="20px"
                  width="30%"
                  height="50px"
                  onClick={handleOrderReady}
                >
                  <p
                    style={{
                      fontWeight: "700",
                      fontFamily: "Roboto",
                      marginBottom: "0px",
                      fontSize: "14px",
                      textTransform:"capitalize"
                    }}
                  >
                    Order Ready
                  </p>
                </CustomButton>
              </div>
        </>
      )}
      {loading && <LoaderActive />}
      {showOrderReady && <ReadyOrder {...props}  />}
    </div>
  );
}

export default ActiveOrder;
