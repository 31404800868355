import { gql } from "@apollo/client";


export const NOTIFICATION_STATUS = gql`
 mutation($storeid:ID!,$orderid:ID!,
  $status:String!,$deliveryDate:String!,
  $deliveryTime:String!,$orderNumber:Int!,
  $shopassistantId:ID){
    scheduleAlertOnStatus(storeid:$storeid,orderid:$orderid,
      status:$status,deliveryDate:$deliveryDate,
      deliveryTime:$deliveryTime,
      orderNumber:$orderNumber,
	  shopassistantId:$shopassistantId)
} `
 
export const CHANGE_TO_ACTIVE_STATUS = gql`
mutation($orderid: ID!, $preparationTime: Int!){
 changeOrderStatusToShopping(orderid: $orderid, preparationTime: $preparationTime)
 {   
      id
      orderNumber
      storeid{
        id
        storeName
      }
      products{
         id
         productid{
	        id
	        productname    
        }
     quantity
     shopAssistantQuantity
     price
     status
     productDiscountPrice
     productPrice
    }
    totalPrice
    deliveryType
    GST
    packingCharge
    handlingCharge
    deliveryTip
    total
    totalPayable
    discountPrice
    preparationTime
    shopAssistantId{
        id
        firstName
    }
    deliveryPartnerId{
         id
         firstName
        }
    stats{
          createdTime
          created
          status
          timestamp
        }    
  }
}
`;
export const CHANGE_ORDER_STATUS_TO_READY = gql`
  mutation($orderid: ID!, $products: [ProductsInput]) {
    changeOrderStatusToReady(orderid: $orderid, products: $products) {
      id
      orderNumber
      storeid {
        id
        storeName
      }
      products {
        id
        productid {
          id
          productname    
        }
        quantity
        shopAssistantQuantity
        price
        status
        productDiscountPrice
        productPrice
      }
      totalPrice
      deliveryType
      GST
      packingCharge
      handlingCharge
      deliveryTip
      total
      totalPayable
      preparationTime
      specialInstructions
      shopAssistantId {
        id
        firstName
      }
      deliveryPartnerId {
        id
        firstName
      }
      stats{
        createdTime
        created
        status
        timestamp
      }
    }
  }
`;

export const CHANGE_ORDER_STATUS_TO_DELIVERY = gql`
mutation($orderid:ID! ,$packingImages: [String]){
changeToOutForDelivery(orderid: $orderid,packingImages: $packingImages)
{
    id
    orderNumber
    storeid{
      id
      storeName
    }
    products{
      id
      productid{
        id
        productname    
      }
      quantity
      shopAssistantQuantity
      price
      status
      productDiscountPrice
      productPrice
    }
    totalPrice
    deliveryType
    GST
    packingCharge
    handlingCharge
    deliveryTip
    total
    totalPayable
    packingImages
    preparationTime
    shopAssistantId{
      id
      firstName
    }
    deliveryPartnerId{
      id
      firstName
    }
    stats{
      createdTime
      created
      status
      timestamp
    }
  }
}	

`;


export const CHANGE_ORDER_STATUS_TO_COMPLETED = gql`
mutation($orderid: ID!, $stats: StatusInput!){
  updateOrderStatus(orderid:$orderid,stats: $stats){
    id
    orderNumber
    storeid{
      id
      storeName
    }
    products{
      id
      productid{
        id
        productname    
      }
      quantity
      shopAssistantQuantity
      price
      status
      productDiscountPrice
      productPrice
    }
    totalPrice
    deliveryType
    GST
    packingCharge
    handlingCharge
    deliveryTip
    total
    totalPayable
    preparationTime
    shopAssistantId{
      id
      firstName
    }
    deliveryPartnerId{
      id
      firstName
    }
    stats{
      createdTime
      created
      status
      timestamp
    }
  }
}

`;
export const ORDER_NEW_UPLOAD =  gql`
mutation($file:Upload,$size:Int,$filename:String,$type:Int,$orderid:String){
  imageEditAndUpload(file:$file,size:$size,filename:$filename,type:$type,orderid:$orderid)
 {
   ETag
   Location
}
}
`;
