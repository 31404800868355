import React from "react";
import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader, Typography, Box } from "@mui/material";
// utils
import { fNumber } from "./formatNumbers";
//
import { BaseOptionChart } from "./Charts";


const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 90;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

export default function OrderCircle(props) {
  const CHART_DATA = [props.dashboardData&&
    props.dashboardData.data&&
    props.dashboardData.data.dashboard&&props.dashboardData.data.dashboard.totalOrdersPlaced,
    props.dashboardData&&
    props.dashboardData.data&&
    props.dashboardData.data.dashboard&&props.dashboardData.data.dashboard.totalOrdersAccepted,
    props.dashboardData&&
    props.dashboardData.data&&
    props.dashboardData.data.dashboard&&props.dashboardData.data.dashboard.totalOrdersRejected,
    props.dashboardData&&
    props.dashboardData.data&&
    props.dashboardData.data.dashboard&&props.dashboardData.data.dashboard.totalOrdersShopping,
    props.dashboardData&&
    props.dashboardData.data&&
    props.dashboardData.data.dashboard&&props.dashboardData.data.dashboard.totalOrdersReady,
    props.dashboardData&&
    props.dashboardData.data&&
    props.dashboardData.data.dashboard&&props.dashboardData.data.dashboard.totalOrdersCompleted];

  const theme = useTheme();

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      '#d500f9',
      '#FFA48D',
      theme.palette.error.main,
      theme.palette.info.main,
      theme.palette.warning.main,
      '#357a38'
    ],
    labels: [
      "Order-Placed",
      "Order-Accepted",
      "Order-Rejected",
      "Shopping-in-Progress",
      "Order-Ready",
      "Order-Completed",
    ],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: "center" },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `#${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
     <Card
      style={{
        // boxShadow: theme.customShadows.z16,
         borderRadius: 12,
        position: "relative",
        zIndex: 0,
      
      }}
    >
      <CardHeader
        style={{
          padding: theme.spacing(3, 3, 0),
          titleTypographyProps: { variant: "h6" },
          subheaderTypographyProps: { variant: "body2" },
        }}
        title="Order Status"
      />
      <ChartWrapperStyle dir="ltr">
        {props.dashboardData&&
        props.dashboardData.data&&
        props.dashboardData.data.dashboard!==null?
        <ReactApexChart
          type="donut"
          series={CHART_DATA}
          options={chartOptions}
          height={280}
        />
        :
        <div>
       {/* <img src={NoOrderChart} alt={'NoOrders'} height={270}/> */}
       <Box style={{alignItems:'center', justifyContent:'center',textAlign:'center',paddingTop:'5px'}}>

          <Typography style={{ fontWeight: 500,
fontSize:'18px'}}  noWrap>
            {'No orders yet !'}
          </Typography>
        </Box>
       </div>
}
      </ChartWrapperStyle>
    </Card>
  );
}
