import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import RetailerForm from "../../Components/RetailerFormNew";
import StoreDetailsNew from "../../Components/StoreDetailsNew";
import AdditionalDetailsEdit from "../../Components/AdditionalDetailsEdit";
import BankDetails from "../../Components/BankDetails";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import MuiAlert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import StepLabel from "@material-ui/core/StepLabel";
import ConfirmationForm from "../../Components/ConfirmationForm";
import TopHeaders from "../../Components/Topheader";
import { ColorlibConnector, ColorlibStepIcon } from "./Colorlib";
import { initialConfiguration, steps } from "./constants";
import Subscription from "../../Components/Subscription";
import Documents from "../../Components/Documents";
import {
	handleFormChange,
	handleTableChange,
	getLocationChange,
	getRadioButtonChange,
	formWrap,
	getCoordinatesMap,
	handleAddToForm,
} from "./helper";
import { styles } from "./style";
import "./index.css";
import { CircularProgress } from "@material-ui/core";


class index extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...initialConfiguration,
			activeStep: 0,
			confirmWindow: false,
		};
	}
	// componentDidUpdate(prevProps){
	// 	if(prevProps.searchDetails.store!==this.props.searchDetails.store)
	// 	{
	// 		this.props.getStoreDetails(localStorage.getItem('storeid'));
	// 	}
	// }
	componentDidMount() {
		this.props.getStoreDetails(localStorage.getItem('storeid'));
		this.props.getRetailer();
			document.body.scrollTop = 0;
	}

	handleNext = () => {
		this.setState({ activeStep: this.state.activeStep + 1 });
	};
	handleBack = () => {
		this.setState({ activeStep: this.state.activeStep - 1 });
	};

	handleLogoUpload = (formdata) => {
		this.props.imageSubmit(formdata);
	};
	handleAlertComplete = () => {
		this.props.closeAlert();
	};
	confirmForm = () => {
		this.setState({
			confirmWindow: !this.state.confirmWindow,
		});
	};
	submitForm = async () => {
		let FormData = await formWrap(this.state, this.props.timings);
		this.props.formSubmit(FormData);
	};
	saveClick = () => {		
		this.props.updatestore(this.props.searchDetails.store);
	};
	render() {
		
		let { activeStep } = this.state;
		const { classes } = this.props;
		let props = {
			...this.props,
			formConfiguration: this.state,
			handleChange: handleFormChange,
			handleLogoUpload: this.handleLogoUpload,
			handleTableChange: handleTableChange,
			getLocationChange: getLocationChange,
			getRadioButtonChange: getRadioButtonChange,
			getCoordinates: getCoordinatesMap,
			handleAddToForm: handleAddToForm,
		};
		const roteHome = () => {
			if (this.props.isSignSuccess) {
				setTimeout(() => {
					this.props.history.push("/");
				}, 5000);
			}
		};

		const getStepContent=(step) =>{
			switch (step) {
				case 0:
					return <RetailerForm {...props} next={this.handleNext}/>;
				case 1:
					return <StoreDetailsNew {...props} next={this.handleNext} back={this.handleBack}/>;
				case 2:
					return <AdditionalDetailsEdit {...props} next={this.handleNext} back={this.handleBack}/>;
				case 3:
					return <BankDetails {...props} next={this.handleNext} back={this.handleBack}/>
				case 4:
					return <Documents {...props} next={this.handleNext} back={this.handleBack}/>
				case 5:
					return <Subscription {...props} next={this.saveClick} back={this.handleBack}/>

				default:
					throw new Error("Unknown step");
			}
		}
		function Alert(props) {
			return <MuiAlert elevation={6} variant="filled" {...props} />;
		}

		return (
			props.searchingstoredetails?
			<CircularProgress/>
			:
			(
		this.state.confirmWindow || this.props.isSignFailes ? (
			<>
				<TopHeaders />
				<div className={classes.root}>
					<Snackbar
						onClose={this.handleAlertComplete}
						style={{ margin: "0px 0px 200px" }}
						open={this.props.isSignFailes}
						autoHideDuration={8000}>
						<Alert onClose={this.handleAlertComplete} severity="error">
							<AlertTitle>SignUp Failed</AlertTitle>
							{this.props.failureMessage ? this.props.failureMessage : null}
						</Alert>
					</Snackbar>
				</div>
				<div className={classes.root}>
					<Snackbar
						onClose={this.handleAlertComplete}
						style={{ margin: "0px 0px 400px" }}
						open={this.props.isSignLoading}
						autoHideDuration={1000}>
						<Alert onClose={this.handleAlertComplete} severity="warning">
							Submitting SignUp
							{ }
						</Alert>
					</Snackbar>
				</div>
				<div className={classes.root}>
					<Snackbar
						onClose={this.handleAlertComplete}
						style={{ margin: "0px 0px 400px" }}
						open={this.props.isSignSuccess}
						autoHideDuration={8000}>
						<Alert onClose={this.handleAlertComplete} severity="success">
							SignUp Success
						</Alert>
					</Snackbar>
				</div>
				{roteHome()}
				<ConfirmationForm
					{...this.state}
					confirmForm={this.confirmForm}
					submitForm={this.submitForm}
				/>
			</>
		) : (
			<>
			<div
          style={{
            backgroundColor: "white",
            transform: "translateY(100px)",
            width: "90%",
            borderRadius: "30px",
            padding:'15px',
            boxShadow:'0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)'
          }}
          position="sticky"
        >
				<Stepper
					alternativeLabel
					connector={<ColorlibConnector />}
					style={{ backgroundColor: "transparent"}}
					activeStep={this.state.activeStep}
					className={classes.stepper}>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel StepIconComponent={ColorlibStepIcon}>
								{label}
							</StepLabel>
						</Step>
					))}
 				</Stepper>
				 <main  className={classes.layout}>
						<React.Fragment>
							{getStepContent(activeStep)}
						</React.Fragment>
					<div className={classes.root}>
					<Snackbar
						onClose={this.handleAlertComplete}
						style={{ margin: "0px 0px 400px", marginLeft: "50px" }}
						open={this.props.isImageUploaded}
						autoHideDuration={1000}>
						<Alert onClose={this.handleAlertComplete} severity="success">
							Uploaded Succefully
						</Alert>
					</Snackbar>
				</div>
				<div className={classes.root}>
					<Snackbar
						onClose={this.handleAlertComplete}
						style={{ margin: "0px 0px 400px" }}
						open={this.props.isImageFailed}
						autoHideDuration={1000}>
						<Alert onClose={this.handleAlertComplete} severity="error">
							Upload Failed
						</Alert>
					</Snackbar>
				</div>
				</main>
				
				 </div>
				 
				{/* <CssBaseline /> */}
				
			</>
		)
		)
		);
	}
}
export default withStyles(styles, { withTheme: true })(index);
