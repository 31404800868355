import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import StorefrontIcon from "@material-ui/icons/Storefront";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import StepConnector from "@material-ui/core/StepConnector";
import { CreditCard as PaymentIcon} from "react-feather";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArticleIcon from '@mui/icons-material/Article';
// import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
// import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";

export const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22,
	},
	active: {
		"& $line": {
			backgroundImage:
				"linear-gradient( 136deg, rgb(4 239 55) 0%, rgb(3 138 7) 50%, rgb(87 214 22) 100%)",
		},
	},
	completed: {
		"& $line": {
			backgroundImage:
				"linear-gradient( 136deg, rgb(4 239 55) 0%, rgb(3 138 7) 50%, rgb(87 214 22) 100%)",
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: "#eaeaf0",
		borderRadius: 1,
	},
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: "#ccc",
		zIndex: 1,
		color: "#fff",
		width: 50,
		height: 50,
		display: "flex",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
	},
	active: {
		backgroundImage:
			"linear-gradient(  136deg,rgb(19 150 48) 0%, rgb(117 194 37) 50%, rgb(87 214 22) 100%)",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
	},
	completed: {
		backgroundImage:
			"linear-gradient( 136deg, rgb(19 150 48) 0%, rgb(117 194 37) 50%, rgb(87 214 22) 100%)",
	},
});

export function ColorlibStepIcon(props) {
	const classes = useColorlibStepIconStyles();
	const { active, completed } = props;

	const icons = {
		1: <AssignmentIndIcon />,
		2: <StorefrontIcon />,
		3: <ShoppingCartIcon />,
		4: <AccountBalanceIcon />,
		5:<ArticleIcon/>,
		6: <PaymentIcon />,
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}>
			{icons[String(props.icon)]}
		</div>
	);
}
