import React, { useState } from "react";
import { useFormik } from "formik";
import { validationSchema } from "./Validation";
import { Row, Col } from "react-bootstrap";
import "./index.css";
import TextField from "@material-ui/core/TextField";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import FileInputDemo from "../file-input/FileInputDemo";
import NewImg from "./ImageCropper/NewImg";
import MultipleImg from "./ImageCropper/MultipleImg";
import MenuItem from "@mui/material/MenuItem";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import NoDepartment from './NoDepartment';
import { useMediaQuery } from 'react-responsive';
import { Container } from "@mui/material";
import client from "../../Configurations/apollo";
import { gql } from "@apollo/client";
import { message } from 'antd';

const SEARCH = gql`
query($productname:String,
  $pagination:PaginationArg){
  viewMasterProducts(productname:$productname,pagination:$pagination){
  count
  items{
  id
  productname
  image{
  primary
  }
  desc
  }
  hasNext
  }
  }
`;


const Add = (props) => {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(false);


  React.useEffect(() => {
    document.body.scrollTop = 0;
    setWordEntered("");
    localStorage.setItem("imageUrl",null)
  },// eslint-disable-next-line react-hooks/exhaustive-deps
    [props.productAdded])
  React.useEffect(() => {
    // setFormikValue();
    // props.setMaster();
    resetFormik();
    props.getDepartmentList();
    localStorage.setItem("imageUrl",null)
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  React.useEffect(() => {
    if (props.MasterProductDetailsSuccess === true)
     { setFormikValue();
      localStorage.setItem("imageUrl",props.MasterProductDetailsData&&
      props.MasterProductDetailsData.data&&props.MasterProductDetailsData.data.viewParticularMasterProduct&&
      props.MasterProductDetailsData.data&&props.MasterProductDetailsData.data.viewParticularMasterProduct.image&&
      props.MasterProductDetailsData.data&&props.MasterProductDetailsData.data.viewParticularMasterProduct.image.primary)}
    else
      resetFormik();
  },// eslint-disable-next-line react-hooks/exhaustive-deps
    [props.MasterProductDetailsSuccess])
  const handleFilter = (event) => {
    setOpen(true)
    const searchWord = event.target.value;
    formik.setFieldValue(
      "Productname",
      searchWord
    );
    setWordEntered(searchWord);
    // setMenuOpen(true);
    setValue(true)
    if (searchWord.length >= 3) {
      client
        .query({
          query: SEARCH,
          variables: {
            productname: `${searchWord}`,
            pagination: { offset: 0, first: 10 },
          },
        })
        .then((res) => {
          if (
            res.data &&
            res.data.viewMasterProducts &&
            res.data.viewMasterProducts !== null
          ) {
            setFilteredData(res.data);
            //  newFilter = res.data.viewMasterProducts.items.map((i) => {
            //   return i.productname.toLowerCase().includes(searchWord.toLowerCase());
            // })
          } else if (res.errors) {
            console.log(res.errors);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(filteredData);
    }
  };

  // const isBigScreen = useMediaQuery({ query: '(min-device-width: 1224px)' })
  // const isBigScreen = useMediaQuery({ query: '(min-device-width: 1224px)' })
  const isSmallScreen = useMediaQuery({ query: '(min-width: 3000px)' })

  document.title = "Admin Portal | Add Products";
  const formik = useFormik({
    initialValues: {
      Productname: "",
      Description: "",
      barcode: "",
      Price: "",
      PromoPrice: "",
      brand: "",
      MfgName: "",
      Quantity: "",
      unit: "",
      displayRank: "",
      status: "",
    },
    validationSchema: validationSchema,

    onReset: (values) => {
      formik.setValues({
        Productname:"",
        Description:"",
        barcode:"",
        Price:"",
        PromoPrice:"",
        brand:"",
        MfgName:"",
        Quantity:"",
        unit:"",
        displayRank:"",
        status: "",
      });
    },
    onSubmit: (values, { resetForm }) => {
      let department = state.department;
      let category = state.salescategory;
      let subcategory = state.salescategory1;
      let category2 = state.salescategory2;
      // localStorage.getItem('imageUrl')
      document.body.scrollTop = 0;
      window.scrollTo(0, 0);
      if (props.fileListLogo === true && props.proImage === null) {
        Modal.confirm({
          title: "Confirm",
          icon: <ExclamationCircleOutlined />,
          content:
            "Please upload the image or delete the image before Submiting.",
          okText: "Ok",
        });
      } else if (props.multiFile === true && props.multiImage === null) {
        Modal.confirm({
          title: "Confirm",
          icon: <ExclamationCircleOutlined />,
          content:
            "Please upload the image or delete the image before Submiting.",
          okText: "Ok",
        });
      }



      else if (localStorage.getItem("imageUrl") === null) {
        if (values.PromoPrice === "") {
          props.AddPrdt(values, department, category, subcategory, category2);
        
          setTimeout(() => {
            resetForm();
            setState({
              department: "",
              salescategory: "",
              salescategory1: "",
              salescategory2: "",
            });
          }, 2000);
        }
        else {
          if (Number(values.PromoPrice) >= Number(values.Price)) {
            message.error({
              content: 'Promo price must be lesser than price',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }
          else {

            if (values.PromoPrice === "") {
              props.AddPrdt(values, department, category, subcategory, category2);
              
              setTimeout(() => {
                resetForm();
                setState({
                  department: "",
                  salescategory: "",
                  salescategory1: "",
                  salescategory2: "",
                });
              }, 2000);
            }
            else {
              if (Number(values.PromoPrice) >= Number(values.Price)) {
                message.error({
                  content: 'Promo price must be lesser than price',
                  className: 'custom-class',
                  style: {
                    marginTop: '20vh',
                  },
                });
              }
              else {
                props.AddPrdt(values, department, category, subcategory, category2);
               
                setTimeout(() => {
                  resetForm();
                  setState({
                    department: "",
                    salescategory: "",
                    salescategory1: "",
                    salescategory2: "",
                  });
                }, 2000);
              }
            }

          }
        }
      }

      else {

        if (values.PromoPrice === "") {

        
          props.AddProduct(
            values,
            department,
            category,
            subcategory,
            category2,
            props.multipleSignedUrl ? props.multipleSignedUrl
              && props.multipleSignedUrl.uploadMultipleFiles
              && props.multipleSignedUrl.uploadMultipleFiles
              : null,
            props.thumbnailUrl ? props.thumbnailUrl : null

          );
          setTimeout(() => {
            resetForm();
            setState({
              department: "",
              salescategory: "",
              salescategory1: "",
              salescategory2: "",
            });
          }, 2000);
        }
        else {
          if (Number(values.PromoPrice) >= Number(values.Price)) {
            message.error({
              content: 'Promo price must be lesser than price',
              className: 'custom-class',
              style: {
                marginTop: '20vh',
              },
            });
          }
          else {
                      props.AddProduct(
              values,
              department,
              category,
              subcategory,
              category2,
              props.multipleSignedUrl ? props.multipleSignedUrl
                && props.multipleSignedUrl.uploadMultipleFiles
                && props.multipleSignedUrl.uploadMultipleFiles
                : null,
              props.thumbnailUrl ? props.thumbnailUrl : null

            );
            setTimeout(() => {
              resetForm();
              setState({
                department: "",
                salescategory: "",
                salescategory1: "",
                salescategory2: "",
              });
            }, 2000);
          }
        }

      }




    },
  });

  const clearInput = (id) => {
    setFilteredData([]);
    setValue(false)

    props.getMasterProductDetails(id);

  };



  const resetFormik = () => {
    formik.setFieldValue(
      "Description", ''
    );
    formik.setFieldValue(
      "brand",
      ''
    );
    formik.setFieldValue(
      "MfgName",
      ''
    );
    formik.setFieldValue(
      "Price", ''
    );
    formik.setFieldValue(
      "status",
      ''
    );
  }

  const setFormikValue = () => {
    // if(props.MasterProductDetailsData &&
    // props.MasterProductDetailsData.data &&
    // props.MasterProductDetailsData.data
    //   .viewParticularMasterProduct===null)
    //   {
    //     resetFormik()
    //   }
    //   else{


    formik.setFieldValue(
      "Description",
      props.MasterProductDetailsData &&
      props.MasterProductDetailsData.data &&
      props.MasterProductDetailsData.data
        .viewParticularMasterProduct &&
      props.MasterProductDetailsData.data
        .viewParticularMasterProduct.desc
    );
    formik.setFieldValue(
      "brand",
      props.MasterProductDetailsData &&
      props.MasterProductDetailsData.data &&
      props.MasterProductDetailsData.data
        .viewParticularMasterProduct &&
      props.MasterProductDetailsData.data
        .viewParticularMasterProduct.brand
    );
    formik.setFieldValue(
      "MfgName",
      props.MasterProductDetailsData &&
      props.MasterProductDetailsData.data &&
      props.MasterProductDetailsData.data
        .viewParticularMasterProduct &&
      props.MasterProductDetailsData.data
        .viewParticularMasterProduct.mfgname
    );
    formik.setFieldValue(
      "Price",
      props.MasterProductDetailsData &&
      props.MasterProductDetailsData.data &&
      props.MasterProductDetailsData.data
        .viewParticularMasterProduct &&
      props.MasterProductDetailsData.data
        .viewParticularMasterProduct.price
    );
    formik.setFieldValue(
      "status",
      props.MasterProductDetailsData &&
        props.MasterProductDetailsData.data &&
        props.MasterProductDetailsData.data
          .viewParticularMasterProduct &&
        props.MasterProductDetailsData.data
          .viewParticularMasterProduct.status === "available" ? "active" : ""
    );
    // }
  }
  const [state, setState] = React.useState({
    department: "",
    salescategory: "",
    salescategory1: "",
    salescategory2: "",
  });
  const handleFormChange = (e, name) => {
    // set
    if (e.target.name === "department") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        salescategory: "",
        salescategory1: "",
        salescategory2: "",
      });
    }
    else if (e.target.name === "salescategory") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        salescategory1: "",
        salescategory2: "",
      });
    }
    else if (e.target.name === "salescategory1") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        salescategory2: "",
      });
    }
    else {
      setState({
        ...state,
        [e.target.name]: e.target.value
      });
    }

  };
  const getCategoriesList = (id) => {
    props.getCategoryList(id);
  };
  const getCategory1List = (id) => {

    props.getCategory1List(id);
  };
  const getCategories2List = (id) => {
    props.getCategory2List(id);
  };

  const theme = createMuiTheme({
    palette: {
      primary: green,
    },
    overrides: {
      MuiInputBase: {
        input: {
          "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
        },
      },
    },
  });

  return (
    isSmallScreen ? (
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid rgba(0, 0, 0, 0.16)",
          borderRadius: "10px",
          Top: "40px",
          padding: "30px",
          width: "100%",
        }}
      >
        {props.DepartmentList && props.DepartmentList.data && props.DepartmentList.data.salesdepartments &&
          props.DepartmentList.data.salesdepartments.length !== 0 ?
          (<>
            <div
              style={{
                paddingTop: "15px",
                paddingBottom: "10px",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              <h5 style={{ fontWeight: 800, fontSize: "23px" }}>
                {"Add new product"}
              </h5>
            </div>
            <form

              // enableReinitialize={true}
              style={{ width: "100%" }}
              onSubmit={formik.handleSubmit}
              onReset={formik.handleReset}
            >
              <Row style={{ width: "100%" }}>
                <Col md={12} sm={12} xs={12}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      type="text"
                      label="Product Name"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                        // style:{ position:'absolute'}
                      }}
                      style={{
                        width: "100%", marginBottom: "30px", marginLeft: 10
                      }}
                      InputProps={{ style: { height: 35 } }}
                      value={wordEntered}
                      onChange={handleFilter}
                    />

                    {filteredData && filteredData.length !== 0 && (
                      <div className="dataResult" style={{ width: '100px', fontSize: '9px' }}>
                        {filteredData.viewMasterProducts.items.map((i) => {
                          return (

                            <MenuItem onClick={() => {
                              clearInput();
                              setWordEntered(i.productname)
                            }}>
                              <img
                                src={i.image.primary !== null ? i.image.primary : null}
                                alt="product"
                                style={{ marginRight: "7px" }}
                                height="20px"
                                width="20px"
                              />
                              {i.productname}
                            </MenuItem>

                          );
                        })}

                      </div>
                    )}
                  </ThemeProvider>
                </Col>
              </Row>
              <Row style={{ width: "100%" }}>
                <Col md={12} sm={12} xs={12}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{ marginBottom: "50px", marginLeft: 10 }}
                      InputProps={{ style: { height: 75 } }}
                      fullWidth
                      required
                      variant="outlined"
                      value={formik.values.Description || " "}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.Description && Boolean(formik.errors.Description)
                      }
                      helperText={
                        formik.touched.Description && formik.errors.Description
                      }
                      className="form-control"
                      as="textarea"
                      label="Short description about the product "
                      rows={3}
                      name="Description"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </ThemeProvider>
                </Col>
              </Row>
              <Row style={{ width: "100%" }}>
                <Col md={12} sm={12} xs={12}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{ marginBottom: "30px", marginLeft: 10 }}
                      InputProps={{ style: { height: 35 } }}
                      fullWidth
                      variant="outlined"
                      value={formik.values.barcode || " "}
                      onChange={formik.handleChange}
                      error={formik.touched.barcode && Boolean(formik.errors.barcode)}
                      helperText={formik.touched.barcode && formik.errors.barcode}
                      className="form-control"
                      name="barcode"
                      label="Barcode number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </ThemeProvider>
                </Col>
              </Row>
              <Row style={{ width: '100%' }}>
                <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{ marginBottom: "15px", marginLeft: 10 }}
                      InputProps={{ style: { height: 35 } }}
                      fullWidth
                      variant="outlined"
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                        }}
                      value={formik.values.Price || " "}
                      onChange={formik.handleChange}
                      error={formik.touched.Price && Boolean(formik.errors.Price)}
                      helperText={formik.touched.Price && formik.errors.Price}
                      name="Price"
                      label="Price*"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </ThemeProvider>
                </Col>
                <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{
                        marginBottom: "15px", marginLeft: 10
                      }}
                      InputProps={{ style: { height: 35 } }}
                      fullWidth
                      variant="outlined"
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                        }}
                      value={formik.values.PromoPrice || " "}
                      error={
                        formik.touched.PromoPrice &&
                        Boolean(formik.errors.PromoPrice)
                      }
                      helperText={
                        formik.touched.PromoPrice && formik.errors.PromoPrice
                      }
                      onChange={formik.handleChange}
                      name="PromoPrice"
                      label="Promo Price"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </ThemeProvider>
                </Col>
              </Row>
              <Row style={{ width: '100%' }}>
                <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{ marginBottom: "15px", marginLeft: 10 }}
                      InputProps={{ style: { height: 35 } }}
                      fullWidth
                      variant="outlined"
                      name="brand"
                      value={formik.values.brand || " "}
                      onChange={formik.handleChange}
                      error={formik.touched.brand && Boolean(formik.errors.brand)}
                      helperText={formik.touched.brand && formik.errors.brand}
                      label="Brand name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </ThemeProvider>
                </Col>
                <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{
                        marginBottom: "15px", marginLeft: 10
                      }}
                      InputProps={{ style: { height: 35 } }}
                      fullWidth
                      variant="outlined"
                      name="MfgName"
                      value={formik.values.MfgName || " "}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.MfgName && Boolean(formik.errors.MfgName)
                      }
                      helperText={formik.touched.MfgName && formik.errors.MfgName}
                      label="Manufacturer name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </ThemeProvider>
                </Col>
              </Row>
              <Row style={{ width: '100%' }}>
                <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{ marginBottom: "15px", marginLeft: 10 }}
                      InputProps={{ style: { height: 35 } }}
                      fullWidth
                      variant="outlined"
                      name="Quantity"
                      value={formik.values.Quantity || " "}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.Quantity && Boolean(formik.errors.Quantity)
                      }
                      helperText={formik.touched.Quantity && formik.errors.Quantity}
                      label="Quantity "
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </ThemeProvider>
                </Col>
                <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{
                        marginBottom: "15px",
                        marginLeft: 10,
                        height: 35,
                      }}
                      InputProps={{ style: { height: 35 } }}
                      SelectProps={{ autoWidth: true }}
                      fullWidth
                      variant="outlined"
                      select
                      label="Unit of measurement"
                      name="unit"
                      value={formik.values.unit || ''}
                      onChange={formik.handleChange}
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="No.s" style={{ cursor: "pointer" }}>
                        No.s
                      </MenuItem>
                      <MenuItem value="kg" style={{ cursor: "pointer" }}>
                        Kilogram (kg)
                      </MenuItem>
                      <MenuItem value="g" style={{ cursor: "pointer" }}>
                        gram (g)
                      </MenuItem>
                      <MenuItem value="gal" style={{ cursor: "pointer" }}>
                        gallon (gal)
                      </MenuItem>
                      <MenuItem value="l" style={{ cursor: "pointer" }}>
                        liter (l)
                      </MenuItem>
                      <MenuItem value={"ml"} style={{ cursor: "pointer" }}>
                        milliliter (ml)
                      </MenuItem>
                    </TextField>
                  </ThemeProvider>
                </Col>
              </Row>
              <Row style={{ paddingTop: 0, width: '100%' }}>
                <Col md={12} xs={12} style={{ marginBottom: "20px", marginLeft: "10px" }}>
                  {/* <ImageCropper {...props} /> */}
                  <h6>{"Upload your primary image of the product "}</h6>
                  <NewImg {...props} fileName={formik.values.Productname} />
                </Col>
              </Row>
              {props.newSignedUrl ?
                <Row style={{ marginLeft: "15px", paddingTop: 0, width: '100%' }}>
                  <Col md={12} xs={12} style={{ marginBottom: "20px", marginLeft: "10px" }}>
                    {/* <ImageCropper {...props} /> */}
                    <h6>
                      {"Upload your secondary images of the product ( if any ) "}
                    </h6>
                    <MultipleImg {...props} />
                  </Col>
                </Row>
                :
                null
              }
              <Row style={{ width: '100%' }}>
                <Col md={6} xs={12} style={{ paddingTop: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{
                        marginBottom: "15px", marginLeft: 10
                      }}
                      InputProps={{ style: { height: 35 } }}
                      fullWidth
                      variant="outlined"
                      name="displayRank"
                      value={formik.values.displayRank}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.displayRank &&
                        Boolean(formik.errors.displayRank)
                      }
                      helperText={
                        formik.touched.displayRank && formik.errors.displayRank
                      }
                      label="Display rank in the category"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </ThemeProvider>
                </Col>
                <Col md={6} xs={12} style={{ paddingTop: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{
                        marginBottom: "15px",
                        marginLeft: 10,
                        width: "100%"
                      }}
                      InputProps={{ style: { height: 35 } }}
                      select
                      variant="outlined"
                      SelectProps={{ autoWidth: true, width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Status"
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      error={formik.touched.status && Boolean(formik.errors.status)}
                      helperText={formik.touched.status && formik.errors.status}
                      defaultValue=""
                    >
                      <MenuItem value={"active"} style={{ cursor: "pointer" }}>
                        {"active"}
                      </MenuItem>
                      <MenuItem value={"inactive"} style={{ cursor: "pointer" }}>
                        {"inactive"}
                      </MenuItem>
                    </TextField>
                  </ThemeProvider>
                </Col>
              </Row>

              {/* </Form> */}
              <Row style={{ width: '100%', marginLeft: "15px" }}>
                <h6
                  style={{
                    paddingBottom: "20px",
                    paddingTop: "20px",
                  }}
                >
                  <b>
                    {"Select the hierarchy in which the product should display"}
                  </b>
                </h6>
              </Row>
              <Row style={{ width: '100%' }}>
                {props.DepartmentList !== "" && (
                  <>
                    <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                      <ThemeProvider theme={theme}>
                        <TextField
                          style={{
                            marginBottom: "15px",
                            marginLeft: 10,
                            width: "100%",
                          }}
                          InputProps={{ style: { height: 35, width: "100%" } }}
                          sx={{ minWidth: 70 }}
                          //  fullWidth
                          select
                          variant="outlined"
                          SelectProps={{ autoWidth: true }}
                          label="Department"
                          name="department"
                          required
                          defaultValue=""
                          value={state.department}
                          onChange={(e) => {
                            handleFormChange(e);
                            getCategoriesList(e.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          {props.DepartmentList &&
                            props.DepartmentList.data &&
                            props.DepartmentList.data.salesdepartments !== null &&
                            props.DepartmentList.data.salesdepartments.map((s) => (
                              <MenuItem value={`${s.id}`} key={s.id}>{s.name}</MenuItem>
                            ))}
                        </TextField>
                      </ThemeProvider>
                    </Col>
                    {
                      props?.CategoriesList?.length !== 0 && (
                        <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                          <ThemeProvider theme={theme}>
                            <TextField
                              required
                              style={{
                                marginBottom: "15px",
                                width: "100%",
                                marginLeft: 10
                              }}
                              InputProps={{
                                style: { height: 35, width: "100%" },
                                autoComplete: "off",
                              }}
                              defaultValue=""
                              select
                              variant="outlined"
                              // sx={{ minWidth: "auto"}}
                              // SelectProps={{ autoWidth: true }}
                              label=" Category"
                              name="salescategory"
                              value={state.salescategory}
                              onChange={(e) => {
                                handleFormChange(e);
                                getCategory1List(e.target.value);

                              }}
                              // error={formik.touched.salescategory && Boolean(formik.errors.salescategory)}
                              // helperText={formik.touched.salescategory && formik.errors.salescategory}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              {props.CategoriesList &&
                                props.CategoriesList.map((c) => (
                                  <MenuItem value={`${c.id}`} key={c.id}>
                                    {c.name}</MenuItem>
                                ))}
                            </TextField>
                          </ThemeProvider>
                        </Col>
                      )}
                  </>
                )}
              </Row>
              <Row style={{ paddingTop: 15, width: '100%' }}>
                {state.salescategory !== "" &&
                  props.CategoriesList &&
                  props.CategoriesList.length !== 0 &&
                  props.Categories1List &&
                  props.Categories1List.length !== 0 && (
                    <>
                      <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                        <ThemeProvider theme={theme}>
                          <TextField
                            defaultValue=""
                            required
                            style={{
                              marginBottom: "15px",
                              width: "100%",
                              marginLeft: 10
                            }}
                            InputProps={{
                              style: { height: 35, width: "100%" },
                              autoComplete: "off",
                            }}
                            select
                            variant="outlined"
                            // SelectProps={{ autoWidth: true }}
                            label="Sub-Category 1"
                            name="salescategory1"
                            value={state.salescategory1}
                            onChange={(e) => {
                              handleFormChange(e);
                              getCategories2List(e.target.value);
                            }}
                            // error={formik.touched.salescategory1 && Boolean(formik.errors.salescategory1)}
                            // helperText={formik.touched.salescategory1 && formik.errors.salescategory1}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            {props.Categories1List &&
                              props.Categories1List.map((c) => (
                                <MenuItem
                                  style={{ cursor: "pointer" }}
                                  value={`${c.id}`}
                                  key={c.id}
                                >
                                  {c.name}
                                </MenuItem>
                              ))}
                          </TextField>
                        </ThemeProvider>
                      </Col>
                      {state.salescategory1 !== "" &&
                        props.CategoriesList &&
                        props.CategoriesList.length !== 0 &&
                        props.Categories2List &&
                        props.Categories2List.length !== 0 && (
                          <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                            <ThemeProvider theme={theme}>
                              <TextField
                                defaultValue=""
                                required
                                style={{
                                  marginBottom: "15px",
                                  width: "100%",
                                  marginLeft: 10
                                }}
                                InputProps={{
                                  style: { height: 35, width: "100%" },
                                  autoComplete: "off",
                                }}
                                select
                                variant="outlined"
                                // SelectProps={{ autoWidth: true }}
                                label="Sub-Category 2"
                                name="salescategory2"
                                value={state.salescategory2}
                                onChange={(e) => handleFormChange(e)}
                                // error={formik.touched.salescategory2 && Boolean(formik.errors.salescategory2)}
                                // helperText={formik.touched.salescategory2 && formik.errors.salescategory2}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              >
                                {props.Categories2List &&
                                  props.Categories2List.map(
                                    (c) => (
                                      <MenuItem
                                        style={{
                                          cursor: "pointer",
                                          width: "fit-content",
                                        }}
                                        value={`${c.id}`}
                                        key={c.id}
                                      >
                                        {c.name}
                                      </MenuItem>
                                    )
                                  )}
                              </TextField>
                            </ThemeProvider>
                          </Col>
                        )}
                    </>
                  )}
              </Row>

              <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'cneter', textAlign: 'center' }}>
                <Col sm={12} md={12} xs={12} style={{ paddingTop: "15px" }}>
                  <button
                    style={{
                      color: "rgb(255, 255, 255)",
                      backgroundImage:
                        "-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
                      width: "75px",
                      height: "37px",
                      fontSize: "16px",
                      fontWeight: "600",
                      borderRadius: "5px",
                      borderWidth: "1px",
                      borderStyle: " solid",
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'cneter',
                      textAlign: 'center',
                      borderColor: "transparent",
                      borderImage: "initial",
                      overflow: "hidden",
                      outline: "none",
                      minHeight: " 14px",
                      fontFamily: "Varela Round, sans-serif",
                    }}
                    type="submit"
                  >
                    {props.addingProduct ? "loading" : "submit"}
                  </button>
                </Col>
              </Row>
              <br />
            </form>
          </>)
          :
          (<NoDepartment />)}

      </div>
    ) : (

      <Container maxWidth='md'
        style={{
          backgroundColor: "white",
          border: "1px solid rgba(0, 0, 0, 0.16)",
          borderRadius: "10px",
          marginTop: "90px",
          padding: "30px",
          display: 'flex',
          flexDirection: 'column',
          flex: ' 1 1 0',
          width: "100%",
        }}
      >
        {props.DeparmentList && 
          props.DeparmentList.length !== 0 ?
          (<>
            <div
              style={{
                paddingTop: "15px",
                paddingBottom: "10px",
                textAlign: "center",
                fontWeight: 600,
              }}
            >
              <h5 style={{ fontWeight: 800, fontSize: "23px" }}>
                {"Add new product"}
              </h5>
            </div>
            
            <form
              //  enableReinitialize
              // enableReinitialize={true}
              style={{ width: "100%",paddingTop: "15px" }}
              onSubmit={formik.handleSubmit}
              onReset={formik.handleReset}
            >
              <Row style={{ width: "100%", paddingBottom: '30px' }}>
                <Col md={12} sm={12} xs={12}>

                  <ThemeProvider theme={theme}>
             
                    <TextField
                style={{ marginBottom: "30px" }}
                InputProps={{
                  style: { height: 35, backgroundColor: "white" },
                }}
                fullWidth
                variant="outlined"
                className="form-control"
                value={formik.values.Productname}
                onChange={formik.handleChange}
                error={
                  formik.touched.Productname && Boolean(formik.errors.Productname)
                }
                helperText={
                  formik.touched.Productname && formik.errors.Productname
                }
                name="Productname"
                label="Enter product name*"
                InputLabelProps={{
                  shrink: true,
                }}
              />

                  </ThemeProvider>
                </Col>
              </Row>


              <Row style={{ width: "100%" }}>
                <ThemeProvider theme={theme}>
                  <Col md={12} sm={12} xs={12}>
                    <TextField
                      style={{ marginBottom: "50px" }}
                      InputProps={{ style: { height: 75 } }}
                      fullWidth
                      variant="outlined"
                      value={formik.values.Description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.Description && Boolean(formik.errors.Description)
                      }
                      helperText={
                        formik.touched.Description && formik.errors.Description
                      }
                      className="form-control"
                      as="textarea"
                      label="Short description about the product *"
                      rows={3}
                      name="Description"
                      InputLabelProps={{
                        shrink: true,

                      }}

                    />
                  </Col>
                </ThemeProvider>
              </Row>
              <Row style={{ width: "100%" }}>
                <ThemeProvider theme={theme}>
                  <Col md={12} sm={12} xs={12}>
                    <TextField
                      style={{ marginBottom: "30px", }}
                      InputProps={{ style: { height: 35 } }}
                      fullWidth
                      variant="outlined"
                      value={formik.values.barcode}
                      onChange={formik.handleChange}
                      error={formik.touched.barcode && Boolean(formik.errors.barcode)}
                      helperText={formik.touched.barcode && formik.errors.barcode}
                      className="form-control"
                      name="barcode"
                      label="Barcode number"
                      InputLabelProps={{
                        shrink: true,

                      }}
                    />
                  </Col>
                </ThemeProvider>
              </Row>
              <Row style={{ width: '100%' }}>
                <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{ marginBottom: "15px" }}
                      InputProps={{ style: { height: 35 } }}
                      fullWidth
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                        }}
                      variant="outlined"
                      value={formik.values.Price}
                      onChange={formik.handleChange}
                      error={formik.touched.Price && Boolean(formik.errors.Price)}
                      helperText={formik.touched.Price && formik.errors.Price}
                      name="Price"
                      label="Price*"
                      InputLabelProps={{
                        shrink: true,

                      }}
                    />
                  </ThemeProvider>
                </Col>
                <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{
                        marginBottom: "15px",

                      }}
                      InputProps={{ style: { height: 35 } }}
                      fullWidth
                      variant="outlined"
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                        }}
                      value={formik.values.PromoPrice}
                      error={
                        formik.touched.PromoPrice &&
                        Boolean(formik.errors.PromoPrice)
                      }
                      helperText={
                        formik.touched.PromoPrice && formik.errors.PromoPrice
                      }
                      onChange={formik.handleChange}
                      name="PromoPrice"
                      label="Promo Price"
                      InputLabelProps={{
                        shrink: true,

                      }}
                    />
                  </ThemeProvider>
                </Col>
              </Row>
              <Row style={{ width: '100%' }}>
                <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{ marginBottom: "15px" }}
                      InputProps={{ style: { height: 35 } }}
                      fullWidth
                      variant="outlined"
                      name="brand"
                      value={formik.values.brand}
                      onChange={formik.handleChange}
                      error={formik.touched.brand && Boolean(formik.errors.brand)}
                      helperText={formik.touched.brand && formik.errors.brand}
                      label="Brand name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </ThemeProvider>
                </Col>
                <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{
                        marginBottom: "15px",

                      }}
                      InputProps={{ style: { height: 35 } }}
                      fullWidth
                      variant="outlined"
                      name="MfgName"
                      value={formik.values.MfgName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.MfgName && Boolean(formik.errors.MfgName)
                      }
                      helperText={formik.touched.MfgName && formik.errors.MfgName}
                      label="Manufacturer name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </ThemeProvider>
                </Col>
              </Row>
              <Row style={{ width: '100%' }}>
                <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{ marginBottom: "15px" }}
                      InputProps={{ style: { height: 35 } }}
                      fullWidth
                      variant="outlined"
                      name="Quantity"
                      value={formik.values.Quantity}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.Quantity && Boolean(formik.errors.Quantity)
                      }
                      helperText={formik.touched.Quantity && formik.errors.Quantity}
                      label="Quantity "
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </ThemeProvider>
                </Col>
                <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
                      style={{
                        marginBottom: "15px",

                        height: 35,
                      }}
                      InputProps={{ style: { height: 35 } }}
                      SelectProps={{ autoWidth: true }}
                      fullWidth
                      variant="outlined"
                      select
                      label="Unit of measurement"
                      name="unit"
                      value={formik.values.unit || ''}
                      onChange={formik.handleChange}
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem value="No.s" style={{ cursor: "pointer" }}>
                        No.s
                      </MenuItem>
                      <MenuItem value="kg" style={{ cursor: "pointer" }}>
                        Kilogram (kg)
                      </MenuItem>
                      <MenuItem value="g" style={{ cursor: "pointer" }}>
                        gram (g)
                      </MenuItem>
                      <MenuItem value="gal" style={{ cursor: "pointer" }}>
                        gallon (gal)
                      </MenuItem>
                      <MenuItem value="l" style={{ cursor: "pointer" }}>
                        liter (l)
                      </MenuItem>
                      <MenuItem value={"ml"} style={{ cursor: "pointer" }}>
                        milliliter (ml)
                      </MenuItem>
                    </TextField>
                  </ThemeProvider>
                </Col>
              </Row>
              <Row style={{ width: '100%' }}>
                <Col md={6} xs={12} style={{ paddingTop: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{
                        marginBottom: "15px"
                      }}
                      InputProps={{ style: { height: 35 } }}
                      fullWidth
                      variant="outlined"
                      name="displayRank"
                      inputProps={{ maxLength: 6 }}
                      value={formik.values.displayRank}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.displayRank &&
                        Boolean(formik.errors.displayRank)
                      }
                      helperText={
                        formik.touched.displayRank && formik.errors.displayRank
                      }
                      label="Display rank in the category"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </ThemeProvider>
                </Col>
                <Col md={6} xs={12} style={{ paddingTop: "15px" }}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      style={{
                        marginBottom: "15px",

                        width: "100%"
                      }}
                      InputProps={{ style: { height: 35 } }}
                      select
                      variant="outlined"
                      SelectProps={{ autoWidth: true, width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Status"
                      name="status"
                      key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      error={formik.touched.status && Boolean(formik.errors.status)}
                      helperText={formik.touched.status && formik.errors.status}
                      defaultValue=""
                    >
                      <MenuItem value={"active"} style={{ cursor: "pointer" }}>
                        {"active"}
                      </MenuItem>
                      <MenuItem value={"inactive"} style={{ cursor: "pointer" }}>
                        {"inactive"}
                      </MenuItem>
                    </TextField>
                  </ThemeProvider>
                </Col>
              </Row>

              {/* </Form> */}
              <Row style={{ width: '100%' }}>
                <h6
                  style={{
                    paddingBottom: "20px",
                    paddingTop: "20px",
                  }}
                >
                  <b>
                    {"Select the hierarchy in which the product should display"}
                  </b>
                </h6>
              </Row>
              <Row style={{ width: '100%' }}>
                {props.DeparmentList !== "" && (
                  <>
                    <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                      <ThemeProvider theme={theme}>
                        <TextField
                          style={{
                            marginBottom: "15px",
                            width: "100%",
                          }}
                          key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
                          InputProps={{ style: { height: 35, width: "100%" } }}
                          sx={{ minWidth: 70 }}
                          select
                          variant="outlined"
                          SelectProps={{ autoWidth: true }}
                          label="Department"
                          name="department"
                          required
                          defaultValue=""
                          value={state.department}
                          onChange={(e) => {
                            handleFormChange(e);
                            getCategoriesList(e.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          {props.DeparmentList &&
                            props.DeparmentList.map((s) => (
                              <MenuItem value={`${s.id}`} key={s.id}>{s.name}</MenuItem>
                            ))}
                        </TextField>
                      </ThemeProvider>
                    </Col>

                    {
                      (props?.categorieslistsuccess)&&
                      (props?.CategoriesList?.length !== 0) && (
                        <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
                          <ThemeProvider theme={theme}>
                            <TextField
                              key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
                              required
                              style={{
                                marginBottom: "15px",

                                width: "100%",
                              }}
                              InputProps={{
                                style: { height: 35, width: "100%" },
                                autoComplete: "off",
                              }}
                              defaultValue=""
                              select
                              variant="outlined"
                              // sx={{ minWidth: "auto"}}
                              // SelectProps={{ autoWidth: true }}
                              label="Category"
                              name="salescategory"
                              value={state.salescategory}
                              onChange={(e) => {
                                handleFormChange(e);
                                getCategory1List(e.target.value);

                              }}
                              // error={formik.touched.salescategory && Boolean(formik.errors.salescategory)}
                              // helperText={formik.touched.salescategory && formik.errors.salescategory}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >

                              {props.CategoriesList &&
                                props.CategoriesList.map((c) => (
                                  <MenuItem value={`${c.id}`} key={c.id}>{c.name}</MenuItem>
                                ))}
                            </TextField>
                          </ThemeProvider>
                        </Col>
                      )}
                    
                  </>
                )}
              </Row>
            

              <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "15px" }}>
                {/* <Col sm={6} md={6} xs={12} style={{ paddingBottom: "15px" }}>
                {" "}
              </Col>
              <Col sm={6} md={6} xs={12} style={{ paddingLeft: "50%", paddingTop: "15px" }}> */}
                <button
                  style={{
                    justifyContent: " center",
                    color: "rgb(255, 255, 255)",
                    backgroundColor: 'rgb(137, 199, 74, 1)',
                    boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
                    width: "150px",
                    float: "right",
                    height: "37px",
                    fontSize: "16px",
                    fontWeight: "600",
                    borderRadius: "5px",
                    borderWidth: "1px",
                    borderStyle: " solid",
                    borderColor: "transparent",
                    borderImage: "initial",
                    overflow: "hidden",
                    outline: "none",
                    minHeight: " 14px",
                    fontFamily: "Varela Round, sans-serif",
                  }}
                  id="submit-button"
                  type="submit"
                >
                  {props.addingProduct ? "loading" : "Submit"}
                </button>
                {/* </Col> */}
              </Row>
              <br />
            </form>
          </>)
          :
          (<NoDepartment />)}

      </Container>
    )

  );
};

export default Add;
