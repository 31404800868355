import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


const style = {
   textAlign:'left',
   padding:'10px'
  };


const ShopAssistant = (props) => {
 
  console.log("this props is from shopasiistant",props)
  // console.log(props.getShopAssistantFullDetails(props?.OrderDetails?.orderdetails.shopAssistantId))
    return (
        <Box sx={{ flexGrow: 1 }}>
       <Grid container spacing={3} style={{padding:'20px',justifyContent:'center'}}>
        <Grid item xs={4} md={4} sx={style}>
         Shop Assistant First Name
        </Grid>
        <Grid item xs={4} md={2} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
        {props.OrderDetails&&
          props?.OrderDetails?.orderdetails&&
          props?.OrderDetails?.orderdetails?.shopAssistantId&&
          props?.OrderDetails?.orderdetails?.shopAssistantId?.firstName
          }
        </Grid>
        <Grid item xs={4} md={4} sx={style}>
        Shop Assistant Last Name
        </Grid>
        <Grid item xs={4} md={2} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
        {props.OrderDetails&&
          props.OrderDetails.orderdetails&&
          props.OrderDetails.orderdetails.shopAssistantId&&
          props.OrderDetails.orderdetails.shopAssistantId.lastName&&
          props.OrderDetails.orderdetails.shopAssistantId.lastName
          }
        </Grid>
        <Grid item xs={4} md={4} sx={style}>
        Shop Assistant Phone Number
        </Grid>
        <Grid item xs={4} md={2} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
        {props.OrderDetails&&
          props.OrderDetails.orderdetails&&
          props.OrderDetails.orderdetails.shopAssistantId&&
          props.OrderDetails.orderdetails.shopAssistantId.phoneNumber&&
          props.OrderDetails.orderdetails.shopAssistantId.phoneNumber
          }
        </Grid>
      </Grid>
    </Box>
    )
}

export default ShopAssistant
