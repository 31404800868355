import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from 'react-bootstrap';

const useStyles = makeStyles(() => ({
    card: {
        boxShadow: 'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        padding: '15px',
        borderRadius: '14px'
    },
    col: {
        padding: '8px',
        color: 'rgb(99, 115, 129)',
        fontWeight: 600,
        fontSize: '14px',
    },
}));

const DeliveryPartnerDetails = (props) => {

    const classes = useStyles();

    return (
        <>
            <Row className={classes.col}>
                <Col sm={6} >
                    Partner Name
                </Col>
                <Col sm={6}>
                    {props.Complaint.orderid && props.Complaint.orderid.deliveryPartnerId && props.Complaint.orderid.deliveryPartnerId.firstName&&
                    props.Complaint.orderid.deliveryPartnerId.firstName}{' '}
                     {props.Complaint.orderid && props.Complaint.orderid.deliveryPartnerId && props.Complaint.orderid.deliveryPartnerId.lastName&&
                    props.Complaint.orderid.deliveryPartnerId.lastName}
                </Col>
            </Row>
            <Row className={classes.col}>
                <Col sm={6}>
                    Phone Number
                </Col>
                <Col sm={6}>
                    {props.Complaint.orderid && props.Complaint.orderid.deliveryPartnerId && props.Complaint.orderid.deliveryPartnerId.phoneNumber && props.Complaint.orderid.deliveryPartnerId.phoneNumber}
                </Col>
            </Row>
        </>
    )
}

export default DeliveryPartnerDetails
