import React from "react";
import HomeBanner from "../../Components/Banner";
import Footer from "../../Components/Footer";
import "./index.css";
import Snackbar from "@material-ui/core/Snackbar";
import { AlertTitle } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class HomePage extends React.Component {
	constructor(props) {
		super(props);
		this.state={};
	}
	componentDidUpdate(prevProps){
		if (this.props.successData !== prevProps.successData) {
			this.loginUser()
		}
	}
	loginUser=()=>{
			if (this.props.isLoggedIn && this.props.successData) {
			let { userid } = this.props.successData;
			localStorage.setItem("adminID",userid);
			this.props.history.push({
				pathname: `/home`,
				state: {

					userId:userid,
				},
			});
		}
	}
	render() {		
		return (			
			<>
				<HomeBanner {...this.props} />
				<Footer/>
				{this.props.isLoggedIn === false && this.props.errorMessage ? (
					<Snackbar
						style={{ width: "100%" }}
						anchorOrigin={{vertical:'top',horizontal:'center'}}
						open={this.props.isLoggedIn === false ? true : false}
						autoHideDuration={500}>
						<Alert severity="error">
							<AlertTitle>{this.props.errorMessage.message}</AlertTitle>
						</Alert>
					</Snackbar>
				) : null}
					{this.props.isPasswordset === false && this.props.incorrectMessage ? (
					<Snackbar
						style={{ width: "100%" }}
						anchorOrigin={{vertical:'top',horizontal:'center'}}
						open={this.props.isPasswordset === false ? true : false}
						autoHideDuration={500}>
						<Alert severity="error">
							<AlertTitle>{this.props.incorrectMessage.message}</AlertTitle>
						</Alert>
					</Snackbar>
				) : null}
				{this.props.isPasswordset ===true&& this.props.passwordData ? (
					<Snackbar
						style={{ width: "100%" }}
						anchorOrigin={{vertical:'top',horizontal:'center'}}
						open={this.props.isPasswordset === false ? true : false}
						autoHideDuration={500}>
						<Alert severity="error">
							<AlertTitle>{this.props.passwordData.resetPassword}</AlertTitle>
						</Alert>
					</Snackbar>
				) : null}
			</>			
		);		
	}
}
export default HomePage;
