export const fieldState = {
	userName: "",
	password: "",
};
export const fields = [
	{
		label: "Username",
		type: "email",
		name: "userName",
		placeholder: "Eg:abc@example.com",
	},
	{
		label: "Password",
		type: "password",
		name: "password",
		placeholder: "",
	},
];
