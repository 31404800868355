import React, { useEffect, useRef, useState } from "react";
import "./pageStyle.css";
import {
  Breadcrumbs,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "../MUIComponents/index";
import Direction from "../Utilities/Icon/Direction.svg";
import DirectionSub from "../Utilities/Icon/DirectionSub.svg";
import Phone from "../Utilities/Icon/Phone.svg";
import ReadyForLine from "../Utilities/Icon/ReadyForLine.svg";
import { Navigate, useLocation } from "react-router-dom";
import lines from "../Utilities/Icon/lines.svg";
import UploadImage from "../Utilities/Icon/UploadImage.svg";
import Camera from "../Utilities/Icon/Camera.svg";
import LineInReadyForDel from "../Utilities/Icon/LineInReadyForDel.svg";
import BlueClock from "../Utilities/Icon/BlueClock.svg";
import Pointer from "../Utilities/Icon/Pointer.svg";
import CustomButton from "../CommonComponents/button";
import OutForDelivery from "./OutForDelivery";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_14,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_18_xs_13,
  contentFontSize_20_xs_18,
  contentFontsize_17,
} from "../Typography";
// import NewImg from "../../AddProduct/ImageCropper/NewImg"
import CustomCard from "../CommonComponents/card";
import { Upload, message } from "antd";
import OrderImage from "../AddProducts/ImageCropper/OrderImage";
import LoaderReady from "./Loader/LoaderReady";

import "./OrderRejected.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function OrderRejected(props) {
  
  const OrderRejectedDetails = props.orderData;
 const rejectedstats = props?.rejectedDetails?.updateOrderStatus;
 console.log("rejected",rejectedstats)
  const totalProductPrice = OrderRejectedDetails
    ? OrderRejectedDetails?.products?.reduce(
        (total, item) => total + item.quantity * item.productPrice,
        0
      )
    : 0;
  const deliveryCharge = OrderRejectedDetails?.deliveryCharge;
  const packingCharge = OrderRejectedDetails?.packingCharge;
  const discountPrice = OrderRejectedDetails?.discountPrice;
  const history = useHistory();
  function handleRejected(){
    history.push('/home/Orders');
  }
  // Calculate the total charges
  const totalCharges = deliveryCharge + packingCharge - discountPrice;

  // Calculate the grand total
  const grandTotal = totalProductPrice + totalCharges;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div>
      <div style={{ display: "flex", gap: "50px" }}>
        <div style={{ width: "55%" }}>
          <div style={{ marginTop: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <img
                      src={Direction}
                      style={{ width: "20px", height: "20px" }}
                      alt="Direction Icon"
                    />

                    <div>
                      <p
                        className="nameStyleRejected"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        {" "}
                        {OrderRejectedDetails &&
                          OrderRejectedDetails.customerName}
                      </p>
                    </div>
                  </div>

                  <div>
                    <p
                      style={{
                        fontWeight: "400",
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        textAlign: "left",
                        color: "black",
                      }}
                    >
                      {OrderRejectedDetails &&
                        OrderRejectedDetails?.deliveryAddress}
                      ,
                      {OrderRejectedDetails &&
                        OrderRejectedDetails?.deliveryDate}
                      ,
                    </p>
                  </div>
                  <div>
                    <p
                      style={{
                        fontWeight: "400",
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        color: "black",
                      }}
                    >
                      {OrderRejectedDetails &&
                        OrderRejectedDetails.deliverytime}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: smmatch ? "flex-start" : "flex-end",
                    height: "100%",
                    marginTop: "18px",
                  }}
                >
                  <img
                    src={Phone}
                    style={{ width: "20px", height: "20px" }}
                    alt="Phone icon"
                  />
                  <span>
                    <p className="numberStyle1" style={{ color: "black" }}>
                      +91 {OrderRejectedDetails?.userid?.phoneNumber}
                    </p>
                  </span>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="orderRejectedBox">
            <span>
              <p
                fontSize={contentFontsize_17}
                className="textStyleNew"
                style={{ color: "black" }}
              >
                #{OrderRejectedDetails && OrderRejectedDetails.orderNumber}-
                Rejected By Store
              </p>
            </span>
          </div>

          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sm={12}
              sx={{ marginTop: "20px" }}
            >
              <img
                style={{ width: "100%" }}
                src={LineInReadyForDel}
                alt="LineInReadyForDel"
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "20px",
              padding: smmatch ? "10px 10px" : "0px 50px 0px 0px",
            }}
          >
            {OrderRejectedDetails &&
              OrderRejectedDetails?.products?.map((item) => (
                <>
                  <div style={{ display: "flex" }}>
                    {console.log("item vannu", item)}
                    <div style={{ flex: "1" }}>
                      <div style={{ display: "flex", gap: "60px" }}>
                        <div className="productDetails">
                          <img
                            style={{
                              width: matchessm ? "15px" : "20px",
                              height: matchessm ? "15px" : "20px",
                            }}
                            src={Pointer}
                            alt={item?.name}
                          />
                        </div>

                        <div>
                          <p
                            fontSize={contentFontsize_17}
                            className="productDetails"
                            style={{
                              marginBottom: "0px",
                              textAlign: "left",
                              color: "black",
                            }}
                          >
                            {item.productid?.productname}
                            <br />
                            <p
                              fontSize={contentFontsize_14}
                              style={{
                                fontWeight: "400",
                                fontFamily: "Roboto",
                                marginBottom: "0px",
                                textAlign: "left",
                              }}
                            >
                              {item.shopAssistantQuantity === null ? (
                                <>
                                  {item.quantity} X ₹
                                  {item.productPrice.toFixed(2)}
                                </>
                              ) : (
                                <>
                                  {item.shopAssistantQuantity} X ₹
                                  {item.productPrice.toFixed(2)}
                                </>
                              )}
                            </p>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style={{ flex: "1" }}>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1px",
                          }}
                        >
                          {/* Calculate price based on updated quantity if active is "change", otherwise use original quantity */}
                          <p
                            className="productDetails"
                            fontSize={contentFontsize_17}
                            style={{ color: "black" }}
                          >
                            ₹
                            {(item.shopAssistantQuantity === null
                              ? item.quantity * item.productPrice
                              : item?.shopAssistantQuantity * item?.productPrice
                            )?.toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sm={12}
              sx={{ marginTop: "20px" }}
            >
              <img
                style={{ width: "100%" }}
                src={LineInReadyForDel}
                alt="LineInReadyForDel"
              />
            </Grid>
          </Grid>
          <div>
            <Typography
              sx={{
                textAlign: "left",
                fontWeight: "bold",
                fontFamily: "Roboto",
                marginTop: "15px",
                fontSize: "20px",
                color: "black",
              }}
            >
              Payment Summary
            </Typography>
          </div>
          <div style={{ padding: smmatch ? "10px 10px" : "0px 50px 0px 75px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p fontSize={contentFontSize_16} className="productDetails1">
                Total Product price
              </p>
              {/* </Grid> */}
              {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>  */}
              <p className="productDetails1" fontSize={contentFontSize_16}>
                ₹{totalProductPrice?.toFixed(2)}
              </p>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p fontSize={contentFontSize_16} className="productDetails1">
                Delivery Charge
              </p>
              {/* </Grid> */}

              {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3} > */}
              <p
                className="productDetails1"
                fontSize={contentFontSize_16}
                // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
              >
                ₹ {OrderRejectedDetails?.deliveryCharge?.toFixed(2)}
              </p>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                fontSize={contentFontSize_16}
                style={{ color: "rgba(45, 147, 251, 1)" }}
                className="productDetails1"
              >
                Coupon -  ({OrderRejectedDetails?.couponID?.couponCode})
              </p>
              {/* </Grid> */}

              {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
              <p
                className="productDetails1"
                style={{ color: "rgba(45, 147, 251, 1)" }}
                fontSize={contentFontSize_16}
              >
                -₹{OrderRejectedDetails?.discountPrice?.toFixed(2)}
              </p>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p fontSize={contentFontSize_16} className="productDetails1">
                Packing Charges
              </p>
              {/* </Grid> */}

              {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
              <p
                className="productDetails1"
                fontSize={contentFontSize_16}
                // sx={{marginLeft:"22px"}}
              >
                ₹ {OrderRejectedDetails?.packingCharge?.toFixed(2)}
              </p>
            </div>
          </div>

          <Grid container>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <img
                style={{ width: "100%" }}
                src={LineInReadyForDel}
                alt="LineInReadyForDel"
              />
            </Grid>
          </Grid>

          <div style={{ padding: smmatch ? "10px 10px" : "0px 50px 0px 75px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                fontSize={contentFontSize_20_xs_18}
                sx={{ fontWeight: "700", fontFamily: "Roboto", color: "black" }}
              >
                Total
              </Typography>
              {/* </Grid>

          <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
              <Typography
                sx={{ fontWeight: "700", fontFamily: "Roboto", color: "black" }}
                fontSize={contentFontSize_16}
              >
                ₹{grandTotal.toFixed(2)}
              </Typography>
            </div>
          </div>
          <Grid container>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <img
                style={{ width: "100%" }}
                src={LineInReadyForDel}
                alt="LineInReadyForDel"
              />
            </Grid>
          </Grid>
          
        </div>
        <div style={{ marginTop: "200px" }}>
              <div className="timeline-containerActive">
              {(rejectedstats
                    ? rejectedstats.stats
                    : OrderRejectedDetails.stats
                  ).map((each, index) => (
                    <div key={index} className="timeline-entryActive">
                      <div className="timeline-markerOut">
                        <div className="dotActive"></div>
                        {index <
                          (rejectedstats 
                            ? rejectedstats.stats.length
                            : OrderRejectedDetails.stats.length) -
                            1 && <div className="connecting-lineActive"></div>}
                      </div>
                      <div className="timeline-contentActive">
                        <div>
                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "800",
                              fontFamily: "Roboto",
                              color: "#000000",
                            }}
                          >
                            {each.status}
                          </p>
                          {each?.status === "Order-Placed" && (
                            <p
                              style={{
                                color: "grey",
                                fontSize: "14px",
                                marginTop: "10px",
                              }}
                            >
                              {each?.created}, {each?.createdTime}
                            </p>
                          )}
                        </div>
                        <div className="detailsActive">
                          <p
                            style={{
                              fontSize: "15px",
                              fontWeight: "400",
                              fontFamily: "Roboto",
                              color: "#000000",
                              textAlign: "left",
                              width: "100%",
                            }}
                          >
                            {each.status === "Order-Rejected" ? (
                              <>
                             {each.created},{each.createdTime}
                           
                            </>
                            ):(
                              <>
                               Scheduled for {OrderRejectedDetails.deliveryDate},{" "}
                            {OrderRejectedDetails.deliveryTime}
                              </>
                            )}
                          </p>
                        </div>
                        
                      </div>
                    </div>
                  ))}
              </div>
            </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <CustomButton
          width="350px"
          height="50px"
          borderRadius="20px"
             onClick={handleRejected}
        >
          <Typography fontSize={contentFontSize_18} className="deliveryButton">
            {" "}
            Back
          </Typography>
        </CustomButton>
      </div>
    </div>
  );
}

export default OrderRejected;
