import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    card: {
        boxShadow: 'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        padding: '15px',
        borderRadius: '14px'
    },
    col: {
        padding: '8px',
        color: 'rgb(99, 115, 129)',
        fontWeight: 600,
        fontSize: '14px',
    },
}));

const UserDetails = (props) => {

    const classes = useStyles();

    return (
        <>
            <Row className={classes.col}>
                <Col sm={6} >
                    Customer Name
                </Col>
                <Col sm={6}>
                    {props.Complaint.userid && props.Complaint.userid.firstName && props.Complaint.userid.firstName}
                    {' '}
                    {props.Complaint.userid && props.Complaint.userid.lastName && props.Complaint.userlastName}
                </Col>
            </Row>
            <Row className={classes.col}>
                <Col sm={6}>
                    Email
                </Col>
                <Col sm={6} style={{width:'100%'}}>
                <Tooltip title= {props.Complaint.userid && props.Complaint.userid.email && props.Complaint.userid.email}>
                    <div style={{width:'100%',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',display: 'inline-block'}}>
                    {props.Complaint.userid && props.Complaint.userid.email && props.Complaint.userid.email}
                    </div>
                    </Tooltip>
                </Col>
            </Row>
            <Row className={classes.col}>
                <Col sm={6}>
                    Phone Number
                </Col>
                <Col sm={6}>
                    {props.Complaint.userid && props.Complaint.userid.phoneNumber && props.Complaint.userid.phoneNumber}
                </Col>
            </Row>
        </>
    )
}

export default UserDetails
