import { gql } from "@apollo/client";

export const ALL_COMPLAINTS = gql`
query($complaintStatus:Int,$pagination:PaginationArg){
    allComplaints(complaintStatus:$complaintStatus,pagination:$pagination)
    {
      count
      items{
        id
        orderid{
          orderNumber
          razorpayPaymentId
        }
        storeid{
          storeName
        }
        userid{
          firstName
          lastName
          email
        }
        userMessage
        complaintStatus 
      }
      hasNext
    }
  }`

export const COMPLAINT_DETAILS = gql`
  query($complaintid:ID){
    complaint(complaintid:$complaintid)
    {
      id
      orderid{
        id
        orderNumber
        totalPrice
        deliveryAddress
        deliveryTime
        totalPriceDelivery
        finalBillAmount
        productCount
        lastStatus
        deliveryCharge
        razorpayPaymentId
        shopAssistantId{
          id
          firstName
          lastName
          phoneNumber
        }
        deliveryPartnerId{
          id
          firstName
          lastName
          phoneNumber
        }
          }
          storeid{
          id
          storeName
          storeManagerFirstName
          storeManagerLastName
          storeManagerMobile
          businessType
          forAdmin{
            emailAddress
          }
        }
        userid{
          firstName
          lastName
          email
          phoneNumber
        }
        userMessage
        itemList
        image
        quantity
        refund
        replacement
        refundAmount
        refundStatus
        replacementStatus
        complaintStatus
        adminActions
    }
    }`

export const COMPLAINT_ACTION = gql`   
mutation($complaintid:ID!,$adminAction:[String]){
      complaintAdminAction(complaintid:$complaintid,adminAction:$adminAction)
      {
        id
      }
    }`

export const CLOSE_COMPLAINT = gql`
mutation($complaintid:ID,$complaintStatus:Int){
  changeComplaintStatus(complaintid:$complaintid,complaintStatus:$complaintStatus)
  {
    id
  }
}
`
export const SOLVE_COMPLAINT = gql`
mutation( $complaintid:ID,
          $orderid:ID,
          $orderNumber:Int,
          $refund:Boolean,
          $replacement:Boolean,
          $refundStatus:String,
          $refundAmount:Float!,
          $paymentId:String,
          $replacementStatus:String)
      {
        solveComplaint(
          complaintid:$complaintid,
          orderid:$orderid,
          orderNumber:$orderNumber,
          refund:$refund,
          replacement:$replacement,
          refundStatus:$refundStatus,
          refundAmount:$refundAmount,
          paymentId:$paymentId,
          replacementStatus:$replacementStatus)
               {
                  id
              }
      }`
  
export const ORDER_COMPLAINTS= gql `
query($orderid:ID){
  orderComplaints(orderid:$orderid)
    {
      id
      userMessage
      image
      itemList
      quantity
      refund
      replacement
      complaintStatus
      adminActions
    }
}
`