import React from 'react';
import MainCard from '../../ui-component/cards/MainCard';
import Label from "../Label";
import {
    Stack,
    Typography,
    Dialog
} from '@mui/material';
import Details from './Details';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { CircularProgress } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { AlertTitle } from "@material-ui/lab";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const OpenComplaints = (props) => {

    const [open, setOpen] = React.useState(false);
    const [offset, setOffset] = React.useState(0);

    const handleClickOpen = (id) => {
        props.ComplaintDetails(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const close = () => {
        props.clearclosedcomplaintprops();
        props.allComplaints(1, 15, offset);
        
        setOpen(false);
    };

    const loadPrevious = () => {
        setOffset(offset-15);
        props.allComplaints(1, 15, offset-15);
    };

    const loadNext = () => {
        setOffset(offset+15)
        props.allComplaints(1, 15, offset+15);
    };
    React.useEffect(() => {
        props.allComplaints(1, 15, offset);
    },
        /* eslint-disable */
        [])
    return (
        <div
            style={{
                backgroundColor: "white",
                transform: "translateY(100px)",
                width: "100%",
                borderRadius: "30px",
                // padding: '15px',
            }}
            position="sticky"
        >
            {props.complaintsloading ?
                (<MainCard border={false} elevation={16} content={false}
                    style={{ backgroundColor: 'white', borderRadius: '14px', width: '100%' }}
                    boxShadow
                    shadow={'gb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px'}>
                    <CircularProgress />
                </MainCard>)
                : (
                    props.complaintssuccess && props.Complaints && props.Complaints.allComplaints &&
                        props.Complaints.allComplaints !== null &&
                        props.Complaints.allComplaints.count &&
                        props.Complaints.allComplaints.count > 0 ? (
                        props.Complaints.allComplaints.items &&
                        props.Complaints.allComplaints.items.filter(((a) => a.complaintStatus === 1)).map((c, i) =>
                            <div key={i} style={{ paddingBottom: '12px', cursor: 'pointer',width:'100%' }} onClick={() => handleClickOpen(c.id && c.id)}>
                                <MainCard border={false} elevation={16} content={false}
                                    style={{ backgroundColor: 'white', padding: '15px', borderRadius: '14px', width: '100%', textAlign: 'left' }}
                                    boxShadow
                                    shadow={'gb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px'}>
                                    <span style={{ float: 'left' }}>
                                        <Stack direction="column" alignItems="left" spacing={1}>
                                            <Typography variant="h6" Wrap>
                                                {c.userMessage && c.userMessage.length && c.userMessage.length > 0 &&
                                                    c.userMessage[c.userMessage.length - 1] && c.userMessage[c.userMessage.length - 1]}
                                            </Typography>
                                            <Typography variant="caption" noWrap>
                                                {'Order#'} {' '} {c.orderid && c.orderid.orderNumber && c.orderid.orderNumber}
                                            </Typography>
                                            <Typography variant="caption" noWrap>
                                                {c.storeid && c.storeid.storeName && c.storeid.storeName}
                                            </Typography>
                                            <Typography variant="caption" noWrap>
                                                {'Complaint by: '}{c.userid && c.userid.firstName && c.userid.firstName}
                                                {' '}{c.userid.lastName && c.userid.lastName}
                                            </Typography>
                                        </Stack>
                                    </span>
                                    <span style={{ float: 'right' }}>
                                        <Label
                                            variant="ghost"
                                            color={c.complaintStatus && c.complaintStatus === 1 ? 'warning' : 'info'}
                                        >
                                            {c.complaintStatus && c.complaintStatus === 1 ? 'Open' : 'Closed'}
                                        </Label>
                                    </span>
                                </MainCard>
                            </div>
                        )
                    ) : (
                        <MainCard border={false} elevation={16} content={false}
                            style={{ backgroundColor: 'white', padding: '15px', borderRadius: '14px', width: '90%' }}
                            boxShadow
                            shadow={'gb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px'}>
                            No complaints
                        </MainCard>
                    ))}
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'static', backgroundColor: '#00C853' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {`Complaint #`} {props.Complaint && props.Complaint !== null &&
                                props.Complaint.id && props.Complaint.id}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {props.complaintdetailsloading ?
                    <MainCard border={false} elevation={16} content={false}
                        style={{ backgroundColor: 'white', padding: '15px', borderRadius: '14px', width: '90%' }}
                        boxShadow
                        shadow={'gb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px'}>
                        <CircularProgress />
                    </MainCard> : props.Complaint && props.Complaint !== null &&
                    <Details {...props} />
                }
                {props.complaintclosed || props.closingcomplaintfailed || props.solvedcomplaint || props.solvingcomplaintfailed ? (
                    <Snackbar
                        style={{ width: "fit-content" }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={
                            props.complaintclosed || props.closingcomplaintfailed || props.solvedcomplaint || props.solvingcomplaintfailed
                        }
                        autoHideDuration={2000}
                        onClose={() => close()}>
                        <Alert
                            severity={
                                props.complaintclosed || props.solvedcomplaint
                                    ? "success"
                                    : props.closingcomplaintfailed || props.solvingcomplaintfailed ? "error"
                                        : "info"
                            }>
                            <AlertTitle>
                                {props.complaintclosed
                                    ? "Complaint Closed !"
                                    : props.closingcomplaintfailed
                                        ? "Closing Complaint Failed !"
                                        : props.solvedcomplaint
                                            ? "Complaint Solved !"
                                            : props.solvingcomplaintfailed ?
                                                "Solving Complaint Failed !"
                                                : null}
                            </AlertTitle>
                        </Alert>
                    </Snackbar>
                ) : null}
            </Dialog>
            {
                <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    {offset >0 &&
                        <span style={{ float: 'left', justifyContent: 'left', alignItems: 'left', textAlign: 'left', paddingRight: '30px' }}>
                            <button
                                style={{
                                    padding: '10px',
                                    color: 'rgb(117,194,36)',
                                    backgroundColor: '#ffffff',
                                    boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
                                    height: '40px',
                                    fontSize: '18px',
                                    fontWeight: 600,
                                    borderRadius: '20px',
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderColor: 'transparent',
                                    overflow: 'hidden',
                                    outline: 'none',
                                    fontFamily: ' Varela Round, sans-serif',
                                    textTransform: 'none',
                                    textAlign: 'center',
                                    width: '40px',
                                    cursor: 'pointer',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex'
                                }}
                                onClick={() => loadPrevious()}>
                                <ArrowBackIosIcon fontSize='small' />
                            </button>
                        </span>}
                    {props.complaintssuccess && props.Complaints && props.Complaints.allComplaints &&
                        props.Complaints.allComplaints !== null &&
                        props.Complaints.allComplaints.count &&
                        props.Complaints.allComplaints.count > 0 &&
                        props.Complaints.allComplaints.hasNext === true &&
                        <span style={{ float: 'right', justifyContent: 'right', alignItems: 'right', textAlign: 'right', paddingLeft: '30px' }}>
                            <button
                                style={{
                                    padding: '10px',
                                    color: 'rgb(117,194,36)',
                                    backgroundColor: '#ffffff',
                                    boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
                                    height: '40px',
                                    fontSize: '18px',
                                    fontWeight: 600,
                                    borderRadius: '20px',
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    borderColor: 'transparent',
                                    overflow: 'hidden',
                                    outline: 'none',
                                    fontFamily: ' Varela Round, sans-serif',
                                    textTransform: 'none',
                                    textAlign: 'center',
                                    width: '40px',
                                    cursor: 'pointer',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex'
                                }} onClick={() => loadNext()}>
                                <ArrowForwardIosIcon fontSize='small' />
                            </button>
                        </span>
                    }
                </div>
            }
        </div>
    )
}

export default OpenComplaints
