import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { fieldState, fields } from "./helper";
import TextField from '@material-ui/core/TextField';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./index.css";

const LoginForm = (props) => {
	const [state, setState] = React.useState(fieldState);
	const handleChange = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		props.signInClick(state);
	};
	const [showPassword, setShowPassword] = React.useState(false);

  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };

	return (
		<div className="login_container">
			<form id="login-form">
				<div className="login-heading">
					<h3>{'Administrator'}</h3>
				</div>         
				{fields.map((field) => {
					return (
						<Row className="login-row" key={field.type}>
							<Col className="login-Col">	
								<TextField id={field.name}
								onChange={(e) => {
									handleChange(e);
								}}
								 InputProps={{
									style: {
										width:'100%',borderRadius:'15px'
									},
									endAdornment:
									  field.type === "password" ? (
										<InputAdornment position="end">
										  <div
											style={{ cursor: "pointer" }}
											onClick={handleClick}
										  >
											{showPassword ? (
											  <Visibility style={{ cursor: "pointer" }} />
											) : (
											  <VisibilityOff
												style={{ cursor: "pointer" }}
											  />
											)}
										  </div>
										</InputAdornment>
									  ) : null,
								  }}
								  type={
									(field.name === "password" && showPassword) ||
									field.name === "userName"
									  ? "text"
									  : "password"
								  }
								 name={field.name}
								 placeholder={field.placeholder} 
								label={field.label} variant="outlined" 
								fullWidth
								/>	
							</Col>
						</Row>
					);
				})}
				<Row className="login-row">
					<Col className="login-Col">
						<Button
							id="login-btn"
							onClick={(e) => handleSubmit(e)}
							type="submit"
							variant="success"
							width="100%">
							Login
						</Button>
					</Col>
				</Row>
				{/* {props.isLoggedIn === false && props.errorMessage ? (
					<Snackbar
						style={{ width: "fit-content" }}
						anchorOrigin={{vertical:'top',horizontal:'center'}}
						open={props.isLoggedIn === false ? true : false}
						autoHideDuration={500}>
						<Alert severity="error">
							<AlertTitle>{props.errorMessage.message}</AlertTitle>
						</Alert>
					</Snackbar>
				) : null}
					{props.isPasswordset === false && props.incorrectMessage ? (
					<Snackbar
						style={{ width: "fit-content", left: "65%", bottom: "81%" }}
						open={props.isPasswordset === false ? true : false}
						autoHideDuration={500}>
						<Alert severity="error">
							<AlertTitle>{props.incorrectMessage.message}</AlertTitle>
						</Alert>
					</Snackbar>
				) : null}
				{props.isPasswordset ===true&& props.passwordData ? (
					<Snackbar
						style={{ width: "fit-content", left: "65%", bottom: "81%" }}
						open={props.isPasswordset === false ? true : false}
						autoHideDuration={500}>
						<Alert severity="error">
							<AlertTitle>{props.passwordData.resetPassword}</AlertTitle>
						</Alert>
					</Snackbar>
				) : null} */}
			</form>
		</div>
	);
};
export default LoginForm;
