import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Uploader from "./Uploader";
import MultipleUploader from "./MultipleUploader";
import MenuItem from "@material-ui/core/MenuItem";
import { useFormik } from "formik";
import { validationSchema } from "./Validation";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Container } from "@mui/material";
import { message } from 'antd';
import { useMediaQuery } from 'react-responsive';
// import {NoImage}  from '../../utils/image';
import "./index.css";

const customerFields = [
  { label: "Mfg Name", value: "mfgname" },
  { label: "Brand", value: "brand" },
  { label: "Price", value: "price" },
  { label: "Promo Price", value: "promoprice" },
  // { label: "Unit of Measurment", value: "uom" },
  { label: "Quantity", value: "quantity" },
  { label: "Display Rank", value: "displayrank" },
];
const statusFilters = [
  { option: "active", value: "active" },
  { option: "inactive", value: "inactive" },
];

const ProductDetailsCard = (props) => {
  document.title = "Admin Portal | Edit Products";
  const { getProductDetails, getDepartmentList } = props;
 
  useEffect(()=>{
    // console.log( " props.location.state.masterdepartment",props.location.state.masterdepartment)
    setState({
      masterdepartment:
      props.location.state.masterdepartment})
  },
// eslint-disable-next-line react-hooks/exhaustive-deps
  [])
 
  useEffect(
   
    () => {
      props.getProductDetails(props.location.state.Productid);
      props.getDepartmentList();
      // formik.setFieldValue('status',props.location.state.status);
      // formik.setFieldValue('uom',props.location.state.uom)
      setState({
        productid: props.location.state.Productid,
        displayrank:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.displayrank,
        masterdepartment:
        props.location.state.masterdepartment,
        mastercategory:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory&&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory
            ? props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory
            : '',
        mastercategory1:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1 &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1&&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1
            ? props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1
            : '',
        mastercategory2:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory2 &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory2&&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory2
            ? props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory2
            : '',
      },
      
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getProductDetails, getDepartmentList]
  );


  // 
  useEffect(() => {
    props.getDepartmentList();
    formik.setFieldValue('status',props.location.state.status);
      // formik.setFieldValue('uom',props.location.state.status.uom)
      formik.setFieldValue('uom',props.ProductDetailsData &&
      props.ProductDetailsData.data&&
      props.ProductDetailsData.data.viewParticularMasterProduct&&
      props.ProductDetailsData.data.viewParticularMasterProduct.uom)
    if(props.ProductDetailsData &&
      props.ProductDetailsData.data &&
      props.ProductDetailsData.data.viewParticularMasterProduct &&
      props.ProductDetailsData.data.viewParticularMasterProduct.masterdepartment &&
      props.ProductDetailsData.data.viewParticularMasterProduct.masterdepartment&&
      props.ProductDetailsData.data.viewParticularMasterProduct.masterdepartment){
        setState({ masterdepartment:
          props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.viewParticularMasterProduct &&
            props.ProductDetailsData.data.viewParticularMasterProduct.masterdepartment &&
            props.ProductDetailsData.data.viewParticularMasterProduct.masterdepartment&&
            props.ProductDetailsData.data.viewParticularMasterProduct.masterdepartment})
        props.getCategoryList(props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.masterdepartment &&
          props.ProductDetailsData.data.viewParticularMasterProduct.masterdepartment&&
          props.ProductDetailsData.data.viewParticularMasterProduct.masterdepartment)
      }
    if( props.ProductDetailsData &&
      props.ProductDetailsData.data &&
      props.ProductDetailsData.data.viewParticularMasterProduct &&
      props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory &&
      props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory&&
      props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory){
        props.getCategory1List( props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory&&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory)
      }
      if( props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.viewParticularMasterProduct &&
        props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1 &&
        props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1&&
        props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1){
          props.getCategory2List( props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.viewParticularMasterProduct &&
            props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1 &&
            props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1&&
            props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1)
        }
      setState({
        productid: props.location.state.Productid,
        displayrank:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.displayrank,
        masterdepartment:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.masterdepartment &&
          props.ProductDetailsData.data.viewParticularMasterProduct.masterdepartment&&
          props.ProductDetailsData.data.viewParticularMasterProduct.masterdepartment,
        mastercategory:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory&&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory===null?
          ''
          : props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory&&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory,
        mastercategory1:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1 &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1&&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1===null?
          ''
          :
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1 &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1&&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1
          ,
        mastercategory2:
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory2 &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory2&&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory2===null?
          ''
          :
          props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory2 &&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory2&&
          props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory2
          ,
      });
      formik.setFieldValue('status',props.ProductDetailsData &&
      props.ProductDetailsData.data &&
      props.ProductDetailsData.data.viewParticularMasterProduct&&
      props.ProductDetailsData.data.viewParticularMasterProduct.status)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.ProductDetailsData]
  );

 
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      productname:
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.viewParticularMasterProduct &&
        props.ProductDetailsData.data.viewParticularMasterProduct.productname===null?''
        :
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.viewParticularMasterProduct &&
        props.ProductDetailsData.data.viewParticularMasterProduct.productname,

      desc:
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.viewParticularMasterProduct &&
        props.ProductDetailsData.data.viewParticularMasterProduct.desc===null?''
        :
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.viewParticularMasterProduct &&
        props.ProductDetailsData.data.viewParticularMasterProduct.desc,

      mfgname:
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.viewParticularMasterProduct &&
        props.ProductDetailsData.data.viewParticularMasterProduct.mfgname === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.viewParticularMasterProduct &&
            props.ProductDetailsData.data.viewParticularMasterProduct.mfgname,

      brand:
        (props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.brand) === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.viewParticularMasterProduct &&
            props.ProductDetailsData.data.viewParticularMasterProduct.brand,

      price:
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.viewParticularMasterProduct &&
        props.ProductDetailsData.data.viewParticularMasterProduct.price===null?''
        :
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.viewParticularMasterProduct &&
        props.ProductDetailsData.data.viewParticularMasterProduct.price,

      barcode:
        (props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.barcode) === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.viewParticularMasterProduct &&
            props.ProductDetailsData.data.viewParticularMasterProduct.barcode,

      promoprice:
        (props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.promoprice) === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.viewParticularMasterProduct &&
            props.ProductDetailsData.data.viewParticularMasterProduct.promoprice,

      uom:
        (props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.uom) === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.viewParticularMasterProduct &&
            props.ProductDetailsData.data.viewParticularMasterProduct.uom,

      status:
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.viewParticularMasterProduct&&
        props.ProductDetailsData.data.viewParticularMasterProduct.status,

      quantity:
        (props.ProductDetailsData &&
          props.ProductDetailsData.data &&
          props.ProductDetailsData.data.viewParticularMasterProduct &&
          props.ProductDetailsData.data.viewParticularMasterProduct.quantity) === null
          ? ""
          : props.ProductDetailsData &&
            props.ProductDetailsData.data &&
            props.ProductDetailsData.data.viewParticularMasterProduct &&
            props.ProductDetailsData.data.viewParticularMasterProduct.quantity,

      displayrank:
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.viewParticularMasterProduct &&
        props.ProductDetailsData.data.viewParticularMasterProduct.displayrank===null?''
        :
        props.ProductDetailsData &&
        props.ProductDetailsData.data &&
        props.ProductDetailsData.data.viewParticularMasterProduct &&
        props.ProductDetailsData.data.viewParticularMasterProduct.displayrank,
    },
    validationSchema: validationSchema,
    
    onReset: (values) => {
      formik.setValues({
        productname:"",
        desc:"",
        barcode: "",
        price:"",
        promoprice:"",
        brand:"",
        mfgname:"",
        quantity:"",
        unit:"",
        displayrank:"",
        status:"",
      });
    },

    onSubmit: (values) => {
      console.log("omnSubmit")
      //  if (props.fileListLogo === true && props.proImage === null) {
      //   Modal.confirm({
      //     title: "Confirm",
      //     icon: <ExclamationCircleOutlined />,
      //     content:
      //       "Please upload the image or delete the image before Submiting.",
      //     okText: "Ok",
      //   });
      // } 
      // else  if (props.newMultiFile) {
      //   // if (props.multiEditImage.uploadMultipleFiles.length < 4) {
      //     let multi = [],
      //     i = 0;
      //     let department = state.masterdepartment;
      //     let category = state.mastercategory;
      //     let subcategory = state.mastercategory1;
      //     let category2 = state.mastercategory2;
      //     let productid = state.productid;
      //     let thumbnail = props.newthumbnailUrl
      //     ? props.newthumbnailUrl.imageEditAndUpload.Location
      //     : props.ProductDetailsData &&
      //       props.ProductDetailsData.data &&
      //       props.ProductDetailsData.data.viewParticularMasterProduct &&
      //       props.ProductDetailsData.data.viewParticularMasterProduct.image &&
      //       props.ProductDetailsData.data.viewParticularMasterProduct &&
      //       props.ProductDetailsData.data.viewParticularMasterProduct.image.thumbnail !== null
      //     ? props.ProductDetailsData &&
      //       props.ProductDetailsData.data &&
      //       props.ProductDetailsData.data.viewParticularMasterProduct &&
      //       props.ProductDetailsData.data.viewParticularMasterProduct.image &&
      //       props.ProductDetailsData.data.viewParticularMasterProduct &&
      //       props.ProductDetailsData.data.viewParticularMasterProduct.image.thumbnail
      //     : null;

      //   let primary = localStorage.getItem("updateUrl")
      //     ? localStorage.getItem("updateUrl")
      //     : props.ProductDetailsData &&
      //       props.ProductDetailsData.data &&
      //       props.ProductDetailsData.data.viewParticularMasterProduct &&
      //       props.ProductDetailsData.data.viewParticularMasterProduct.image &&
      //       props.ProductDetailsData.data.viewParticularMasterProduct &&
      //       props.ProductDetailsData.data.viewParticularMasterProduct.image.primary;
      //     // props.newMultiFile.params.map((m) => {
      //     //   multi[i] = m.url;
      //     //   i++;
      //     //   return multi;})
      //     props.newMultiFile &&
      //       props.newMultiFile.params.map((s) => {
      //         if (i <= 4) {
      //           if (s.url) {
      //             multi[i] = s.url;
      //             i++;
      //           }
      //         }
      //         return multi;
      //       });
    //         if(values.promoprice===""){
    //           props.UpdateProduct(
    //             values,
    //             department,
    //             category,
    //             subcategory,
    //             category2,
    //             productid,
    //             thumbnail,
    //             multi,
    //             primary
    //           );
           
    //     }
    //     else{
    //       // 
    //       if(Number(values.promoprice)>=Number(values.price))
          
    //     {  
    //       document.body.scrollTop = 0;
    //       formik.setFieldError('promoprice','Promoprice must be lesser than price.')
    //       message.error({
    //         content: 'Promoprice must be lesser than price',
    //         className: 'custom-class',
    //         style: {
    //           marginTop: '20vh',
    //         },
    //       });}
    //       else{
    //         props.UpdateProduct(
    //           values,
    //           department,
    //           category,
    //           subcategory,
    //           category2,
    //           productid,
    //           thumbnail,
    //           multi,
    //           primary
    //         );
            
    //       }
    //     }
           
    //       }
 
    //    else if (localStorage.getItem("updateUrl")) {
    //     let thumbnail = props.newthumbnailUrl
    //       ? props.newthumbnailUrl.imageEditAndUpload.Location
    //       : props.ProductDetailsData &&
    //         props.ProductDetailsData.data &&
    //         props.ProductDetailsData.data.viewParticularMasterProduct &&
    //         props.ProductDetailsData.data.viewParticularMasterProduct.image &&
    //         props.ProductDetailsData.data.viewParticularMasterProduct &&
    //         props.ProductDetailsData.data.viewParticularMasterProduct.image.thumbnail !== null
    //       ? props.ProductDetailsData &&
    //         props.ProductDetailsData.data &&
    //         props.ProductDetailsData.data.viewParticularMasterProduct &&
    //         props.ProductDetailsData.data.viewParticularMasterProduct.image &&
    //         props.ProductDetailsData.data.viewParticularMasterProduct &&
    //         props.ProductDetailsData.data.viewParticularMasterProduct.image.thumbnail
    //       : null;

    //     let primary = localStorage.getItem("updateUrl")
    //       ? localStorage.getItem("updateUrl")
    //       : props.ProductDetailsData &&
    //         props.ProductDetailsData.data &&
    //         props.ProductDetailsData.data.viewParticularMasterProduct &&
    //         props.ProductDetailsData.data.viewParticularMasterProduct.image &&
    //         props.ProductDetailsData.data.viewParticularMasterProduct &&
    //         props.ProductDetailsData.data.viewParticularMasterProduct.image.primary;
    //     let multi = [],
    //       i = 0;
    //     if (props.newMultiFile) {
    //       // if (props.multiEditImage.uploadMultipleFiles.length < 4) {
    //         props.newMultiFile.params.map((m) => {
    //           multi[i] = m.url;
    //           i++;
    //           return multi;})
    //         props.newMultiFile &&
    //           props.newMultiFile.params.map((s) => {
    //             if (i <= 4) {
    //               if (s.url) {
    //                 multi[i] = s.url;
    //                 i++;
    //               }
    //             }
    //             return multi;
    //           });
    //       // } 
    //     //   else {
    //     //     props.multiEditImage.uploadMultipleFiles.map((m) => {
    //     //       multi[i] = m.Location;
    //     //       i++;
    //     //       return multi;
    //     //     });
    //     //   }
    //      } 
    //     else {
    //       props.ProductDetailsData&&
    //         props.ProductDetailsData.data&&
    //         props.ProductDetailsData.data.viewParticularMasterProduct&&
    //         props.ProductDetailsData.data.viewParticularMasterProduct.image&&
    //         props.ProductDetailsData.data.viewParticularMasterProduct.image.secondary!==null&&
    //         props.ProductDetailsData.data.viewParticularMasterProduct.image.secondary.map(
    //           (s) => {
    //             multi[i] = s;
    //             i++;
    //             return multi;
    //           }
    //         );
    //     }

    //     let department = state.masterdepartment;
    //     let category = state.mastercategory;
    //     let subcategory = state.mastercategory1;
    //     let category2 = state.mastercategory2;
    //     let productid = state.productid;
    //     if(values.promoprice===""){
    //       props.UpdateProduct(
    //         values,
    //         department,
    //         category,
    //         subcategory,
    //         category2,
    //         productid,
    //         thumbnail,
    //         multi,
    //         primary
    //       );
         
    // }
    // else{
    //   // 
    //   if(Number(values.promoprice)>=Number(values.price))
    //   {
    //   document.body.scrollTop = 0;
    //   formik.setFieldError('promoprice','Promoprice must be lesser than price.')
    //   message.error({
    //     content: 'Promoprice must be lesser than price',
    //     className: 'custom-class',
    //     style: {
    //       marginTop: '20vh',
    //     },
    //   });
    // }
    //   else{
    //     props.UpdateProduct(
    //       values,
    //       department,
    //       category,
    //       subcategory,
    //       category2,
    //       productid,
    //       thumbnail,
    //       multi,
    //       primary
    //     );
       
    //   }
    // }
       
        
       
    //   } else {
    //     if (props.multiFileEdited === true) {
    //       let thumbnail = props.newthumbnailUrl
    //         ? props.newthumbnailUrl.imageEditAndUpload.Location
    //         : props.ProductDetailsData &&
    //           props.ProductDetailsData.data &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct.image &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct.image.thumbnail !==
    //             null
    //         ? props.ProductDetailsData &&
    //           props.ProductDetailsData.data &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct.image &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct.image.thumbnail
    //         : null;

    //       let primary = localStorage.getItem("updateUrl")
    //         ? localStorage.getItem("updateUrl")
    //         : props.ProductDetailsData &&
    //           props.ProductDetailsData.data &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct.image &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct.image.primary;

    //       let multi = [],
    //         i = 0;

    //       // let j;

    //       if (props.multiEditImage) {
    //         if (props.multiEditImage.uploadMultipleFiles.length < 4) {
    //           props.multiEditImage.uploadMultipleFiles.map((m) => {
    //             multi[i] = m.Location;

    //             i++;

    //             return multi;
    //           });

    //           props.newMultiFile &&
    //             props.newMultiFile.params.map((s) => {
    //               if (i <= 4) {
    //                 if (s.url) {
    //                   multi[i] = s.url;

    //                   i++;
    //                 }
    //               }

    //               return multi;
    //             });
    //         } else {
    //           props.multiEditImage.uploadMultipleFiles.map((m) => {
    //             multi[i] = m.Location;

    //             i++;

    //             return multi;
    //           });
    //         }
    //       } else {
    //         props.ProductDetailsData &&
    //           props.ProductDetailsData.data &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct.image &&
    //           props.ProductDetailsData.data.viewParticularMasterProduct.image.secondary!==null&&
    //           props.ProductDetailsData.data.viewParticularMasterProduct.image.secondary.map(
    //             (s) => {
    //               multi[i] = s;

    //               i++;

    //               return multi;
    //             }
    //           );
    //       }

    //       let department = state.masterdepartment;

    //       let category = state.mastercategory;

    //       let subcategory = state.mastercategory1;

    //       let category2 = state.mastercategory2;

    //       let productid = state.productid;
    //       if(values.promoprice===""){
    //         props.UpdateProduct(
    //           values,
    //           department,
    //           category,
    //           subcategory,
    //           category2,
    //           productid,
    //           thumbnail,
    //           multi,
    //           primary
    //         );
           
    //   }
    //   else{
    //     // 
    //     if(Number(values.promoprice)>=Number(values.price))
    //     {
    //       document.body.scrollTop = 0;
    //       formik.setFieldError('promoprice','Promoprice must be lesser than price.')
    //     message.error({
    //       content: 'Promoprice must be lesser than price',
    //       className: 'custom-class',
    //       style: {
    //         marginTop: '20vh',
    //       },
    //     });
    //   }
    //     else{
    //       props.UpdateProduct(
    //         values,
    //         department,
    //         category,
    //         subcategory,
    //         category2,
    //         productid,
    //         thumbnail,
    //         multi,
    //         primary
    //       );
        
    //     }
    //   }

    //       // props.UpdateProduct(
    //       //   values,
    //       //   department,
    //       //   category,
    //       //   subcategory,
    //       //   category2,
    //       //   productid,
    //       //   thumbnail,
    //       //   multi,
    //       //   primary
    //       // );
    //       // setTimeout(() => {
    //       //   values.status==="inactive"&&props.getSellerWorking(props.retailerDetails&&props.retailerDetails.data
    //       //     &&props.retailerDetails.data.retailerdetails
    //       //     &&props.retailerDetails.data.retailerdetails["id"])
    //       // }, 3000);
    //     } else {
      
          let department = state.masterdepartment;
          let category = state.mastercategory;
          let subcategory = state.mastercategory1;
          let category2 = state.mastercategory2;
          let productid = state.productid;
          if(values.promoprice===""){
            console.log("if")
            console.log(values, department,
              category,
              subcategory,
              category2,
              productid)
            props.ProductUpload(
              values,
              department,
              category,
              subcategory,
              category2,
              productid
            );
           
      }
      else{
        if(Number(values.promoprice)>=Number(values.price))
      
       {   document.body.scrollTop = 0;
        formik.setFieldError('promoprice','Promoprice must be lesser than price.')
        message.error({
          content: 'Promoprice must be lesser than price',
          className: 'custom-class',
          style: {
            marginTop: '20vh',
          },
        });}
        else{
          console.log("else")
          props.ProductUpload(
            values,
            department,
            category,
            subcategory,
            category2,
            productid
          );
        
        }
      }

        
         
       }

    //   }
    // },
  });
  

  const [state, setState] = useState({
    productid: props.location.state.Productid,
    displayrank:
      props.ProductDetailsData &&
      props.ProductDetailsData.data &&
      props.ProductDetailsData.data.viewParticularMasterProduct &&
      props.ProductDetailsData.data.viewParticularMasterProduct.displayrank,
    masterdepartment:
    props.location.state.masterdepartment,
    mastercategory:
    (  props.ProductDetailsData &&
      props.ProductDetailsData.data &&
      props.ProductDetailsData.data.viewParticularMasterProduct &&
      props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory &&
      props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory&&
      props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory)||""
      ,
    mastercategory1:
     ( props.ProductDetailsData &&
      props.ProductDetailsData.data &&
      props.ProductDetailsData.data.viewParticularMasterProduct &&
      props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1 &&
      props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1&&
      props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory1)||""
      ,
    mastercategory2:
    (  props.ProductDetailsData &&
      props.ProductDetailsData.data &&
      props.ProductDetailsData.data.viewParticularMasterProduct &&
      props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory2 &&
      props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory2&&
      props.ProductDetailsData.data.viewParticularMasterProduct.mastercategory2)||""
      ,
  });
  const handleFormChange = (e, name) => {
    // set
    if (e.target.name === "department") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        mastercategory: null,
        mastercategory1: null,
        mastercategory2: null,
      });
    } else if (e.target.name === "mastercategory") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        mastercategory1: null,
        mastercategory2: null,
      });
    } else if (e.target.name === "mastercategory1") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        mastercategory2: null,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }
  };
  const getCategoryList = (id) => {
    props.getCategoryList(id);
  };
  const getCategory1List = (id) => {
    props.getCategory1List(id);
  };
  const getCategory2List = (id) => {
    props.getCategory2List(id);
  };

  const theme = createMuiTheme({
    palette: {
      primary: green,
    },
    overrides: {
      MuiInputBase: {
        input: {
          "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
        },
      },
    },
  });

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' })

  return (
    isSmallScreen?
<Container maxWidth='md'
      style={{
        backgroundColor: "white",
        border: "1px solid rgba(0, 0, 0, 0.16)",
        borderRadius: "10px",
        Top: "40px",
        padding: "30px",
        width: "100%",
      }}
    >
      <>
        <div
          style={{
            paddingLeft: "30px",
            paddingRight: "10px",
            paddingTop: "15px",
            paddingBottom: "10px",
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          <h5 style={{ fontWeight: 800, fontSize: "23px" }}>Edit product</h5>
        </div>
        <form
          enablereinitialize={'true'}
          initialvalues={formik.initialValues}
          style={{ width: "100%" }}
          onSubmit={formik.handleSubmit}
          id="myForm"
        >
          <Row style={{ width: "100%" }}>
            {props.ProductDetailsData && (
              <>
                <ThemeProvider theme={theme}>
                  <TextField
                    className="form-control"
                    style={{ marginBottom: "12px", marginLeft: 30 }}
                    InputProps={{ style: { height: 35, width: "100%" } }}
                    variant="outlined"
                    label="Product name"
                    name="productname"
                    value={
                     ( formik.values.productname && formik.values.productname)||""
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.productname &&
                      Boolean(formik.errors.productname)
                    }
                    helperText={
                      formik.touched.productname && formik.errors.productname
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // defaultValue={props.ProductDetailsData&&props.ProductDetailsData.data&&
                    //     props.ProductDetailsData.data.viewParticularMasterProduct&&
                    //     props.ProductDetailsData.data.viewParticularMasterProduct.productname&&
                    //     props.ProductDetailsData.data.viewParticularMasterProduct.productname}
                  />
                </ThemeProvider>
                {/* <div style={{ paddingTop:'20px',width:'100%' }}> */}
                <ThemeProvider theme={theme}>
                  <TextField
                    className="form-control"
                    style={{ marginBottom: "12px", marginLeft: 30 }}
                    InputProps={{ style: { height: 50, width: "100%" } }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={(formik.values.desc && formik.values.desc)||""}
                    onChange={formik.handleChange}
                    error={formik.touched.desc && Boolean(formik.errors.desc)}
                    helperText={formik.touched.desc && formik.errors.desc}
                    label="Short description about the product"
                    // defaultValue={props.ProductDetailsData&&props.ProductDetailsData.data&&
                    //     props.ProductDetailsData.data.viewParticularMasterProduct&&props.ProductDetailsData.data.viewParticularMasterProduct.desc
                    // &&props.ProductDetailsData.data.viewParticularMasterProduct.desc}
                    name="desc"
                  />
                </ThemeProvider>
                {/* </div> */}
                <Row
                  style={{ paddingTop: "25px", width: "100%", marginLeft: 10 }}
                >
                  {/* <div style={{ paddingTop:'25px',width:'100%' }}> */}
                  <ThemeProvider theme={theme}>
                    <TextField
                      className="form-control"
                      style={{ marginBottom: "12px", marginLeft: 20 }}
                      InputProps={{ style: { height: 35, width: "99%" } }}
                      variant="outlined"
                      label="Barcode number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={(formik.values.barcode && formik.values.barcode)||""}
                      // defaultValue={props.ProductDetailsData&&props.ProductDetailsData.data&&
                      //     props.ProductDetailsData.data.viewParticularMasterProduct&&props.ProductDetailsData.data.viewParticularMasterProduct.barcode&&
                      //     props.ProductDetailsData.data.viewParticularMasterProduct.barcode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.barcode && Boolean(formik.errors.barcode)
                      }
                      helperText={
                        formik.touched.barcode && formik.errors.barcode
                      }
                      name="barcode"
                    />
                  </ThemeProvider>
                </Row>
                {/* </div> */}
                {customerFields.map((detail,index) => {
                  return (
                    <React.Fragment key={index}>
                      {localStorage.setItem(
                        "imageUrl",
                        props.ProductDetailsData&&
                        props.ProductDetailsData.data&&
                        props.ProductDetailsData.data.viewParticularMasterProduct&&
                          props.ProductDetailsData.data.viewParticularMasterProduct.image!==
                            null
                          ? props.ProductDetailsData.data.viewParticularMasterProduct.image
                              .primary
                          : null
                      )}
                      {localStorage.setItem(
                        "multiple",
                        props.ProductDetailsData&&
                        props.ProductDetailsData.data&&
                        props.ProductDetailsData.data.viewParticularMasterProduct&&
                          props.ProductDetailsData.data.viewParticularMasterProduct.image&&
                          props.ProductDetailsData.data.viewParticularMasterProduct.image.secondary!==null&&
                          props.ProductDetailsData.data.viewParticularMasterProduct.image.secondary.length !== 0
                          ? props.ProductDetailsData.data.viewParticularMasterProduct.image
                              .secondary
                          : null
                      )}

                      <Col md={6} style={{ paddingTop: 30 }}>
                        <ThemeProvider theme={theme}>
                          <TextField
                            key={`${detail.label}`}
                            style={{ marginLeft: "12px" }}
                            InputProps={{ style: { height: 35 } }}
                            label={detail.label}
                            fullWidth
                            name={detail.value && detail.value}
                            // defaultValue={props.ProductDetailsData&&props.ProductDetailsData.data&&
                            //     props.ProductDetailsData.data.viewParticularMasterProduct&&props.ProductDetailsData.data.viewParticularMasterProduct[detail.value]}
                            value={(formik.values[detail.value])||""}
                            onChange={formik.handleChange}
                            error={
                              formik.touched[detail.value] &&
                              Boolean(formik.errors[detail.value])
                            }
                            helperText={
                              formik.touched[detail.value] &&
                              formik.errors[detail.value]
                            }
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </ThemeProvider>
                      </Col>
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </Row>

          <Row style={{ paddingTop: 30, width: "100%" }}>
            <Col md={6} style={{ paddingTop: 30 }}>
              <ThemeProvider theme={theme}>
                <TextField
                  style={{
                    marginBottom: "15px",
                    marginLeft: 12,
                    width: "100%",
                  }}
                  InputProps={{ style: { height: 35, width: "100%" } }}
                  fullWidth
                  variant="outlined"
                  select
                  label="Unit of measurement"
                  name="uom"
                  SelectProps={{ 
                    multiple:false,
                    value:formik.values.uom?formik.values.uom&&formik.values.uom
                      :(props.location.state.uom===null?'':props.location.state.uom)
                   }}
                 
                  onChange={formik.handleChange}
                  error={formik.touched.uom && Boolean(formik.errors.uom)}
                  helperText={formik.touched.uom && formik.errors.uom}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value={"No.s"} style={{ cursor: "pointer" }}>
                    No.s
                  </MenuItem>
                  <MenuItem value={"kg"} style={{ cursor: "pointer" }}>
                    Kilogram (kg)
                  </MenuItem>
                  <MenuItem value={"g"} style={{ cursor: "pointer" }}>
                    gram (g)
                  </MenuItem>
                  <MenuItem value={"gal"} style={{ cursor: "pointer" }}>
                    gallon (gal)
                  </MenuItem>
                  <MenuItem value={"l"} style={{ cursor: "pointer" }}>
                    liter (l)
                  </MenuItem>
                  <MenuItem value={"ml"} style={{ cursor: "pointer" }}>
                    milliliter (ml)
                  </MenuItem>
                </TextField>
              </ThemeProvider>
            </Col>
            <Col md={6} style={{ paddingTop: 30 }}>
              <ThemeProvider theme={theme}>
                <TextField
                 SelectProps={{ 
                  multiple:false,
                  value:formik.values.status?formik.values.status&& formik.values.status
                    :props.location.state.status,
                    style: { width: "100%", height: 35 } 
                 }}
                  onChange={formik.handleChange}
                  error={formik.touched.status&&Boolean(formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                  select
                  variant="outlined"
                  style={{ width: "100%",marginLeft: "12px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Status"
                  name="status"
                >
                  {statusFilters.map((statusFilter, index) => {
                    return (
                      <MenuItem key={index} value={statusFilter.value}>
                        {statusFilter.option}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </ThemeProvider>
            </Col>
          </Row>
          <Row style={{ paddingTop: 30 }}>
            <Col style={{ marginBottom: "20px", marginLeft: "10px" }}>
              <h6>{"Primary image of the product "}</h6>
              <Uploader
                {...props}
                imge={
                  props.ProductDetailsData&&
                  props.ProductDetailsData.data &&
                  props.ProductDetailsData.data.viewParticularMasterProduct &&
                  props.ProductDetailsData.data.viewParticularMasterProduct.image &&
                  props.ProductDetailsData.data.viewParticularMasterProduct.image.primary
                }
              />
              {/* <div Style="display: flex;min-width: 0px;overflow: hidden;">
                   {props.ProductDetailsData&& 
                    // <>
                    //  <img Style="display: block;width: 72px;height: 90px;" 
                    //  src={props.ProductDetailsData.data.viewParticularMasterProduct&&props.ProductDetailsData.data.viewParticularMasterProduct.image&&
                    //  props.ProductDetailsData.data.viewParticularMasterProduct.image!==null?
                    //  `${props.ProductDetailsData.data.viewParticularMasterProduct.image.primary}`:
                    //  NoImage} alt="product"/>
                    //  </>
                   
                    }
                    
                    </div> */}
            </Col>
          </Row>
          <Row style={{ paddingTop: 30 }}>
            <Col style={{ marginBottom: "20px", marginLeft: "10px" }}>
              <h6>{"Secondary image of the product "}</h6>
              <MultipleUploader {...props} product={props.ProductDetailsData} />
            </Col>
          </Row>

          <Row>
            <h6
              style={{
                marginLeft: "10px",
                paddingBottom: "20px",
                paddingTop: "20px",
              }}
            >
              <b>
                {"Select the hierarchy in which the product should display"}
              </b>
            </h6>
          </Row>
          <Row>
            {
            props.DepartmentList &&
            props.DepartmentList.data&&
            props.DepartmentList&&
            props.DepartmentList.length!==0 && (
                <>
                  <Col md={6}>
                    
                    <ThemeProvider theme={theme}>
                      <TextField
                        select
                        variant="outlined"
                        required
                        style={{
                          width: "100%",
                          marginLeft: "12px",
                          marginBottom: "15px",
                        }}
                        InputProps={{
                          style: { height: 35, width: "100%" },
                          autoComplete: "off",
                        }}
                        SelectProps={{ style: { width: "auto", height: 35 },}}
                        label="Department"
                         value={(state.masterdepartment&& state.masterdepartment)||""}
                        //   defaultValue={state.masterdepartment&&state.masterdepartment}
                        name="masterdepartment"
                        onChange={(e) => {
                          setState({
                            ...state,
                            masterdepartment: e.target.value,
                            mastercategory: null,
                            mastercategory1: null,
                            mastercategory2: null,
                          });
                          getCategoryList(e.target.value);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {props.DepartmentList &&
                          props.DepartmentList.data&&
                          props.DepartmentList&&
                          props.DepartmentList.map(
                            (s) => (
                              <MenuItem
                                style={{ width: "fit-content" }}
                                key={s}
                                value={s}
                              >
                                {s.name}
                              </MenuItem>
                            )
                          )}
                      </TextField>
                    </ThemeProvider>
                  </Col>
                  {state.mastercategory !== ''
                    ? props.CategoryList &&
                      props.CategoryList.data &&
                      props.CategoryList.data.mastercategory &&
                      props.CategoryList.data.mastercategory.length !== 0 && (
                        <Col md={6}>
                          <ThemeProvider theme={theme}>
                            <TextField
                              required
                              // defaultValue={state.mastercategory}
                              select
                              SelectProps={{ style: { width: "auto", height: 35 },
                              value:state.mastercategory&&state.mastercategory}}
                              variant="outlined"
                              style={{
                                width: "100%",
                                marginLeft: "12px",
                              }}
                              InputProps={{
                                style: { height: 35, width: "100%" },
                                autoComplete: "off",
                              }}
                              label="Category"
                              name="mastercategory"
                              onChange={(e) => {
                                handleFormChange(e);
                                getCategory1List(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              {props.CategoryList &&
                                props.CategoryList.data.mastercategory.map(
                                  (c) => (
                                    <MenuItem
                                      style={{ width: "fit-content" }}
                                      key={c}
                                      value={c}
                                    >
                                      {c.name}
                                    </MenuItem>
                                  )
                                )}
                            </TextField>
                          </ThemeProvider>
                        </Col>
                      )
                    : props.CategoryList &&
                      props.CategoryList.data &&
                      props.CategoryList.data.mastercategory &&
                      props.CategoryList.data.mastercategory.length !== 0 && (
                        <Col md={6}>
                          <ThemeProvider theme={theme}>
                            <TextField
                              required
                              // defaultValue={state.mastercategory&&state.mastercategory}
                              select
                              variant="outlined"
                              SelectProps={{ style: { width: "auto", height: 35 },
                              value:state.mastercategory&&state.mastercategory}}
                              style={{
                                width: "100%",
                                marginLeft: "12px",
                                marginBottom: "15px",
                              }}
                              InputProps={{
                                style: { height: 35, width: "100%" },
                                autoComplete: "off",
                              }}
                              label="Category"
                              name="mastercategory"
                              onChange={(e) => {
                                handleFormChange(e);
                                getCategory1List(e.target.value);
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              {props.CategoryList &&
                                props.CategoryList.data.mastercategory.map(
                                  (c) => (
                                    <MenuItem
                                      style={{ width: "fit-content" }}
                                      key={c}
                                      value={c}
                                    >
                                      {c.name}
                                    </MenuItem>
                                  )
                                )}
                            </TextField>
                          </ThemeProvider>
                        </Col>
                      )}
                </>
              )}
          </Row>
          {/* SUB CATEGORY SELECT */}

          <Row style={{ paddingTop: 30 }}>
            <>
              {state.mastercategory1 !== ''
                ? props.CategoryList &&
                  props.CategoryList.data.mastercategory.length !== 0 &&
                  props.Category1List &&
                  props.Category1List.data.mastercategory.length !== 0 && (
                    <>
                      <Col md={6}>
                        <ThemeProvider theme={theme}>
                          <TextField
                            required
                            InputLabelProps={{
                              shrink: true,
                            }}
                            select
                            variant="outlined"
                            style={{
                              width: "100%",
                              marginLeft: "12px",
                              marginBottom: "15px",
                            }}
                            InputProps={{
                              style: { height: 35, width: "100%" },
                              autoComplete: "off",
                            }}
                            label="Sub-Category 1"
                            SelectProps={{ style: { width: "auto", height: 35 },
                            value:state.mastercategory1&&state.mastercategory1}}
                            //   defaultValue={state.mastercategory1&&state.mastercategory1}
                            name="mastercategory1"
                            onChange={(e) => {
                              handleFormChange(e);
                              getCategory2List(e.target.value);
                            }}
                          >
                            {props.CategoryList &&
                              props.CategoryList.data.mastercategory.length !==
                                0 &&
                              props.Category1List &&
                              props.Category1List.data.mastercategory.map(
                                (c) => (
                                  <MenuItem
                                    style={{ width: "fit-content" }}
                                    key={c}
                                    value={c}
                                  >
                                    {c.name}
                                  </MenuItem>
                                )
                              )}
                          </TextField>
                        </ThemeProvider>
                      </Col>
                    </>
                  )
                : state.mastercategory !== '' &&
                  props.CategoryList &&
                  props.CategoryList.data.mastercategory.length !== 0 &&
                  props.Category1List &&
                  props.Category1List.data.mastercategory.length !== 0 && (
                    <Col md={6}>
                      <ThemeProvider theme={theme}>
                        <TextField
                          required
                          SelectProps={{ style: { width: "auto", height: 35 },
                          value:state.mastercategory1&&state.mastercategory1}}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          select
                          variant="outlined"
                          style={{
                            width: "100%",
                            marginLeft: "12px",
                            marginBottom: "15px",
                          }}
                          InputProps={{
                            style: { height: 35, width: "100%" },
                            autoComplete: "off",
                          }}
                          label="Sub-Category 1"
                          name="mastercategory1"
                          onChange={(e) => {
                            handleFormChange(e);
                            getCategory2List(e.target.value);
                          }}
                        >
                          {props.CategoryList &&
                            props.CategoryList.data.mastercategory.length !==
                              0 &&
                            props.Category1List &&
                            props.Category1List.data.mastercategory.map((c) => (
                              <MenuItem
                                style={{ width: "fit-content" }}
                                key={c}
                                value={c}
                              >
                                {c.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </ThemeProvider>
                    </Col>
                  )}

              {state.mastercategory2 !== ''
                ? props.Category2List &&
                  props.Category2List.data.mastercategory.length !== 0 && (
                    <Col md={6}>
                      <ThemeProvider theme={theme}>
                        <TextField
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          SelectProps={{ style: { width: "auto", height: 35 },
                          value:state.mastercategory2&&state.mastercategory2}}
                          select
                          variant="outlined"
                          style={{
                            width: "100%",
                            marginLeft: "12px",
                            marginBottom: "15px",
                          }}
                          InputProps={{
                            style: { height: 35, width: "100%" },
                            autoComplete: "off",
                          }}
                          label="Sub-Category 1"
                          //   defaultValue={state.mastercategory2&&state.mastercategory2}
                          name="mastercategory2"
                          onChange={(e) => {
                            handleFormChange(e);
                          }}
                        >
                          {props.Category2List &&
                            props.Category2List.data.mastercategory.map((i) => (
                              <MenuItem
                                style={{ width: "fit-content" }}
                                key={i}
                                value={i}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </ThemeProvider>
                    </Col>
                  )
                : state.mastercategory1 !== '' &&
                  props.Category2List &&
                  props.Category2List.data.mastercategory.length !== 0 && (
                    <Col md={6}>
                      <ThemeProvider theme={theme}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                          SelectProps={{ style: { width: "auto", height: 35 },
                          value:state.mastercategory2&&state.mastercategory2}}
                          select
                          variant="outlined"
                          style={{
                            marginBottom: "15px",
                            width: "100%",
                            marginLeft: "12px",
                          }}
                          InputProps={{
                            style: { height: 35, width: "100%" },
                            autoComplete: "off",
                          }}
                          label="Sub-Category 1"
                          name="mastercategory2"
                          onChange={(e) => {
                            handleFormChange(e);
                          }}
                        >
                          {props.Category2List &&
                            props.Category2List.data.mastercategory.map((i) => (
                              <MenuItem
                                style={{ width: "fit-content" }}
                                value={i}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </ThemeProvider>
                    </Col>
                  )}
            </>
          </Row>

          <Row style={{ paddingBottom: "30px", paddingTop: 30 }}>
            <Col>
              <button
                style={{
                  height: "37px",
                  width: "100%",
                  backgroundColor: "white",
                  border: "2px solid #75c224",
                  color: "#75c224",
                  fontSize: "14px",
                  fontWeight: 600,
                  borderRadius: 5,
                }}
                variant="outline-success"
                onClick={() => {
                  props.history.push("/home/EditProducts");
                }}
              >
                Back
              </button>
            </Col>
            <Col>
              <button
                id="myForm"
                style={{
                  justifyContent: " center",
                  color: "rgb(255, 255, 255)",
                  backgroundColor:'rgb(137, 199, 74, 1)',
                  width: "100%",
                  float: "right",
                  height: "37px",
                  fontSize: "14px",
                  fontWeight: "600",
                  borderRadius: "5px",
                  borderWidth: "1px",
                  borderStyle: " solid",
                  borderColor: "transparent",
                  borderImage: "initial",
                  overflow: "hidden",
                  outline: "none",
                  minHeight: " 14px",
                  fontFamily: "Varela Round, sans-serif",
                }}
                type="submit"
              >
                {props.proupdating === true ? "Updating" : "Update"}
              </button>
            </Col>
          </Row>
        </form>
      </>
    </Container>

    :

    <Container maxWidth='md'
    style={{
      backgroundColor: "white",
      border: "1px solid rgba(0, 0, 0, 0.16)",
      borderRadius: "10px",
      marginTop: "100px",
      padding: "30px",
      width: "100%",
    }}
  >
    <>
      <div
        style={{
          paddingLeft: "30px",
          paddingRight: "10px",
          paddingTop: "15px",
          paddingBottom: "10px",
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        <h5 style={{ fontWeight: 800, fontSize: "23px" }}>Edit product</h5>
      </div>
      <form
        enablereinitialize={'true'}
        initialvalues={formik.initialValues}
        style={{ width: "100%" }}
        onSubmit={formik.handleSubmit}
        id="myForm"
      >
        <Row style={{ width: "100%" }}>
          {props.ProductDetailsData && (
            <>
              <ThemeProvider theme={theme}>
                <TextField
                  className="form-control"
                  style={{ marginBottom: "12px", marginLeft: 30 }}
                  InputProps={{ style: { height: 35, width: "100%" } }}
                  variant="outlined"
                  label="Product name"
                  name="productname"
                  value={
                   ( formik.values.productname && formik.values.productname)||""
                  }
                  onChange={formik.handleChange}
                  error={
                    formik.touched.productname &&
                    Boolean(formik.errors.productname)
                  }
                  helperText={
                    formik.touched.productname && formik.errors.productname
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // defaultValue={props.ProductDetailsData&&props.ProductDetailsData&&
                  //     props.ProductDetailsData.viewParticularMasterProduct&&
                  //     props.ProductDetailsData.viewParticularMasterProduct.productname&&
                  //     props.ProductDetailsData.viewParticularMasterProduct.productname}
                />
              </ThemeProvider>
              {/* <div style={{ paddingTop:'20px',width:'100%' }}> */}
              <ThemeProvider theme={theme}>
                <TextField
                  className="form-control"
                  style={{ marginBottom: "12px", marginLeft: 30 }}
                  InputProps={{ style: { height: 50, width: "100%" } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(formik.values.desc && formik.values.desc)||""}
                  onChange={formik.handleChange}
                  error={formik.touched.desc && Boolean(formik.errors.desc)}
                  helperText={formik.touched.desc && formik.errors.desc}
                  label="Short description about the product"
                  // defaultValue={props.ProductDetailsData&&props.ProductDetailsData&&
                  //     props.ProductDetailsData.viewParticularMasterProduct&&props.ProductDetailsData.viewParticularMasterProduct.desc
                  // &&props.ProductDetailsData.viewParticularMasterProduct.desc}
                  name="desc"
                />
              </ThemeProvider>
              {/* </div> */}
              <Row
                style={{ paddingTop: "25px", width: "100%", marginLeft: 10 }}
              >
                {/* <div style={{ paddingTop:'25px',width:'100%' }}> */}
                <ThemeProvider theme={theme}>
                  <TextField
                    className="form-control"
                    style={{ marginBottom: "12px", marginLeft: 20 }}
                    InputProps={{ style: { height: 35, width: "99%" } }}
                    variant="outlined"
                    label="Barcode number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={(formik.values.barcode && formik.values.barcode)||""}
                    // defaultValue={props.ProductDetailsData&&props.ProductDetailsData&&
                    //     props.ProductDetailsData.viewParticularMasterProduct&&props.ProductDetailsData.viewParticularMasterProduct.barcode&&
                    //     props.ProductDetailsData.viewParticularMasterProduct.barcode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.barcode && Boolean(formik.errors.barcode)
                    }
                    helperText={
                      formik.touched.barcode && formik.errors.barcode
                    }
                    name="barcode"
                  />
                </ThemeProvider>
              </Row>
              {/* </div> */}
              {customerFields.map((detail,index) => {
                return (
                  <React.Fragment key={index}>
                    {localStorage.setItem(
                      "imageUrl",
                      props.ProductDetailsData&&
                      props.ProductDetailsData&&
                      props.ProductDetailsData.viewParticularMasterProduct&&
                        props.ProductDetailsData.viewParticularMasterProduct.image!==
                          null
                        ? props.ProductDetailsData.viewParticularMasterProduct.image
                            .primary
                        : null
                    )}
                    {localStorage.setItem(
                      "multiple",
                      props.ProductDetailsData&&
                      props.ProductDetailsData&&
                      props.ProductDetailsData.viewParticularMasterProduct&&
                        props.ProductDetailsData.viewParticularMasterProduct.image&&
                        props.ProductDetailsData.viewParticularMasterProduct.image.secondary!==null&&
                        props.ProductDetailsData.viewParticularMasterProduct.image.secondary.length !== 0
                        ? props.ProductDetailsData.viewParticularMasterProduct.image
                            .secondary
                        : null
                    )}

                    <Col md={6} style={{ paddingTop: 30 }}>
                      <ThemeProvider theme={theme}>
                        <TextField
                          key={`${detail.label}`}
                          style={{ marginLeft: "12px" }}
                          InputProps={{ style: { height: 35 } }}
                          label={detail.label}
                          fullWidth
                          name={detail.value && detail.value}
                          // defaultValue={props.ProductDetailsData&&props.ProductDetailsData&&
                          //     props.ProductDetailsData.viewParticularMasterProduct&&props.ProductDetailsData.viewParticularMasterProduct[detail.value]}
                          value={(formik.values[detail.value])||""}
                          onChange={formik.handleChange}
                          error={
                            formik.touched[detail.value] &&
                            Boolean(formik.errors[detail.value])
                          }
                          helperText={
                            formik.touched[detail.value] &&
                            formik.errors[detail.value]
                          }
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </ThemeProvider>
                    </Col>
                  </React.Fragment>
                );
              })}
            </>
          )}
        </Row>

        <Row style={{ paddingTop: 30, width: "100%" }}>
          <Col md={6} style={{ paddingTop: 30 }}>
            <ThemeProvider theme={theme}>
              <TextField
                style={{
                  marginBottom: "15px",
                  marginLeft: 12,
                  width: "100%",
                }}
                InputProps={{ style: { height: 35, width: "100%" } }}
                fullWidth
                variant="outlined"
                select
                label="Unit of measurement"
                name="uom"
                SelectProps={{ 
                  multiple:false,
                  value:formik.values.uom?formik.values.uom&&formik.values.uom
                    :(props.location.state.uom===null?'':props.location.state.uom)
                 }}
               
                onChange={formik.handleChange}
                error={formik.touched.uom && Boolean(formik.errors.uom)}
                helperText={formik.touched.uom && formik.errors.uom}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value={"No.s"} style={{ cursor: "pointer" }}>
                  No.s
                </MenuItem>
                <MenuItem value={"kg"} style={{ cursor: "pointer" }}>
                  Kilogram (kg)
                </MenuItem>
                <MenuItem value={"g"} style={{ cursor: "pointer" }}>
                  gram (g)
                </MenuItem>
                <MenuItem value={"gal"} style={{ cursor: "pointer" }}>
                  gallon (gal)
                </MenuItem>
                <MenuItem value={"l"} style={{ cursor: "pointer" }}>
                  liter (l)
                </MenuItem>
                <MenuItem value={"ml"} style={{ cursor: "pointer" }}>
                  milliliter (ml)
                </MenuItem>
              </TextField>
            </ThemeProvider>
          </Col>
          <Col md={6} style={{ paddingTop: 30 }}>
            <ThemeProvider theme={theme}>
              <TextField
               SelectProps={{ 
                multiple:false,
                value:formik.values.status?formik.values.status&& formik.values.status
                  :props.location.state.status,
                  style: { width: "100%", height: 35 } 
               }}
                onChange={formik.handleChange}
                error={formik.touched.status&&Boolean(formik.errors.status)}
                helperText={formik.touched.status && formik.errors.status}
                select
                variant="outlined"
                style={{ width: "100%",marginLeft: "12px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Status"
                name="status"
              >
                {statusFilters.map((statusFilter, index) => {
                  return (
                    <MenuItem key={index} value={statusFilter.value}>
                      {statusFilter.option}
                    </MenuItem>
                  );
                })}
              </TextField>
            </ThemeProvider>
          </Col>
        </Row>
       
        <Row>
          <h6
            style={{
              marginLeft: "10px",
              paddingBottom: "20px",
              paddingTop: "20px",
            }}
          >
            <b>
              {"Select the hierarchy in which the product should display"}
            </b>
          </h6>
        </Row>
        <Row>
          {
          props.DeparmentList &&
          props.DeparmentList.length!==0 && (
              <>
                <Col md={6}>
                  
                  <ThemeProvider theme={theme}>
                    <TextField
                      select
                      variant="outlined"
                      required
                      style={{
                        width: "100%",
                        marginLeft: "12px",
                      }}
                      InputProps={{
                        style: { height: 35, width: "100%" },
                        autoComplete: "off",
                      }}
                      SelectProps={{ style: { width: "auto", height: 35 },}}
                      label="Department"
                       value={(state.masterdepartment && state.masterdepartment)||""}
                      //   defaultValue={state.masterdepartment&&state.masterdepartment}
                      name="masterdepartment"
                      onChange={(e) => {
                        setState({
                          ...state,
                          masterdepartment: e.target.value,
                          mastercategory: null,
                          mastercategory1: null,
                          mastercategory2: null,
                        });
                        console.log(e.target.value)
                        getCategoryList(e.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {props.DeparmentList &&
                        props.DeparmentList.map(
                          (s) => (
                            <MenuItem
                             
                              key={s}
                              value={s.id}
                            >
                              {s.name}
                            </MenuItem>
                          )
                        )}
                    </TextField>
                  </ThemeProvider>
                </Col>
                {state.mastercategory !== null
                  ?
                  //  props.CategoriesList &&
                  //   props.CategoriesList.length !== 0 &&
                     (
                      <Col md={6}>
                        <ThemeProvider theme={theme}>
                          <TextField
                            required
                            // defaultValue={state.mastercategory}
                            select
                            SelectProps={{ style: { width: "auto", height: 35 },
                            value:state.mastercategory&&state.mastercategory}}
                            variant="outlined"
                            style={{
                              width: "100%",
                              marginLeft: "12px",
                            }}
                            InputProps={{
                              style: { height: 35, width: "100%" },
                              autoComplete: "off",
                            }}
                            label="Category"
                            name="mastercategory"
                            onChange={(e) => {
                              handleFormChange(e);
                              getCategory1List(e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            {props.CategoriesList &&
                              props.CategoriesList.map(
                                (c) => (
                                  <MenuItem
                                    key={c.id}
                                    value={c.id}
                                  >
                                    {c.name}
                                  </MenuItem>
                                )
                              )}
                          </TextField>
                        </ThemeProvider>
                      </Col>
                    )
                  : props.CategoriesList &&
                    props.CategoriesList.length !== 0 && (
                      <Col md={6}>
                        <ThemeProvider theme={theme}>
                          <TextField
                            required
                            // defaultValue={state.mastercategory&&state.mastercategory}
                            select
                            variant="outlined"
                            SelectProps={{ style: { width: "auto", height: 35 },
                            value:state.mastercategory&&state.mastercategory}}
                            style={{
                              width: "100%",
                              marginLeft: "12px",
                            }}
                            InputProps={{
                              style: { height: 35, width: "100%" },
                              autoComplete: "off",
                            }}
                            label="Category"
                            name="mastercategory"
                            onChange={(e) => {
                              handleFormChange(e);
                              getCategory1List(e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            {props.CategoriesList &&
                              props.CategoriesList.map(
                                (c) => (
                                  <MenuItem
                                    key={c.id}
                                    value={c.id}
                                  >
                                    {c.name}
                                  </MenuItem>
                                )
                              )}
                          </TextField>
                        </ThemeProvider>
                      </Col>
                    )}
              </>
            )}
        </Row>
        {/* SUB CATEGORY SELECT */}

        <Row style={{ paddingTop: 30 }}>
          <>
            {state.mastercategory1 !== null
              ? props.CategoriesList &&
                props.CategoriesList.length !== 0 &&
                props.Categories1List &&
                props.Categories1List.length !== 0 && (
                  <>
                    <Col md={6}>
                      <ThemeProvider theme={theme}>
                        <TextField
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          select
                          variant="outlined"
                          style={{
                            width: "100%",
                            marginLeft: "12px",
                          }}
                          InputProps={{
                            style: { height: 35, width: "100%" },
                            autoComplete: "off",
                          }}
                          label="Sub-Category 1"
                          SelectProps={{ style: { width: "auto", height: 35 },
                          value:state.mastercategory1&&state.mastercategory1}}
                          //   defaultValue={state.mastercategory1&&state.mastercategory1}
                          name="mastercategory1"
                          onChange={(e) => {
                            handleFormChange(e);
                            getCategory2List(e.target.value);
                          }}
                        >
                          {props.CategoriesList &&
                            props.CategoriesList.length !== 0&&
                            props.Categories1List .map(
                              (c) => (
                                <MenuItem
                                  key={c.id}
                                  value={c.id}
                                >
                                  {c.name}
                                </MenuItem>
                              )
                            )}
                        </TextField>
                      </ThemeProvider>
                    </Col>
                  </>
                )
              : state.mastercategory !==null &&
                props.CategoriesList &&
                props.CategoriesList.length !== 0&&
                props.Categories1List &&
                props.Categories1List.length !== 0 && (
                  <Col md={6}>
                    <ThemeProvider theme={theme}>
                      <TextField
                        required
                        SelectProps={{ style: { width: "auto", height: 35 },
                        value:state.mastercategory1&&state.mastercategory1}}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        select
                        variant="outlined"
                        style={{
                          width: "100%",
                          marginLeft: "12px",
                        }}
                        InputProps={{
                          style: { height: 35, width: "100%" },
                          autoComplete: "off",
                        }}
                        label="Sub-Category 1"
                        name="mastercategory1"
                        onChange={(e) => {
                          handleFormChange(e);
                          getCategory2List(e.target.value);
                        }}
                      >
                        {props.Categories1List &&
                          props.Categories1List.length !== 0 &&
                          props.Categories1List.map((c) => (
                            <MenuItem
                              key={c.id}
                              value={c.id}
                            >
                              {c.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </ThemeProvider>
                  </Col>
                )}

            {state.mastercategory2 !== null
              ? props.Categories2List &&
                props.Categories2List.length !== 0 && (
                  <Col md={6}>
                    <ThemeProvider theme={theme}>
                      <TextField
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                        SelectProps={{ style: { width: "auto", height: 35 },
                        value:state.mastercategory2&&state.mastercategory2}}
                        select
                        variant="outlined"
                        style={{
                          width: "100%",
                          marginLeft: "12px",
                        }}
                        InputProps={{
                          style: { height: 35, width: "100%" },
                          autoComplete: "off",
                        }}
                        label="Sub-Category 2"
                        //   defaultValue={state.mastercategory2&&state.mastercategory2}
                        name="mastercategory2"
                        onChange={(e) => {
                          handleFormChange(e);
                        }}
                      >
                        {props.Categories2List &&
                          props.Categories2List.map((i) => (
                            <MenuItem
                              key={i.id}
                              value={i.id}
                            >
                              {i.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </ThemeProvider>
                  </Col>
                )
              : state.mastercategory1 !==null &&
                props.Categories2List &&
                props.Categories2List.length !== 0 && (
                  <Col md={6}>
                    <ThemeProvider theme={theme}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                        SelectProps={{ style: { width: "auto", height: 35 },
                        value:state.mastercategory2&&state.mastercategory2}}
                        select
                        variant="outlined"
                        style={{
                          width: "100%",
                          marginLeft: "12px",
                        }}
                        InputProps={{
                          style: { height: 35, width: "100%" },
                          autoComplete: "off",
                        }}
                        label="Sub-Category 2"
                        name="mastercategory2"
                        onChange={(e) => {
                          handleFormChange(e);
                        }}
                      >
                        {props.Categories2List &&
                          props.Categories2List.map((i) => (
                            <MenuItem
                              value={i.id}
                            >
                              {i.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </ThemeProvider>
                  </Col>
                )}
          </>
        </Row>

        <Row style={{ paddingBottom: "30px", paddingTop: 30 }}>
          <Col>
            <button
              style={{
                height: "37px",
                width: "100%",
                backgroundColor: "white",
                border: "2px solid #75c224",
                color: "#75c224",
                fontSize: "14px",
                fontWeight: 600,
                borderRadius: 5,
              }}
              variant="outline-success"
              onClick={() => {
                props.history.push("/home/EditMasterProducts");
              }}
            >
              Back
            </button>
          </Col>
          <Col>
            <button
              id="myForm"
              style={{
                justifyContent: " center",
                color: "rgb(255, 255, 255)",
                backgroundColor:'rgb(137, 199, 74, 1)',
                width: "100%",
                float: "right",
                height: "37px",
                fontSize: "14px",
                fontWeight: "600",
                borderRadius: "5px",
                borderWidth: "1px",
                borderStyle: " solid",
                borderColor: "transparent",
                borderImage: "initial",
                overflow: "hidden",
                outline: "none",
                minHeight: " 14px",
                fontFamily: "Varela Round, sans-serif",
              }}
              type="submit"
              onClick={formik.handleSubmit}
            >
              {props.proupdating === true ? "Updating" : "Update"}
            </button>
          </Col>
        </Row>
      </form>
      </>
      </Container>
  );
};
export default ProductDetailsCard;
