import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { NO_RESULT } from "../../utils/image";
import Select from '@mui/material/Select';
import './index.css';
import { FormControl, InputLabel, MenuItem } from "@mui/material";

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
});


export default function Results(props) {
	const classes = useStyles();
	const [status,setStatus] = React.useState('All');
	const [searchresults,setSearchResults] = React.useState(null);

	const handleStatusChange=(e)=>{
		setStatus(e.target.value);
	}
	
	const getDetails = (storeid) => {
		localStorage.setItem('storeid', storeid.toString());
		props.getStoreDetails(storeid);
		
		props.history.push({
			pathname: `/home/upload`
		});
	};



	return (
		<>
    	{((props.searchData &&
			 props.searchData.searchstore &&
			props.searchData.searchstore.items.length !== 0)||
			(
				props.searchData && 
				props.searchData.searchStoreName &&
				props.searchData.searchStoreName.length !== 0 
			)) ?
				<TableContainer
					style={{ width: "100%", overflowX: "auto", marginLeft: "0px" }}
					component={Paper}>
					<Table aria-label="customized table">
						<TableHead>
							<TableRow>
								
								<TableCell align="left">Store Name</TableCell>
                                <TableCell align="left">Store Location</TableCell>
								<TableCell align="left">
								<FormControl fullWidth variant="standard">
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label="Status"
          onChange={(e)=>handleStatusChange(e)}
        >
			 <MenuItem value={'All'}>All</MenuItem>
          <MenuItem value={'active'}>Active</MenuItem>
		  <MenuItem value={'submitted'}>Submitted</MenuItem>
		  <MenuItem value={'inactive'}>Inactive</MenuItem>
		  <MenuItem value={'banned'}>Banned</MenuItem>
		  <MenuItem value={'rejected'}>Rejected</MenuItem>
        </Select>
      </FormControl>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.searchData &&
							props.searchData.searchstore &&
							props.searchData.searchstore.items &&
							props.searchData.searchstore.items.length !== 0? (
								status==="All"?
								props.searchData.searchstore.items.map((row,index) =>
								
										<TableRow
											key={index}>
											<TableCell
											 align="left">
												{row.storeName && row.storeName}
											</TableCell>
                                            <TableCell align="left">

												{row.storeLocation && row.storeLocation}
											</TableCell>
											<TableCell align="left">

												{row.storeStatus && row.storeStatus}
											</TableCell>
                                            <TableCell align="left"
                                            style={{cursor:'pointer'}}
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
												localStorage.setItem(`retailerid`, row.retailer);
											}}>
												Upload
											</TableCell>
										</TableRow>
									
									)
									:
									props.searchData.searchstore.items.filter((i)=>i.storeStatus===status).map((row,index) =>
									<TableRow
										key={index}>
										<TableCell
										 align="left">
											{row.storeName && row.storeName}
										</TableCell>
										<TableCell align="left">

											{row.storeLocation && row.storeLocation}
										</TableCell>
										<TableCell align="left">

											{row.storeStatus && row.storeStatus}
										</TableCell>
										<TableCell align="left"
										style={{cursor:'pointer'}}
										onClick={() => {
											getDetails(row.id);
											localStorage.setItem(`storeid`, row.id);
											localStorage.setItem(`retailerid`, row.retailer);
										}}>
											Upload
										</TableCell>
									</TableRow>
									)
							) : (
								props.searchData && props.searchData.searchStoreName &&
									props.searchData.searchStoreName.length !== 0 ? (
										status==='All'?
									props.searchData.searchStoreName.map((row, index) =>
										// rows.map((row,index)=>(
										<TableRow
											key={index}>
											<TableCell align="left">
												{row.storeName && row.storeName}
											</TableCell>
                                            <TableCell align="left">
												{row.storeLocation && row.storeLocation}
											</TableCell>
											<TableCell align="left">
												{row.storeStatus && row.storeStatus}
											</TableCell>
                                            <TableCell align="left"
                                            style={{cursor:'pointer'}}
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
                                                localStorage.setItem(`storeName`, row.storeName);
												localStorage.setItem(`retailerid`, row.retailer);
											}}>
												<div 
												style={{
													color:'rgb(117,194,36)',
													border:'1px solid rgb(117,194,36)',
													borderRadius:'10px',
													padding:'7px',
													display:'flex',
													justifyContent:'center',
													textAlign:'center',
													alignItems:'center'}}>
												<UploadFileIcon/>&nbsp;Upload
												</div>
											</TableCell>
										
										</TableRow>
									)
									:
									props.searchData.searchStoreName.filter((i)=>i.storeStatus===status).map((row, index) =>
										// rows.map((row,index)=>(
										<TableRow
											key={index}>
											<TableCell align="left">
												{row.storeName && row.storeName}
											</TableCell>
                                            <TableCell align="left">
												{row.storeLocation && row.storeLocation}
											</TableCell>
											<TableCell align="left">
												{row.storeStatus && row.storeStatus}
											</TableCell>
                                            <TableCell align="left"
                                            style={{cursor:'pointer'}}
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
                                                localStorage.setItem(`storeName`, row.storeName);
												localStorage.setItem(`retailerid`, row.retailer);
											}}>
												<div 
												style={{
													color:'rgb(117,194,36)',
													border:'1px solid rgb(117,194,36)',
													borderRadius:'10px',
													padding:'7px',
													display:'flex',
													justifyContent:'center',
													textAlign:'center',
													alignItems:'center'}}>
												<UploadFileIcon/>&nbsp;Upload
												</div>
											</TableCell>
										
										</TableRow>)
									
									)
									: (
										null
									))
							}

						</TableBody>
					</Table>
				</TableContainer>
				:
			props.clearedsearchresults&&
			props.clearedsearchresults!==true&&
				<div style={{   
					width:'75vw',
					paddingTop:'20px',
								 display:'flex',
								 justifyContent:'center'}}>
						 <img style={{ maxWidth: "810px"}} src={NO_RESULT} alt="noresult"></img>
					  </div>
					  
					  }
		</>
	);
}
