import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "../../MUIComponents/index";
import { DialogActions } from "../../MUIComponents/index";
import { DialogContent } from "../../MUIComponents/index";
import { DialogContentText } from "../../MUIComponents/index";
import { DialogTitle } from "../../MUIComponents/index";
import CloseIcon from "@mui/icons-material/Close";

function CustomDialog(props) {
  const {
    open,
    onClose,
    title,
    contentText,
    agreeText,
    disagreeText,
    children,
    titleAlign,
    width,
    height,
    borderRadius,
    className,
    bottom,
    top,
    left,
    right,
    padding,
    
    contentpadding,
    maxWidth,
  showCloseButton } = props;

  const handleClickInside = (event) => {
    // Prevent closing when clicking inside the dialog
    event.stopPropagation();
  };

  const closeButton = showCloseButton ? (
    <div onClick={onClose} style={{ position: 'absolute', right: right, top:top,left:left,bottom:bottom, cursor: 'pointer' }}>
      <div style={{ height: '27px', width: '27px', border: '2px solid #8B8989', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CloseIcon style={{ color: '#8B8989' }} />
      </div>
    </div>
  ) : null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      sx={{
        "& .MuiDialog-paper": {
          width: width, // Set the desired width
          maxHeight: height, // Set the desired max height
          borderRadius: borderRadius,
        },
      }}

      //this is the new code to change the width and height of the dialog
//       sx={{
//         "& .MuiDialog-container": {
//           "& .MuiPaper-root": {
//             width: width,
//             height:height,
//  borderRadius: borderRadius,
//           },
//         },
//       }}
      className={className}
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ padding: padding, textAlign: titleAlign }}

      >
        {title}
        {/* <div
          onClose={onClose}
          style={{
            position: "absolute",
            right: "8px",
            top: "8px",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              height: "27px",
              width: "27px",
              border: "2px solid #8B8989",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CloseIcon style={{ color: "#8B8989" }}  onClick={onClose} />
          </div>
        </div> */}
        {closeButton}
      </DialogTitle>
      <DialogContent onClick={handleClickInside} style={{padding:contentpadding}}>
        <DialogContentText id="alert-dialog-description">
          {children || contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  agreeText: PropTypes.string.isRequired,
  disagreeText: PropTypes.string.isRequired,
  children: PropTypes.node,
  titleAlign: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
  showCloseButton: PropTypes.bool, // New prop to conditionally show/hide the close button
  right:PropTypes.string,
  left:PropTypes.string,
  top:PropTypes.string,
  bottom:PropTypes.string,
  padding:PropTypes.string,
  contentpadding:PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  
};

CustomDialog.defaultProps = {
  showCloseButton: true ,// Default to true, meaning the close button will be shown unless explicitly set to false
  className: PropTypes.string
};

export default CustomDialog;
