import React from "react";
// material
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  useMediaQuery,
  useTheme,
} from '@mui/material';
//components
import Label from "../Label";
import OrderListHead from "./OrderListHead";
import { NO_RESULT } from "../../utils/image";
import { io } from "socket.io-client";
// import NoSearchResult from "../NoSearchResult";

const TABLE_HEAD = [
  { id: 'Customername', label: 'Customer Name', alignRight: false },
  { id: 'store', label: 'Store Name', alignRight: false },
  { id: 'orderno', label: 'Order No.', alignRight: false },
  { id: 'date', label: 'Delivery Date', alignRight: false },
  { id: 'time', label: 'Delivery Time', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

export default function SearchOrderResults(props) {

  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  // const [off,setOff]=React.useState(0)
  const getDetails = (id) => {
    props.getOrderDetails(id);
    localStorage.setItem("orderID", id)
    props.history.push({
      pathname: "/home/Orderdetails",
      state: {
        orderid: id
      }
    });
  };

  // const socket = io.connect(`${process.env.REACT_APP_SOCKET_URL}`);
  // socket.on("connect", () => {
  //   console.log("Connected to the WebSocket server");
  //   if(props.searchOrderData.items){
  //   window.location.reload()
  //   const data = props.searchOrderData.items;
  //   localStorage.setItem("data",data)
  // }
  // });
  return (
    <>
      <Typography
        variant="h6"
        style={{ color: "black", paddingBottom: '10px' }}
      >
        Search Results
      </Typography>
      {props.searchOrderData &&
              props.searchOrderData.items &&
              props.searchOrderData.items.length > 0 ? (
      <TableContainer style={{ width: "100%", overflowX: "auto", marginLeft: "0px" }}>
        <Table>
          <OrderListHead
            headLabel={TABLE_HEAD}
          />
          <TableBody>
              {props.searchOrderData.items.map((row, index) =>
              (
                <TableRow
                  hover
                  style={{ cursor: 'pointer' }}
                  key={row.id}
                  tabIndex={-1}
                  role="checkbox"
                  onClick={() => getDetails(row.id)}
                >
                  <TableCell align="center" >
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {/* <Avatar alt={name} src={avatarUrl} /> */}
                      <Typography variant="subtitle2" noWrap>
                        {row.customerName && row.customerName}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell >{row.storeid.storeName && row.storeid.storeName}</TableCell>
                  <TableCell align="left">{row.orderNumber && row.orderNumber}</TableCell>
                  <TableCell align="left">{row.deliveryDate && row.deliveryDate}</TableCell>
                  <TableCell align="left">{row.deliveryTime && row.deliveryTime}</TableCell>
                  <TableCell align="left">
                    <Label
                      variant="ghost"
                      color={(row.lastStatus === 'Order-Placed' ? 'info' :
                        row.lastStatus === 'Order-Accepted' ? 'primary'
                          : row.lastStatus === 'Order-Rejected' ? 'error' :
                            row.lastStatus === 'Order-Ready' ? 'warning' :
                              row.lastStatus === 'Shopping-In-Progress' ? 'secondary' : 'success')}
                    >
                      {row.lastStatus && row.lastStatus}
                    </Label>
                  </TableCell>
                </TableRow>
              ))}             
          </TableBody>
        </Table>
      </TableContainer>):(
        <div>
          {isSmallScreen ?
                    <div style={{
                      width: '75vw',
                      paddingTop: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      position:'relative',
                      left:'16px'
                    }}>
                      <h5>No Search Results</h5>
                    </div>
                    :
                    <div style={{
                      width: '75vw',
                      paddingTop: '20px',
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                      <img style={{ maxWidth: "810px",alignSelf: 'center' }} src={NO_RESULT} alt="noresult"></img>
                    </div>
                  }
        </div>
      )}
    </>
  );
}
