import React from 'react';
import { Row,Col, Container } from "react-bootstrap";
import MediaCard from './MediaCard';
import NoCard from './NoCard';
import NoImage from '../../utils/images/NoImage.jpg'
import './index.css';

function index(props) {
 
  return (
    <Container>
    <div style={{ fontWeight: 600 }}>
      <h4 style={{ paddingTop:'30px',fontWeight: 500, textAlign: "center" ,color:'black',textDecoration:'underline'}}>
        {"Documents uploaded"}
      </h4>
      <Row style={{paddingLeft:'50px'}}>
          <Col>
            <h5 style={{fontWeight: 500,color:'black',textAlign:'left',justifyContent:'left',alignItems:'left'}}>
                {'Aadhar'}
            </h5>
            <div style={{paddingTop:'30px',fontWeight: 500, textAlign: "center" ,color:'black'}}>
            {
                props.Retailer&&
                props.Retailer.data&&
                props.Retailer.data.retailerdetails&&
                props.Retailer.data.retailerdetails.aadharUrl==null?
                <NoCard image={NoImage}/>
                :
                <MediaCard image={ props.Retailer&&
                    props.Retailer.data&&
                    props.Retailer.data.retailerdetails&&
                    props.Retailer.data.retailerdetails.aadharUrl}/>
            } 
            </div>
            </Col>
            <Col>
            <h5 style={{fontWeight: 500,color:'black',textAlign:'left',justifyContent:'left',alignItems:'left'}}>
                {'License'}
            </h5>
            <div style={{paddingTop:'30px',fontWeight: 500, textAlign: "center" ,color:'black'}}>
            {
                props.searchDetails&&
                props.searchDetails.store&&
                props.searchDetails.store.licenseUrl===null?
                <NoCard image={NoImage}/>
                :
                <MediaCard image={ props.searchDetails&&
                props.searchDetails.store&&
                props.searchDetails.store.licenseUrl}/>
            } 
            </div>
            </Col>
        </Row>
        <Row style={{paddingLeft:'50px',paddingTop:'20px'}}>
           <Col>
           <h5 style={{fontWeight: 500,color:'black',textAlign:'left',justifyContent:'left',alignItems:'left'}}>
                {'GST'}
            </h5>
            <div style={{paddingTop:'30px',fontWeight: 500, textAlign: "center" ,color:'black'}}>
            {
                props.Retailer.data&&
                props.Retailer.data.retailerdetails&&
                props.Retailer.data.retailerdetails.GSTurl===null?
                <NoCard image={NoImage}/>
                :
                <MediaCard image={props.Retailer&&
                    props.Retailer.data&&
                    props.Retailer.data.retailerdetails&&
                    props.Retailer.data.retailerdetails.GSTurl}/>
            } 
            </div>
           </Col>
           <Col>
           {props.searchDetails&&
                props.searchDetails.store&&
                props.searchDetails.store.fssaiUrl?
                <>
            <h5 style={{fontWeight: 500,color:'black',textAlign:'left',justifyContent:'left',alignItems:'left'}}>
                {'Fssai'}
            </h5>
            <div style={{paddingTop:'30px',fontWeight: 500, textAlign: "center" ,color:'black'}}>
            {
                props.searchDetails&&
                props.searchDetails.store&&
                props.searchDetails.store.fssaiUrl===null?
                <NoCard image={NoImage}/>
                :
                <MediaCard image={ props.searchDetails&&
                props.searchDetails.store&&
                props.searchDetails.store.fssaiUrl}/>
            } 
            </div>
       
        </>
         :
         null
}
           </Col>
        </Row>
        <div className='buttons' style={{ paddingTop: '15px' }}>
						<button
							type="button"
							onClick={props.back}
							className='nextbutton'>
							Back
						</button>
						<button type="button"
							onClick={props.next} className='nextbutton'>
							{'Save & Next'}
						</button>
					</div>
      </div>
      </Container>
  )
}

export default index
