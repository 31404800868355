import React from "react";
import { Container } from "react-bootstrap";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import { gql } from "@apollo/client";
import client from "../../Configurations/apollo";
import { UPLOAD_CATEGORY_EXCEL, UPLOAD_BARCODE_EXCEL } from "./Mutation";
import * as XLSX from "xlsx";
import Snackbar from "@material-ui/core/Snackbar";
import { AlertTitle } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import "./index.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class StoreUpload extends React.Component {
  state = {
    cols: [],
    rows: [],
    file: null,
    selectedValue: "category",
    excelFile: null,
    excelFileError: null,
    excelFileErrorOpen: false,
    excelData: null,
    erroredExcel: null,
    PriceRegex: /^([+-]?(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?)$/,
    open: false,
    Erroropen: false,
    ErrorMessage:
      "Something wrong in the excel.Please check the data and upload it again.",
  };

  uploadFile = (event) => {
    console.log("Upload File");

    let selectedFile = event.target.files[0];
    const fileType = ["application/vnd.xlsx"];
    if (selectedFile) {
      if (
        selectedFile.type === "application/vnd.xlsx" ||
        selectedFile.type.toString().includes(".xlsx") ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          this.setState({ excelFileError: null, excelFileErrorOpen: false });
          this.setState({ excelFile: e.target.result });
        };
      } else {
        console.log("Please select only excel file types");
        this.setState({
          excelFileErrorOpen: true,
          excelFileError: "Please select only xlsx file types",
          file: null,
        });
        this.setState({ excelFile: null });
      }
    } else {
      console.log("plz select your file");
    }

    let fileObj = event.target.files[0];
    this.setState({ file: fileObj });
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log("Excel Renderer", err);
      } else {
        const { cols, rows } = resp;
        this.setState(
          {
            cols: cols,
            rows: rows,
          },
          () => {
            const data = [...rows];
            data.shift();
            // this.props.uploadHandler(data);
          }
        );
      }
    });
  };

  containsOnlyNumbers(str) {
    return /^[0-9\.]*$/.test(str);
  }

  uploadExcel = async () => {
    // let files = this.state.file;
    let formData = new FormData();
    formData.append("excelFile", this.state.file);

    //   e.preventDefault();
    if (this.state.excelFile !== null) {
      const workbook = XLSX.read(this.state.excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      this.setState({ excelData: data });

      this.state.selectedValue === "category"
        ? // UPLOAD_CATEGORY_EXCEL

          client
            .mutate({
              mutation: UPLOAD_CATEGORY_EXCEL,
              variables: {
                file: this.state.file,
                storeid: localStorage.getItem("storeid"),
              },
            })
            .then((res) => {
              console.log("RESSS", res);
              if (res.data && res.errors) {
                this.setState({
                  Erroropen: true,
                  ErrorMessage: res.errors[0].message,
                  excelFileErrorOpen: false,
                });
              } else if (
                res.data &&
                res.data.uploadCategorySalesProductsFromExcel.length === 0
              ) {
                this.setState({ open: true });
              } else if (res.errors) {
                this.setState({
                  Erroropen: true,
                  ErrorMessage: res.errors[0].message,
                  excelFileErrorOpen: false,
                });
              }
            })
            .catch((errors) => {
              this.setState({
                Erroropen: true,
                ErrorMessage: errors.message,
                excelFileErrorOpen: false,
              });
            })
        : client
            .mutate({
              mutation: UPLOAD_BARCODE_EXCEL,
              variables: {
                file: this.state.file,
                storeid: localStorage.getItem("storeid"),
              },
            })
            .then((res) => {
              if (res.data && res.errors) {
                this.setState({
                  Erroropen: true,
                  ErrorMessage: res.errors[0].message,
                });
                this.setState({ excelData: null });
              } else if (
                res.data &&
                res.data.uploadBarcodeSalesProductsFromExcel.length === 0
              ) {
                this.setState({ open: true });
              } else if (
                (res.data &&
                  res.data.uploadBarcodeSalesProductsFromExcel !== null) ||
                (res.data &&
                  res.data.uploadBarcodeSalesProductsFromExcel.length > 0)
              ) {
                this.setState({ open: true });
              } else {
                this.setState({
                  Erroropen: true,
                  ErrorMessage:
                    "Something wrong in the excel.Please check the data and upload it again.",
                });
              }
            })
            .catch((err) => {
              this.setState({
                excelData: null,
                Erroropen: true,
                ErrorMessage: err.message,
              });
            });
    } else {
      this.setState({ excelData: null });
    }

    // readXlsxFile( this.state.file).then((rows) => {
    //     console.log("rows",rows)
    //   // `rows` is an array of rows
    //   // each row being an array of cells.
    // })

    //   const url = 'https://servermerchantdb.nearshopz.com/api/excelUpload';
    //         fetch('http://localhost:4000/api/excelUpload',{
    //           method: 'POST',
    //           // url: url,
    //           headers: {
    //             'Content-Type': 'multipart/form-data'
    //           },
    //        body:formData
    //         })
    //           .then(res => console.log(res))
    //           .catch(err => console.log(err));
  };

  handleChange = (event) => {
    this.setState({ selectedValue: event.target.value });
  };

  render() {
    return (
      <Container className="Container-Style">
        <Snackbar
          style={{ width: "100%" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.open}
          autoHideDuration={2000}
          onClose={() => {
            this.setState({
              ...this.state,
              open: false,
              rows: [],
              columns: [],
              file: null,
            });
            this.props.history.push("/home/ExcelUpload");
          }}
        >
          <Alert severity="success">
            <AlertTitle>{"Successfully uploaded"}</AlertTitle>
          </Alert>
        </Snackbar>
        <Snackbar
          style={{ width: "100%" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.Erroropen}
          autoHideDuration={2000}
          onClose={() => {
            this.setState({
              ...this.state,
              Erroropen: false,
              rows: [],
              columns: [],
              file: null,
            });
            this.props.history.push("/home/ExcelUpload");
          }}
        >
          <Alert severity="error">
            <AlertTitle>{this.state.ErrorMessage}</AlertTitle>
          </Alert>
        </Snackbar>
        <Snackbar
          style={{ width: "100%" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.excelFileErrorOpen}
          autoHideDuration={2000}
          onClose={() => {
            this.setState({
              ...this.state,
              Erroropen: false,
              rows: [],
              columns: [],
              file: null,
              excelFileError: false,
              excelFileErrorOpen: false,
            });
            // this.props.history.push('/home/ExcelUpload');
          }}
        >
          <Alert severity="error">
            <AlertTitle>{this.state.excelFileError}</AlertTitle>
          </Alert>
        </Snackbar>
        <div className="excel-import-container">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="category"
              name="radio-buttons-group"
              onChange={this.handleChange}
            >
              <FormControlLabel
                value="category"
                control={<Radio />}
                label="Excel with category"
              />
              <FormControlLabel
                value="barcode"
                control={<Radio />}
                label="Excel with barcode"
              />
            </RadioGroup>
          </FormControl>
          <div className="file-upload">
            <h4>{`${localStorage.getItem("storeName")}`}</h4>
            <input type="file" onChange={this.uploadFile} />
            <button>
              <NoteAddIcon />
            </button>
          </div>
          <div className="excel-table-wrapper">
            <div>
              <OutTable
                data={this.state.rows}
                columns={this.state.cols}
                tableClassName="excel-table"
              />
            </div>
          </div>
          {this.state.file && (
            <div>
              <button className="NextButton" onClick={this.uploadExcel}>
                upload
              </button>
            </div>
          )}
        </div>
      </Container>
    );
  }
}

export default StoreUpload;
