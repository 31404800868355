import React from "react";
import { Row, Col } from "react-bootstrap";
import Container from "@mui/material/Container";
import TextField from "@material-ui/core/TextField";
import Results from "./Results";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import "./index.css";

export default class ExcelUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      mobileNumber: "",
      emailAddress: "",
      sellerName: "",
      offset: 0,
      first: 20,
      Store: [],
      storename: "",
    };
  }

  componentDidMount() {
    this.props.clearsearchstoresData();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.searchData !== prevProps.searchData) {
      this.setState(
        {
          Store: [
            ...this.state.Store,
            this.props.searchData &&
              this.props.searchData.searchstore &&
              this.props.searchData.searchstore.items.map((p) => p),
          ],
        },
        () => {
          this.props.setStore(this.state.Store);
        }
      );
    }
  }

  render() {
    const searchPrevious = () => {
      this.setState(
        {
          ...this.state,
          offset: this.state.offset - 20,
        },
        () => {
          this.props.getSearchResult({ ...this.state });
        }
      );
    };
    const searchOrder = () => {
      this.setState(
        {
          ...this.state,
          offset: this.state.offset + 20,
        },
        () => {
          this.props.getSearchResult({ ...this.state });
        }
      );
    };

    const handleChange = (e) => {
      e.preventDefault();
      if (e.key === "enter") {
        this.props.getSearchResultByName(this.state.storename);
      }
      this.setState({
        ...this.state.storename,
        storename: e.target.value,
      });
    };

    const handleKeypress = (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        this.props.getSearchResultByName(this.state.storename);
      }
    };

    return (
      <Container maxWidth="md" className="Container-Style">
        <Row className="Heading">
          <h5 id="excel">{"Excel Upload"}</h5>
        </Row>

        <form>
          <Row>
            <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
              <TextField
                id="filled-read-only-input"
                label="Store Name"
                type="text"
                name="storename"
                value={this.state.storename}
                variant="outlined"
                onChange={(e) => {
                  handleChange(e);
                }}
                onKeyPress={handleKeypress}
                fullWidth
              />
            </Col>
            <Col md={6} xs={12}>
              <button
                className="searchBtn"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.getSearchResultByName(this.state.storename);
                }}
                type="submit"
              >
                Search Store
              </button>
            </Col>
          </Row>
        </form>
        <Row>
          {this.props.searching ? (
            <Box sx={{  display: 'flex', flexDirection: 'row', alignItems: 'center',width:"100%",justifyContent:"center" }}>
              <CircularProgress />
            </Box>
          ) : this.props.searchSuccess ? (
            <>
              <Results
                stores={this.props.Stores}
                criteria={this.state}
                {...this.props}
              />
              <div
                id="pagination-div"
                style={{ width: "100%", paddingTop: "20px" }}
              >
                {this.state.offset > 0 && (
                  <span className="right">
                    <button
                      className="NextButton"
                      onClick={() => searchPrevious()}
                    >
                      Previous
                    </button>
                  </span>
                )}
                {this.props.searchData &&
                  this.props.searchData.searchstore &&
                  this.props.searchData.searchstore.hasNext === true && (
                    <span className="right">
                      <button
                        className="NextButton"
                        onClick={() => searchOrder()}
                      >
                        Next
                      </button>
                    </span>
                  )}
              </div>
            </>
          ) : null}
        </Row>
      </Container>
    );
  }
}
