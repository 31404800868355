import { useEffect, useState } from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports
import EarningCard from './EarningCard';
import PopularCard from './PopularCard';
import TotalOrderLineChartCard from './TotalOrderLineChartCard';
import TotalStores from './TotalStores';
import TotalShopAssistants from './TotalShopAssistants';
import OrderChart from './OrderChart';
import TotalCustomers from './TotalCustomers';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = (props) => {
      /* eslint-disable */
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        props.getDashboardData();
        setLoading(false);
    }, []);

    return (
        <Grid  container spacing={3} style={{paddingTop:'100px',paddingBottom:'50px',paddingLeft:'20px',paddingRight:'20px'}}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <EarningCard isLoading={props.loadingdashboard} {...props}/>
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <TotalOrderLineChartCard isLoading={props.loadingdashboard} {...props} />
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                <TotalStores isLoading={props.loadingdashboard} {...props}/>
                            </Grid>
                            {/* <Grid item sm={6} xs={12} md={6} lg={12}>
                                <TotalIncomeLightCard isLoading={isLoading} />
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TotalCustomers isLoading={props.loadingdashboard} {...props}/>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <TotalShopAssistants isLoading={props.loadingdashboard} {...props} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                        <PopularCard isLoading={props.loadingdashboard} {...props}/>
                    </Grid>
                    <Grid item xs={12} md={8} style={{justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                        <OrderChart isLoading={props.loadingdashboard} {...props}/>
                    </Grid>
                   
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Dashboard;