import React from "react";
import Dropzone from "react-dropzone";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

class EditImageDropZone extends React.Component {
	state = {
		src: null,
		fileName: "",
		fileType: "",
		file: null,
	};

	handleDrop = (e) => {
		if (e && e.length > 0) {
			const reader = new FileReader();
			this.setState({
				fileName: e[0].name,
				fileType: e[0].type,
				file: e,
			});
			reader.addEventListener("load", () =>
				this.setState({ src: reader.result })
			);
			reader.readAsDataURL(e[0]);
		}
	};

	handleUpload = () => {
		this.props.uploadNewImage({
			file: this.state.file,
		});
	};
	revertUpload = () => {
		this.setState({
			src: null,
			fileName: "",
			fileType: "",
			file: null,
			selectedFile: null,
		});
	};

	render() {
		return !this.props.newSignedUrl ? (
			<>
				<Dropzone multiple={false} onDrop={this.handleDrop}>
					{({ getRootProps, getInputProps }) => {
						let inputProps = { ...getInputProps() };
						return (
							<section style={{ height: "350px" }}>
								{this.state.src ? (
									<div style={{ height: "150px" }}>
										<h1>You have selected image</h1>
										<h4>{this.state.fileName}</h4>
									</div>
								) : null}
								{!this.state.src ? (
									<div
										{...getRootProps({
											className: "dropzone",
											style: { outline: "none" },
										})}>
										<input {...inputProps} />
										<h1 style={{ height: "450px" }}>Click to Add Your Logo</h1>
									</div>
								) : null}
							</section>
						);
					}}
				</Dropzone>
				{this.state.src ? (
					<span>
						<Button
							variant="contained"
							color="primary"
							disabled={this.props.newImageLoading}
							onClick={this.handleUpload}>
							{this.props.isImageLoading ? (
								<CircularProgress color="secondary" />
							) : (
								"Upload"
							)}
						</Button>
						<Button
							disabled={this.props.newImageLoading}
							onClick={this.revertUpload}
							style={{ float: "right" }}>
							cancel
						</Button>
					</span>
				) : null}
			</>
		) : (
			<div style={{ maxWidth: "520px" }}>
				<img
					style={{ maxWidth: "810px", paddingLeft: 80 }}
					src={this.props.newSignedUrl}
					alt="EDITIMAGE">
					</img>
					
			</div>
		);
	}
}
export default EditImageDropZone;

// this.props.newSignedUrl &&
// 			this.props.postNewUrl({
// 				url: this.props.newSignedUrl,
// 				details: this.props.retailerDetails.data.retailerdetails,
// 			});
