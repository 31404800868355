import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';



export default function BasicCard() {
  return (
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Remember
        </Typography>
        <Typography sx={{ fontSize: 12 }} component="div">
        Only upload non-animated photos in jpg/png/jpeg format that are smaller than 1MB.
        </Typography>
      </CardContent>
    </Card>
  );
}