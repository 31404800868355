import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { styled } from '@mui/material/styles';
import { Avatar, Box, Grid, Typography } from '@mui/material';

// // third-party
// import Chart from 'react-apexcharts';

// project imports
import MainCard from '../../ui-component/cards/MainCard';
import SkeletonTotalOrderCard from '../../ui-component/cards/Skeleton/EarningCard';

// import ChartDataMonth from './chart-data/total-order-month-line-chart';
// import ChartDataYear from './chart-data/total-order-year-line-chart';

// assets
// import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { fShortenNumber } from "./formatNumbers";

const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: '#1E88E5',
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '24px',
    '&>div': {
        position: 'relative',
        zIndex: 5
    },
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: '#1565C0',
        borderRadius: '50%',
        zIndex: 1,
        top: -85,
        right: -95,
        [theme.breakpoints.down('sm')]: {
            top: -105,
            right: -140
        }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        width: 210,
        height: 210,
        background: '#1565C0',
        borderRadius: '50%',
        top: -125,
        right: -15,
        opacity: 0.5,
        [theme.breakpoints.down('sm')]: {
            top: -155,
            right: -70
        }
    }
}));

// ==============================|| DASHBOARD - TOTAL ORDER LINE CHART CARD ||============================== //

const TotalOrderLineChartCard = (props) => {
  /* eslint-disable */
    const [timeValue, setTimeValue] = useState(false);

    return (
        <>
            {props.loadingdashboard ? (
                <SkeletonTotalOrderCard />
            ) : (props.dashboardsuccess &&
                props.dashboardData &&
                props.dashboardData !== null &&
                props.dashboardData.data &&
                props.dashboardData.data.dashboard &&
                props.dashboardData.data.dashboard !== null &&
                <CardWrapper border={false} content={false} elevation={0}>
                    <Box sx={{ p: 2.25 }}>
                        <Grid container direction="column">
                            <Grid item>
                                <Grid container justifyContent="space-between">
                                    {/* <Grid item>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                // ...theme.typography.commonAvatar,
                                                // ...theme.typography.largeAvatar,
                                                backgroundColor: '#1565C0',
                                                color: '#fff',
                                                mt: 1
                                            }}
                                        >
                                            <LocalMallOutlinedIcon fontSize="inherit" />
                                        </Avatar>
                                    </Grid> */}
                                    <Grid item>
                                        <Typography
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: 500,
                                                color: '#90CAF9'
                                            }}
                                        >
                                            Total Orders Received
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ mb: 0.75 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                                                    {fShortenNumber(props.dashboardData.data.dashboard.totalOrdersRecieved &&
                                                        props.dashboardData.data.dashboard.totalOrdersRecieved)
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Avatar
                                                    sx={{
                                                        // ...theme.typography.smallAvatar,
                                                        cursor: 'pointer',
                                                        backgroundColor: '#90CAF9',
                                                        color: '#1E88E5'
                                                    }}
                                                >
                                                    <ArrowDownwardIcon fontSize="inherit" sx={{ transform: 'rotate3d(1, 1, 1, 45deg)' }} />
                                                </Avatar>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TotalOrderLineChartCard.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalOrderLineChartCard;
