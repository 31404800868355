import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { styled } from '@mui/material/styles';
import { Avatar, Box, Grid, Typography } from '@mui/material';

// project imports
import MainCard from '../../ui-component/cards/MainCard';
import SkeletonEarningCard from '../../ui-component/cards/Skeleton/EarningCard';

// assets
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { fShortenNumber } from "./formatNumbers";

const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: '#90caf9',
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    borderRadius:'24px',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: '#42a5f5',
        borderRadius: '50%',
        top: -85,
        right: -95,
        [theme.breakpoints.down('sm')]: {
            top: -105,
            right: -140
        }
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: '#42a5f5',
        borderRadius: '50%',
        top: -125,
        right: -15,
        opacity: 0.5,
        [theme.breakpoints.down('sm')]: {
            top: -155,
            right: -70
        }
    }
}));

// ===========================|| DASHBOARD DEFAULT - EARNING CARD ||=========================== //

const TotalCustomers = (props) => {
  /* eslint-disable */
    const [anchorEl, setAnchorEl] = useState(null);


    return (
        <>
            {props.loadingdashboard ? (
                <SkeletonEarningCard />
            ) : (props.dashboardsuccess&&
                props.dashboardData&&
                props.dashboardData!==null&&
                props.dashboardData.data&&
                props.dashboardData.data.dashboard&&
                props.dashboardData.data.dashboard!==null&&       
                <CardWrapper border={false} content={false} elevation={0}>
                    <Box sx={{ p: 2.25 }}>
                        <Grid container direction="column">
                            <Grid item>
                                <Grid container >
                                    <Grid item>
                                        <Avatar
                                         variant="rounded"
                                         sx={{
                                             backgroundColor: '#42a5f5',
                                             color: '#fff',
                                             mt: 1
                                         }}
                                            aria-controls="menu-earning-card"
                                            aria-haspopup="true"
                                        >
                                            <AccountBoxIcon fontSize="inherit" />
                                        </Avatar>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center" >
                                <Grid item>
                                <Typography
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 500,
                                        color: '#42a5f5'
                                    }}
                                >
                                    Total Customers
                                </Typography>
                            </Grid>
                                    <Grid item>
                                        <Typography sx={{ fontSize: '2.5rem', fontWeight: 500, mr: 1,  mb: 0.75 }}>
                                        &nbsp;{fShortenNumber(props.dashboardData.data.dashboard.totalCustomers&&
                                        props.dashboardData.data.dashboard.totalCustomers)
                                        }
                                        </Typography>
                                    </Grid>
                                  
                                </Grid>
                               
                            </Grid>
                           
                        </Grid>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TotalCustomers.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalCustomers;