// import React, { useState, useRef } from "react";
// // import ReactDOM from 'react-dom';
// import ImgCrop from "antd-img-crop";
// import { Upload, Modal } from "antd";
// import Cards from "../../Cards";
// import { Row, Col } from "react-bootstrap";
// // import "antd/dist/antd.css";
// import "./index.css";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
// import { useMediaQuery } from "react-responsive";
// // import ImageEditorRc from 'react-cropper-image-editor';
// import Cropper from "react-easy-crop";
// import "cropperjs/dist/cropper.css";

// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
// }

// const OrderImage = (props) => {
//   const [fileList, setFileList] = useState([]);
//   const isSmallScreen = useMediaQuery({ query: "(max-width: 1224px)" });
// //   React.useEffect(
// //     () => {
// //       (props.MasterProductDetailsData &&
// //         props.MasterProductDetailsData.data &&
// //         props.MasterProductDetailsData.data.viewParticularMasterProduct &&
// //         props.MasterProductDetailsData.data.viewParticularMasterProduct.image &&
// //         props.MasterProductDetailsData.data.viewParticularMasterProduct.image
// //           .primary !== null) ||
// //       (props.MasterProductDetailsData &&
// //         props.MasterProductDetailsData.data &&
// //         props.MasterProductDetailsData.data.viewParticularMasterProduct &&
// //         props.MasterProductDetailsData.data.viewParticularMasterProduct.image &&
// //         props.MasterProductDetailsData.data.viewParticularMasterProduct.image
// //           .primary !== "undefined")
// //         ? setFileList([
// //             {
// //               uid: "-1",
// //               name: "image.png",
// //               status: "done",
// //               url:
// //                 props.MasterProductDetailsData &&
// //                 props.MasterProductDetailsData.data &&
// //                 props.MasterProductDetailsData.data
// //                   .viewParticularMasterProduct &&
// //                 props.MasterProductDetailsData.data.viewParticularMasterProduct
// //                   .image &&
// //                 props.MasterProductDetailsData.data.viewParticularMasterProduct
// //                   .image.primary,
// //             },
// //           ])
// //         : setFileList([]);

// //       props.setImage(
// //         props.MasterProductDetailsData &&
// //           props.MasterProductDetailsData.data &&
// //           props.MasterProductDetailsData.data.viewParticularMasterProduct &&
// //           props.MasterProductDetailsData.data.viewParticularMasterProduct
// //             .image &&
// //           props.MasterProductDetailsData.data.viewParticularMasterProduct.image
// //             .primary
// //       );
// //     },
// //     [props.MasterProductDetailsData]
// //   );
//   const [previewImage, setPreviewImage] = useState();
//   const [previewVisible, setPreviewVisible] = useState();
//   const [previewTitle, setPreviewTitle] = useState();
//   const [openModal, setOpenModal] = useState(false);
//   // const [cropSize, setCropSize] = useState({ width: 512, height: 512 });
//   const handlePreview = async (file) => {
//     if (!file.url && !file.preview) {
//       file.preview = await getBase64(file.originFileObj);
//     }
//     setPreviewImage(file.url || file.preview);
//     setPreviewVisible(true);
//     setPreviewTitle(
//       file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
//     );
//   };
//   const handleCancel = () => {
//     setPreviewVisible(false);
//   };

//   const onChange = ({ fileList: newFileList }) => {
//    // setOpenModal(true);
//     if (props.fileName === "") {
//       Modal.confirm({
//         title: "Confirm",
//         icon: <ExclamationCircleOutlined />,
//         content: "Please fil the product name and upload the image.",
//         okText: "Ok",
//       });
//     } else {
//     //   props.RemoveLogo();
//     //   props.setImage(null);
//       setFileList(newFileList);
//     }
//   };
//   React.useEffect(() => {
//     props.productAdded && setFileList([]);
//   }, [props.productAdded]);

//   const SubmitNow = () => {
//     props.uploadImage({
//       files: fileList[0].originFileObj,
//       fileName: props.fileName,
//     });
//     props.uploadThumbnail({
//       files: fileList[0].originFileObj,
//       fileName: props.fileName,
//     });
//   };

//   const handleRemove = () => {
//     props.SingleDelete();
//   };

//   const dummyRequest = ({ file, onSuccess }) => {
//     setTimeout(() => {
//       onSuccess("ok");
//     }, 0);
//   };
// //   const cropperRef = useRef(null);

//   return isSmallScreen ? (
//     <>
//       <Row>
//         <Col xs={12}>
//           <ImgCrop
//             modalTitle="product"
//             modalWidth={700}
//             modalHeight={800}
//             minZoom={0.1}
//             aspect={1 / 1}
//             rotate={true}
//             objectFit="vertical-cover"
//             grid
//           >
//             <Upload
//               maxCount={1}
//               customRequest={dummyRequest}
//               accept="image/*"
//               listType="picture-card"
//               fileList={fileList}
//               onPreview={handlePreview}
//               onRemove={handleRemove}
//               onChange={onChange}
//             >
//               {fileList.length < 1 && "Click here to choose the image"}
//             </Upload>
//           </ImgCrop>
//         </Col>
//         <Col xs={12}>
//           <Cards />
//         </Col>
//       </Row>
//       {fileList.length !== 0 &&
//       props.masterUrl === null &&
//       props.proImage === null ? (
//         <button
//           style={{
//             justifyContent: " center",
//             color: "rgb(255, 255, 255)",
//             backgroundImage:
//               "-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
//             boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
//             width: "80px",
//             height: "30px",
//             fontSize: "16px",
//             fontWeight: "600",
//             borderRadius: "5px",
//             borderWidth: "1px",
//             borderStyle: " solid",
//             borderColor: "transparent",
//             borderImage: "initial",
//             overflow: "hidden",
//             outline: "none",
//             minHeight: " 14px",
//             fontFamily: "Varela Round, sans-serif",
//           }}
//           type="button"
//           onClick={SubmitNow}
//         >
//           Upload
//         </button>
//       ) : null}
//       <Modal
//         visible={previewVisible}
//         title={previewTitle}
//         footer={null}
//         onCancel={handleCancel}
//         centered
//       >
//         <img alt="example" style={{ width: "100%" }} src={previewImage} />
//       </Modal>
//     </>
//   ) : (
//     <>
//       <Row>
//         <Col>
//           <Upload
//             maxCount={1}
//             customRequest={dummyRequest}
//             accept="image/*"
//             listType="picture-card"
//             fileList={fileList}
//             onPreview={handlePreview}
//             onRemove={handleRemove}
//             onChange={onChange}
//           >
//             {fileList.length < 1 && "Click here to choose the image"}
//           </Upload>
//           {/* </ImgCrop> */}
//         </Col>
//       </Row>
//       {fileList.length !== 0 &&
//       props.masterUrl === null &&
//       props.proImage === null ? (
//         <button
//           style={{
//             justifyContent: " center",
//             color: "rgb(255, 255, 255)",
//             backgroundImage:
//               "-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
//             boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
//             width: "80px",
//             height: "30px",
//             fontSize: "16px",
//             fontWeight: "600",
//             borderRadius: "5px",
//             borderWidth: "1px",
//             borderStyle: " solid",
//             borderColor: "transparent",
//             borderImage: "initial",
//             overflow: "hidden",
//             outline: "none",
//             minHeight: " 14px",
//             fontFamily: "Varela Round, sans-serif",
//           }}
//           type="button"
//           onClick={SubmitNow}
//         >
//           Upload
//         </button>
//       ) : null}

//       <Modal
//         visible={openModal}
//         title={previewTitle}
//         footer={null}
//         onCancel={handleCancel}
//       >
//         {/* <Cropper
//           ref={cropperRef}
//           crossOrigin="true"
//           src={fileList && fileList.length > 0 && fileList[0].originFileObj} // Added guard
//           style={{ height: 400, width: 400 }}
//           aspectRatio={NaN}
//           className={"your custom class"}
//           guides={true}
//           rotatable={true}
//           imageName="image name with extension to download"
//           responseType="blob/base64"
//         /> */}
//       </Modal>
//       <Modal
//         visible={previewVisible}
//         title={previewTitle}
//         footer={null}
//         onCancel={handleCancel}
//       >
//         <img alt="example" style={{ width: "100%" }} src={previewImage} />
//       </Modal>
//     </>
//   );
// };
// export default OrderImage;
// my code 
import React, { useState } from "react";
import ImgCrop from "antd-img-crop";
import { Upload, Modal, Button, message } from "antd";
import { Row, Col } from "react-bootstrap";
import "./index.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import Cards from "../../Cards"; // Assuming this is correctly imported
import CustomButton from "../../CommonComponents/button/index"; 

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const OrderImage = (props) => {
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState();
  const [confirmUploadVisible, setConfirmUploadVisible] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1224px)" });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleUploadConfirm = () => {
    // Perform upload logic here
    console.log("Uploading file...");
    setFileList([]); // Clear fileList after upload
    setConfirmUploadVisible(false); // Close the confirmation modal after upload
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const [show,setShow] = useState(true)
  function handleUpload(){
    console.log("file",fileList)
    const type =2; 
    
    const orderid = localStorage.getItem('Rid')
    const Imgdata = fileList[0]?.originFileObj
    const size = fileList[0]?.size;
    const filename = fileList[0]?.name;
    console.log(Imgdata)
    // {fileList.map((each)=>(
    props?.uploadOrderImage({files:Imgdata,size:size,filename:filename,type:type,orderid})
    console.log("hehe",props.orderImageUploaded)
    // console.log("HUHU",props.orderImageUplo)
   
  
  console.log("error",props.orderUrlError)

  if(Imgdata === undefined ){
    
      message.error({
      content: "Please Upload the Image", 
      className: "custom-class",
      style: {
        marginTop: "40vh",
        marginLeft: "40vh",
      },
    })
 
  }
  else {
    
    message.success({
      content: "Image Uploaded Succesfully", 
      className: "custom-class",
      style: {
        marginTop: "40vh",
        marginLeft: "40vh",
      },
    })
 
   }
}
//   setTimeout(() => { 
//   if(props.orderImageFailed){
//     message.error({
//       content: "Please Upload the Image", 
//       className: "custom-class",
//       style: {
//         marginTop: "20vh",
//         marginLeft: "40vh",
//       },
//     })
//   }
// },1000)
// // ))}
//   }
  return (
    <> 
      <Row>
        <Col xs={6}>
          <ImgCrop
            modalTitle="product"
            modalWidth={500}
            modalHeight={500}
            minZoom={0.1}
            aspect={1 / 1}
            rotate={true}
            objectFit="vertical-cover"
            grid
            
          >
            <Upload
              maxCount={1}
              customRequest={dummyRequest}
              accept="image/*"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onRemove={handleRemove}
              onChange={onChange}
            
            >
              {fileList.length < 1 && "Click here to choose the image"}
            </Upload>
          </ImgCrop>
        </Col>
        <Col xs={12}>
          <Cards />
        </Col>
      </Row>
      {fileList.length !== 0 && props.masterUrl === null && props.proImage === null ? (
        <Button
          style={{
            marginTop: "10px",
            backgroundColor: "rgb(83,170,73)",
            color: "white",
            border: "none",
          }}
          onClick={() => setConfirmUploadVisible(true)}
        >
          Confirm Upload
        </Button>
      ) : null}
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        centered
      >
        <img alt="example" style={{ width: "50%" }} src={previewImage} />
      </Modal>
      <Modal
        visible={confirmUploadVisible}
        title="Confirm Upload"
        onCancel={() => setConfirmUploadVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setConfirmUploadVisible(false)}>
            Cancel
          </Button>,
          <Button key="upload" type="primary" onClick={handleUploadConfirm}>
            Upload
          </Button>,
        ]}
      >
        Are you sure you want to upload this image?
      </Modal>
      {confirmUploadVisible && (
        <CustomButton
          style={{
            marginTop: "10px",
            backgroundColor: "rgb(83,170,73)",
            color: "white",
            border: "none",
          }}
          onClick={handleUploadConfirm}
        >
          Upload
        </CustomButton>
      )}
      <div style={{marginTop:"20px"}}>
      <CustomButton onClick={handleUpload}>
        Upload
      </CustomButton>
      </div>
    </> 
  );
};

export default OrderImage;
// end
