import PropTypes from 'prop-types';
// material
import { TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';

// ----------------------------------------------------------------------

OrderListHead.propTypes = {
  headLabel: PropTypes.array,
};

export default function OrderListHead({
  headLabel,
}) {
  return (
    <TableHead>
      <TableRow style={{ textAlign: "center" }}>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.alignRight ? 'right' : 'left'}
            style={{width:'16%',padding:0, textAlign:'center'}}
          >
            <TableSortLabel
              hideSortIcon
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}