export const handleFormChange = (e, configuration) => {
	let { id, value, type } = e.target;
	if (type === "checkbox") {
		if (e.target.checked === true) {
			configuration[id].options[value].status = true;
			configuration[id].value.push(configuration[id].options[value].name);
		} else {
			let index = configuration[id].value.indexOf(
				configuration[id].options[value].name
			);
			configuration[id].options[value].status = false;
			configuration[id].value.splice(index, 1);
		}
	} else {
		configuration[id].value = value;
	}
};

export const handleTableChange = (e, index, config) => {
	let { value } = e.target;
	config.charges[index].value = value;
};
// NO seprate handler for pin drop in  map.so its integrated with this
export const getLocationChange = (val, config, index, coordinate) => {
	config[index].value = val;
	config[index].Coordinates = [coordinate];
	config[4].value = { coordinates: [coordinate.lng, coordinate.lat] };
};
export const getRadioButtonChange = (val, config, index) => {
	config[index].value = val;
};
export const getCoordinatesMap = (val, config, index) => {
	config[4].value = { coordinates: [val.lng, val.lat] };
	// config[4].value.coordinates[0] = val.lng;
	// config[4].value.coordinates[1] = val.lat;
};

export const formWrap = async (state, timings) => {
	let {
		deliveryForm,
		distanceTable,
		retailerFormConfiguration,
		storeDetailConfiguration,
	} = state;
	const datawrap = (timings) => {
		let time = [];
		timings.forEach((timing) => {
			var obj = {};
			obj["deliveryDay"] = timing.day;
			obj["T9amTO12pm"] = timing.slots[0].status === true ? "Yes" : "No";
			obj["T12pmTO3pm"] = timing.slots[1].status === true ? "Yes" : "No";
			obj["T3pmTO6pm"] = timing.slots[2].status === true ? "Yes" : "No";
			obj["T6pmTO9pm"] = timing.slots[3].status === true ? "Yes" : "No";
			time.push(obj);
		});
		return time;
	};
	let datamap = datawrap(timings);

	let distanceValue = {
		[distanceTable[1].name]: distanceTable[1].charges.map((charge) => {
			return {
				kmRange: charge.label,
				charge: charge.value,
			};
		}),
	};

	let form = [
		...deliveryForm,
		...retailerFormConfiguration,
		...storeDetailConfiguration,
		{ name: "deliveryDays", value: datamap },
	];

	const formValue = [];
	let distanceMaxDistance = {
		[distanceTable[0].name]: distanceTable[0].value,
	};
	const obj = {};
	form.forEach((fer) => {
		obj[fer.name] = fer.value;
	});
	formValue.push(obj);
	formValue.push(distanceValue);
	formValue.push(distanceMaxDistance);
	return formValue;
};
export const handleAddToForm = (e, form) => {
	if (e.target) {
		form[e.target.id].value = e.target.value;
	} else {
		form[10].value = form[0].value;
		form[11].value = form[1].value;
		form[12].value = form[2].value;
		form[13].value = form[3].value;
	}
};
