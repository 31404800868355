import React from 'react'
import '@coreui/coreui/dist/css/coreui.min.css';
import {Row} from 'react-bootstrap';
const Footer =()=> {
 
        return (
          <div style={{display:'flex',textAlign:'center',alignItems:'center',justifyContent:'center',width:'100%'}}>          
          <Row style={{display:'flex',textAlign:'center',alignItems:'center',justifyContent:'center',width:'100%'}}>
            <span>&copy; Copyright 2022 NearShopz - All rights Reserved.</span>
          </Row>    
      </div>
        )
 
}
 
export default Footer
