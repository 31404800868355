import Home from "../Routes/HomePage";
import SignIn from "../Routes/SignInPage";
import PageNotFound from "../Components/PageNotFound/PageNotFound"
import StoreSearch from "../Components/StoreSearch";
import StoreStepper from "../Components/StoreStepper";

export const routesList = [
	{
		path: "/home",
		components: SignIn,
		exact:true,
	},	
	{
		path: "/home/*",
		components: SignIn,
		exact:true,
	},	
	{
		path: "/",
		components: Home,
		exact:true
	},

	
];
