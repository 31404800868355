import { createSelector } from "reselect";

const getSignInReducer = (state) => state.SignInReducer;

const mergeSelectedReducers = (SignInReducer) => ({ ...SignInReducer });

export const SelectState = createSelector(
	[getSignInReducer],
	mergeSelectedReducers
);
