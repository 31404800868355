import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";
import TrackDetails from "./TrackDetails";

export default function TrackHistory(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  /* eslint-disable */
  const [maxWidth, setMaxWidth] = React.useState('md');

  const handleClickOpen = (id) => {
    setOpen(true);
    props.shopAssistantDetails(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Link component="button" variant="body2" onClick={()=>handleClickOpen(props.Assistantid)}>
        Track History
      </Link>
      <Dialog
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Order History"}
        </DialogTitle>
        <DialogContent>
            <TrackDetails 
            Orderstatus={props.OrderStatus}
            {...props}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
           Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
