import * as yup from 'yup';

export const validationSchema = yup.object({
    couponCode: yup.string()
    .trim("Space not allowed")
    .required('Coupon code is required')
    .min(3, "Enter atleast 3 characters")
    .max(15, "Only 15 characters are allowed"),
    usesperCustomer: yup.string()
    .trim("Space not allowed")
    .required('Uses per customer is required')
    .matches(/^\d+$/,'Only numbers are allowed'),
    disAmt:yup.string()
    .trim("Space not allowed")
    .required('Discount amount is required')
    .matches(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,'Only numbers are allowed'),
    minOrder:yup.string()
    .trim("Space not allowed")
    .required('Minimum order is required')
    .matches(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,'Only numbers are allowed'),
    disPercent:yup.string()
    .trim("Space not allowed")
    .required('Discount percent is required')
    .matches(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,'Only numbers are allowed'),
    // fromdate:yup.string()
    // .trim("Space not allowed")
    // .required('From Date is required'),
    // todate:yup.string()
    // .trim("Space not allowed")
    // .required('To Date is required'),
    fromtime:yup.string()
    .trim("Space not allowed")
    .required('From Time is required'),
    totime:yup.string()
    .trim("Space not allowed")
    .required('To Time is required'),
})

export const flatdiscountvalidationSchema = yup.object({
    couponCode: yup.string()
    .trim("Space not allowed")
    .required('Coupon code is required')
    .min(3, "Enter atleast 3 characters")
    .max(15, "Only 15 characters are allowed"),
    usesperCustomer: yup.string()
    .trim("Space not allowed")
    .required('Uses per customer is required')
    .matches(/^\d+$/,'Only numbers are allowed'),
    disAmt:yup.string()
    .trim("Space not allowed")
    .required('Discount amount is required')
    .matches(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,'Only numbers are allowed'),
    minOrder:yup.string()
    .trim("Space not allowed")
    .required('Minimum order is required')
    .matches(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,'Only numbers are allowed'),
    // fromdate:yup.string()
    // .trim("Space not allowed")
    // .required('From Date is required'),
    // todate:yup.string()
    // .trim("Space not allowed")
    // .required('To Date is required'),
    fromtime:yup.string()
    .trim("Space not allowed")
    .required('From Time is required'),
    totime:yup.string()
    .trim("Space not allowed")
    .required('To Time is required'),
})

export const buyxgetyvalidationSchema = yup.object({
    couponCode: yup.string()
    .trim("Space not allowed")
    .required('Coupon code is required')
    .min(3, "Enter atleast 3 characters")
    .max(15, "Only 15 characters are allowed"),
    usesperCustomer: yup.string()
    .trim("Space not allowed")
    .required('Uses per customer is required')
    .matches(/^\d+$/,'Only numbers are allowed'),
    buyitem:yup.string()
    .trim("Space not allowed")
    .required('Field is required')
    .matches(/^\d+$/,'Only numbers are allowed'),
    getitem:yup.string()
    .trim("Space not allowed")
    .required('Field is required')
    .matches(/^\d+$/,'Only numbers are allowed'),
    selectedproducts:yup.string()
    .trim("Space not allowed")
    .required('Please select items'),
    // fromdate:yup.string()
    // .trim("Space not allowed")
    // .required('From Date is required'),
    // todate:yup.string()
    // .trim("Space not allowed")
    // .required('To Date is required'),
    fromtime:yup.string()
    .trim("Space not allowed")
    .required('From Time is required'),
    totime:yup.string()
    .trim("Space not allowed")
    .required('To Time is required'),
})

export const freedeliveryvalidationSchema = yup.object({
    couponCode: yup.string()
    .trim("Space not allowed")
    .required('Coupon code is required')
    .min(3, "Enter atleast 3 characters")
    .max(15, "Only 15 characters are allowed"),
    usesperCustomer: yup.string()
    .trim("Space not allowed")
    .required('Uses per customer is required')
    .matches(/^\d+$/,'Only numbers are allowed'),
    minOrder:yup.string()
    .trim("Space not allowed")
    .required('Minimum order is required')
    .matches(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,'Only numbers are allowed'),
    // fromdate:yup.string()
    // .trim("Space not allowed")
    // .required('From Date is required'),
    // todate:yup.string()
    // .trim("Space not allowed")
    // .required('To Date is required'),
    fromtime:yup.string()
    .trim("Space not allowed")
    .required('From Time is required'),
    totime:yup.string()
    .trim("Space not allowed")
    .required('To Time is required'),
})