import { gql } from "@apollo/client";

export const GET_CATEGORY = gql`
query($id:ID!){
  mastercategory(id:$id){
    id
    name
    parentid
    status
    childCategoryPresent
    displayrank
    businessTypeId
    categoryProductsCount
  }
}
`

export const UPDATE_HEIRARCHY = gql`
  mutation (
    $hierarchyid: ID!
    $name: String
    $parentid: ID
    $status: String
    $displayrank: Int
    $childCategoryPresent: Boolean
    $businessTypeId:[Int]
  ) {
    updateMasterHierarchy(
      hierarchyid: $hierarchyid
      name: $name
      parentid: $parentid
      status: $status
      displayrank: $displayrank
      childCategoryPresent: $childCategoryPresent
      businessTypeId:$businessTypeId
    ) {
      id
      name
      status
      businessTypeId
      displayrank
      childCategoryPresent
    }
  }
`;
export const UPDATE_HEIRARCHY1 = gql`
  mutation (
    $hierarchyid: ID!
    $name: String
    $status: String
    $displayrank: Int
    $childCategoryPresent: Boolean
    $businessTypeId:[Int]
  ) {
    updateMasterHierarchy(
      hierarchyid: $hierarchyid
      name: $name
      status: $status
      displayrank: $displayrank
      childCategoryPresent: $childCategoryPresent
      businessTypeId:$businessTypeId
    ) {
      id
      name
      status
      businessTypeId
      displayrank
      childCategoryPresent
    }
  }
`;
export const CREATE_HEIRARCHY = gql`
  mutation (
    $name: String!
    $displayrank: Int!
    $parentid: ID
    $status: String!
    $childCategoryPresent: Boolean!
    $level:Int
    $parentChildCategory:Boolean
    $businessTypeId:[Int]
  ) {
    createMasterHierarchy(
      level:$level
      parentChildCategory:$parentChildCategory
      name: $name
      displayrank: $displayrank
      parentid: $parentid
      status: $status
      childCategoryPresent: $childCategoryPresent
      businessTypeId:$businessTypeId
    ) {
      id
      name
      status
      businessTypeId
      displayrank
      childCategoryPresent
    }
  }
`;

export const CREATE_FIRSTLEVEL_HEIRARCHY = gql`
  mutation (
    $name: String!
    $displayrank: Int!
    $parentid: ID
    $status: String!
    $childCategoryPresent: Boolean!
    $level:Int
    $businessTypeId:[Int]
  ) {
    createMasterHierarchy(
      level:$level
      name: $name
      displayrank: $displayrank
      parentid: $parentid
      status: $status
      childCategoryPresent: $childCategoryPresent
      businessTypeId:$businessTypeId
    ) {
      id
      name
      status
      displayrank
      businessTypeId
      childCategoryPresent
    }
  }
`;