import client from "../../../Configurations/apollo";
import { gql } from "@apollo/client";

const GET_LOGIN = gql`
query($email:String!,$password:String!,$fcmToken:String)
{
	adminlogin(email:$email,password:$password,fcmToken:$fcmToken)
	{
		userid
  		token
	}
}
`;

export default function loginAccess(params) {
	return async (dispatch) => {
		function onSuccess(success) {
			dispatch({ type: "LOGIN_ACCESS_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "LOGIN_ACCESS_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "LOGIN_ACCESS_ERROR" });
		}
		const success = await client.query({
			query: GET_LOGIN,
			variables: {
				email: params.userName,
				password: params.password,
				fcmToken:localStorage.getItem('fcmToken')?localStorage.getItem('fcmToken'):null
			},
		});
		if (success.data.adminlogin !== null) {
			localStorage.setItem("token",success.data.adminlogin.token);
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
}


