import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import './index.css';

export default function BankDetails(props) {
  React.useEffect(()=>{
		document.body.scrollTop = 0;
	}
	,[])

  const validationSchema = Yup.object().shape({
		accountHolderName: Yup.string()
    .required("Required")
    .matches(/^[a-zA-Z ]*$/,'only alphabets are allowed'),
    bankAccountNumber:Yup.string().matches(/^\S*$/,'Space is not allowed')
    .required("Required")
    .matches(/^\d{9,18}$/,"please enter valid Account Number"),
    IFSCcode:Yup.string()
    .required("Required")
    .matches(/^\S*$/,'Space is not allowed')
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/,"please enter valid IFSC Code"),
    razorPayAccountId:Yup.string()
    .required("Required")
    .min(7,"Razorpay account id should contain atleast 7 characters")
    .trim("Space not allowed")
    .matches(/^[a][c][c][_][A-Za-z0-9]*$/, "account id must start with acc_"),
	  });
	  
	  const formik = useFormik({
		initialValues: {
 
      accountHolderName: (props.searchDetails&&props.searchDetails.store&&
      props.searchDetails.store.bankDetails&&
      props.searchDetails.store.bankDetails.accountHolderName) || "",
      bankAccountNumber:props.searchDetails.store.bankDetails.bankAccountNumber || "",
      IFSCcode:props.searchDetails.store.bankDetails.IFSCcode || "",
      razorPayAccountId:props.searchDetails.store.razorPayAccountId || ""
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
      props.saveDetails({...props.searchDetails.store,
        bankDetails:{accountHolderName:values.accountHolderName,
          bankAccountNumber:values.bankAccountNumber,
          IFSCcode:values.IFSCcode
        },
        razorPayAccountId:values.razorPayAccountId
			  });
		 props.next();
		},
	  });
  const bankLabels = [
    "Account Holder Name",
    "Account Number",
    "IFSC Code",
    "RazorPay Account Id"
  ];
  /* eslint-disable */
  let retailerDetails =
    props.searchDetails &&
    props.searchDetails.store &&
    Object.keys(props.searchDetails.store.bankDetails);

  let bankTextField = [
    "accountHolderName",
    "bankAccountNumber",
    "IFSCcode",
    "razorPayAccountId"
  ];
  return (
    <Container>
      <div style={{ fontWeight: 600 }}>
        <h4 style={{ paddingTop:'30px',fontWeight: 500, textAlign: "center" ,color:'black',textDecoration:'underline'}}>
          {"Bank Details"}
        </h4>
        <form onSubmit={formik.handleSubmit}>
          <ul>
            <Row>
              {bankLabels
                ? bankTextField.map((detail, index) =>
                    index < 7 ? (
                      <Col md={6} key={index} style={{ paddingTop: 30 }}>
                        <TextField
                          InputProps={{ style: { height: 50 , borderRadius:'15px' } }}
                          id="filled-read-only-input"
                          label={bankLabels[index]}
                          background="red"
                          name={detail}
                          value={formik.values[detail]}
                      	  onChange={formik.handleChange}
                      	  error={
                          formik.touched[detail] && Boolean(formik.errors[detail])
                      	  }
                      	  helperText={formik.touched[detail] && formik.errors[detail]}
                          variant="outlined"
                          fullWidth
                        />
                      </Col>
                    ) : null
                  )
                : null}
            </Row>
          </ul>
          <div className='buttons'>
          <button
          type="button"
										onClick={props.back}
                    className='nextbutton'>
										Back
									</button>
          <button type='submit' className='nextbutton'>
            {'Save & Next'}
          </button>
          </div>
        </form>
      </div>
    </Container>
  );
}
