import React from "react";
import TextField from "@material-ui/core/TextField";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Grid from "@material-ui/core/Grid";
class EditLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.searchDetails.store.storeLocation,
    };
  }
  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({lat,lng}) =>
        this.props.getEditedLocation({
          address: address,
          coordinates: {lat:lat,lng:lng},
        })
      )
      .catch((error) => console.error("Error", error));
  };

  render() {
    const searchOptions = {
      componentRestrictions: { country: ["IN"] },
    };
    
    return (
      <PlacesAutocomplete
        searchOptions={searchOptions}
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div
            style={{
              margin: "16px 0px 0px",
              padding: "3px 0 25px",
              marginTop: "16px",
              width: "100%",
            }}
          >
            <Grid item xs={12} style={{ paddingTop: 50 }}>
              <TextField
                InputProps={{ style: { height: 50 } }}
                InputLabelProps={{
                  shrink: true,
                }}
               
                defaultValue={
                  this.props.searchDetails &&
                  this.props.searchDetails.store &&
                  this.props.searchDetails.store.storeLocation
                }
                fullWidth
                variant="outlined"
                required
                label="Store Location"
                {...getInputProps({
                  className: "location-search-input",
                })}
              />
            </Grid>
            <div
              className="autocomplete-dropdown-container"
            >
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";

                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
export default EditLocation;
