import React from 'react';
import { Row, Col, Container } from "react-bootstrap";
import { TextField, CircularProgress, Box } from "@material-ui/core";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import producterror from "../../utils/images/producterror.svg";

const ProductDetails = (props) => {
  const Labels = [
    "Product Name",
    "Short description about the product",
    "Barcode Number",
    "Mfg Name",
    "Brand",
    "Price",
    "Promo Price",
    "Quantity",
    "Display Rank",
    "Unit of measurement",
    // "Status",
    // "Department",
    // "Category",
    // "Category1",
    // "Category2",
  ];

  let productTextField = [
    'productname',
    'desc',
    'barcode',
    'mfgname',
    "brand",
    "price",
    "promoprice",
    "quantity",
    "displayrank",
    "uom",
    // 'status',
    // 'masterdepartment',
    // 'mastercategory',
    // 'mastercategory1',
    // 'mastercategory2',
  ];

  React.useEffect(() => {
    props.getDepartmentList();
  },
    // eslint-disable-next-line
    [])
  return (
    <Container>
      <div
        style={{
          backgroundColor: "white",
          transform: "translateY(100px)",
          width: "90%",
          borderRadius: "30px",
          padding: '15px',
          boxShadow: 'rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px'
        }}>
        <h4 style={{ paddingTop: '30px', fontWeight: 500, textAlign: "center", color: 'black', textDecoration: 'underline' }}>
          {"Product Details"}
        </h4>
        {props.loadingnewproductdetails && props.loadingnewproductdetails === true ?
          <CircularProgress /> :
          props.newproductdetailsfailed === true ? 'Error' :
            (props.newproductdetailssuccess === true && props.NewProductDetails &&
              props.NewProductDetails !== null) ?
              <form id="editable-form">
                <ul>
                  <Row>
                    {productTextField.map((detail, index) =>
                      <Col md={(index === 0 || index === 1 || index === 2) ? 12 : 6} key={index} style={{ paddingTop: 30 }}>
                        <TextField
                          inputprops={{ style: { height: 50, borderRadius: '24px' } }}
                          id="filled-read-only-input"
                          label={Labels[index]}
                          background="red"
                          name={detail}
                          defaultValue={props.NewProductDetails[detail] && props.NewProductDetails[detail]}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            props.NewProductEditChanges(e, props.NewProductDetails);
                          }}
                        />
                      </Col>
                    )}
                    <Col md={6} style={{ paddingTop: 30 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{'Status'}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          defaultValue={props.NewProductDetails.status && props.NewProductDetails.status}
                          value={props.NewProductDetails.status && props.NewProductDetails.status}
                          label="Status"
                          name="status"
                          InputProps={{ style: { height: 50, borderRadius: '15px' } }}
                          onChange={(e) => {
                            props.NewProductEditChanges(e, props.NewProductDetails);
                          }}
                        >
                          <MenuItem value={'active'}>Active</MenuItem>
                          <MenuItem value={'inactive'}>Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                    <Col md={12} style={{ paddingTop: 30, display: 'flex', justifyContent: 'center' }}>
                      <Box>
                        {props.NewProductDetails && props.NewProductDetails.image &&
                          props.NewProductDetails.image.primary && (props.NewProductDetails.image.primary==="null"||
                          props.NewProductDetails.image.primary===null||
                          props.NewProductDetails.image.primary==="undefined"||
                          props.NewProductDetails.image.primary===undefined?
                          <div style={{width: '250px', height: '250px', borderRadius: '10px',
                          justifyContent:'center',
                          alignItems:'center',
                          textAlign:'center',
                          display:'flex',       
                          boxShadow: 'rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px',
                        background:'#fff'}}>
                          <img src={producterror}
                            alt="product"
                            style={{
                              maxWidth: '100%', maxHeight: '100%', 
                              width:'50px',height:'50px'
                            }} />
                            </div>
                          :
                        <img src={props.NewProductDetails && props.NewProductDetails.image &&
                          props.NewProductDetails.image.primary && props.NewProductDetails.image.primary}
                          alt="product"
                          style={{
                            maxWidth: '100%', maxHeight: '100%', width: '250px', height: '250px', borderRadius: '10px',
                            boxShadow: 'rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px'
                          }} />
                          )}
                      </Box>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} style={{ paddingTop: 30 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{'Department'}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          // defaultValue={props.NewProductDetails.status && props.NewProductDetails.status}
                          value={props.NewProductDetails.masterdepartment && props.NewProductDetails.masterdepartment}
                          label="Department"
                          name="masterdepartment"
                          InputProps={{ style: { height: 50, borderRadius: '15px' } }}
                          onChange={(e) => {
                            props.NewProductEditChanges(e, props.NewProductDetails);
                            props.getCategoryList(e.target.value);
                          }}
                        >
                          {props.departmentslistsuccess && props.departmentslistsuccess === true &&
                            props.DeparmentList && props.DeparmentList !== null && props.DeparmentList.length &&
                            props.DeparmentList.length > 0 && props.DeparmentList.map((department) =>
                              <MenuItem value={`${department.id}`}>
                                {department.name}
                              </MenuItem>
                            )}
                        </Select>
                      </FormControl>
                    </Col>
                    {(props.categorieslistsuccess && props.categorieslistsuccess === true && props.CategoriesList &&
                      props.CategoriesList !== null && props.CategoriesList.length && props.CategoriesList.length > 0) ?
                      <Col md={6} style={{ paddingTop: 30 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">{'Category'}</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // defaultValue={props.NewProductDetails.status && props.NewProductDetails.status}
                            value={props.NewProductDetails.mastercategory && props.NewProductDetails.mastercategory}
                            label="Category"
                            name="mastercategory"
                            InputProps={{ style: { height: 50, borderRadius: '15px' } }}
                            onChange={(e) => {
                              props.NewProductEditChanges(e, props.NewProductDetails);
                              props.getCategory1List(e.target.value);
                            }}
                          >
                            {props.categorieslistsuccess && props.categorieslistsuccess === true &&
                              props.CategoriesList && props.CategoriesList !== null && props.CategoriesList.length &&
                              props.CategoriesList.length > 0 && props.CategoriesList.map((category) =>
                                <MenuItem value={`${category.id}`}>
                                  {category.name}
                                </MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </Col>
                      : props.loadingcategories ?
                        <Col md={6} style={{ paddingTop: 30 }}>
                          <CircularProgress />
                        </Col>
                        : props.categorieslistfailed === true ? null :
                          null}
                    {(props.categorieslistsuccess && props.categorieslistsuccess === true &&
                      props.categories1listsuccess && props.categories1listsuccess === true && props.Categories1List &&
                      props.Categories1List !== null && props.Categories1List.length && props.Categories1List.length > 0) ?
                      <Col md={6} style={{ paddingTop: 30 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">{'Category1'}</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // defaultValue={props.NewProductDetails.status && props.NewProductDetails.status}
                            value={props.NewProductDetails.mastercategory1 && props.NewProductDetails.mastercategory1}
                            label="Category1"
                            name="mastercategory1"
                            InputProps={{ style: { height: 50, borderRadius: '15px' } }}
                            onChange={(e) => {
                              props.NewProductEditChanges(e, props.NewProductDetails);
                              props.getCategory2List(e.target.value);
                            }}
                          >
                            {props.categories1listsuccess && props.categories1listsuccess === true &&
                              props.Categories1List && props.Categories1List !== null && props.Categories1List.length &&
                              props.Categories1List.length > 0 && props.Categories1List.map((category1) =>
                                <MenuItem value={`${category1.id}`}>
                                  {category1.name}
                                </MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </Col>
                      : props.loadincategories1 ?
                        <Col md={6} style={{ paddingTop: 30 }}>
                          <CircularProgress />
                        </Col>
                        : props.categories1listfailed === true ? null :
                          null}
                    {(props.categorieslistsuccess && props.categorieslistsuccess === true &&
                      props.categories1listsuccess && props.categories1listsuccess === true &&
                      props.categories2listsuccess && props.categories2listsuccess === true && props.Categories2List &&
                      props.Categories2List !== null && props.Categories2List.length && props.Categories2List.length > 0) ?
                      <Col md={6} style={{ paddingTop: 30 }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">{'Category2'}</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // defaultValue={props.NewProductDetails.status && props.NewProductDetails.status}
                            value={props.NewProductDetails.mastercategory2 && props.NewProductDetails.mastercategory2}
                            label="Category1"
                            name="mastercategory1"
                            InputProps={{ style: { height: 50, borderRadius: '15px' } }}
                            onChange={(e) => {
                              props.NewProductEditChanges(e, props.NewProductDetails);
                            }}
                          >
                            {props.categories2listsuccess && props.categories2listsuccess === true &&
                              props.Categories2List && props.Categories2List !== null && props.Categories2List.length &&
                              props.Categories2List.length > 0 && props.Categories2List.map((category2) =>
                                <MenuItem value={`${category2.id}`}>
                                  {category2.name}
                                </MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </Col>
                      : props.loadincategories2 ?
                        <Col md={6} style={{ paddingTop: 30 }}>
                          <CircularProgress />
                        </Col>
                        : props.categories2listfailed === true ? null :
                          null}
                  </Row>
                </ul>
                <Row style={{ paddingTop: '15px' }}>
                  <Col md={12}>
                    <button
                      style={{
                        color: 'rgb(255, 255, 255)',
                        backgroundImage: '-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)',
                        boxShadow: ' 0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
                        height: '50px',
                        fontSize: '18px',
                        fontWeight: 600,
                        borderRadius: '5px',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: 'transparent',
                        overflow: 'hidden',
                        outline: 'none',
                        fontFamily: 'Varela Round, sans-serif',
                        textTransform: 'none',
                        textAlign: 'center',
                        width: '80%'
                      }}
                      type='button'
                      onClick={() => {props.addNewProduct(props.NewProductDetails);
                      props.history.push('/home/NewProducts')}}
                    >
                      {'Add Product'}
                    </button>
                  </Col>
                </Row>
              </form>
              : null
        }
      </div>
    </Container>
  )
}

export default ProductDetails