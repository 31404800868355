import React, { Component } from "react";
import { Row, Col } from'react-bootstrap';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ShareIcon from '@material-ui/icons/Share';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import './index.css';
// import { green } from '@material-ui/core/colors';

export default class index extends Component {
  // componentDidMount=()=>{
  //   this.props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
  //     }
   
  constructor(props) {
    super(props)

    this.state = {
      isMobile: window.innerWidth < 768,
        // url:this.props.DynamicLink&&this.props.DynamicLink,
      copySuccess: false,
      open:false,
      selected:'true'
    }
  }
 
  handleChange = e => {
    this.setState({ selected: e.target.value });
    if(e.target.value==="true")
    {
      this.props.ShareLink({id:this.props.id,openStore:true})
    }
    else{
      this.props.ShareLink({id:this.props.id,openStore:false})
    }
  };

  copyCodeToClipboard = () => {
    const el = this.input
    el.select()
    document.execCommand("copy")
    this.setState({copySuccess: true})
  }

  render() {
    // s
  const handleClickOpen = () => {
    this.props.ShareLink({id:this.props.id,openStore:true})
    this.setState({open:true});
  };

  const handleClose = () => {
    this.setState({open:false});
    this.setState({copySuccess: false})
  };


  return (
    <>
     <ShareIcon  style={{ color:'rgb(137, 199, 74, 1)',cursor:'pointer'} } onClick={handleClickOpen}/>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={this.state.open}
        onClose={handleClose}
      >
        <DialogTitle> Share Url</DialogTitle>
        <DialogContent>
        <FormControl>
      {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
      <RadioGroup
      value={this.state.selected}
        onChange={this.handleChange}
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
      >
        <FormControlLabel value="true" control={<Radio />} label="Playstore Link" />
        <FormControlLabel value="false" control={<Radio />} label="Web Link" />
      </RadioGroup>
    </FormControl>
             <Row style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
         <Col md={8} sm={12} xs={12}>    
      <input
      style={{width:'100%',height: '37px',borderRadius: '13px',border:'1px solid rgba(196, 196, 196, 0.2)',
      background: 'rgba(196, 196, 196, 0.2)',color:'#85BE49',fontFamily: 'Varela Round, sans-serif',justifyContent:' center',
      minHeight:'14px',}}
      ref={(input) => this.input = input}
      type="text"
      value={this.props?.DynamicLink}
      readOnly
    />
    </Col> 
    <Col md={4} sm={6} xs={6}>
    <div>
        <button 
        type="button"
        style={{
          justifyContent:' center',
          color: 'rgb(255, 255, 255)',
          backgroundImage: '-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)',
          width:'150px',
          height: '37px',
          fontSize: '16px',
          fontWeight: '600',
          borderRadius: '13px',
          borderWidth: '1px',
          borderStyle:' solid',
          borderColor: 'transparent',
          borderImage: 'initial',
          overflow: 'hidden',
          outline: 'none',
          minHeight:'14px',
          fontFamily: 'Varela Round, sans-serif'
          }}
          onClick={() => this.copyCodeToClipboard()}>
           Copy Url
        </button>
       
      </div>
    </Col>
      </Row>
      <Row style={{justifyContent:'center',alignItems:'center'}}>
      {
          this.state.copySuccess ?
          <div style={{color:'#85BE49',paddingTop:'6px'}}>
            Copied to Clipboard
          </div> : null
        }
      </Row>
          
       
        </DialogContent>
        <DialogActions style={{paddingRight:'20px'}}>
          <Button color='success' onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
   )
  }
}