import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { NO_RESULT } from "../../utils/image";
const NoSearchResult = () => {
	const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('md'));
	return (
		<>
		{
			isSmallScreen?
			<div style={{ width:'100%',display:'flex',justifyContent:'center',alignItems:'center', }}>
			<h5>No Search Results</h5>
		</div>
			:
		<div style={{ maxWidth: "520px" }}>
			<img style={{ maxWidth: "810px", paddingLeft: 80 }} src={NO_RESULT} alt="NO SEARCH RESULTS"></img>
		</div>
		}
		</>
	);
};
export default NoSearchResult;
