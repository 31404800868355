import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useLocation,useHistory } from "react-router-dom";
import Direction from "../Utilities/Icon/Direction.svg";
import Phone from "../Utilities/Icon/Phone.svg";
import Pointer from "../Utilities/Icon/Pointer.svg";
import LineInReadyForDel from "../Utilities/Icon/LineInReadyForDel.svg";
import * as React from "react";
import UploadImage from "../Utilities/Icon/UploadImage.svg";
import Camera from "../Utilities/Icon/Camera.svg";
import "./OrderHistory.css";
import {   
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  HeaderFontSize_24,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontSize_20_xs_18,
  contentFontsize_14,
  contentFontsize_17,
} from "../Typography";
import ReadyForLine from "../Utilities/Icon/ReadyForLine.svg";
// import Direction from "../Utilities/Icon/Direction.svg";
import DirectionSub from "../Utilities/Icon/DirectionSub.svg";
import CustomButton from "../CommonComponents/button";
import BlueClock from "../Utilities/Icon/BlueClock.svg";
import CustomCard from "../CommonComponents/card";
import { useState } from "react";
import OrderDetails from "../OrderDetails"; 
import OrderSearch from "../OrderSearch";
import GoogleMapOrder from "./GoogleMapOrder";
import { DialogContent, InputAdornment } from "@material-ui/core";
import CustomDialog from "../CommonComponents/dialog";
import CustomTextField from "../CommonComponents/input";
function OrderHistory(props) {
  const location = useLocation();
  const orderid = localStorage.getItem("orderID")
  console.log("orderId:", orderid);

  // React.useEffect(() => {
  //   props.getOrderDetails(orderid);
  //   console.log("two", props.getOrderDetails(orderid));
  // }, []);

  const completedOrderDetails = props?.OrderDetails &&
  props?.OrderDetails?.orderdetails
  console.log("completedOrderDetails", completedOrderDetails);

  const connectorStyle = {
    backgroundColor: "blue", // Set the background color
    height: "200px", // Set the height of the connector
    // Set the width of the connector
    margin: "-12px", // Set the margin
    // Add any other styles you want to customize
  };
    //const nav = useNavigate();
  function handleClickOne() {
    // nav("/home/MyOrders/ActiveOrders");
  }
  function handleClickTwo() {
    // nav("/home/MyOrders/OrderHistory");
  }
  const OrderHistoryData = JSON.parse(localStorage.getItem("selectedItems"));
  const outDetails=( "props",props&&props.ChangetoCompleted&&props.ChangetoCompleted.updateOrderStatus)
  //console.log(OrderHistoryData);
  const totalProductPrice = outDetails 
    ? outDetails.products.reduce((total, item) => total + item.shopAssistantQuantity * item.productPrice, 0)
    : completedOrderDetails 
      ? completedOrderDetails.products.reduce((total, item) => total + item.shopAssistantQuantity * item.productPrice, 0)
      : 0;

  const deliveryCharge = completedOrderDetails?.deliveryCharge;
  const packingCharge = completedOrderDetails?.packingCharge + completedOrderDetails?.handlingCharge;
  const discountPrice = completedOrderDetails?.discountPrice;

  // Calculate the total charges
  const totalCharges = deliveryCharge + packingCharge - discountPrice;

  // Calculate the grand total
  const grandTotal = totalProductPrice + totalCharges;
  const [Back,setBack] = useState(false)
  const history = useHistory();
  function handleorderHistory() {
    // nav("/home/MyOrders/OrderHistory");
    setBack(true)
    history.push('/home/Orders');
   // nav("/home/Orders")
  }
 
    
    console.log("poda2",outDetails)
    const [packingImagess,setPackingImages] = useState([])
    React.useEffect(() => {
      // Retrieve the images from localStorage
      const storedImages = localStorage.getItem('packingImages');
      if (storedImages) {
        setPackingImages(JSON.parse(storedImages));
      }
    }, []);
    const imagesToDisplay = completedOrderDetails?.packingImages && completedOrderDetails.packingImages.length > 0 
    ? completedOrderDetails.packingImages.map((detail) => detail) 
    : packingImagess;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const timelineStyle = {
    padding: matchessm
      ? "50px 0px 0px 18px"
      : xlmatch
      ? "50px 0px 0px 80px"
      : upXlmatch
      ? "50px 0px 0px 80px"
      : "25px 2px 3px 4px",
  };
  const [direction, setDirection] = useState(false);
  function handleDirection() {
    setDirection(true);
  }

  const [share,setShare] = useState(false)
  const [open, setOpen] = useState(false);
  function handleShare (){
    setShare(true)
    setOpen(true)
  }
  const url = localStorage.getItem('googleMapsUrl')
  
  const [copySuccess, setCopySuccess] = useState(false);

  console.log("the dynamic link", url);

  

  const handleClickOpen = () => {
    setOpen(true);
  };
 
  const handleClose = () => {
    setOpen(false);
    setCopySuccess(false);
  };

  const copyCodeToClipboard = async () => {
    const el = inputRef.current;
    el.select();
    try {
      await navigator.clipboard.writeText(el.value);
      setCopySuccess(true);
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  const inputRef = React.createRef();
  return (
    <div>
       {!direction && (
   
    <div style={{marginRight:"10px",marginLeft:"-15px"}}>
      {!direction && (
        <>
      <div style={{ display: "flex", gap: "50px" }}>
        <div style={{ width: "57%" }}>
         
            <div style={{ marginTop: "30px" }}>
              <CustomCard
                height="100%"
                width="100%"
                padding="0px"
                boxShadow="white"
              >
                <Grid container spacing={2} sx={{ paddingLeft: "0px" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ paddingLeft: "0px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "28px 40px 2px 30px",
                      }}
                    >
                      <div style={{ display: "flex", gap: "5px" }}>
                        <img
                          src={Direction}
                          style={{ width: "20px", height: "20px" }}
                          alt="Direction Icon"
                        />

                        <div>
                          <p
                            className="nameStyleOut"
                            fontSize={contentFontsize_17}
                            style={{ marginBottom: "0px",color:"black" }}
                          >
                            {completedOrderDetails &&
                              completedOrderDetails.customerName}
                          </p>
                        </div>
                      </div>

                      <div>
                        <p
                          className="addressOut"
                          style={{
                            fontSize: { contentFontsize_14 },
                            textAlign: "left",
                            marginBottom: "0px",
                            color: "black",
                          }}
                        >
                          {completedOrderDetails &&
                            completedOrderDetails.deliveryAddress}
                        </p>
                      </div>
                      <div>
                        <p
                          className="addressOut"
                          style={{
                            fontSize: { contentFontsize_14 },
                            textAlign: "left",
                            marginBottom: "0px",
                            color: "black",
                          }}
                        >
                          {completedOrderDetails &&
                            completedOrderDetails.deliveryDate}
                          ,
                          {completedOrderDetails &&
                            completedOrderDetails.deliveryTime}{" "}
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ paddingLeft: "0px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        justifyContent: smmatch ? "flex-start" : "flex-end",
                        height: "100%",
                        margin: "28px 0px 2px 30px",
                      }}
                    >
                      <img
                        src={Phone}
                        style={{ width: "20px", height: "20px" }}
                        alt="Phone icon"
                      />
                      <span>
                        <p
                          className="numberStyleOut"
                          style={{ color:"black" }} 
                        >
                          +91{" "}
                          {completedOrderDetails &&
                            completedOrderDetails.userid.phoneNumber}
                        </p>
                      </span>
                    </div>
                  </Grid>
                </Grid>
                <div style={{ margin: "20px 0px" }}>
                  <img style={{ width: "100%" }} src={ReadyForLine} />
                </div>
                <Grid container spacing={2} sx={{ paddingLeft: "0px" }}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ margin: "1px -125px 44px 47px", paddingLeft: "0px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "80px",
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                background: "#85BE49",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                              }}
                            ></div>
                            <div
                              style={{
                                background: "#85BE49",
                                height: "10px",
                                width: "10px",
                                borderRadius: "50%",
                              }}
                            ></div>
                          </div>
                          <div className="connecting-line"></div>
                          {/* </div> */}
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "space-between",
                            lineHeight: "1",
                          }}
                        >
                          <div
                            fontSize={contentFontSize_18}
                            style={{
                              fontWeight: "800",
                              fontFamily: "Roboto",
                              color: "#000000",
                            }}
                          >
                            Outlet
                          </div>
                          <div
                            fontSize={contentFontSize_18}
                            style={{
                              fontWeight: "800",
                              fontFamily: "Roboto",
                              color: "#000000",
                            }}
                          >
                            35 mins
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "space-between",
                            lineHeight: "1",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              color: "#8B8989",
                              fontWeight: "500",
                            }}
                          >
                            Le Arabia
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              color: "#8B8989",
                              fontWeight: "500",
                            }}
                          >
                            3.6 kilometers
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{
                      margin: "1px -20px 44px 47px",
                      paddingLeft: "0px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "30px",
                        height: "100%",
                        justifyContent: smmatch ? "flex-start" : "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <CustomButton
                          width="120px"
                          height="45px"
                          variant="outlined"
                          color="#85BE49"
                          borderColor="#85BE49"
                          background="none"
                          hasIcon={true}
                          iconPosition="startIcon"
                          onClick={handleDirection} 
                        >
                          <img
                            src={Direction}
                            style={{
                              width: "15px",
                              height: "15px",
                              marginRight: "10px",
                            }}
                          />
                          Direction
                        </CustomButton>
                      </div>
                      <div  onClick={handleShare}>
                        <img
                        onClick={handleShare}
                          src={DirectionSub}
                          style={{
                            width: "25px",
                            height: "33px",
                            marginRight: "24px",
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CustomCard>
              {share && (
              <CustomDialog
              width="600px"
              height="200px"
              borderRadius="20px"
              open={open}
              onClose={handleClose}
              titleAlign="center"
              // title="Share URL"
              top={"15px"}
              right={"20px"}
              // showCloseButton={false}
            >
              <Typography
                style={{
                  color: "#170F49",
                  textAlign: "center",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                }}
                className="head-font"
                fontSize={HeaderFontSize_24}
              >
                Share URL
              </Typography>
              <DialogContent>
                <div>
                  <CustomTextField
                    width="100%"
                    value={url}
                    inputRef={inputRef}
                    endAdornment={
                      <InputAdornment position="end">
                        <div>
                          <CustomButton onClick={() => copyCodeToClipboard()}>
                            <Typography fontSize={contentFontSize_16}>
                              Copy Url
                            </Typography>
                          </CustomButton>
                        </div>
                      </InputAdornment>
                    }
                  ></CustomTextField>
                  <div style={{ justifyContent: "center", alignItems: "center" }}>
                    {copySuccess ? (
                      <div style={{ color: "#85BE49", paddingTop: "6px" }}>
                        <Typography fontSize={contentFontSize_16}>
                          Copied to Clipboard
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                </div>
              </DialogContent>
            </CustomDialog>
            )}
            </div>
            <Grid
              container
              justifyContent="center"
              style={{ marginTop: "10px" }}
            >
              <Grid item xs={12} md={8} lg={12}>
                <div className="orderHistoryBox">
                  <div>
                    <p
                      fontSize={contentFontsize_17}
                      style={{ marginBottom: "0px", color: "black" }}
                      className="textStyleOut"
                    >
                      #
                      {completedOrderDetails &&
                        completedOrderDetails.orderNumber}{" "}
                      - Scheduled on{" "}
                      {completedOrderDetails &&
                        completedOrderDetails.deliveryDate}
                      ,{" "}
                      {completedOrderDetails &&
                        completedOrderDetails.deliveryTime}
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop: "20px",
                padding: smmatch ? "10px 10px" : "0px 50px 0px 0px",
              }}
            >
              {completedOrderDetails &&
                completedOrderDetails?.products?.map((item) => (
                  <>
                    <div style={{ display: "flex" }}>
                      {console.log("item vannu", item)}
                      <div style={{ flex: "1" }}>
                        <div style={{ display: "flex", gap: "60px" }}>
                          <div className="productDetails">
                            <img
                              style={{
                                width: matchessm ? "15px" : "20px",
                                height: matchessm ? "15px" : "20px",
                              }}
                              src={Pointer}
                              alt={item?.name}
                            />
                          </div>

                          <div>
                            <p
                              fontSize={contentFontsize_17}
                              className="productDetails"
                              style={{ textAlign: "left", color: "black" }}
                            >
                              {item.productid.productname}
                              <br />
                              <p
                                fontSize={contentFontsize_14}
                                style={{
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                  color: "black",
                                  textAlign: "left",
                                }}
                              >
                                {item?.shopAssistantQuantity ? (
                        <>
                      {item.shopAssistantQuantity}X ₹{(item?.productPrice)?.toFixed(2)}
                      </>
                    ):(
                      <>
                      {item.quantity}X ₹{(item?.productPrice)?.toFixed(2)}
                      </>
                    )}
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div style={{ flex: "1" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "1px",
                            }}
                          >
                            {/* Calculate price based on updated quantity if active is "change", otherwise use original quantity */}
                            <p
                              className="productDetails"
                              fontSize={contentFontsize_17}
                              style={{ color: "black" }}
                            >
                              {item.shopAssistantQuantity ? (
                      <>
                    ₹{(item?.shopAssistantQuantity * item?.productPrice)?.toFixed(2)}
                    </>
                  ):(
                    <>
                    ₹{(item?.quantity * item?.productPrice)?.toFixed(2)}
                    </>
                  )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>

            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sm={12}
                sx={{ marginTop: "20px" }}
              >
                <img
                  style={{ width: "100%" }}
                  src={LineInReadyForDel}
                  alt="LineInReadyForDel"
                />
              </Grid>
            </Grid>

            <div
              style={{ padding: smmatch ? "10px 10px" : "0px 50px 0px 75px" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p fontSize={contentFontSize_16} className="productDetails1">
                  Total Product price
                </p>
                {/* </Grid> */}
                {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}>  */}
                <p className="productDetails1" fontSize={contentFontSize_16}>
                  ₹{" "}{totalProductPrice?.toFixed(2)}
                </p>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p fontSize={contentFontSize_16} className="productDetails1">
                  Delivery Charge
                </p>

                <p
                  className="productDetails1"
                  fontSize={contentFontSize_16}
                  // sx={{marginLeft:"15px",paddingLeft:"-15px"}}
                >
                  ₹ {" "}{completedOrderDetails?.deliveryCharge?.toFixed(2)}
                </p>
              </div>

              {completedOrderDetails?.discountPrice ? ( 
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    fontSize={contentFontSize_16}
                    style={{ color: "rgba(45, 147, 251, 1)" }}
                    className="productDetails1"
                  >
                    Coupon - ({completedOrderDetails?.couponID?.couponCode})
                  </p>
                  {/* </Grid> */}

                  {/* <Grid item xs={4} md={3} lg={3} sm={3} xl={3}> */}
                  <p
                    className="productDetails1"
                    style={{ color: "rgba(45, 147, 251, 1)" }}
                    fontSize={contentFontSize_16}
                  >
                    -₹{" "}{completedOrderDetails?.discountPrice?.toFixed(2)}
                  </p>
                </div>
                  ):(null)}

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p fontSize={contentFontSize_16} className="productDetails1">
                  Packing Charges
                </p>

                <p
                  className="productDetails1"
                  fontSize={contentFontSize_16}
                  // sx={{marginLeft:"22px"}}
                >
                  ₹
                  {packingCharge === null
                    ? 0.0
                    : packingCharge?.toFixed(2)}
                </p>
              </div>
            </div>

            <Grid container>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <img
                  style={{ width: "100%" }}
                  src={LineInReadyForDel}
                  alt="LineInReadyForDel"
                />
              </Grid>
            </Grid>

            <div
              style={{ padding: smmatch ? "10px 10px" : "0px 50px 0px 75px" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontSize={contentFontSize_20_xs_18}
                  sx={{
                    fontWeight: "700",
                    fontFamily: "Roboto",
                    color: "black",
                  }}
                >
                  Total
                </Typography>

                <Typography
                  sx={{
                    fontWeight: "700",
                    fontFamily: "Roboto",
                    color: "black",
                  }}
                  fontSize={contentFontSize_16}
                >
                  ₹{" "}{grandTotal?.toFixed(2)}
                </Typography>
              </div>
            </div>

            <div style={{ marginTop: "20px" }}>
              <Typography
                style={{ fontWeight: "600", color: "black", textAlign: "left" }}
                fontSize={contentFontSize_20}
              >
                Upload Product Images<span style={{ color: "#EA4335" }}>*</span>
              </Typography>
            </div>
            <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    gap: "20px",
                    flexDirection: "row",
                  }}
                >
                  {imagesToDisplay?.map(
                    (imageUrl, index) => (
                      <img 
                        key={index} // Adding a key prop for each image to help React identify them uniquely
                        style={{
                          marginTop: "20px",
                          width: "100px",
                          height: "100px",
                        }}
                        src={imageUrl}
                        alt={`Image ${index}`} // Providing alt text for accessibility
                      />
                    )
                  )}
                </div>
      </div>
              <div style={{ marginTop: "450px" }}>
                <div className="timeline-containerReady">
                {(outDetails
                    ? outDetails.stats
                    : completedOrderDetails.stats
                  ).map((each, index) => (
                    <div key={index} className="timeline-entryOut">
                      <div className="timeline-markerOut">
                        <div className="dotActive"></div>
                        {index <
                          (outDetails
                            ? outDetails.stats.length
                            : completedOrderDetails.stats.length) -
                            1 && <div className="connecting-lineOut"></div>}
                      </div>
                      <div className="timeline-contentOut">
                        <div>
                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "800",
                              fontFamily: "Roboto",
                              color: "#000000",
                            }}
                          >
                            {each.status}
                          </p>
                          {each?.status === "Order-Placed" && (
                            <p
                              style={{
                                color: "grey",
                                fontSize: "14px",
                                marginTop: "10px",
                              }}
                            >
                              Scheduled for {completedOrderDetails.deliveryDate},{" "}
                            {completedOrderDetails.deliveryTime}
                              
                            </p>
                          )}
                        </div>
                        <div className="detailsOut">
                          <p
                            style={{
                              fontSize: "15px",
                              fontWeight: "400",
                              fontFamily: "Roboto",
                              color: "#000000",
                              textAlign: "left",
                              width: "100%",
                            }}
                          >
                            {each?.created}, {each?.createdTime}
                            
                          </p>
                        </div>
                        {each.status === "Order-Accepted" &&
                          outDetails?.shopAssistantId?.firstName && (
                            <div
                              className="shop-assistant"
                              style={{
                                fontSize: "16px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                                color: "#000000",
                                textAlign: "left",
                                width: "100%",
                              }}
                            >
                              Shop Assistant:{" "}
                              {outDetails?.shopAssistantId?.firstName}
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
      </div>
      <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <CustomButton
            width="350px"
            height="50px"
            borderRadius="20px"
            color="white"
            background="#659B1B"
            onClick={handleorderHistory}
          >
            <Typography
              fontSize={contentFontSize_18}
              sx={{ fontFamily: "Roboto", fontWeight: "500",textTransform:"capitalize" }}
            >
              Back
            </Typography>
          </CustomButton>
        </div>
        </>
      )}


    </div>
       )}
       {direction && <GoogleMapOrder {...props} />}
     </div>
  );
}

export default OrderHistory;
