import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


const style = {
   textAlign:'left',
   padding:'10px'
  };

const DeliveryPartner = (props) => {
  // console.log("get full details",props.getShopAssistantFullDetails(props?.OrderDetails?.orderdetails.shopAssistantId))
  // console.log("this props is from Delivery partner",props)
    return (
        <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} style={{padding:'20px',justifyContent:'center'}}>
        <Grid item xs={4} md={4} sx={style}>
         Delivery Partner First Name
        </Grid>
        <Grid item xs={4} md={2} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
        {props.OrderDetails&&
          props.OrderDetails.orderdetails&&
          props.OrderDetails.orderdetails.deliveryPartnerId&&
          props.OrderDetails.orderdetails.deliveryPartnerId.firstName&&
          props.OrderDetails.orderdetails.deliveryPartnerId.firstName
          }
        </Grid>
        <Grid item xs={4} md={4} sx={style}>
        Delivery Partner Last Name
        </Grid>
        <Grid item xs={4} md={2} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
        {props.OrderDetails&&
          props.OrderDetails.orderdetails&&
          props.OrderDetails.orderdetails.deliveryPartnerId&&
          props.OrderDetails.orderdetails.deliveryPartnerId.lastName&&
          props.OrderDetails.orderdetails.deliveryPartnerId.lastName
          }
        </Grid>
        <Grid item xs={4} md={4} sx={style}>
        Delivery Partner Phone Number
        </Grid>
        <Grid item xs={4} md={2} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
        {props.OrderDetails&&
          props.OrderDetails.orderdetails&&
          props.OrderDetails.orderdetails.deliveryPartnerId&&
          props.OrderDetails.orderdetails.deliveryPartnerId.phoneNumber&&
          props.OrderDetails.orderdetails.deliveryPartnerId.phoneNumber
          }
        </Grid>
      </Grid>
    </Box>
    )
}

export default DeliveryPartner
