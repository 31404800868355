import { gql } from "@apollo/client";

export const GET_USERS_LIST = gql`
query($email:String,$phoneNumber:String,$status:String,$pagination: PaginationArg){
    searchUser(email:$email,phoneNumber:$phoneNumber,status:$status,pagination:$pagination){
      count
      items{
        id
        firstName
        lastName
        email
        phoneNumber
        status
      }
      hasNext
    }
  }`

export const USER_DETAILS = gql`
   query($userid:ID){
    user(userid:$userid)
      {
        id
        firstName
        lastName
        email
        password
        address{
          id
          houseNumber
          landmark
          locationDescription
        }
        phoneNumber
        verified
        status
      }
    }`

export const BLOCK_USER = gql`   
mutation($userid:ID){
      blockUser(userid:$userid)
      {
        id
      }
    }`

export const UNBLOCK_USER = gql`
    mutation($userid:ID){
      unblockUser(userid:$userid){
        id
      }
    }`