export const LoginReducer = (state = { isLoggedIn: false }, action) => {
	switch (action.type) {
		case "LOGIN_ACCESS_SUCCESS":
			return {
				...state,
				isLoggedIn: true,
				successData: action.payload.data.adminlogin,
			};

		case "LOGIN_ACCESS_FAILED":
			return {
				...state,
				errorMessage: action.payload,
				isLoggedIn: false,
			};
		case "LOGIN_ACCESS_ERROR":
			return {
				...state,
				isLoggedIn: false,
				incorrectMessage: "Network Error",
			};
		default:
			return state;
	}
};
