import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

const style = {
  textAlign: "left",
  padding: "10px",
};
const Customerdetails = (props) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={3}
        style={{ padding: "20px", justifyContent: "center" }}
      >
        <Grid item xs={4} md={3} sx={style}>
          First Name
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
          {props.OrderDetails &&
            props.OrderDetails.orderdetails &&
            props.OrderDetails.orderdetails.userid &&
            props.OrderDetails.orderdetails.userid.firstName &&
            props.OrderDetails.orderdetails.userid.firstName}
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          Last Name
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
          {props.OrderDetails &&
            props.OrderDetails.orderdetails &&
            props.OrderDetails.orderdetails.userid &&
            props.OrderDetails.orderdetails.userid.lastName &&
            props.OrderDetails.orderdetails.userid.lastName}
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          EmailAddress
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
          {props.OrderDetails &&
            props.OrderDetails.orderdetails &&
            props.OrderDetails.orderdetails.userid &&
            props.OrderDetails.orderdetails.userid.email &&
            props.OrderDetails.orderdetails.userid.email}
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          Phone No.
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
          {props.OrderDetails &&
            props.OrderDetails.orderdetails &&
            props.OrderDetails.orderdetails.userid &&
            props.OrderDetails.orderdetails.userid.phoneNumber &&
            props.OrderDetails.orderdetails.userid.phoneNumber}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Customerdetails;
