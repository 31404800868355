import { Grid,Typography } from '@mui/material'
import React from 'react'
import Animation from "../../utils/Icon/Animation.gif"
import { contentFontsize_24, contentFontsize_30 } from "../../Typography/index"
function Loaders(props) {
  return (
    // <div>
    //     <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
    //   <Grid item lg={12} xs={12} sm={12} md={12} xl={12} sx={{ margin: 'auto' }}>
    //     <img src={Animation} alt="Loader" sx={{margin:"auto"}} />
    //  <Typography fontSize={contentFontsize_30} sx={{fontWeight:"500",fontFamily:"Roboto"}} >
    //    Your order is now moved to Ready For Delivery.</Typography>
    //   </Grid>
    // </Grid>
    // </div> 
    <div style={{ minHeight: '100vh', display: "flex", justifyContent: "center", alignItems: "center" }}>
    <Grid item lg={12} xs={12} sm={12} md={12} xl={12} sx={{ textAlign: 'center' }}>
      <img src={Animation} alt="Loader" />
      <Typography fontSize={contentFontsize_24} sx={{ fontWeight: '500', fontFamily: 'Roboto', marginTop: '20px' }}>
      Your order is now moved to In Progress.
      </Typography>
    </Grid>
  </div>
  )
}

export default Loaders