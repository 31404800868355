import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

const style = {
  textAlign: "left",
  padding: "10px",
};
const Storedetails = (props) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={3}
        style={{ padding: "20px", justifyContent: "center" }}
      >
        <Grid item xs={4} md={3} sx={style}>
          Store Name
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
          {props.OrderDetails &&
            props.OrderDetails.orderdetails &&
            props.OrderDetails.orderdetails.storeid &&
            props.OrderDetails.orderdetails.storeid.storeName &&
            props.OrderDetails.orderdetails.storeid.storeName}
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          Store Location
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
          {props.OrderDetails &&
            props.OrderDetails.orderdetails &&
            props.OrderDetails.orderdetails.storeid &&
            props.OrderDetails.orderdetails.storeid.storeLocation &&
            props.OrderDetails.orderdetails.storeid.storeLocation}
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          Store Manager First Name
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
          {props.OrderDetails &&
            props.OrderDetails.orderdetails &&
            props.OrderDetails.orderdetails.storeid &&
            props.OrderDetails.orderdetails.storeid.storeManagerFirstName &&
            props.OrderDetails.orderdetails.storeid.storeManagerFirstName}
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          Store Manager Last Name
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
          {props.OrderDetails &&
            props.OrderDetails.orderdetails &&
            props.OrderDetails.orderdetails.storeid &&
            props.OrderDetails.orderdetails.storeid.storeManagerLastName &&
            props.OrderDetails.orderdetails.storeid.storeManagerLastName}
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          Store Manager Mobile No.
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
          {props.OrderDetails &&
            props.OrderDetails.orderdetails &&
            props.OrderDetails.orderdetails.storeid &&
            props.OrderDetails.orderdetails.storeid.storeManagerMobile &&
            props.OrderDetails.orderdetails.storeid.storeManagerMobile}
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          Email Address
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
          {props.OrderDetails &&
            props.OrderDetails.orderdetails &&
            props.OrderDetails.orderdetails.storeid &&
            props.OrderDetails.orderdetails.storeid.forAdmin &&
            props.OrderDetails.orderdetails.storeid.forAdmin.emailAddress &&
            props.OrderDetails.orderdetails.storeid.forAdmin.emailAddress}
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          Store Status
        </Grid>
        <Grid item xs={4} md={3} sx={style}>
          :
        </Grid>
        <Grid item xs={4} md={6} sx={style}>
          {props.OrderDetails &&
            props.OrderDetails.orderdetails &&
            props.OrderDetails.orderdetails.storeid &&
            props.OrderDetails.orderdetails.storeid.storeStatus &&
            props.OrderDetails.orderdetails.storeid.storeStatus}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Storedetails;
