import React from "react";
import Container from '@mui/material/Container';
import { Row, Col } from 'react-bootstrap';
import TextField from "@material-ui/core/TextField";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { useFormik } from "formik";
import { buyxgetyvalidationSchema } from "./Validation";
import './index.css';
import { InputAdornment, MenuItem } from "@mui/material";

export default function BuyXgetYCoupon(props) {

  const theme = createMuiTheme({
    palette: {
      primary: green,
    },
    overrides: {
      MuiInputBase: {
        input: {
          "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
        },
      },
    },
  });

  const formik = useFormik({
    initialValues: {
      couponCode: '',
      usesperCustomer: '',
      buyitem: '',
      getitem: '',
      selectedproducts: '',
      fromdate: '',
      todate: '',
      fromtime: '',
      totime: '',
    },
    validationSchema: buyxgetyvalidationSchema,

    onSubmit: (values) => {
      console.log(values)
    }
  })

  return (
    <>
      <form
        //  enableReinitialize
        // enableReinitialize={true}
        style={{ width: "100%", paddingTop: "15px" }}
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
      >
        <Container maxWidth='md'
          style={{
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.16)",
            borderRadius: "10px",
            //   Top: "90px",
            marginTop: '100px',
            padding: "30px",
            width: "100%",
          }}
        >
          <h3 className="CouponType">
            {'Buy x Get y free coupon'}
          </h3>
        </Container>
        <Container maxWidth='md'
          style={{
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.16)",
            borderRadius: "10px",
            //   Top: "90px",
            marginTop: "20px",
            padding: "30px",
            width: "100%",
          }}
        >


          <Row
            className="CouponHeading">
            <p >
              Create coupon
            </p>
          </Row>
          <div className="formDiv">

            <Row>

              <Col md={6} sm={12} xs={12}>
                <ThemeProvider theme={theme}>
                  <TextField
                    InputProps={{ style: { height: 35 } }}
                    fullWidth
                    variant="outlined"
                    value={formik.values.couponCode}
                    onChange={formik.handleChange}
                    error={formik.touched.couponCode && Boolean(formik.errors.couponCode)}
                    helperText={formik.touched.couponCode && formik.errors.couponCode}
                    className="form-control"
                    name="couponCode"
                    label="Coupon code"
                    InputLabelProps={{
                      shrink: true,

                    }}
                  />
                </ThemeProvider>
              </Col>


              <Col md={6} sm={12} xs={12}>
                <ThemeProvider theme={theme}>
                  <TextField
                    InputProps={{ style: { height: 35 } }}
                    fullWidth
                    variant="outlined"
                    value={formik.values.usesperCustomer}
                    onChange={formik.handleChange}
                    error={formik.touched.usesperCustomer && Boolean(formik.errors.usesperCustomer)}
                    helperText={formik.touched.usesperCustomer && formik.errors.usesperCustomer}
                    className="form-control"
                    name="usesperCustomer"
                    label="Uses per customer"
                    InputLabelProps={{
                      shrink: true,

                    }}
                  />
                </ThemeProvider>
              </Col>

            </Row>


          </div>
        </Container>

        <Container maxWidth='md'
          style={{
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.16)",
            borderRadius: "10px",
            marginTop: "20px",
            padding: "30px",
            width: "100%",
          }}
        >

          <Row
            className="CouponHeading">
            <p >
              Coupon details
            </p>
          </Row>
          <div className="formDiv">

            <Row>
              <Col md={6} sm={12} xs={12}>
                <ThemeProvider theme={theme}>
                  <TextField
                    InputProps={{ style: { height: 35 }, endAdornment: <InputAdornment position="end">Items</InputAdornment>, }}
                    fullWidth
                    variant="outlined"
                    value={formik.values.buyitem}
                    onChange={formik.handleChange}
                    error={formik.touched.buyitem && Boolean(formik.errors.buyitem)}
                    helperText={formik.touched.buyitem && formik.errors.buyitem}
                    className="form-control"
                    name="buyitem"
                    label="Buy"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </ThemeProvider>
              </Col>

              <Col md={6} sm={12} xs={12}>
                <ThemeProvider theme={theme}>
                  <TextField
                    InputProps={{ style: { height: 35 }, endAdornment: <InputAdornment position="end">Items FREE</InputAdornment>, }}
                    fullWidth
                    variant="outlined"
                    value={formik.values.getitem}
                    onChange={formik.handleChange}
                    error={formik.touched.getitem && Boolean(formik.errors.getitem)}
                    helperText={formik.touched.getitem && formik.errors.getitem}
                    className="form-control"
                    name="getitem"
                    label="Get"
                    InputLabelProps={{
                      shrink: true,

                    }}
                  />
                </ThemeProvider>
              </Col>
            </Row>
            <Row style={{ paddingTop: '35px' }}>
              <Col md={6} sm={12} xs={12}>
                <ThemeProvider theme={theme}>
                  <TextField
                    InputProps={{ style: { height: 35 } }}
                    id="outlined-select-currency"
                    select
                    variant="outlined"
                    value={formik.values.selectedproducts}
                    onChange={formik.handleChange}
                    error={formik.touched.selectedproducts && Boolean(formik.errors.selectedproducts)}
                    helperText={formik.touched.selectedproducts && formik.errors.selectedproducts}
                    className="form-control"
                    name="selectedproductts"
                    label="Apply Coupon on"
                    InputLabelProps={{
                      shrink: true,

                    }}
                  >
                    {/* {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))} */}
                  </TextField>
                </ThemeProvider>
              </Col>
            </Row>
          </div>
        </Container>

        <Container maxWidth='md'
          style={{
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.16)",
            borderRadius: "10px",
            marginTop: "20px",
            padding: "30px",
            width: "100%",
          }}
        >

          <Row
            className="CouponHeading">
            <p >
              Coupon Validity
            </p>
          </Row>
          <div className="formDiv">

            <Row>

              <Col md={6} sm={12} xs={12}>
                <ThemeProvider theme={theme}>
                  <TextField
                    type='date'
                    InputProps={{ style: { height: 35 } }}
                    fullWidth
                    variant="outlined"
                    value={formik.values.fromdate}
                    onChange={formik.handleChange}
                    error={formik.touched.fromdate && Boolean(formik.errors.fromdate)}
                    helperText={formik.touched.fromdate && formik.errors.fromdate}
                    className="form-control"
                    name="fromdate"
                    label="From"
                    InputLabelProps={{
                      shrink: true,

                    }}
                  />
                </ThemeProvider>
              </Col>


              <Col md={6} sm={12} xs={12}>
                <ThemeProvider theme={theme}>
                  <TextField
                    type='time'
                    InputProps={{ style: { height: 35 } }}
                    fullWidth
                    variant="outlined"
                    value={formik.values.fromtime}
                    onChange={formik.handleChange}
                    error={formik.touched.fromtime && Boolean(formik.errors.fromtime)}
                    helperText={formik.touched.fromtime && formik.errors.fromtime}
                    className="form-control"
                    name="fromtime"
                    label="Time"
                    InputLabelProps={{
                      shrink: true,

                    }}
                  />
                </ThemeProvider>
              </Col>

            </Row>

            <Row style={{ paddingTop: '35px' }}>

              <Col md={6} sm={12} xs={12}>
                <ThemeProvider theme={theme}>
                  <TextField
                    type='date'
                    InputProps={{ style: { height: 35 } }}
                    fullWidth
                    variant="outlined"
                    value={formik.values.todate}
                    onChange={formik.handleChange}
                    error={formik.touched.todate && Boolean(formik.errors.todate)}
                    helperText={formik.touched.todate && formik.errors.todate}
                    className="form-control"
                    name="todate"
                    label="To"
                    InputLabelProps={{
                      shrink: true,

                    }}
                  />
                </ThemeProvider>
              </Col>


              <Col md={6} sm={12} xs={12}>
                <ThemeProvider theme={theme}>
                  <TextField
                    type='time'
                    InputProps={{ style: { height: 35 } }}
                    fullWidth
                    variant="outlined"
                    value={formik.values.totime}
                    onChange={formik.handleChange}
                    error={formik.touched.totime && Boolean(formik.errors.totime)}
                    helperText={formik.touched.totime && formik.errors.totime}
                    className="form-control"
                    name="totime"
                    label="Time"
                    InputLabelProps={{
                      shrink: true,

                    }}
                  />
                </ThemeProvider>
              </Col>

            </Row>
          </div>
        </Container>
        <Row className="centerDiv">
          <button type='submit' className="couponButton">
            Create Coupon
          </button>
        </Row>
      </form>
    </>
  )
}