import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import DeliveryPartner from './DeliveryPartner';
import ShopAssistant from './ShopAssistant';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  backgroundColor:'#ebedef'
}));


const DeliveryDetails = (props) => {
  console.log("This props is from Delivery details",props)
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
        <Item>
          <ShopAssistant {...props}/>
          </Item>
        </Grid>
        <Grid item xs={12} md={4}>
          <Item>
            <DeliveryPartner {...props}/>
          </Item>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DeliveryDetails
