import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import FormHeaders from "../../Components/FormHeaders";
import EditImageDropZone from "../EditImageDropZone";
// import "../ImageModal/index.css";

const ImageEditWindow = (params) => {
	return (
		<Modal show={true}>
			<Modal.Header>Logo Upload</Modal.Header>
			<Modal.Body style={{ height: 500 }}>
				<FormHeaders header="Logo Upload" />
				<EditImageDropZone {...params} />
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="contained"
					color="primary"
					onClick={params.handleImageEdit}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
export default ImageEditWindow;
