import { gql } from "@apollo/client";

export const UPLOAD_CATEGORY_EXCEL = gql`
mutation($file:Upload!,$storeid:ID!){
    uploadCategorySalesProductsFromExcel(file:$file,storeid:$storeid)
    {
        ProductName
		PromoPrice
        Price
        Quantity
        UnitOfMeasure
        Level1
        Level2
        Level3
        Description
        Manufacturer
        Brand
        BarCode
        ItemCode
    }
  }
`

export const UPLOAD_BARCODE_EXCEL = gql`
mutation($file:Upload!,$storeid:ID!){
    uploadBarcodeSalesProductsFromExcel(file:$file,storeid:$storeid)
      {
        BarCode
        Name
        HSNCODE
        CCP
        MRP
      }
    }
`