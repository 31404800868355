import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MainCard from '../../ui-component/cards/MainCard';
import { Avatar, Stack, Typography, Grid, Button, TextField, Box, Divider, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    border: 'none',
    boxShadow: 'none',
    justifyContent: 'center',
    width: '100%',
    alignContent: 'center',
    alignItems: 'center'
}));

const UserDetails = (props) => {
    const [open, setOpen] = React.useState(false);
    const [modalopen, setModalOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const BlockUser = (id, status) => {
        if (status === "Blocked") { props.unblockUser(id); }
        else {
            props.blockUser(id);
        }
        setOpen(false);
        handleModalOpen();
    };

    return (
        <Container>
            {props.userloading ?
                <div
                    style={{
                        backgroundColor: "white",
                        transform: "translateY(100px)",
                        width: "90%",
                        borderRadius: "30px",
                        padding: '15px',
                        boxShadow: 'rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px'
                    }}
                    position="sticky"
                >
                    <CircularProgress />
                </div>
                :
                (
                    props.userslistsuccess && props.User && props.User.user &&
                        props.User.user !== null ? (
                        <Row>
                            <Col sm={12} md={4}>
                                <div
                                    style={{
                                        backgroundColor: "white",
                                        transform: "translateY(100px)",
                                        width: "90%",
                                        borderRadius: "30px",
                                        padding: '15px',
                                        boxShadow: 'rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px',
                                    }}
                                    position="sticky"
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Item style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                                <div style={{ alignItems: 'center', border: '1px dashed #9E9E9E', padding: '5px', borderRadius: '50%', width: '112px', height: '112px', justifyContent: 'center' }}>
                                                    <Avatar sx={{ width: 100, height: 100 }}>
                                                        {props.User.user.firstName && props.User.user.firstName.charAt(0)}
                                                    </Avatar>
                                                </div>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Stack>
                                                <Typography variant="h5">
                                                    {props.User.user.firstName && props.User.user.firstName}{' '}{props.User.user.lastName && props.User.user.lastName}
                                                </Typography>
                                                <Button color="error" variant="contained" onClick={handleClickOpen}>
                                                    {props.User.user.status &&
                                                        props.User.user.status === "Blocked" ? 'Unblock User' : 'Block User'}
                                                </Button>
                                            </Stack>
                                        </Item>
                                    </Grid>
                                </div>
                            </Col>

                            <Col sm={12} md={8} style={{paddingTop:'5px'}}>
                                <div
                                    style={{
                                        backgroundColor: "white",
                                        transform: "translateY(100px)",
                                        width: "90%",
                                        borderRadius: "30px",
                                        padding: '15px',
                                        boxShadow: 'rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px'
                                    }}
                                    position="sticky"
                                >
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="outlined-error"
                                                    label="Email"
                                                    defaultValue={props.User.user.email && props.User.user.email}
                                                    fullWidth
                                                    InputProps={{ style: { borderRadius: '14px' } }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="outlined-error"
                                                    label="Mobile No"
                                                    defaultValue={props.User.user.phoneNumber && props.User.user.phoneNumber}
                                                    fullWidth
                                                    InputProps={{ style: { borderRadius: '14px'} }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} style={{ textAlign: 'left' }}>
                                                <Item>
                                                    <Typography variant="h6" style={{ textAlign: 'left' }}>
                                                        Addresses
                                                    </Typography>
                                                    <Divider />
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {props.User.user.address && props.User.user.address !== null && props.User.user.address.length > 0 ?
                                                    props.User.user.address.map((a, i) =>
                                                        <Item key={i}>
                                                            <MainCard border={false} elevation={0} content={false}
                                                                style={{ backgroundColor: 'white', padding: '15px', borderRadius: '14px', width: '100%', border: '1px solid #E0E0E0', textAlign: 'left' }}
                                                            >
                                                                <Stack>
                                                                    <Typography variant="subtitle1">
                                                                        {a.houseNumber && a.houseNumber}
                                                                    </Typography>
                                                                    {a.landmark &&
                                                                        <Typography variant="caption">
                                                                            {'('}  {a.landmark && a.landmark}{')'}
                                                                        </Typography>
                                                                    }
                                                                    <Typography variant="subtitle1">
                                                                        {a.locationDescription && a.locationDescription}
                                                                    </Typography>
                                                                </Stack>
                                                            </MainCard>
                                                        </Item>
                                                    ) : (
                                                        <MainCard border={false} elevation={0} content={false}
                                                            style={{ backgroundColor: 'white', padding: '25px', borderRadius: '14px', width: '100%', border: '1px solid #E0E0E0', textAlign: 'left' }}
                                                        >
                                                            No addresses!
                                                        </MainCard>
                                                    )}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </Col>
                        </Row >
                    ) : ('Error')
                )}
            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                PaperProps={{ style: { padding: '20px' } }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to block the user?"}
                </DialogTitle>
                <DialogActions>
                    <Button variant="contained" color="success" onClick={() => BlockUser(props.User && props.User.user && props.User.user.id &&
                        props.User.user.id, props.User.user.status &&
                    props.User.user.status)}>Yes</Button>
                    <Button variant="contained" color="error" onClick={handleClose} autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                PaperProps={{ style: { padding: '20px' } }}
                open={modalopen}
                onClose={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    {props.blockinguser ?
                        <>
                            <CircularProgress />
                            Blocking user ...Please wait
                        </>
                        :
                        props.blockeduser ?
                            (props.unblockeduser ?
                                'Unblocked User ! '
                                :
                                'Blocked User ! ')
                            : props.unblockinguser ?
                                <>
                                    <CircularProgress />
                                    Blocking user ...Please wait
                                </>
                                :
                                props.unblockeduser &&
                                'Unblocked User ! '
                    }
                </DialogContent>
                <DialogActions>
                    {props.blockeduser &&
                        <Button variant="contained" color="success" onClick={handleModalClose} autoFocus>
                            OK
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </Container >
    )
}

export default UserDetails
