import React, { useState, useCallback } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import ImageViewer from 'react-simple-image-viewer';

export default function MediaCard(props) {

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
   `${props.image}`
  ];
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <>
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="140"
        image={props.image}
        alt={'image'}
      />
      <CardActions>
        <Button onClick={ () => openImageViewer(0) } size="small">view</Button>
      </CardActions>
    </Card>
     {isViewerOpen && (
      <ImageViewer
        src={ images }
        currentIndex={ currentImage }
        disableScroll={true}
        closeOnClickOutside={true}
        onClose={ closeImageViewer }
      />
    )}
    </>
  );
}