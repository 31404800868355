export const BECOME_SELLER = "Become A Seller";
export const DESCRIPTION =
"We are one of the fastest growing network of grocery stores and restaurants.";

// "Does your business need on-demand, hyperlocal deliveries?";
export const DESCRIPTION_2 =
"Our unique platform helps local business owners to meet their customers online, serve and grow.";

// "Mirchicart for Business is the one-stop solution you’re looking for.";
// export const SIGNUP = "SignUp";
export const LOGIN = "Login";
