export const initialConfiguration = {
	retailerFormConfiguration1: [
		{
			label: "First Name",
			type: "input",
			required: true,
			name: "firstName",
			value: null,
			placeHolder: "First Name",
			errors: "",
		},
	],
	retailerFormConfiguration2: [
		{
			label: "Last Name",
			type: "input",
			required: true,
			name: "lastName",
			value: null,
			placeHolder: "Last Name",
			errors: "",
		},
	],
	retailerFormConfiguration3: [
		{
			label: "Mobile Number",
			type: "input",
			required: true,
			name: "mobileNumber",
			value: null,
			placeHolder: "Contact Number",
			errors: "",
		},
	],
	retailerFormConfiguration4: [
		{
			label: "Alternate contact number",
			type: "input",
			required: true,
			name: "secondaryNumber",
			value: null,
			placeHolder: "Phone Number",
			errors: "",
		},
	],
	retailerFormConfiguration5: [
		{
			label: "Email",
			type: "input",
			required: true,
			name: "emailAddress",
			value: null,
			placeHolder: "email",
			errors: "",
		},
	],
	retailerFormConfiguration6: [
		{
			label: "Password",
			type: "password",
			required: true,
			name: "password",
			value: null,
			placeHolder: "Enter Password",
			errors: "",
		},
	],
	retailerFormConfiguration7: [
		{
			label: "Confirm Password",
			type: "password",
			required: true,
			name: "Confirm password",
			value: null,
			placeHolder: "Confirm Number",
			errors: "",
		},
	],
	// retailerFormConfiguration8: [
	// 	{
	// 		label: "VAT Number",
	// 		type: "input",
	// 		required: true,
	// 		name: "VATnumber",
	// 		value: null,
	// 		placeHolder: "VAT Number",
	// 		errors: "",
	// 	},
	// ],
	retailerFormConfiguration8: [
		{
			label: "Aadhar Number",
			type: "input",
			required: true,
			name: "aadharNumber",
			value: null,
			placeHolder: "Aadhar Number",
			errors: "",
		},
	],
	retailerFormConfiguration9: [
		{
			label: "GST Number",
			type: "input",
			required: true,
			name: "GSTnumber",
			value: null,
			placeHolder: "GST Number",
			errors: "",
		},
	],
	retailerFormConfiguration10: [
		{
			label: "PAN Number",
			type: "input",
			required: true,
			name: "PANnumber",
			value: null,
			placeHolder: "PAN Number",
			errors: "",
		},
	],
	
	retailerFormConfiguration11: [
		{
			label: "Store manager first name",
			type: "input",
			required: true,
			value: null,
			placeHolder: "storeManagerFirstName",
			errors: "",
			name: "storeManagerFirstName",
		},
	],
	retailerFormConfiguration12: [
		{
			label: "Store manager last name",
			type: "input",
			required: true,
			value: null,
			placeHolder: "storeManagerLastName",
			errors: "",
			name: "storeManagerLastName",
		},
	],
	retailerFormConfiguration13: [
		{
			label: "Mobile number",
			type: "input",
			required: true,
			value: null,
			placeHolder: "storeManagerPhone",
			errors: "",
			name: "storeManagerPhone",
		},
	],
	retailerFormConfiguration14: [
			{
				label: "Alternate contact number",
				type: "input",
				required: true,
				value: null,
				placeHolder: "storeManagerPhone",
				errors: "",
				name: "storeManagerPhone",
			},
	],
	storeDetailConfiguration: [
		{
			label: "Enter the Name of your store*",
			type: "input",
			required: true,
			name: "storeName",
			value: null,
			placeHolder: "Store Name",
			errors: "",
		},

		{
			label: "Store Description",
			type: "input",
			required: true,
			name: "storeDescription",
			value: null,
			placeHolder: "Store description",
			errors: "",
		},
		{
			label: "Store Logo",
			type: "file",
			required: true,
			name: "storeLogoUrl",
			value: null,
			placeHolder: "Logo for your Store",
			errors: "",
		},
		{
			label: "Store Location",
			type: "location",
			required: true,
			name: "storeLocation",
			value: null,
			Coordinates: [],
			placeHolder: "Store Location",
			errors: "",
		},

		{
			label: "Map Coordinates",
			type: "coordinates",
			name: "storeCoordinates",
			value: null,
			placeHolder: "Coordinates",
		},
		{
			label: "Opening Time",
			name: "openingTime",
			type: "time",
			value: "1",
			options: [
				{ label: "1", value: "1" },
				{ label: "2", value: "2" },
				{ label: "3", value: "3" },
				{ label: "4", value: "4" },
				{ label: "5", value: "5" },
				{ label: "6", value: "6" },
				{ label: "7", value: "7" },
				{ label: "8", value: "8" },
				{ label: "9", value: "9" },
				{ label: "10", value: "10" },
				{ label: "11", value: "11" },
				{ label: "12", value: "12" },
			],
		},
		{
			name: "openingampm",
			type: "ampm",
			value: [],
			options: [
				{ name: "am", status: false }, 
				{ name: "pm", status: false },
				
			],
		},
		{
			label: "Closing Time",
			name: "closingTime",
			type: "time",
			value: "1",
			options: [
				{ label: "1", value: "1" },
				{ label: "2", value: "2" },
				{ label: "3", value: "3" },
				{ label: "4", value: "4" },
				{ label: "5", value: "5" },
				{ label: "6", value: "6" },
				{ label: "7", value: "7" },
				{ label: "8", value: "8" },
				{ label: "9", value: "9" },
				{ label: "10", value: "10" },
				{ label: "11", value: "11" },
				{ label: "12", value: "12" },
			],
		},
		{
			name: "closingampm",
			type: "ampm",
			value: [],
			options: [
				{ name: "am", status: false }, 
				{ name: "pm", status: false },
				
			],
		},
		{
			label: "Holidays",
			name: "holidays",
			type: "checkbox",
			value: [],
			options: [
				{ name: "Sunday", status: false },
				{ name: "Monday", status: false },
				{ name: "Tuesday", status: false },
				{ name: "Wednesday", status: false },
				{ name: "Thursday", status: false },
				{ name: "Friday", status: false },
				{ name: "Saturday", status: false }, 
			],
		}
	],
	deliveryForm: [
		{
			label: "Delivery Type",
			name: "deliveryType",
			type: "checkbox",
			value: [],
			options: [
				{ name: "delivering the order to customer location ", status: false }, 
				{ name: "make the order ready for customer pick-up", status: false },
			],
		},

		{
			label: "Payment Supported",
			name: "paymentSupported",
			type: "checkbox",
			value: [],
			options: [
				{ name: "Cash", status: false },
				{ name: "Card", status: false },
				{ name: "Online", status: false },
			],
		},

		{
			label: "Delivery Time Slots",
			name: "deliveryTimeSlots",
			type: "grid",
			value: [],
		},
		// {
		// 	label: "Delivery Boy Available",
		// 	name: "storeDeliveryPerson",
		// 	type: "radio",
		// 	value: "No",
		// 	options: [
		// 		{ label: "Yes", value: "Yes" },
		// 		{ label: "No", value: "No" },
		// 	],
		// },
	],
	distanceTable: [
		{
			label: "Delivery Max-Distance",
			name: "deliveryMaxDistance",
			type: "selectbox",
			value: "0",
			options: ["0", "3", "6", "9", "12", "15"],
		},
		{
			name: "deliveryCharge",
			type: "table",
			charges: [
				{ label: "0-3 Km", distanceValue: 3, value: null },
				{ label: "3-6 Km", distanceValue: 6, value: null },
				{ label: "6-9 Km", distanceValue: 9, value: null },
				{ label: "9-12 Km", distanceValue: 12, value: null },
				{ label: "12-15 Km", distanceValue: 15, value: null },
			],
		},
	],
	errors: [],
};
export const steps = [
	"Retailer Details",
	"Store Details",
	"Delivery Details",
	"Bank Details",
	"Documents",
	"Payment",
];
