import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { SHOP } from "../../utils/image";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import NavItem from "../NavItem";
import PropTypes from "prop-types";
import { green } from "@material-ui/core/colors";
import MenuPopover from "./MenuPopover";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 300,
  },
  ScrollHeight: {
    height: '100%',
    // maxHeight: 'calc(100vh - 205px)',
    overflowX: 'hidden'
  },
  desktopDrawer: {
    width: 300,
    top: 64,
    overflow: "unset",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  listContainer: {
    paddingRight: '10px',
    overflowY: 'scroll',
    boxShadow: "0px 10px 20px 0px rgb(0 0 0 / 16%)",
    '@global': {
      '*::-webkit-scrollbar': {
        'scrollbar-width': 'thin',
        width: '3px',
        visibility: 'hidden'
      }
    },
    '&:hover': {
      overflowY: 'scroll',
      '@global': {
        '*': {
          'scrollbar-width': 'thin',

        },
        '*::-webkit-scrollbar': {
          width: '4px',
          cursor: 'pointer',
          height: '5px'
        }
        ,
        /* Track */
        '*::-webkit-scrollbar-track': {
          '*-webkit-border-radius': '10px',
          borderRadius: '13px'
        },

        /* Handle */
        '*::-webkit-scrollbar-thumb': {
          '*-webkit-border-radius': '10px',
          borderRadius: '13px',
          background: 'rgb(137, 199, 74, 1)'
        },
        '*::-webkit-scrollbar-thumb:window-inactive': {
          background: 'rgb(137, 199, 74, 1)'
        }
      }
    }

  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    width: drawerWidth,
    flexShrink: 0,
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: "white",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: ` ${drawerWidth}px`,
    [theme.breakpoints.down("sm")]: {
      width: `100%`,
      marginLeft: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    buttonDanger: {
      backgroundColor: "red",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    // overflowY: "hidden",
    border: 'none',
    '@global': {
      '*::-webkit-scrollbar': {
        'scrollbar-width': 'thin',
        width: '3px',
        visibility: 'hidden'
      }
    },
    '&:hover': {
      overflowY: 'scroll',
      '@global': {
        '*': {
          'scrollbar-width': 'thin',

        },
        '*::-webkit-scrollbar': {
          width: '4px',
          cursor: 'pointer',
          height: '5px'
        }
        ,
        /* Track */
        '*::-webkit-scrollbar-track': {
          '*-webkit-border-radius': '10px',
          borderRadius: '13px'
        },

        /* Handle */
        '*::-webkit-scrollbar-thumb': {
          '*-webkit-border-radius': '10px',
          borderRadius: '13px',
          background: 'rgb(137, 199, 74, 1)'
        },
        '*::-webkit-scrollbar-thumb:window-inactive': {
          background: 'rgb(137, 199, 74, 1)'
        }
      }
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawerContainer: {
    paddingTop: "100px",
  },
  grow: {
    flexGrow: 1,
  },
  contents: {
    marginLeft: drawerWidth + 10,
    backgroundColor: 'transparent',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      padding: '10px'
    },
  },
  box: {
    float: 'right',
    marginLeft: '16px',
    marginRight: '24px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '16px'
    }
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: 'all .2s ease-in-out',
    backgroundColor: "#EDE7F6",
    color: '#5E35B1',
    '&[aria-controls="menu-list-grow"],&:hover': {
      backgroundColor: '#5E35B1',
      color: '#EDE7F6'
    }
  },
}));


function NavBar(props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const theme = useTheme();
  /* eslint-disable */
  const [open, setOpen] = React.useState(false);
  /* eslint-disable */
  const [value, setValue] = React.useState('');
  const anchorRef = React.useRef(null);

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const content = (
    <Box height="100%" style={{ justifyContent: 'center', width: '100%' }}>
      <div style={{ justifyContent: 'center', padding: '12px' }}>
        <img style={{ height: "35px", width: '160px' }} src={SHOP} alt="SHOP.png" />
      </div>
      {/* <Box className={classes.listContainer} display="flex" flexDirection="coloumn" > */}
      <NavItem selectedList={props.selectedList} reatilerId={props.id} onclick={() => setMobileOpen(false)} />
      {/* </Box> */}
    </Box>
  );
  const container =
    PropTypes.func.window !== undefined
      ? () => window().document.body
      : undefined;

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon style={{ color: green[500] }} />
          </IconButton>
          <Hidden smUp implementation="css">
            <img style={{ height: "35px", width: '160px' }} src={SHOP} alt="SHOP.png" />
          </Hidden>
          <div className={classes.grow} />
          {/* <NotificationSection {...props}/> */}
          <MenuPopover {...props} />
        </Toolbar>

      </AppBar>
      <Box
        component="main"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Hidden smUp implementation="css">
          <Drawer
            className={classes.listContainer}
            // container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {content}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.listContainer}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {content}
          </Drawer>
        </Hidden>
        <Box component="main" sx={{ backgroundColor: '#E3F2FD' }}>
          {/* <Toolbar /> */}
          <div className={classes.contents}>
            {props.children}
          </div>
        </Box>
      </Box>
    </>
  );
}

NavBar.propTypes = {
  window: PropTypes.func,
};

export default NavBar;
