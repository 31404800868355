import React from "react";
import { Row, Col } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import "./index.css";

function Subscription(props) {

  React.useEffect(()=>{
		document.body.scrollTop = 0;
	}
	,[])

  
  const validationSchema = Yup.object().shape({
		storeStatus: Yup.string().required("Please Enter Store Status"),
	  });
	  
	  const formik = useFormik({
		initialValues: {
      storeStatus: props.searchDetails.store.storeStatus || "",
    },
		validationSchema: validationSchema,
		onSubmit: (values) => {
      props.saveDetails({...props.searchDetails.store,
        storeStatus:values.storeStatus
        });
		 props.next();
		},
	  });

  return (
    <>
      <h4
        style={{
          paddingTop: "30px",
          fontWeight: 500,
          textAlign: "center",
          color: "black",
          textDecoration: "underline",
        }}
      >
        {"Subscription Details"}
      </h4>
      <form onSubmit={formik.handleSubmit} className="sub-form" style={{ marginTop: "30px" }}>
        <div className="sub-form1" id="F1">
          <ListGroup className="list">
           
              <ListGroup.Item
                className="list"
                style={{
                  width: "76%",
                  height: 110,
                  textAlign: "left",
                  margin: "0 40px 0 80px",
                  paddingLeft: "25px",
                }}
              >
                <h4> Basic Type</h4>
                <br />
                <Row>
                  <Col md={6}>Free for 6 months</Col>
                </Row>
              </ListGroup.Item>
         
          </ListGroup>
        </div>
        <Grid container spacing={2} style={{paddingTop:'15px',paddingBottom:'20px',paddingLeft:'13px',width:'100%'}}>
        <Grid item xs={4} >
          <label >
            Store status
          </label>
          </Grid>
          <Grid item xs={6}  style={{ height: 35,width:'100%'}} >
          <TextField
          fullWidth
                  InputProps={{ style: { height: 35,width:'100%'} }}
                  select
                  variant="outlined"
                  SelectProps={{ width: "100%"}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    borderRadius: "5px",
                    height: 30,
                  }}
                  id="status"
                  name="storeStatus"
                  value={formik.values.storeStatus}
                                onChange={formik.handleChange}
                                error={
                                formik.touched.storeStatus && Boolean(formik.errors.storeStatus)
                                }
                                helperText={formik.touched.storeStatus && formik.errors.storeStatus}
                >       
                <MenuItem value={'submitted'}>
                 Submitted
                </MenuItem>
                <MenuItem value={'active'}>
                 Active
                </MenuItem>
                <MenuItem value={'inactive'}>
                 Inactive
                </MenuItem>
                <MenuItem value={"banned"}>
                 Banned
                </MenuItem>
                <MenuItem value={"reject"}>
                 Reject
                </MenuItem>
                </TextField>
               </Grid>
      </Grid>
        <div className='buttons'>
          <button
          type="button"
										onClick={props.back}
                    className='nextbutton'>
										Back
									</button>
          <button type='submit' className='nextbutton'>
            {'Save & Next'}
          </button>
          </div>
      </form>
    </>
  );
}

export default Subscription;
