import React from "react";
// material
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
} from "@mui/material";
//components
import Label from "../Label";
import OrderListHead from "./OrderListHead";
import NoSearchResult from "../NoSearchResult";
import Link from "@mui/material/Link";
import TrackHistory from "./TrackHistory";

const TABLE_HEAD = [
  { id: "Order#", label: "Order #", alignRight: false, alignLeft: true },
  { id: "Placed", label: "Placed", alignRight: false, alignLeft: true },
  {
    id: "AcknowledgementETA",
    label: "Acknowledgement ETA",
    alignRight: false,
    alignLeft: true,
  },
  { id: "Status", label: "Status", alignRight: false, alignLeft: true },
  {
    id: "DeliveryETA",
    label: "Delivery ETA",
    alignRight: false,
    alignLeft: true,
  },
  {
    id: "Need-attention",
    label: "Need attention",
    alignRight: false,
    alignLeft: true,
  },
];

export default function SearchOrderResults(props) {
  const getDetails = (id) => {
    props.getOrderDetails(id);
    localStorage.setItem("orderID", id);
    props.history.push({
      pathname: "/home/Orderdetails",
      state: {
        orderid: id,
      },
    });
  };

  return (
    <>
      <div
        style={{
          color: "black",
          padding: "12px",
          boxSizing: "border-box",
          margin: " 15px 0",
        }}
      >
        <Typography variant="h6">Search Results</Typography>
      </div>
      {props.searchActiveOrderData &&
            props.searchActiveOrderData.items &&
            props.searchActiveOrderData.items.length > 0 ? (
      <TableContainer style={{ width: "100%", overflowX: "auto", marginLeft: "0px" }}> 
        <Table>
          <OrderListHead headLabel={TABLE_HEAD} />
          <TableBody>
              {props.searchActiveOrderData.items.map((row, index) => (
                <TableRow
                  hover
                  style={{ cursor: "pointer" }}
                  key={index}
                  tabIndex={-1}
                  role="checkbox"
                >
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    style={{ textAlign: "center" }}
                  >
                    <Typography variant="subtitle2" noWrap>
                      {row.orderid &&
                        row.orderid.orderNumber &&
                        row.orderid.orderNumber}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.orderid &&
                      row.orderid.stats &&
                      row.orderid.stats[row.orderid.stats.length - 1].created &&
                      row.orderid.stats[row.orderid.stats.length - 1].created}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.updatedAt &&
                      new Date(Number(row.updatedAt)).toString().slice(" ", 24)}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Label
                      variant="ghost"
                      color={
                        row.orderid &&
                        row.orderid.stats &&
                        row.orderid.stats[row.orderid.stats.length - 1] &&
                        row.orderid.stats[row.orderid.stats.length - 1]
                          .status === "Order-Placed"
                          ? "info"
                          : row.orderid &&
                            row.orderid.stats &&
                            row.orderid.stats[row.orderid.stats.length - 1] &&
                            row.orderid.stats[row.orderid.stats.length - 1]
                              .status === "Order-Accepted"
                          ? "primary"
                          : row.orderid &&
                            row.orderid.stats &&
                            row.orderid.stats[row.orderid.stats.length - 1] &&
                            row.orderid.stats[row.orderid.stats.length - 1]
                              .status === "Order-Rejected"
                          ? "error"
                          : row.orderid &&
                            row.orderid.stats &&
                            row.orderid.stats[row.orderid.stats.length - 1] &&
                            row.orderid.stats[row.orderid.stats.length - 1]
                              .status === "Order-Ready"
                          ? "warning"
                          : row.orderid &&
                            row.orderid.stats &&
                            row.orderid.stats[row.orderid.stats.length - 1] &&
                            row.orderid.stats[row.orderid.stats.length - 1]
                              .status === "Shopping-In-Progress"
                          ? "secondary"
                          : "success"
                      }
                    >
                      {row.orderid &&
                        row.orderid.stats &&
                        row.orderid.stats[row.orderid.stats.length - 1] &&
                        row.orderid.stats[row.orderid.stats.length - 1].status}
                    </Label>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {" "}
                    {`${
                      row.orderid &&
                      row.orderid.deliveryDate &&
                      row.orderid.deliveryDate
                    }:
                          ${
                            row.orderid &&
                            row.orderid.deliveryTime &&
                            row.orderid.deliveryTime
                          }`}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Label
                      variant="filled"
                      color={
                        row.counter && row.counter === 2 ? "error" : "success"
                      }
                    >
                      {row.counter && row.counter === 2 ? "Yes" : "No"}
                    </Label>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Link
                      component="button"
                      variant="body2"
                      // color="green"
                      onClick={() =>
                        getDetails(
                          row.orderid && row.orderid.id && row.orderid.id
                        )
                      }
                    >
                      View Order
                    </Link>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <TrackHistory
                      OrderStatus={
                        row.orderid &&
                        row.orderid.stats &&
                        row.orderid.stats &&
                        row.orderid.stats
                      }
                      Assistantid={
                        row.orderid &&
                        row.orderid.shopAssistantId &&
                        row.orderid.shopAssistantId.id &&
                        row.orderid.shopAssistantId.id
                      }
                      {...props}
                    />
                  </TableCell>
                </TableRow>
              ))}

          </TableBody>
        </Table>
      </TableContainer>):(<NoSearchResult/>)}
    </>
  );
}
