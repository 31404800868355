import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Container, Row, Col } from 'react-bootstrap';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { refundvalidationSchema, replacementvalidationSchema } from './utils';
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import { makeStyles } from "@material-ui/core/styles";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const useStyles = makeStyles(() => ({
    card: {
        boxShadow: 'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        padding: '15px',
        borderRadius: '14px'
    },
    col: {
        padding: '8px',
        color: 'rgb(99, 115, 129)',
        fontWeight: 600,
        fontSize: '14px',
    },
}));

const Complaint = (props) => {

    const classes = useStyles();
    const [action, setAction] = React.useState('');
    const [clicked, setClicked] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const refundformik = useFormik({
        initialValues: {
            refundamount: '',
        },
        validationSchema: refundvalidationSchema,
        onSubmit: (values) => {
            handleSolveRefund(values.refundamount);
            setOpen(false);
        },
    });

    const replacementformik = useFormik({
        initialValues: {
            replacementstatus: '',
        },
        validationSchema: replacementvalidationSchema,
        onSubmit: (values) => {
            handleSolveReplacement(values.replacementstatus);
            setOpen(false);
        },
    });

    const [state, setState] = React.useState({
        refundamount: '',
        refundstatus: '',
        replacementstatus: ''
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSolveRefund = (refundamount) => {
        props.SolveComplaint({
            complaintid: props.Complaint.id && props.Complaint.id,
            orderid: props.Complaint.orderid && props.Complaint.orderid.id && props.Complaint.orderid.id,
            orderNumber: Number(props.Complaint.orderid && props.Complaint.orderid.orderNumber && props.Complaint.orderid.orderNumber),
            refund: props.Complaint.refund && props.Complaint.refund,
            replacement: props.Complaint.replacement && props.Complaint.replacement,
            refundStatus: state.refundstatus && state.refundstatus,
            refundAmount: Number(refundamount && refundamount) * 100,
            paymentId: props.Complaint.orderid && props.Complaint.orderid.razorpayPaymentId && props.Complaint.orderid.razorpayPaymentId,
            replacementStatus: null
        })
    }

    const handleSolveReplacement = (replacementstatus) => {
        props.SolveComplaint({
            complaintid: props.Complaint.id && props.Complaint.id,
            orderid: props.Complaint.orderid && props.Complaint.orderid.id && props.Complaint.orderid.id,
            orderNumber: Number(props.Complaint.orderid && props.Complaint.orderid.orderNumber && props.Complaint.orderid.orderNumber),
            refund: props.Complaint.refund && props.Complaint.refund,
            replacement: props.Complaint.replacement && props.Complaint.replacement,
            refundStatus: null,
            refundAmount: null,
            paymentId: null,
            replacementStatus: replacementstatus && replacementstatus
        })
    }
    const handleSolve = () => {
        props.SolveComplaint({
            complaintid: props.Complaint.id && props.Complaint.id,
            orderid: props.Complaint.orderid && props.Complaint.orderid.id && props.Complaint.orderid.id,
            orderNumber: Number(props.Complaint.orderid && props.Complaint.orderid.orderNumber && props.Complaint.orderid.orderNumber),
            refund: props.Complaint.refund && props.Complaint.refund,
            replacement: props.Complaint.replacement && props.Complaint.replacement,
            refundStatus: null,
            refundAmount: null,
            paymentId: null,
            replacementStatus: null
        })
    }
    React.useEffect(() => {
        setState({
            refundamount: '',
            refundstatus: '',
            replacementstatus: ''
        });
    }, [])

    return (
        <>
            <Row className={classes.col}>
                <Col sm={12}>
                    <Stepper orientation="vertical">
                        {props.Complaint.userMessage && props.Complaint.userMessage.length > 0 &&
                            props.Complaint.userMessage.map(message =>
                                <Step key={message}>
                                    <StepLabel
                                    >
                                        {message}
                                    </StepLabel>
                                </Step>
                            )}
                    </Stepper>
                </Col>
            </Row>
            {props.Complaint.itemList && props.Complaint.itemList !== null &&
                <Row className={classes.col}>
                    <Col sm={6}>
                        Items
                    </Col>
                    <Col sm={6}>
                        {props.Complaint.itemList.map(i =>
                            `${i},`
                        )}
                    </Col>
                </Row>}
            {props.Complaint.itemList && props.Complaint.itemList !== null &&
                props.Complaint.image && props.Complaint.image !== null &&
                <Row className={classes.col}>
                    <Col sm={6}>
                        Item Image
                    </Col>
                    <Col sm={6}>
                        <img
                            src={props.Complaint.image && props.Complaint.image}
                            alt="item"
                            style={{ width: '100px', height: '100px', maxWidth: '100%', maxHeight: '100%' }} />
                    </Col>
                </Row>
            }
            <Row className={classes.col}>
                <Col sm={6}>
                    Refund
                </Col>
                <Col sm={6}>
                    {props.Complaint.refund && props.Complaint.refund === true ?
                        'Yes'
                        : 'No'}
                </Col>
            </Row>
            {props.Complaint.refund && props.Complaint.refund === true &&
                <Row className={classes.col}>
                    <Col sm={6}>
                        Refund Amount
                    </Col>
                    <Col sm={6}>
                        ₹{props.Complaint.refundAmount && parseFloat(props.Complaint.refundAmount).toFixed(2)}
                    </Col>
                </Row>
            }
            {props.Complaint.refund && props.Complaint.refund === true &&
                <Row className={classes.col}>
                    <Col sm={6}>
                        Refund Status
                    </Col>
                    <Col sm={6}>
                        {props.Complaint.refundStatus && props.Complaint.refundStatus}
                    </Col>
                </Row>
            }
            {props.Complaint.refund && props.Complaint.refund === true &&
                <Row className={classes.col}>
                    <Col sm={6}>
                        Refund Amount
                    </Col>
                    <Col sm={6}>
                        ₹{props.Complaint.refundAmount && parseFloat(props.Complaint.refundAmount).toFixed(2)}
                    </Col>
                </Row>
            }
            <Row className={classes.col}>
                <Col sm={6}>
                    Replacement
                </Col>
                <Col sm={6}>
                    {props.Complaint.replacement && props.Complaint.replacement === true ?
                        'Yes'
                        : 'No'}
                </Col>
            </Row>
            {props.Complaint.replacement && props.Complaint.replacement === true &&
                <Row className={classes.col}>
                    <Col sm={6}>
                        Replacement Status
                    </Col>
                    <Col sm={6}>
                        {props.Complaint.replacementStatus && props.Complaint.replacementStatus}
                    </Col>
                </Row>
            }
            <Row className={classes.col}>
                <Col sm={6}>
                    Actions
                </Col>
            </Row>
            {props.Complaint.adminActions && props.Complaint.adminActions.length > 0 &&
                props.Complaint.adminActions.map(action =>
                    <Row className={classes.col}>
                        <Col sm={12}>
                            <TextField hiddenLabel variant="filled" defaultValue={action && action} disabled fullWidth />
                        </Col>
                    </Row>
                )
            }
            {props.Complaint.complaintStatus !== 2 &&
                <Row className={classes.col}>
                    <Col sm={8}>
                        <TextField id="outlined-basic" label="Action" variant="outlined" fullWidth onChange={(e) => setAction(e.target.value)} />
                    </Col>
                    <Col sm={4}>
                        <Button variant="contained" color="success" fullWidth style={{ height: '100%' }}
                            onClick={() => {
                                setClicked(true); props.AdminAction(props.Complaint.id && props.Complaint.id,
                                    action)
                            }}>
                            Send
                        </Button>
                    </Col>
                </Row>
            }
            {(props.Complaint.adminActions && props.Complaint.adminActions.length > 0 && props.Complaint.complaintStatus !== 2)
                || (action && action !== '' && clicked) ?
                <Row className={classes.col}>
                    <Col sm={12}>
                        <Button variant="contained" color="success" fullWidth style={{ height: '100%' }}
                            onClick={() => props.CloseComplaint(props.Complaint.id && props.Complaint.id, 2)}
                        >
                            Close Complaint
                        </Button>
                    </Col>
                </Row>
                :
                null
            }
            {props.Complaint.complaintStatus !== 2 &&
                <Row className={classes.col}>
                    <Col sm={12}>
                        <Button variant="contained" color="success" fullWidth style={{ height: '100%' }}
                            onClick={handleClickOpen}
                        >
                            Solve Complaint
                        </Button>
                    </Col>
                </Row>
            }
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                    {"Solve Complaint"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Container style={{ paddingTop: '10px' }}>
                            {props.Complaint.refund && props.Complaint.refund === true ?
                                <Form onSubmit={refundformik.handleSubmit}>
                                    <Row>
                                        <TextField
                                            id="outlined-basic"
                                            label="Refund Amount"
                                            variant="outlined"
                                            fullWidth
                                            name="refundamount"
                                            value={refundformik.values.refundamount}
                                            onChange={refundformik.handleChange}
                                            error={
                                                refundformik.touched.refundamount && Boolean(refundformik.errors.refundamount)
                                            }
                                            helperText={refundformik.touched.refundamount && refundformik.errors.refundamount}
                                        />
                                    </Row>
                                    <Row/>
                                    <Row style={{paddingTop:'25px'}}> 
                                        <Col md={6}>
                                            <Button
                                                type="submit"
                                                variant='outlined'
                                                autoFocus>
                                                Solve
                                            </Button>
                                        </Col>
                                        <Col md={6} style={{alignItems:'right'}}>
                                            <Button 
                                            variant='outlined'
                                            onClick={handleClose}>
                                                Cancel
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                                : props.Complaint.replacement && props.Complaint.replacement === true ?
                                    <Form onSubmit={replacementformik.handleSubmit}>
                                        <Row>
                                            <TextField
                                                id="outlined-basic"
                                                label="Replacement Status"
                                                variant="outlined"
                                                name="replacementstatus"
                                                value={replacementformik.values.replacementstatus}
                                                onChange={replacementformik.handleChange}
                                                error={
                                                    replacementformik.touched.replacementstatus && Boolean(replacementformik.errors.replacementstatus)
                                                }
                                                helperText={replacementformik.touched.replacementstatus && replacementformik.errors.replacementstatus}
                                                fullWidth
                                            />
                                        </Row>
                                        <Row style={{paddingTop:'25px'}}>
                                            <Col md={6}>
                                                <Button type="submit"
                                                variant='outlined'
                                                    autoFocus>
                                                    Solve
                                                </Button>
                                            </Col>
                                            <Col md={6} style={{alignItems:'right'}}>
                                                <Button 
                                                variant='outlined'
                                                onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    :
                                    <>
                                        <Row style={{width:'104%',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                                          Are you sure?
                                        </Row>
                                        <Row style={{paddingTop:'25px', width:'100%',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                                            <Col xs={6} md={6}>
                                                <Button type="submit"
                                                variant='outlined'
                                                color='success'
                                                    onClick={handleSolve}
                                                    autoFocus>
                                                    Solve
                                                </Button>
                                            </Col>
                                            <Col xs={6} md={6}>
                                                <Button 
                                                variant='outlined'
                                                color='error'
                                                autoFocus onClick={handleClose}>
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                            }
                        </Container>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Complaint
