import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import FormHeaders from "../../Components/FormHeaders";
import "./index.css";

const ConfirmationForm = (props) => {
	const form = [
		...props.retailerFormConfiguration,
		...props.storeDetailConfiguration,
	];
	const infos = [];
	const obj = {};
	form.map((field) => {
		return obj[field.label] = field.value;
	});
	infos.push(obj);
	return (
		<>
			<Container className="form_container">
				<div className="form_div">
					<FormHeaders header="Confirmation" />
					<form id="signup-form">
						<ul>
							{infos.map((info,index) =>
								Object.keys(info).map((keys) => {
									return keys === "Password" ||
										keys === "Map Coordinates" ||
										keys === "Store Logo" ? null : (
										<>
											<li key={index}>

												{keys} :{" "}
												<span
													style={{
														color: "black",
														fontSize: "large",
														fontWeight: "600",
														paddingLeft: 15,
													}}>
													{" "}
													{info[keys]}
												</span>
											</li>
										</>
									);
								})
							)}
						</ul>
					</form>
					<Row>
						<Col>
							<button
							style={{
								height: "37px",
								backgroundColor: "#75c224",
								color: "white",
								fontSize: "16px",
								fontWeight: 600,
								border: "none",
							  }}
								id="submit-button"
								onClick={props.submitForm}
								variant="success">
								Submit
							</button>
							<Button
								id="cancel-button"
								onClick={props.confirmForm}
								variant="light">
								Back
							</Button>
						</Col>
					</Row>
				</div>
			</Container>
		</>
	);
};
export default ConfirmationForm;
