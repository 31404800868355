import React, { useState , useEffect } from "react";
// import ReactDOM from 'react-dom';
import ImgCrop from "antd-img-crop";
import { Upload, Modal } from "antd";
import Cards from "../Cards";
import {Row,Col} from 'react-bootstrap';
// import "antd/dist/antd.css";
import "./index.css";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


const Uploader = (props) => {
  useEffect(() => {
    if(props.ProductDetailsData&&props.ProductDetailsData.data&&
      props.ProductDetailsData.data.salesproducts&&
      props.ProductDetailsData.data.salesproducts.image&&
      props.ProductDetailsData.data.salesproducts.image.primary!==null)
      {
        setFileList( [{
          uid: '-1',
          name: props.ProductDetailsData&&props.ProductDetailsData.data&&
          props.ProductDetailsData.data.salesproducts&&
          props.ProductDetailsData.data.salesproducts.productname&&
          `${props.ProductDetailsData.data.salesproducts.productname}`,
          status: 'done',
          url:props.ProductDetailsData&&props.ProductDetailsData.data&&
          props.ProductDetailsData.data.salesproducts&&
          props.ProductDetailsData.data.salesproducts.image&&
          props.ProductDetailsData.data.salesproducts.image.primary,
        }])

      }
      else {
        setFileList( [])
      }

  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [props.imge]);
  const [fileList, setFileList] = useState([
    // {
    //   uid: '-1',
    //   name: props.ProductDetailsData&&props.ProductDetailsData.data&&
    //   props.ProductDetailsData.data.salesproducts&&
    //   props.ProductDetailsData.data.salesproducts.productname&&
    //   `${props.ProductDetailsData.data.salesproducts.productname}`,
    //   status: 'done',
    //   url: props.ProductDetailsData&&props.ProductDetailsData.data&&
    //   props.ProductDetailsData.data.salesproducts&&
    //   props.ProductDetailsData.data.salesproducts.image&&
    //   props.ProductDetailsData.data.salesproducts.image.primary,
    // },
  ]);
  const [previewImage,setPreviewImage]=useState(localStorage.getItem('imageUrl'));
  const [previewVisible,setPreviewVisible]=useState();
  const [previewTitle,setPreviewTitle]=useState()
  const [Btn,setButton]=useState(false)
  // const [cropSize, setCropSize] = useState({ width: 512, height: 512 });
  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }
 const handleCancel = () => {setPreviewVisible(false)}
 const handleRemove = () => {
  // props.RemoveLogo();
  props.DeleteImg();
};


  const onChange = ({ fileList: newFileList }) => {
    // console.log("nnn",newFileList )
    localStorage.removeItem('updateUrl')
    if(newFileList.length!==0)
    {
      
      props.RemoveLogo();
     
    }
   
    setFileList(newFileList);
    setButton(true)
  };

  const SubmitNow = () => {
    setButton(false)
    props.Delete_Image()
    props.updateImage({ files:fileList[0].originFileObj,fileName:props.ProductDetailsData&&props.ProductDetailsData.data&&
      props.ProductDetailsData.data.salesproducts&&
      props.ProductDetailsData.data.salesproducts.productname&&
      `${props.ProductDetailsData.data.salesproducts.productname}${Math.floor(Math.random() * (999 - 100 + 1) + 100)}.jpg`});
      props.updateThumbnail({files: fileList[0].originFileObj,fileName:props.ProductDetailsData&&props.ProductDetailsData.data&&
        props.ProductDetailsData.data.salesproducts&&
        props.ProductDetailsData.data.salesproducts.productname&&
        `${props.ProductDetailsData.data.salesproducts.productname}${Math.floor(Math.random() * (999 - 100 + 1) + 100)}.jpg`})
  };


  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  
  return (
    <>
    <Row>
    <Col>
      <ImgCrop
        modalTitle="product"
        modalWidth={700}
        modalHeight={700}
        minZoom={0.1}
        // width={512}
        // height={512}
        aspect={1/1}
        rotate={true}
        grid
        // onModalOk={SubmitNow}
      >
        <Upload
          maxCount={1}
          customRequest={dummyRequest}
          accept="image/*"
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={onChange}
          onRemove={handleRemove}
        >
          {/*  */}
          {fileList.length < 1 && "Click here to choose the image"} 
          {/* {fileList.length===0?"Click here to choose the image":"Click here to replace primary image"} */}
        </Upload>
      </ImgCrop>
      </Col>
      <Col>
        <Cards/>
      </Col>
      </Row>
      {Btn===true&&fileList.length!==0?
      <button
      style={{
        justifyContent: " center",
        color: "rgb(255, 255, 255)",
        backgroundImage:
          "-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
        boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
        width: "80px",
        height: "30px",
        fontSize: "16px",
        fontWeight: "600",
        borderRadius: "5px",
        borderWidth: "1px",
        borderStyle: " solid",
        borderColor: "transparent",
        borderImage: "initial",
        overflow: "hidden",
        outline: "none",
        minHeight: " 14px",
        fontFamily: "Varela Round, sans-serif",
      }}
      type="button"
      onClick={SubmitNow}
    >
      Upload
    </button>
    :null}
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
    </>
  );
};
export default Uploader;




// import React, { Component } from 'react'
// import { DropzoneArea } from 'material-ui-dropzone';

// class Uploader extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       files: [],
//       // img:localStorage.getItem('imageUrl').replace("https://localcommerce.s3.ap-south-1.amazonaws.com/",""),
//     };
//   }
//   handleChange(files) {
//     this.setState({
//       files: files
//     });
//   }

//   render() {
//     const upload = () => {
//       this.props.Delete_Image()
//       this.props.updateImage({ files: this.state.files });
//       this.props.updateThumbnail({files:this.state.files})
//     }
//     return (
//       <>
//         <DropzoneArea style={{height:20}}
//         filesLimit={1}
//         acceptedFiles={['image/*']}
//           onChange={this.handleChange.bind(this)}
//         />
//  {this.state.files.length!==0?(
//           <button variant="success" 
//           style={{
//            height: "37px",
//            width: "100%",
//            backgroundColor: "#75c224",
//            color: "white",
//            fontSize: "16px",
//            fontWeight: 600,
//            border: "none",
//            borderRadius:5
//          }}
//          type="button"
// onClick={upload}>
// {this.props.newImageLoading?('loading'):('upload')}
// </button>
 
//         ):(
//           <button variant="success" 
//                  style={{
//                   height: "37px",
//                   width: "100%",
//                   backgroundColor: "#9b9b9b",
//                   color: "white",
//                   fontSize: "16px",
//                   fontWeight: 600,
//                   border: "none",
//                   borderRadius:5
//                 }}
//                 disabled>
//  upload
//   </button>
        
//         )} 
//       </>
//     )
//   }
// }


// export default Uploader;


