import React from 'react';
import PropTypes from 'prop-types';



const CustomCard = ({ height, width, borderRadius, boxShadow, borderLeft, borderColor, children, background,onClick,padding,cursor,marginLeft,flex,position}) => {
   
    const cardStyles = {
        padding: padding||"40px",
        borderRadius: borderRadius || '1rem',
        boxShadow: boxShadow || '0px 0px 25px 5px rgba(0, 0, 0, 0.1)',
        background: background || '#fff',
        width: width || '100%',
        height: height || '100%',
        borderColor: borderColor || '#fff',
        borderLeft: borderLeft || 'none',
        cursor:cursor,
        position:position,
        marginLeft:marginLeft,
        display:flex,
    };

  return (
    <div style={cardStyles} onClick={onClick}>
      {children}
    </div>
  );
};

CustomCard.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  borderRadius: PropTypes.string,
  boxShadow: PropTypes.string,
  borderLeft: PropTypes.string,
  borderColor: PropTypes.string,
  padding: PropTypes.string,
  opacity: PropTypes.string,marginLeft:PropTypes.string,
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  position:PropTypes.string,
  padding: PropTypes.string,
    cursor:PropTypes.string,
    flex:PropTypes.string,
};

export default CustomCard;
