import React from "react";
import { AppBar } from "@material-ui/core";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import OpenComplaints from "./OpenComplaints";
import ClosedComplaints from "./ClosedComplaints";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function OrderDetails(props) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div
          style={{
            backgroundColor: "white",
            transform: "translateY(100px)",
            width: "90%",
            borderRadius: "30px",
            padding:'10px',
            boxShadow:'0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)'
          }}
          position="sticky"
        >
      <Box
        sx={{
          bgcolor: "background.paper",
          width: '100%',
          position: "relative",
          minHeight: 200,
          justifyContent: "center",
        }}
      >
        <div style={{display:"flex"}}>
        <AppBar position="static" color="default" style={{width:"49%"}}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="action tabs example"
          >
            <Tab label="Open Complaints"  value={0} {...a11yProps(0)} />
            {/* <Tab label="Closed Complaints" {...a11yProps(1)} /> */}
          </Tabs>
        </AppBar>
        <AppBar position="static" color="default" style={{width:"49%",marginLeft:'6px'}}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="action tabs example"
          >
            <Tab label="Closed Complaints"  value={1} {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        </div>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          style={{width:'100%'}}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction} >
          <OpenComplaints {...props}/>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <ClosedComplaints {...props}/>
          </TabPanel>
        </SwipeableViews>
      </Box>
    </div>
  );
}
