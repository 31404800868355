import React, { Component } from 'react';
import SortableTree, { addNodeUnderParent, getNodeAtPath, removeNodeAtPath,toggleExpandedForAll } from '../Tree';
// 
import { message } from 'antd';
import { Form } from 'semantic-ui-react';
import { Row, Col } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TextField, Button } from '@material-ui/core';
import MenuItem from "@material-ui/core/MenuItem";
import Spinner from 'react-bootstrap/Spinner';
import Snackbar from "@material-ui/core/Snackbar";
import { AlertTitle } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import client from "../../Configurations/apollo";
import { CREATE_HEIRARCHY, UPDATE_HEIRARCHY, UPDATE_HEIRARCHY1, CREATE_FIRSTLEVEL_HEIRARCHY,GET_CATEGORY  } from "./GqlQuery";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { green } from "@material-ui/core/colors";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './index.css';
import { Container } from "@mui/material";
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}



// 
function Alert(props) {

  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class Heirarchy extends Component {
  constructor(props) {
    super(props);

  

  
    // this.componentDidMount()
    this.state = {
      personName:[],
      isMobile: window.innerWidth < 768,
      treeData: [],
      open: true,
      tree: false,
      addAsFirstChild: false,
      nodeClicked: null,
      isInput: false,
      checkedA: true,
      Depth: 1,
      depParentid: null,
      childCategoryPresent: false,
      status: "active",
      displayRank: null,
      parent: false,
      parentid: null,
      nodename: "",
      Heirarchyid: null,
      newdep: false,
      update: 1,
      ErrorOpen: true,
      error: false,
      errMsg: "",
      netError: false,
      success: false,
      updateSuccess: false,
      successstatus: false,
      nodeadded: false,
      businessTypeId:[],
      businessTypeName:[]
    };
    this.handleTreeOnChange = this.handleTreeOnChange.bind(this);
    this.handleNodeClick = this.handleNodeClick.bind(this);

  }


  updateIsMobile = () => {
    this.setState({
      isMobile: window.innerWidth < 768
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIsMobile);
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateIsMobile);
    this.props.getDepartmentList();
  }

  alertNodeInfo = ({ node, path, treeIndex }) => {
    this.setState({ isInput: false });
    const objectString = Object.keys(node)
      .map((k) => (k === "childCategories" ? "childCategories: Array" : `${k}: '${node[k]}'`))
      .join(",\n   ");

    global.alert(
      "Info passed to the button generator:\n\n" +
      `node: {\n   ${objectString}\n},\n` +
      `path: [${path.join(", ")}],\n` +
      `treeIndex: ${treeIndex}`
    );
  };

  handleTreeOnChange = (treeData) => {
    this.setState({ treeData });
  };

  handleNodeClick = (node) => {
    this.setState({
      nodeClicked: node,
      isInput: true
    });
  };

  // componentDidMount() {

  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.DeparmentList !== this.props.DeparmentList) {
        this.props.DeparmentList.map((d)=>{
            this.setState({ treeData: this.props.DeparmentList});  
        })
      this.setState({ treeData: this.props.DeparmentList});
      this.setState({ tree: true })

    }
    if (prevState.update !== this.state.update) {
      this.props.getDepartmentList(`${localStorage.getItem('storeid')}`);

    }
  }

  render() {
    document.title = "Admin Portal | Manage Hierarchy";
    let parentKey;
    const token = localStorage.getItem('token');


    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      this.setState({businessTypeId:
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,}
      );
    };
    // const handleNameChange = (event) => {
    //   const {
    //     target: { value },
    //   } = event;
    //   this.setState({businessTypeName:
    //     // On autofill we get a stringified value.
    //     typeof value === 'string' ? value.split(',') : value,}
    //   );
    // };
    const AddHeirarchy = (id) => {
      let depParentid;
      let level;
      let parentChildCategory;
      let parentName;

      {
        this.state.treeData.map(n =>
          n.childCategories ?
            (
              n.childCategories.map(c =>
                c.childCategories ?
                  (
                    c.childCategories.map(s =>
                      s.childCategories ?
                        (
                          s.childCategories.map(a =>
                            a.id === id &&

                            (parentName = s.name)
                          )

                        )
                        :
                        (
                          s.id === id &&
                          (parentName = c.name)
                        )
                    )
                  )
                  :
                  (
                    c.id === id &&
                    (parentName = n.name)
                  )
              )
            )
            : (

              null


            )

        )
      }


      {
        this.state.treeData.map(n =>
          n.childCategories ?
            (
              n.childCategories.map(c =>
                c.childCategories ?
                  (
                    c.childCategories.map(s =>
                      s.childCategories ?
                        (
                          s.childCategories.map(a =>
                            a.id === id &&

                            (parentChildCategory = s.childCategoryPresent)
                          )

                        )
                        :
                        (
                          s.id === id &&
                          (parentChildCategory = c.childCategoryPresent)
                        )
                    )
                  )
                  :
                  (
                    c.id === id &&
                    (parentChildCategory = n.childCategoryPresent)
                  )
              )
            )
            : (

              null


            )

        )
      }

      {
        this.state.treeData.map(n =>
          n.childCategories ?
            (
              n.childCategories.map(c =>
                c.childCategories ?
                  (
                    c.childCategories.map(s =>
                      s.childCategories ?
                        (
                          s.childCategories.map(a =>
                            a.id === id&&

                            (level = 4)
                          )

                        )
                        :
                        (
                          s.id === id &&
                          (level = 3)
                        )
                    )
                  )
                  :
                  (
                    c.id === id &&
                    (level = 2)
                  )
              )
            )
            : (

             null


            )

        )
      }


      {
        this.state.treeData.map(n =>
          n.childCategories ?
            (
              n.childCategories.map(c =>
                c.childCategories ?
                  (
                    c.childCategories.map(s =>
                      s.childCategories ?
                        (
                          s.childCategories.map(a =>
                            a.id === id &&

                            (depParentid = s.id)
                          )

                        )
                        :
                        (
                          s.id === id&&
                          (depParentid = c.id)
                        )
                    )
                  )
                  :
                  (
                    c.id === id &&
                    (depParentid = n.id)
                  )
              )
            )
            : (

              null


            )

        )
      }
      let name = this.state.nodename;
      let { childCategoryPresent, status, displayRank ,businessTypeId} = this.state;
      if (this.state.nodename === "") {
        message.error({
          content: 'Please enter the name of heirarchy',
          className: 'custom-class',
          style: {
            marginTop: '20vh',
            marginLeft: '60vh'
          },
        }, 10);
      }
      else {// this.props.AddDepartment({ name, depParentid, status,childCategoryPresent, displayRank });
        client
          .mutate({
            mutation: CREATE_HEIRARCHY,
            // mutate:{
            fetchPolicy: "no-cache",
            errorPolicy: "all",
            // }
            variables: {
              storeid: localStorage.getItem('storeid'),
              name: name,
              level: level,
              displayrank: Number(displayRank),
              parentid: depParentid,
              status: status,
              childCategoryPresent: childCategoryPresent,
              parentChildCategory: parentChildCategory,
              businessTypeId:businessTypeId
            },
            context: {
              headers: {
                Authorization: 'Bearer ' + token
              }
            },
          })
          .then((res) => {

            if (res.data && res.data.createMasterHierarchy && res.data.createMasterHierarchy !== null) {
              this.setState({ update: this.state.update + 1 });
              this.setState({ success: true });
              UpdateChildcategoryPresent(depParentid, true, parentName);
          
            }
            else if (res.errors) {
              this.setState({ success: false })
              this.setState({ error: true });
              this.setState({ errMsg: res.errors[0].message });
            }

          })
          .catch((errors) => {

            // console.log(errors.errorMessage)
            this.setState({ success: false })
            this.setState({ netError: true });
            this.setState({ errMsg: errors });
          })
      }
    }
    const Add1 = () => {
      let name = this.state.nodename;
      let depParentid = null;
      let { childCategoryPresent, status, displayRank,businessTypeId } = this.state;
      if (this.state.nodename === "") {
        message.error({
          content: 'Please enter the name of heirarchy',
          className: 'custom-class',
          style: {
            marginTop: '20vh',
            marginLeft: '60vh'
          },
        });
      }
      else {
        client
          .mutate({
            mutation: CREATE_FIRSTLEVEL_HEIRARCHY,
            fetchPolicy: "no-cache",
            errorPolicy: "all",
            variables: {
              storeid: localStorage.getItem('storeid'),
              name: name,
              displayrank: Number(displayRank),
              parentid: depParentid,
              status: status,
              childCategoryPresent: childCategoryPresent,
              level: 1,
              businessTypeId:businessTypeId
            },
            context: {
              headers: {
                Authorization: 'Bearer ' + token
              }
            },
          })
          .then((res) => {
            if (res.data && res.data.createMasterHierarchy && res.data.createMasterHierarchy !== null) {
              this.setState({ update: this.state.update + 1 }, () => {
                this.setState({ success: true });
              });
              // window.location.reload(true)
              // this.props.getDepartmentList(`${localStorage.getItem('storeid')}`);
              // this.setState({ treeData: this.props.DepartmentList.data.Masterdepartments });

            


              // window.location.reload(true)
            }
            else if (res.errors) {
              this.setState({ error: true });
              this.setState({ errMsg: res.errors[0].message });
            }
          })
          .catch((err) => {

            this.setState({ netError: true });
            this.setState({ errMsg: err });
          })
      }

    }
    const Add = () => {
      let name = this.state.nodename;
      let depParentid = null;
      let { childCategoryPresent, status, displayRank ,businessTypeId} = this.state;
      if (this.state.nodename === "") {
        message.error({
          content: 'Please enter the name of heirarchy',
          className: 'custom-class',
          style: {
            marginTop: '20vh',
            marginLeft: '60vh'
          },
        });
      }
      else {
        client
          .mutate({
            mutation: CREATE_FIRSTLEVEL_HEIRARCHY,
            fetchPolicy: "no-cache",
            errorPolicy: "all",
            variables: {
              storeid: localStorage.getItem('storeid'),
              name: name,
              level: 1,
              displayrank: Number(displayRank),
              parentid: depParentid,
              status: status,
              childCategoryPresent: childCategoryPresent,
              businessTypeId:businessTypeId
            },
            context: {
              headers: {
                Authorization: 'Bearer ' + token
              }
            },
          })
          .then((res) => {
            if (res.data && res.data.createMasterHierarchy && res.data.createMasterHierarchy !== null) {
              this.setState({ update: this.state.update + 1 }, () => {
                this.setState({ success: true });
              });
              //  window.location.reload(true)
              // this.props.getDepartmentList(`${localStorage.getItem('storeid')}`);
              // this.setState({ treeData: this.props.DepartmentList.data.Masterdepartments });

              

            }
            else if (res.errors) {
              this.setState({ success: false })
              this.setState({ error: true });
              this.setState({ errMsg: res.errors[0].message });
            }
          }).catch((err) => {


            this.setState({ success: false })
            this.setState({ netError: true });
            this.setState({ errMsg: err });
          })
      }
      // .then(()=>{
      //           this.setState({ update:this.state.update+1});
      // })
      // this.props.AddDepartment({ name, depParentid, status,childCategoryPresent,displayRank });
    }
    const Update = (id, parentid, childCategoryPresent) => {
      let hid = id;
      let depParentid = parentid;
      let name = this.state.nodename;
      let { status, displayRank ,businessTypeId} = this.state;
      if (this.state.nodename === "") {
        message.error({
          content: 'Please enter the name of heirarchy',
          className: 'custom-class',
          style: {
            marginTop: '20vh',
            marginLeft: '60vh'
          },
        });
      }
      else {
        client
          .mutate({
            mutation: UPDATE_HEIRARCHY,
            variables: {
              hierarchyid: hid,
              name: name,
              parentid: depParentid,
              status: status,
              displayrank: Number(displayRank),
              childCategoryPresent: Boolean(childCategoryPresent),
              businessTypeId:businessTypeId
            },
            context: {
              headers: {
                Authorization: 'Bearer ' + token
              }
            },
          })
          .then((res) => {
            if (res.data) {
              this.setState({ update: this.state.update + 1 });
              this.setState({ updateSuccess: true });
              

            }
            else if (res.errors) {
              this.setState({ error: true });
              this.setState({ errMsg: res.errors[0].message });
            }
          })
          .catch((err) => {
            this.setState({ netError: true });
            this.setState({ errMsg: "Department or Category exists already" });

          })
      }
    }
    const UpdateChildcategoryPresent = (id, childCategoryPresent, hname) => {
      let hid = id;
      // let depParentid = parentid;
      let name = hname;
      let { status, displayRank,businessTypeId } = this.state;
      if (this.state.nodename === "") {
        return <Alert severity="error">{'Please enter the name of heirarchy'}</Alert>
      }
      else {
        client
          .mutate({
            mutation: UPDATE_HEIRARCHY1,
            variables: {
              hierarchyid: hid,
              name: name,
              // parentid:depParentid,
              status: status,
              displayrank: Number(displayRank),
              childCategoryPresent: Boolean(childCategoryPresent),
              businessTypeId:businessTypeId
            },
            context: {
              headers: {
                Authorization: 'Bearer ' + token
              }
            },
          })
          .then((res) => {
            if (res.data) {
              this.setState({ update: this.state.update + 1 });
              // this.setState({ success:true});
              status === "inactive" && this.setState({ successstatus: true });
              

            }
            else {
              this.setState({ error: true });
              this.setState({ errMsg: res.errors[0].message });
            }
          })
          .catch((err) => {
            this.setState({ netError: true });
            this.setState({ errMsg: "Department or Category exists already" });
          })
      }
      // this.props.UpdateDepartment({ hid, name, depParentid, childCategoryPresent, status, displayRank });
      // window.location.reload(true)
    }
    const theme = createMuiTheme({
      palette: {
        primary: green,
      },
      overrides: {
        MuiInputBase: {
          input: {
            '&:-webkit-autofill': {
              transitionDelay: '9999s',
              transitionProperty: 'background-color, color',
            },
          },
        },
      },
    })
    return (
      <>
       
        {

          <Container maxWidth='md' 
          style={{
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.16)",
            borderRadius: "10px",
            marginTop: "90px",
            padding: "30px",
            display: 'flex',
            flexDirection: 'column',
            flex: ' 1 1 0',
            width: "100%",
          }}>
            <div style={{ paddingBottom: '10px', fontWeight: 600 }}>
              <div style={{ paddingBottom: 8 }}>
                <button
                  style={{
                    height: "45px",
                    width: "228px",
                    backgroundColor: 'rgb(137, 199, 74, 1)',
                    boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                    borderWidth: '1px',
                    borderStyle: ' solid',
                    borderColor: 'transparent',
                    borderImage: 'initial',
                    overflow: 'hidden',
                    outline: 'none',
                    color: "white",
                    fontSize: "16px",
                    fontWeight: 600,
                    border: "none",
                    marginLeft: 44,
                    borderBottomRightRadius: 15,
                    borderTopRightRadius: 15
                  }}
                  onClick={() =>
                    this.setState(state => ({
                      newdep: true,
                      treeData: [{
                        name: null,
                        childCategoryPresent: false
                      }, ...this.state.treeData]
                    }))
                  }
                >
                  Add new department
                </button>

              </div>
              {this.props.DeparmentList && this.props.DeparmentList.length !== 0 && this.state.tree ?
                (
                  <Row>
                    <Col md={7} sm={7} style={{ height: 800, width: '100%' }}>

                      <SortableTree
                        treeData={this.state.treeData}
                        onChange={treeData => this.setState({ treeData })}
                       
                        onMoveNode={({ node, treeIndex, path }) =>
                          global.console.debug(
                            "node:",
                            node,
                            "treeIndex:",
                            treeIndex,
                            "path:",
                            path
                          )
                        }

                        maxDepth={this.state.Depth}
                        canDrag={treeData => false}
                        canDrop={treeData => false}
                        isVirtualized={true}
                        generateNodeProps={({ node, path, rowInfo, treeIndex }) => ({
                          className: `${node.className}`,
                          title:
                            this.state.isInput && node === this.state.nodeClicked ? (
                              node.name

                            ) : (
                              node.name
                            ),

                          buttons: [
                            path.length <=1 && node.name &&
                            <Button
                              data-toggle="tooltip" data-placement="top" title="Click to add a child category here."
                              onClick={() => {
                                this.setState(state => ({
                                  newdep: false,
                                  treeData: addNodeUnderParent({
                                    treeData: this.state.treeData,
                                    parentKey: path[path.length - 1],
                                    getNodeKey: ({ treeIndex }) => treeIndex,
                                    expandParent: true,
                                    newNode: {
                                      name: null,
                                      needstitle: true,
                                      id:`${Math.floor(Math.random()*(9999-1000+1)+1000)}`
                                    },
                                    parent: parentKey,
                                    addAsFirstChild: !this.state.addAsFirstChild,
                                  }).treeData
                                }))
                              }
                              }
                            >
                              <Icon onClick={() => {
                                this.setState(state => ({
                                  treeData: addNodeUnderParent({
                                    treeData: this.state.treeData,
                                    parentKey: path[path.length - 1],
                                    getNodeKey: ({ treeIndex }) => treeIndex,
                                    expandParent: true,
                                    newNode: {
                                      name: null,
                                      needstitle: true,
                                      id:`${Math.floor(Math.random()*(9999-1000+1)+1000)}`
                                    },
                                    parent: parentKey,
                                    addAsFirstChild: !this.state.addAsFirstChild,
                                  }).treeData,
                                }))
                              }

                              }>add_circle</Icon>
                            </Button >,
                            node.childCategoryPresent===true&&!node.childCategories&&
                            <Button 
                            onClick={() => {
                                client
                                .mutate({
                                  mutation:GET_CATEGORY,
                                  fetchPolicy: "no-cache",
                                  errorPolicy: "all",
                                  variables: {
                                    id: node.id
                                  },
                                  context: {
                                    headers: {
                                      Authorization: 'Bearer ' + token
                                    }
                                  },
                                })
                                .then((res) => {
                                    if(res.data&&res.data.mastercategory&&res.data.mastercategory.length!==0)
                                    {
                                        
                                        res.data&&res.data.mastercategory&&res.data.mastercategory.map((c)=>
                                      
                                         this.setState(state => ({
                                            treeData: addNodeUnderParent({
                                              treeData: this.state.treeData,
                                              parentKey: path[path.length - 1],
                                              getNodeKey: ({ treeIndex }) => treeIndex,
                                              expandParent: true,
                                              newNode: {
                                                name: c.name,
                                                id:`${c.id}`,
                                                childCategoryPresent:c.childCategoryPresent,
                                                displayrank:c.displayrank,
                                                status:c.status
                                              },
                                              parent: parentKey,
                                              addAsFirstChild: !this.state.addAsFirstChild,
                                            }).treeData,
                                          }))
                                        )  
                                    }
                                })
                              }
                              }>
                                <ArrowDropDownIcon/>
                            </Button>
                            ,
                            !node.name &&
                            <Button
                              data-toggle="tooltip" data-placement="top" title="Click to add a child category here."
                              onClick={() => {
                                this.setState({
                                  treeData: removeNodeAtPath({
                                    treeData: this.state.treeData,
                                    path: path,   // You can use path from here
                                    getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
                                      // console.log(number);
                                      return number;
                                    },

                                  })
                                })
                              }
                              }
                            >
                              <DeleteForeverIcon />
                            </Button >
                          ],
                          onClick: () => {
                            let parentNode = getNodeAtPath({
                              treeData: this.state.treeData,
                              path: path,
                              getNodeKey: ({ treeIndex }) => treeIndex,
                              ignoreCollapsed: true
                            });
                            let getNodeKey = ({ node: object, treeIndex: number }) => {
                              return number;
                            };
                            let parentKey = getNodeKey(parentNode);
                            this.handleNodeClick(node);
                            this.setState((state) => ({
                              businessTypeId:node.businessTypeId?node.businessTypeId:[],
                              displayRank: node.displayrank ? node.displayrank : 0,
                              status: node.status ? node.status : "active",
                              parentKey: parentNode,
                              depParentid: parentKey,
                              nodename: node.name ? node.name : ""
                            }))


                          },
                          style:
                            node === this.state.nodeClicked
                              ? {
                                border: "3px solid #75c224",
                                borderBottomRightRadius: 16,
                                borderTopRightRadius: 16
                              }
                              : node.status === "inactive"
                                ? {
                                  border: "3px solid  rgb(248, 134, 134)",
                                  borderBottomRightRadius: 16,
                                  borderTopRightRadius: 16
                                } :
                                {},
                        })}
                      />

                    </Col>
                    <br />

                    {this.state.nodeClicked ? (
                      
                      <Col md={5} sm={5} style={{ border: '0.15px solid #ece9e9' }} >
                        {console.log("this.state.nodeClicked",this.state.nodeClicked)}
                        <Form style={{ marginTop: 10 }}>
                          {this.state.updateSuccess || this.state.success ? <Snackbar
                            style={{ width: "fit-content", }}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center"
                            }}
                            open={this.state.updateSuccess || this.state.success}
                            autoHideDuration={2000}
                            onClose={() => {
                              this.setState({ updateSuccess: false });
                              this.setState({ success: false })
                            }}>
                            <Alert severity="success">
                              <AlertTitle>{this.state.updateSuccess ?
                                "changes have been successfully saved" : this.state.success === true ? "Hierarchy added successfully" :
                                  null}</AlertTitle>
                            </Alert>
                          </Snackbar> : null}
                          {this.state.error || this.state.netError ?
                            <Snackbar
                              style={{ width: "fit-content", }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "center"
                              }}
                              open={this.state.error || this.state.netError}
                              autoHideDuration={2000}
                              onClose={() => {
                                this.setState({ error: false });
                                this.setState({ netError: false })
                              }}>
                              <Alert severity="error">
                                <AlertTitle>{this.state.errMsg}</AlertTitle>
                              </Alert>
                            </Snackbar>
                            : null}

                          <ThemeProvider theme={theme}>
                            <Row className="col-Row">
                              <TextField id="name"
                                style={{ width: '100%' }}
                                InputProps={{ style: { width: '100%', height: 35 } }}
                                label="Name"
                                variant="outlined"
                                required
                                value={this.state.nodename}
                                // defaultValue={this.state.nodename}
                                placeholder={'Name of  hierarchy '}
                                onChange={(e) => {
                                  this.setState({ nodename: e.target.value })
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }} />
                            </Row>
                            <Row className="col-Row">
                              <TextField id="name"
                              type="number"
                                value={this.state.displayRank}
                                required
                                // defaultValue={this.state.displayrank}
                                placeholder={'Display Order'}
                                onChange={(e) => {
                                  this.setState({ displayRank: e.target.value })
                                }}
                                style={{ width: '100%' }}
                                InputProps={{ style: { width: '100%', height: 35 } }}
                                label="Display order"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Row>
                            <Row className="col-Row">

                              <TextField
                                onChange={(e) => {
                                  this.setState({ status: e.target.value })
                                }}
                                value={this.state.status}
                                defaultValue={this.state.nodeClicked.status}
                                select
                                style={{ width: '100%' }}
                                variant="outlined"
                                InputProps={{ style: { width: '100%', height: 35 } }}
                                SelectProps={{ style: { width: '100%', height: 35 } }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                name="status"
                                label="Status">
                                <MenuItem value="active" style={{ width: '100%' }}>active</MenuItem>
                                <MenuItem value="inactive" style={{ width: '100%' }}>inactive</MenuItem>
                              </TextField>
                            </Row>
                            {typeof  this.state.nodeClicked.parentid!== 'undefined'?   
                            <Row className="col-Row">
                            <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
                            <Select
                            label="Business Type"
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          InputProps={{ style: { width: '100%', height: 35 } }}
          value={this.state.businessTypeId}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} 
                  label={this.props.BusinessType.data.businessType?.find(e => e.id === value).name} 
                // label={value}
                 />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
    {this.props.BusinessType&&this.props.BusinessType.data&&this.props.BusinessType.data.businessType.map((b)=>
     <MenuItem key={b.id} value={b.id}>
     <Checkbox checked={this.state.businessTypeId.includes(b.id)} />
     <ListItemText primary={b.name} />
   </MenuItem>
    //  <MenuItem value={b.id} style={{ width: '100%' }}>{b.name}</MenuItem>
    )}
 
</Select>
</FormControl>
</Row>
:
typeof  this.state.nodeClicked.id=== 'undefined'&&this.state.nodeClicked.name===null?
<Row className="col-Row">
<FormControl fullWidth>
<InputLabel id="demo-simple-select-label">Business Type</InputLabel>
<Select
label="Business Type"
labelId="demo-multiple-chip-label"
id="demo-multiple-chip"
multiple
InputProps={{ style: { width: '100%', height: 35 } }}
value={this.state.businessTypeId}
onChange={handleChange}
input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
renderValue={(selected) => (
<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
{selected.map((value) => (
<Chip key={value} 
label={this.props.BusinessType.data.businessType?.find(e => e.id === value).name} 
// label={value}
/>
))}
</Box>
)}
MenuProps={MenuProps}
>
{this.props.BusinessType&&this.props.BusinessType.data&&this.props.BusinessType.data.businessType.map((b)=>
<MenuItem key={b.id} value={b.id}>
<Checkbox checked={this.state.businessTypeId.includes(b.id)} />
<ListItemText primary={b.name} />
</MenuItem>
//  <MenuItem value={b.id} style={{ width: '100%' }}>{b.name}</MenuItem>
)}

</Select>
</FormControl>
</Row>
:
null}
                                  
                            <Row className="col-Row">
                              {this.state.nodeClicked.name !== null ? (
                                <Col>
                                  {this.state.treeData.map(n =>
                                    n.childCategories && n.childCategories.length !== 0 ?
                                      (
                                        this.state.nodeClicked.id === n.id ?
                                          <button
                                            key={this.state.nodeClicked.id}
                                            style={{
                                              height: "37px",
                                              width: "100%",
                                              backgroundColor: 'rgb(137, 199, 74, 1)',
                                              boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                              borderWidth: '1px',
                                              borderStyle: ' solid',
                                              borderColor: 'transparent',
                                              borderImage: 'initial',
                                              overflow: 'hidden',
                                              outline: 'none',
                                              color: "white",
                                              fontSize: "16px",
                                              fontWeight: 600,
                                              border: "none",
                                              borderRadius: 5
                                            }}
                                            type="button"
                                            onClick={(e) => {
                                              Update(n.id, null, true)
                                            }}
                                          >
                                            {this.props.updateDep === false ? (' Update ') : (' Updating')}
                                          </button>
                                          :
                                          n.childCategories.map(c =>
                                            c.childCategories && c.childCategories.length !== 0 ?
                                              (
                                                this.state.nodeClicked.id === c.id ?
                                                  <button
                                                    key={this.state.nodeClicked.id}
                                                    style={{
                                                      height: "37px",
                                                      width: "100%",
                                                      backgroundColor: 'rgb(137, 199, 74, 1)',
                                                      boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                                      borderWidth: '1px',
                                                      borderStyle: ' solid',
                                                      borderColor: 'transparent',
                                                      borderImage: 'initial',
                                                      overflow: 'hidden',
                                                      outline: 'none',
                                                      color: "white",
                                                      fontSize: "16px",
                                                      fontWeight: 600,
                                                      border: "none",
                                                      borderRadius: 5
                                                    }}
                                                    type="button"
                                                    onClick={(e) => {
                                                      Update(c.id, null, c.childCategoryPresent)

                                                    }}
                                                  >
                                                    {this.props.updateDep === false ? (' Update ') : (' Updating')}
                                                  </button>
                                                  :

                                                  c.childCategories.map(s =>
                                                    s.childCategories && s.childCategories.length !== 0 ?
                                                      (
                                                        this.state.nodeClicked.id === s.id ?
                                                          <button
                                                            key={this.state.nodeClicked.id}
                                                            style={{
                                                              height: "37px",
                                                              width: "100%",
                                                              backgroundColor: 'rgb(137, 199, 74, 1)',
                                                              boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                                              borderWidth: '1px',
                                                              borderStyle: ' solid',
                                                              borderColor: 'transparent',
                                                              borderImage: 'initial',
                                                              overflow: 'hidden',
                                                              outline: 'none',
                                                              color: "white",
                                                              fontSize: "16px",
                                                              fontWeight: 600,
                                                              border: "none",
                                                              borderRadius: 5
                                                            }}
                                                            type="button"
                                                            onClick={(e) => {
                                                              Update(s.id, null, s.childCategoryPresent)
                                                            }}
                                                          >
                                                            {this.props.updateDep === false ? (' Update ') : (' Updating')}
                                                          </button>
                                                          :
                                                          s.childCategories.map(sc =>

                                                            this.state.nodeClicked.id === sc.id &&
                                                            <button
                                                              key={this.state.nodeClicked.id}
                                                              style={{
                                                                height: "37px",
                                                                width: "100%",
                                                                backgroundColor: 'rgb(137, 199, 74, 1)',
                                                                boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                                                borderWidth: '1px',
                                                                borderStyle: ' solid',
                                                                borderColor: 'transparent',
                                                                borderImage: 'initial',
                                                                overflow: 'hidden',
                                                                outline: 'none',
                                                                color: "white",
                                                                fontSize: "16px",
                                                                fontWeight: 600,
                                                                border: "none",
                                                                borderRadius: 5
                                                              }}
                                                              type="button"
                                                              onClick={(e) => {
                                                                Update(sc.id, null, sc.childCategoryPresent)
                                                              }}
                                                            >
                                                              {this.props.updateDep === false ? (' Update ') : (' Updating')}
                                                            </button>
                                                          )
                                                      )
                                                      :
                                                      (
                                                        this.state.nodeClicked.id === s.id &&
                                                        <button
                                                          key={this.state.nodeClicked.id}
                                                          style={{
                                                            height: "37px",
                                                            width: "100%",
                                                            backgroundColor: 'rgb(137, 199, 74, 1)',
                                                            boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                                            borderWidth: '1px',
                                                            borderStyle: ' solid',
                                                            borderColor: 'transparent',
                                                            borderImage: 'initial',
                                                            overflow: 'hidden',
                                                            outline: 'none',
                                                            color: "white",
                                                            fontSize: "16px",
                                                            fontWeight: 600,
                                                            border: "none",
                                                            borderRadius: 5
                                                          }}
                                                          type="button"
                                                          onClick={(e) => {
                                                            Update(s.id, null, s.childCategoryPresent)
                                                          }}
                                                        >
                                                          {this.props.updateDep === false ? (' Update ') : (' Updating')}
                                                        </button>
                                                      )
                                                  )

                                              )
                                              :
                                              (
                                                this.state.nodeClicked.id === c.id &&
                                                <button
                                                  key={this.state.nodeClicked.id}
                                                  style={{
                                                    height: "37px",
                                                    width: "100%",
                                                    backgroundColor: 'rgb(137, 199, 74, 1)',
                                                    boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                                    borderWidth: '1px',
                                                    borderStyle: ' solid',
                                                    borderColor: 'transparent',
                                                    borderImage: 'initial',
                                                    overflow: 'hidden',
                                                    outline: 'none',
                                                    color: "white",
                                                    fontSize: "16px",
                                                    fontWeight: 600,
                                                    border: "none",
                                                    borderRadius: 5
                                                  }}
                                                  type="button"
                                                  onClick={(e) => {
                                                    Update(c.id, null, c.childCategoryPresent)
                                                  }}
                                                >
                                                  {this.props.updateDep === false ? (' Update ') : (' Updating')}
                                                </button>
                                              )
                                          ))
                                      :
                                      (
                                        this.state.nodeClicked.id === n.id &&
                                        <button
                                          key={this.state.nodeClicked.id}
                                          style={{
                                            height: "37px",
                                            width: "100%",
                                            backgroundColor: 'rgb(137, 199, 74, 1)',
                                            boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                            borderWidth: '1px',
                                            borderStyle: ' solid',
                                            borderColor: 'transparent',
                                            borderImage: 'initial',
                                            overflow: 'hidden',
                                            outline: 'none',
                                            color: "white",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                            border: "none",
                                            borderRadius: 5
                                          }}
                                          type="button"
                                          onClick={(e) => {
                                            Update(n.id, null, this.state.nodeClicked.childCategoryPresent)
                                          }}
                                        >
                                          {this.props.updateDep === false ? (' Update ') : (' Updating')}
                                        </button>
                                      )
                                  )}
                                </Col>
                              ) : (

                                this.state.newdep === true
                                  ?
                                  (
                                    <Col >
                                      <button
                                        key={this.state.nodeClicked.id}
                                        style={{
                                          height: "37px",
                                          width: "100%",
                                          backgroundColor: 'rgb(137, 199, 74, 1)',
                                          boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                          borderWidth: '1px',
                                          borderStyle: ' solid',
                                          borderColor: 'transparent',
                                          borderImage: 'initial',
                                          overflow: 'hidden',
                                          outline: 'none',
                                          color: "white",
                                          fontSize: "16px",
                                          fontWeight: 600,
                                          border: "none",
                                          borderRadius: 5
                                        }}
                                        type="button"
                                        onClick={(e) => {
                                          Add()

                                        }}
                                      >
                                        {this.props.addDep === false ? ('Add') : this.props.HeirarchyError ? ('Adding') : ('Add')}
                                      </button>
                                    </Col>
                                  )
                                  :
                                  (
                                    <Col >
                                    {console.log("this.state.nodeClicked",this.state.nodeClicked,this.state.treeData)}

                                      <button
                                        key={this.state.nodeClicked.id}
                                        style={{
                                          height: "37px",
                                          width: "100%",
                                          backgroundColor: 'rgb(137, 199, 74, 1)',
                                          boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                          borderWidth: '1px',
                                          borderStyle: ' solid',
                                          borderColor: 'transparent',
                                          borderImage: 'initial',
                                          overflow: 'hidden',
                                          outline: 'none',
                                          color: "white",
                                          fontSize: "16px",
                                          fontWeight: 600,
                                          border: "none",
                                          borderRadius: 5
                                        }}
                                        type="button"
                                        onClick={() => {
                                          AddHeirarchy(this.state.nodeClicked.id)


                                        }}>
                                        {this.props.addDep === false ? ('Add') : this.props.HeirarchyError ? ('Adding') : ('Add')}
                                      </button>


                                    </Col>
                                  )

                              )}

                            </Row>
                            <Row className="col-Row">
                              <h5 style={{ fontWeight: 600 }}>
                                Note :
                              </h5>
                              <p>1. Category with low display order will be shown on the top.</p>
                              <p>2. Categories with active status  will be displayed in the customer site.</p>
                            </Row>
                          </ThemeProvider>
                        </Form>
                      </Col>)
                      :
                      (null)}
                  </Row>
                )
                : (
                  this.props.DeparmentList && this.props.DeparmentList.length === 0 ?
                    (
                      <>
                        <Row>
                          {/* <h6>
                  
    </h6>
              </Row>
              <Row>
                <h6>
                 
  </h6>
              </Row>
              <Row>
                <h5>
                  
 </h5> */}
                          <Card>
                            <CardContent>
                              <Typography sx={{ fontSize: 14 }} color="black" gutterBottom>
                                You can organize your products by creating various levels.
                              </Typography>
                              <Typography sx={{ fontSize: 16 }} color="black" gutterBottom component="div">
                                You need atleast one hierarchy to create your products.
                              </Typography>
                              <Typography sx={{ fontSize: 16 }} color="black" gutterBottom component="div">
                                Click on the button above to create your first department .
                              </Typography>
                            </CardContent>
                          </Card>
                        </Row>
                        <Row>
                          <Col md={7} sm={7} style={{ height: 800, width: '100%' }}>
                            <SortableTree
                              treeData={this.state.treeData}
                              onChange={treeData => this.setState({ treeData })}
                              onMoveNode={({ node, treeIndex, path }) =>
                                global.console.debug(
                                  "node:",
                                  node,
                                  "treeIndex:",
                                  treeIndex,
                                  "path:",
                                  path
                                )
                              }

                              maxDepth={this.state.Depth}
                              canDrag={treeData => false}
                              canDrop={treeData => false}
                              isVirtualized={true}
                              generateNodeProps={({ node, path, rowInfo, treeIndex }) => ({
                                className: `${node.className}`,
                                title:
                                  this.state.isInput && node === this.state.nodeClicked ? (
                                    node.name

                                  ) : (
                                    node.name
                                  ),

                                buttons: [
                                  path.length <= 1 && node.name &&
                                  <Button
                                    data-toggle="tooltip" data-placement="top" title="Click to add a child category here."
                                    onClick={() => {
                                      this.setState(state => ({
                                        newdep: false,
                                        treeData: addNodeUnderParent({
                                          treeData: this.state.treeData,
                                          parentKey: path[path.length - 1],
                                          getNodeKey: ({ treeIndex }) => treeIndex,
                                          expandParent: true,
                                          newNode: {
                                            name: null,
                                            needstitle: true,
                                            id:`${Math.floor(Math.random()*(9999-1000+1)+1000)}`
                                          },
                                          parent: parentKey,
                                          addAsFirstChild: !this.state.addAsFirstChild,
                                        }).treeData,
                                      }))
                                    }
                                    }
                                  >
                                    <Icon
                                      onClick={() => {
                                        this.setState(state => ({
                                          treeData: addNodeUnderParent({
                                            treeData: this.state.treeData,
                                            parentKey: path[path.length - 1],
                                            getNodeKey: ({ treeIndex }) => treeIndex,
                                            expandParent: true,
                                            newNode: {
                                              name: null,
                                              needstitle: true,

                                            },
                                            parent: parentKey,
                                            addAsFirstChild: !this.state.addAsFirstChild,
                                          }).treeData,
                                        }))
                                      }
                                      }>add_circle</Icon>
                                  </Button >,
                                  !node.name &&
                                  <Button
                                    data-toggle="tooltip" data-placement="top" title="Click to add a child category here."
                                    onClick={() => {
                                      this.setState({
                                        treeData: removeNodeAtPath({
                                          treeData: this.state.treeData,
                                          path: path,   // You can use path from here
                                          getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
                                            // console.log(number);
                                            return number;
                                          },

                                        })
                                      })
                                    }
                                    }
                                  >
                                    <DeleteForeverIcon />
                                  </Button >
                                ],
                                onClick: () => {
                                  let parentNode = getNodeAtPath({
                                    treeData: this.state.treeData,
                                    path: path,
                                    getNodeKey: ({ treeIndex }) => treeIndex,
                                    ignoreCollapsed: true
                                  });
                                  let getNodeKey = ({ node: object, treeIndex: number }) => {
                                    return number;
                                  };
                                  let parentKey = getNodeKey(parentNode);
                                  this.handleNodeClick(node);
                                  this.setState((state) => ({
                                    displayRank: node.displayrank ? node.displayrank : 0,
                                    status: node.status ? node.status : "active",
                                    parentKey: parentNode,
                                    depParentid: parentKey,
                                    nodename: node.name ? node.name : ""
                                  }))


                                },
                                style:
                                  node === this.state.nodeClicked
                                    ? {
                                      border: "3px solid #75c224",
                                      borderBottomRightRadius: 16,
                                      borderTopRightRadius: 16
                                    }
                                    : node.status === "inactive"
                                      ? {
                                        border: "3px solid  rgb(248, 134, 134)",
                                        borderBottomRightRadius: 16,
                                        borderTopRightRadius: 16
                                      } :
                                      {},
                              })}
                            />

                          </Col>
                          <br />

                          {this.state.nodeClicked ? (
                            <Col md={5} sm={5} style={{ border: '0.15px solid #ece9e9' }} >
                              {console.log("this.state.nodeClicked",this.state.nodeClicked)}
                              <Form style={{ marginTop: 10 }}>
                                <ThemeProvider theme={theme}>
                                  <Row className="col-Row">
                                    <TextField id="name"
                                      style={{ width: '100%' }}
                                      InputProps={{ style: { width: '100%', height: 35 } }}
                                      label="Name"
                                      variant="outlined"
                                      value={this.state.nodename}
                                      // defaultValue={this.state.nodename}
                                      placeholder={'Name of the hierarchy '}
                                      onChange={(e) => {
                                        this.setState({ nodename: e.target.value })
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }} />
                                  </Row>
                                  <Row className="col-Row">
                                    <TextField id="displayRank"
                                      value={this.state.displayRank}
                                      defaultValue={this.state.nodeClicked.displayRank}
                                      placeholder={'Display Order'}
                                      onChange={(e) => {
                                        this.setState({ displayRank: e.target.value })
                                      }}
                                      style={{ width: '100%' }}
                                      InputProps={{ style: { width: '100%', height: 35 } }}
                                      label="Display order"
                                      variant="outlined"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </Row>
                                  <Row className="col-Row">
                                    <TextField
                                      defaultValue={this.state.nodeClicked.status}
                                      select
                                      style={{ width: '100%' }}
                                      variant="outlined"
                                      InputProps={{ style: { width: '100%', height: 35 } }}
                                      SelectProps={{ style: { width: '100%', height: 35 } }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      value={this.state.status}
                                      name="status"
                                      label="Status">
                                      <MenuItem onChange={(e) => {
                                        this.setState({ status: e.target.value })
                                        console.log(this.state.status)
                                      }} value="active" style={{ width: '100%' }}>active</MenuItem>
                                      <MenuItem
                                        onChange={(e) => {
                                          this.setState({ status: e.target.value })
                                          console.log(this.state.status)
                                        }}
                                        value="inactive" style={{ width: '100%' }}>inactive</MenuItem>
                                    </TextField>
                                  </Row>
                            {typeof  this.state.nodeClicked.parentid!== 'undefined'?   
                            <Row className="col-Row">
                            <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
                            <Select
                            label="Business Type"
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          InputProps={{ style: { width: '100%', height: 35 } }}
          value={this.state.businessTypeId}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} 
                  label={this.props.BusinessType.data.businessType?.find(e => e.id === value).name} 
                // label={value}
                 />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
    {this.props.BusinessType&&this.props.BusinessType.data&&this.props.BusinessType.data.businessType.map((b)=>
     <MenuItem key={b.id} value={b.id}>
     <Checkbox checked={this.state.businessTypeId.includes(b.id)} />
     <ListItemText primary={b.name} />
   </MenuItem>
    //  <MenuItem value={b.id} style={{ width: '100%' }}>{b.name}</MenuItem>
    )}
 
</Select>
</FormControl>
</Row>
:
typeof  this.state.nodeClicked.id=== 'undefined'&&this.state.nodeClicked.name===null?
<Row className="col-Row">
<FormControl fullWidth>
<InputLabel id="demo-simple-select-label">Business Type</InputLabel>
<Select
label="Business Type"
labelId="demo-multiple-chip-label"
id="demo-multiple-chip"
multiple
InputProps={{ style: { width: '100%', height: 35 } }}
value={this.state.businessTypeId}
onChange={handleChange}
input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
renderValue={(selected) => (
<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
{selected.map((value) => (
<Chip key={value} 
label={this.props.BusinessType.data.businessType?.find(e => e.id === value).name} 
// label={value}
/>
))}
</Box>
)}
MenuProps={MenuProps}
>
{this.props.BusinessType&&this.props.BusinessType.data&&this.props.BusinessType.data.businessType.map((b)=>
<MenuItem key={b.id} value={b.id}>
<Checkbox checked={this.state.businessTypeId.includes(b.id)} />
<ListItemText primary={b.name} />
</MenuItem>
//  <MenuItem value={b.id} style={{ width: '100%' }}>{b.name}</MenuItem>
)}

</Select>
</FormControl>
</Row>
:
null}
                                  <Row className="col-Row">
                                    {this.state.updateSuccess || this.state.success ? <Snackbar
                                      style={{ width: "fit-content", left: "80%", bottom: "65%" }}
                                      open={this.state.updateSuccess || this.state.success}
                                      autoHideDuration={2000}
                                      onClose={() => {
                                        this.setState({ updateSuccess: false });
                                        this.setState({ success: false })
                                      }}>
                                      <Alert severity="success">
                                        <AlertTitle>{this.state.updateSuccess ?
                                          "changes have been successfully saved" : this.state.success === true ? "Hierarchy added successfully" :
                                            null}</AlertTitle>
                                      </Alert>
                                    </Snackbar> : null}
                                    {this.state.error || this.state.netError ?
                                      <Snackbar
                                        style={{ width: "fit-content", left: "80%", bottom: "80%" }}
                                        open={this.state.error || this.state.netError}
                                        autoHideDuration={2000}
                                        onClose={() => {
                                          this.setState({ error: false });
                                          this.setState({ netError: false })
                                        }}>
                                        <Alert severity="error">
                                          <AlertTitle>{this.state.errMsg}</AlertTitle>
                                        </Alert>
                                      </Snackbar>
                                      : null}
                                    {this.state.nodeClicked.name !== null ? (
                                      <Col>
                                        {this.state.treeData.map(n =>
                                          n.childCategories ?
                                            (
                                              this.state.nodeClicked.id === n.id ?
                                                <button
                                                  key={this.state.nodeClicked.id}
                                                  style={{
                                                    height: "37px",
                                                    width: "100%",
                                                    backgroundColor: 'rgb(137, 199, 74, 1)',
                                                    boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                                    borderWidth: '1px',
                                                    borderStyle: ' solid',
                                                    borderColor: 'transparent',
                                                    borderImage: 'initial',
                                                    overflow: 'hidden',
                                                    outline: 'none',
                                                    color: "white",
                                                    fontSize: "16px",
                                                    fontWeight: 600,
                                                    border: "none",
                                                    borderRadius: 5
                                                  }}
                                                  type="button"
                                                  onClick={(e) => {
                                                    Update(n.id, null, true)
                                                  }}
                                                >
                                                  {this.props.updateDep === false ? (' Update ') : (' Updating')}
                                                </button>
                                                :
                                                n.childCategories.map(c =>
                                                  c.childCategories ?
                                                    (
                                                      this.state.nodeClicked.id === c.id ?
                                                        <button
                                                          key={this.state.nodeClicked.id}
                                                          style={{
                                                            height: "37px",
                                                            width: "100%",
                                                            backgroundColor: 'rgb(137, 199, 74, 1)',
                                                            boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                                            borderWidth: '1px',
                                                            borderStyle: ' solid',
                                                            borderColor: 'transparent',
                                                            borderImage: 'initial',
                                                            overflow: 'hidden',
                                                            outline: 'none',
                                                            color: "white",
                                                            fontSize: "16px",
                                                            fontWeight: 600,
                                                            border: "none",
                                                            borderRadius: 5
                                                          }}
                                                          type="button"
                                                          onClick={(e) => {
                                                            Update(c.id, null, c.childCategoryPresent)
                                                          }}
                                                        >
                                                          {this.props.updateDep === false ? (' Update ') : (' Updating')}
                                                        </button>
                                                        :

                                                        c.childCategories.map(s =>
                                                          s.childCategories ?
                                                            (
                                                              this.state.nodeClicked.id === s.id ?
                                                                <button
                                                                  key={this.state.nodeClicked.id}
                                                                  style={{
                                                                    height: "37px",
                                                                    width: "100%",
                                                                    backgroundColor: 'rgb(137, 199, 74, 1)',
                                                                    boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                                                    borderWidth: '1px',
                                                                    borderStyle: ' solid',
                                                                    borderColor: 'transparent',
                                                                    borderImage: 'initial',
                                                                    overflow: 'hidden',
                                                                    outline: 'none',
                                                                    color: "white",
                                                                    fontSize: "16px",
                                                                    fontWeight: 600,
                                                                    border: "none",
                                                                    borderRadius: 5
                                                                  }}
                                                                  type="button"
                                                                  onClick={(e) => {
                                                                    Update(s.id, null, s.childCategoryPresent)
                                                                  }}
                                                                >
                                                                  {this.props.updateDep === false ? (' Update ') : (' Updating')}
                                                                </button>
                                                                :
                                                                s.childCategories.map(sc =>

                                                                  this.state.nodeClicked.id === sc.id &&
                                                                  <button
                                                                    key={this.state.nodeClicked.id}
                                                                    style={{
                                                                      height: "37px",
                                                                      width: "100%",
                                                                      backgroundColor: 'rgb(137, 199, 74, 1)',
                                                                      boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                                                      borderWidth: '1px',
                                                                      borderStyle: ' solid',
                                                                      borderColor: 'transparent',
                                                                      borderImage: 'initial',
                                                                      overflow: 'hidden',
                                                                      outline: 'none',
                                                                      color: "white",
                                                                      fontSize: "16px",
                                                                      fontWeight: 600,
                                                                      border: "none",
                                                                      borderRadius: 5
                                                                    }}
                                                                    type="button"
                                                                    onClick={(e) => {
                                                                      Update(sc.id, null, sc.childCategoryPresent)
                                                                    }}
                                                                  >
                                                                    {this.props.updateDep === false ? (' Update ') : (' Updating')}
                                                                  </button>
                                                                )
                                                            )
                                                            :
                                                            (
                                                              this.state.nodeClicked.id === s.id &&
                                                              <button
                                                                key={this.state.nodeClicked.id}
                                                                style={{
                                                                  height: "37px",
                                                                  width: "100%",
                                                                  backgroundColor: 'rgb(137, 199, 74, 1)',
                                                                  boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                                                  borderWidth: '1px',
                                                                  borderStyle: ' solid',
                                                                  borderColor: 'transparent',
                                                                  borderImage: 'initial',
                                                                  overflow: 'hidden',
                                                                  outline: 'none',
                                                                  color: "white",
                                                                  fontSize: "16px",
                                                                  fontWeight: 600,
                                                                  border: "none",
                                                                  borderRadius: 5
                                                                }}
                                                                type="button"
                                                                onClick={(e) => {
                                                                  Update(s.id, null, s.childCategoryPresent)
                                                                }}
                                                              >
                                                                {this.props.updateDep === false ? (' Update ') : (' Updating')}
                                                              </button>
                                                            )
                                                        )

                                                    )
                                                    :
                                                    (
                                                      this.state.nodeClicked.id === c.id &&
                                                      <button
                                                        key={this.state.nodeClicked.id}
                                                        style={{
                                                          height: "37px",
                                                          width: "100%",
                                                          backgroundColor: 'rgb(137, 199, 74, 1)',
                                                          boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                                          borderWidth: '1px',
                                                          borderStyle: ' solid',
                                                          borderColor: 'transparent',
                                                          borderImage: 'initial',
                                                          overflow: 'hidden',
                                                          outline: 'none',
                                                          color: "white",
                                                          fontSize: "16px",
                                                          fontWeight: 600,
                                                          border: "none",
                                                          borderRadius: 5
                                                        }}
                                                        type="button"
                                                        onClick={(e) => {
                                                          Update(c.id, null, c.childCategoryPresent)
                                                        }}
                                                      >
                                                        {this.props.updateDep === false ? (' Update ') : (' Updating')}
                                                      </button>
                                                    )
                                                ))
                                            :
                                            (
                                              this.state.nodeClicked.id === n.id &&
                                              <button
                                                key={this.state.nodeClicked.id}
                                                style={{
                                                  height: "37px",
                                                  width: "100%",
                                                  backgroundColor: 'rgb(137, 199, 74, 1)',
                                                  boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                                  borderWidth: '1px',
                                                  borderStyle: ' solid',
                                                  borderColor: 'transparent',
                                                  borderImage: 'initial',
                                                  overflow: 'hidden',
                                                  outline: 'none',
                                                  color: "white",
                                                  fontSize: "16px",
                                                  fontWeight: 600,
                                                  border: "none",
                                                  borderRadius: 5
                                                }}
                                                type="button"
                                                onClick={(e) => {
                                                  Update(n.id, null, this.state.nodeClicked.childCategoryPresent)
                                                }}
                                              >
                                                {this.props.updateDep === false ? (' Update ') : (' Updating')}
                                              </button>
                                            )
                                        )}
                                      </Col>
                                    ) : (

                                      this.state.newdep === true
                                        ?
                                        (
                                          <Col >
                                            <button
                                              key={this.state.nodeClicked.id}
                                              style={{
                                                height: "37px",
                                                width: "100%",
                                                backgroundColor: 'rgb(137, 199, 74, 1)',
                                                boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                                borderWidth: '1px',
                                                borderStyle: ' solid',
                                                borderColor: 'transparent',
                                                borderImage: 'initial',
                                                overflow: 'hidden',
                                                outline: 'none',
                                                color: "white",
                                                fontSize: "16px",
                                                fontWeight: 600,
                                                border: "none",
                                                borderRadius: 5
                                              }}
                                              type="button"
                                              onClick={(e) => {
                                                Add1()

                                              }}
                                            >
                                              {this.props.addDep === false ? ('Add') : this.props.HeirarchyError ? ('Add') : ('Adding')}
                                            </button>
                                          </Col>
                                        )
                                        :
                                        (

                                          <Col >

                                            <button
                                              key={this.state.nodeClicked.id}
                                              style={{
                                                height: "37px",
                                                width: "100%",
                                                backgroundColor: 'rgb(137, 199, 74, 1)',
                                                boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
                                                borderWidth: '1px',
                                                borderStyle: ' solid',
                                                borderColor: 'transparent',
                                                borderImage: 'initial',
                                                overflow: 'hidden',
                                                outline: 'none',
                                                color: "white",
                                                fontSize: "16px",
                                                fontWeight: 600,
                                                border: "none",
                                                borderRadius: 5
                                              }}
                                              type="button"
                                              onClick={() => {
                                                AddHeirarchy(this.state.nodeClicked.id, this.state.nodeClicked.name)


                                              }}>
                                              {this.props.addDep === false ? ('Add') : this.props.HeirarchyError ? ('Adding') : ('Add')}
                                            </button>


                                          </Col>
                                        )

                                    )}

                                  </Row>
                                  <Row className="col-Row">
                                    <h5 style={{ fontWeight: 600 }}>
                                      Note :
                                    </h5>
                                    <p>1. Category with low display order will be shown on the top.</p>
                                    <p>2. Categories with active status  will be displayed in the customer site.</p>
                                  </Row>
                                </ThemeProvider>
                              </Form>
                            </Col>)
                            :
                            (null)}
                        </Row>
                      </>
                    ) :
                    (
                      <Row style={{ marginLeft: 70 }}>
                        <Spinner size={40} animation="border" variant="success" />
                      </Row>
                    )
                )

              }
            </div>


          </Container>


        }

      </>
    );
  }
}
export default Heirarchy
