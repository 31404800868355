import React, { useState , useEffect } from "react";
// import ReactDOM from 'react-dom';
import ImgCrop from "antd-img-crop";
import { Upload, Modal } from "antd";
import {Row,Col} from 'react-bootstrap';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {IMAGE_UPLOAD} from './Query';
import client from "../../Configurations/apollo";
// import "antd/dist/antd.css";
import "./index.css";
// import { BiJoystick } from "react-icons/bi";


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

// async function getFileFromUrl(url, name, defaultType = 'image/jpeg'){

//   const response = await fetch(url,
//     {mode: 'no-cors',
//   headers: {
//     'Access-Control-Allow-Origin':'*'
//   }});
//   const data = await response.blob();
//   return new File([data], name, {
//     type: response.headers.get('content-type') || defaultType,
//   })
// }
const MultipleUploader = (props) => {
 
  useEffect(() => {
    setFileList(props.product&&props.product.data&&
      props.product&&props.product.data&&
      props.product.data.salesproducts&&props.product.data.salesproducts.image&&
      props.product.data.salesproducts&&props.product.data.salesproducts.image.secondary!==null&&
      props.product.data.salesproducts.image.secondary.length!==0?
     ( props.product&&props.product.data&&
      props.product&&props.product.data&&
      props.product.data.salesproducts&&props.product.data.salesproducts.image&&
      props.product.data.salesproducts.image.secondary.map((S,index)=>
    (  {
        id:index,
        url: `${S}`,
      })
      
      ))
    :
    ([]))
  },[props.product]);

  const [fileList, setFileList] = useState([]);


  const [previewImage, setPreviewImage] = useState();
  const [previewVisible, setPreviewVisible] = useState();
  // const [previewTitle,setPreviewTitle]=useState()
  const [multi,setMulti]=useState()
  // const [cropSize, setCropSize] = useState({ width: 512, height: 512 });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    // setPreviewTitle(
    //   file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    // );
  };
  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const onChange = ({ fileList: newFileList }) => {
    console.log(newFileList,fileList)
    setFileList(newFileList);
    setMulti(true)
    props.MultipleImage();
    // props.setMultipleImage(newFileList)
    // console.log(newFileList,newFileList.length)
    if(newFileList.length>fileList.length)
    {
    client
      .mutate({
        mutation: IMAGE_UPLOAD,
        variables: {
          file: newFileList[(newFileList.length)-1].originFileObj,
          type: 2,
          size: 512,
          filename: `${props.ProductDetailsData&&props.ProductDetailsData.data&&
            props.ProductDetailsData.data.salesproducts&&
            props.ProductDetailsData.data.salesproducts.productname&&
            props.ProductDetailsData.data.salesproducts.productname}${Math.floor(Math.random() * (999 - 100 + 1) + 100)}.jpg`,
          storeid: `${localStorage.getItem('storeid')}`
        },
      })
      .then((res) => {
      
          // let newFileList = [...newFileList.slice(newFileList.length-1,1),{ url:res.data&&res.data.&&res.data.imageEditAndUpload.Location},...newFileList.slice(newFileList.length-1+1)]
          
          // newFileList={ url:res.data&&res.data.imageEditAndUpload&&res.data.imageEditAndUpload.Location}
          // fileList.splice(fileList.length-1,1)
          newFileList.pop()
          newFileList.push({ url:res.data&&res.data.imageEditAndUpload&&res.data.imageEditAndUpload.Location})
          setFileList(newFileList)
          props.setMultipleImage(newFileList)
        //   setFileList([
        //    fileList.slice(0, (fileList.length-1)),
        // setFileList({fileList:[...fileList,{ url:res.data&&res.data.imageEditAndUpload&&res.data.imageEditAndUpload.Location}]}),

        //     fileList.slice(fileList.length)
        //   ]);
        //   // array.splice((array.length-1),1,{ url:res.data&&res.data.imageEditAndUpload&&res.data.imageEditAndUpload.Location})
        // setFileList({url:res.data&&res.data.imageEditAndUpload&&res.data.imageEditAndUpload.Location})
        //  props.setMultipleImage(fileList)
        //  console.log(fileList)
     
       
        // fileList.pop()
        // setFileList([...fileList.splice(-1,1),{ url:res.data&&res.data.imageEditAndUpload&&res.data.imageEditAndUpload.Location}])
        // fileList.splice(fileList.length-1,1,{ url:res.data&&res.data.imageEditAndUpload&&res.data.imageEditAndUpload.Location})
        // setFileList(newFileList.splice(-1),res.data&&res.data.imageEditAndUpload&&res.data.imageEditAndUpload.Location)
        // setFileList(fileList);
        
        
      })




  //   props.updateImage({ files:newFileList[(newFileList.length)-1].originFileObj,fileName:props.ProductDetailsData&&props.ProductDetailsData.data&&
  //     props.ProductDetailsData.data.salesproducts&&
  //     props.ProductDetailsData.data.salesproducts.productname&&
  //     `${props.ProductDetailsData.data.salesproducts.productname}`});
  //   // props.secondaryImage({ files: newFileList });
    }
   };
   const handleRemove = ( newFileList) => {
    // console.log(fileList,newFileList)
    fileList.map((f,index)=>{
      if(f.url===newFileList.url)
      {
        fileList.splice(index,1)
      }
    })
    props.setMultipleImage(fileList)
  //   let jk = fileList.filter((file)=>{
  //     // if(file.url!==newFileList.url){
  //       return file.url!==newFileList.url
  //     // }
  //   })
  // // console.log("jk",jk)
  // setFileList(jk);
  // props.setMultipleImage(jk)
    // fileList = fileList.filter(url => newFileList.url== fileList.url);
    // props.setMultipleImage(fileList)
    // props.RemoveLogo();
    // setFileList(newFileList);
  };
  

  // const SubmitNow = () => {
  //   props.secondaryImageUpdate({ files: fileList });
  // };

  const dummyRequest = ({ file, onSuccess }) => {
    
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
     <Row>
    <Col>
    <ImgCrop
        modalTitle="product"
        modalWidth={700}
        modalHeight={700}
        // minZoom={0.1}
        aspect={1 / 1}
        rotate={false}
        zoom={false}
        // cropSize={cropSize}
        grid
      >
        <Upload
          maxCount={4}
          customRequest={dummyRequest}
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onRemove={handleRemove}
          onPreview={handlePreview}
          accept="image/*"
        >
          {fileList.length < 4 && "Click here to choose the image"}
        </Upload>
      </ImgCrop>
      </Col>
      </Row>
      <Row>
        {/* <Col>
      {props.multiFile===true&&fileList.length!==0? (
        <button
          style={{
            justifyContent: " center",
            color: "rgb(255, 255, 255)",
            backgroundImage:
              "-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
            boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
            width: "80px",
            height: "30px",
            fontSize: "16px",
            fontWeight: "600",
            borderRadius: "5px",
            borderWidth: "1px",
            borderStyle: " solid",
            borderColor: "transparent",
            borderImage: "initial",
            overflow: "hidden",
            outline: "none",
            minHeight: " 14px",
            fontFamily: "Varela Round, sans-serif",
          }}
          type="button"
           onClick={SubmitNow}
        >
          Upload
        </button>
      ) : null}
      </Col> */}
<Col>
      <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Remember
        </Typography>
        <Typography sx={{ fontSize: 12 }} component="div">
        You can upload upto 4 secondary images
        </Typography>
        </CardContent>
      </Card>
      <Modal
        visible={previewVisible}
        title={'Image'}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      </Col>
      </Row>
    </>
  );
};
export default MultipleUploader;
