import React from "react";
import { AppBar } from "@material-ui/core";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Orderdetails from "../OrderDetailsAssets/Orderdetails";
import Box from "@mui/material/Box";
import StoreDetails from "./StoreDetails";
import CustomerDetails from "./CustomerDetails";
import DeliveryDetails from "./DeliveryDetails";

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function OrderDetails(props) {
  console.log(props)
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  React.useEffect(() => {
    props.getOrderDetails(localStorage.getItem('orderID'));
  },
    /* eslint-disable*/
    []);

  return (
    <div
      style={{
        backgroundColor: "white",
        transform: "translateY(100px)",
        width: "100%",
        borderRadius: "30px",
        padding: '15px',
        boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)'
      }}
      position="sticky"
    >  
      <Box
        sx={{
          bgcolor: "background.paper",
          width: '100%',
          position: "relative",
          minHeight: 200,
          justifyContent: "center",
        }}
      >
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Order Details" {...a11yProps(0)} />
            <Tab label="Store Details" {...a11yProps(1)} />
            <Tab label="Delivery Details" {...a11yProps(2)} />
            <Tab label="Customer Details" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Orderdetails {...props} orderData={props?.OrderDetails &&
              props?.OrderDetails?.orderdetails} orderId={localStorage.getItem('orderID')} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <StoreDetails {...props} />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <DeliveryDetails {...props} />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <CustomerDetails {...props} />
          </TabPanel>
        </SwipeableViews>
        {/*  */}
      </Box>
    </div>
  );
}
