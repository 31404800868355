export const styles = (theme) => ({
	root: {
		width: "100%",
		"& > * + *": {
			marginTop: theme.spacing(2),
		},
	},
	appBar: {
		position: "relative",
	},
	layout:{
		width:'90%',
		position:'relative',
	},
	stepper: {
		top: "1em",
		height: "100px",
		width: "100%",
		// position: "fixed",
	},
	buttons: {
		display: "flex",
		justifyContent: "flex-end",
	},
	button: {
		color: 'rgb(255, 255, 255)',
    	backgroundImage:'-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)',
    	boxShadow:' 0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
    	height: '50px',
    	fontSize:'18px',
    	fontWeight: 600,
    	borderRadius: '5px',
    	borderWidth: '1px',
    	borderStyle: 'solid',
    	borderColor: 'transparent',
    	overflow: 'hidden',
    	outline: 'none',
    	fontFamily: 'Varela Round, sans-serif',
    	textTransform: 'none',
    	textAlign: 'center',
		width: '20%',
		marginLeft:'10px',
	},
});
