import React from "react";
import "./index.css";
import { Image } from "react-bootstrap";
// import Button from "@material-ui/core/Button";
import { SHOP } from "../../utils/image";

const TopHeaders = () => {
	return (
		<nav className="header">
			<div className="notification-bar"></div>
			<div className="logo">
				<Image src={SHOP} alt="logo.png" />
			</div>
		</nav>
	);
};
export default TopHeaders;