import React  from "react";
import {useHistory} from "react-router-dom"
import { ReactComponent as Vector } from "../../assets/PageNotFound.svg"
import "./index.css";
function PageNotFound(){
  const history=useHistory()
  const handleButtonClick=()=>{
    const currentPath = window.location.pathname;
    if (currentPath.startsWith("/home/")) {
      history.push("/home");
    } else {
      history.push("/");
    }
  }
  return (
    <div className="notfound">
      <div >
        <Vector className="icon"/>
      </div>
      <div >
        <div className="heading">Page Not Found</div>
      </div>
        <div ><button className="refresh-btn" onClick={handleButtonClick}>Refresh</button></div>
    </div>
  )
  
};
export default PageNotFound

