import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import SearchResults from "../w3";
import { Toolbar } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchbyName from './SearchbyName';
import { Formik } from "formik";
import * as Yup from "yup";
import "./index.css";


const schema = Yup.object().shape({
  mobileNumber: Yup.string()
  .matches(/^\S*$/,'Space is not allowed')
  //eslint-disable-next-line
  .matches(/^[0-9]*$/, "Please enter only numbers")
  .min(10, "Please enter 10 digit Mobile number")
  .max(10, "Please enter 10 digit Mobile number"),
    emailAddress: Yup.string()
    .matches(/^\S*$/,'Space is not allowed')
    //eslint-disable-next-line
    .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,'Email Address must be valid'),
})


class StoreSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      mobileNumber: "",
      emailAddress: "",
      sellerName: "",
      offset: 0,
      first: 20,
      Store: []
    };
  }

  componentDidMount(){
    this.props.clearsearchstoresData();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.searchData !== prevProps.searchData) {
      this.setState({
        Store: [...this.state.Store, this.props.searchData
          && this.props.searchData.searchstore
          && this.props.searchData.searchstore.items.map((p) => (p))]
      }
        , () => {
          this.props.setStore(this.state.Store)
        }
      )

    }
  }

  render() {

    const statusFilters = [
      { option: "Submitted", value: "submitted" },
      { option: "Active", value: "active" },
      { option: "Inactive", value: "inactive" },
      { option: "Rejected", value: "rejected" },
      { option: "Banned", value: "banned" },
    ];
    const loadingModal = (
      <div style={{ justifyContent: 'center' }}>
        <CircularProgress style={{ color: "grey" }} />
      </div>
    );
    const handleSearchField = (e) => {
      let { value } = e.target;
      this.setState({
        ...this.state,
        [e.target.name]: value,
      });
    };
    const searchPrevious = () => {
      this.setState({
        ...this.state,
        offset: this.state.offset - 20,
      },
        () => {
          this.props.getSearchResult({ ...this.state })
        })
    } 
    const searchOrder = () => {
      this.setState({
        ...this.state,
        offset: this.state.offset + 20,
      },
        () => {
          this.props.getSearchResult({ ...this.state })
        })
    }
    // const searchPreviousOrder = () => {
    //   this.setState({
    //     ...this.state,
    //     offset: this.state.offset - 20,
    //   },
    //     () => {
    //       this.props.getSearchResult({ ...this.state })
    //     })
    // }
    const searchOrderHandle = () => {
      this.setState({
        Store: [],
        offset: 0,
      }, () => {
        this.props.getSearchResult(this.state);
      });

    };
    return (
      <>
        <div
          style={{
            backgroundColor: "white",
            transform: "translateY(100px)",
            width: "90%",
            borderRadius: "30px",
            padding: '15px',
            boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
           
          }}
          position="sticky"
        >
          <Toolbar 
          style={{
            textAlign: "center",
            justifyContent: 'center'
          }}>
            <h3
              style={{
                fontWeight: 600,
                textAlign: "center",
                color: "black",
                textDecoration: "underline",
              }}
            >
              {'Search Store'}
            </h3>
            <br />
          </Toolbar>
          <div style={{ float: 'right', width: '100%' }}>
            <SearchbyName {...this.props} criteria={this.state}/>
          </div>
          <Formik
              initialValues={{
                emailAddress: "",
                mobileNumber: ""
              }}
              validationSchema={schema}
              // tell the formik to validate onBlur
              validateOnBlur
              onSubmit={(values) => {
             
                this.setState({
                  ...this.state,
                  Store: [],
                  offset: 0,
                  emailAddress:values.emailAddress,
                  mobileNumber:values.mobileNumber
                }, () => {
                  this.props.getSearchResult(this.state);
                })
              }}
            >
              {(props) => {
                const {
                  touched,
                  errors,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur,
                } = props;
                return (
                  <form noValidate onSubmit={handleSubmit}>
             <ul style={{ display: "flex", flexDirection: "column", alignItems: "center",position:'relative',right: '21px' }}>

              <Grid container spacing={3} style={{ padding: '20px' }}>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="filled-read-only-input"
                    label="Email Id"
                    type="text"
                    name="emailAddress"
                    value={values.emailAddress}
                    variant="outlined"
                    onChange={handleChange}
                    error={touched.emailAddress && Boolean(errors.emailAddress)}
                    helperText={touched.emailAddress&& errors.emailAddress}
                    // onChange={(e) => {
                    //   handleSearchField(e);
                    // }}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="filled-read-only-input"
                    label="Mobile Number"
                    type="text"
                    name="mobileNumber"
                    value={values.mobileNumber}
                    onChange={handleChange}
                    error={touched.mobileNumber&& Boolean(errors.mobileNumber)}
                    helperText={touched.mobileNumber&& errors.mobileNumber}
                    variant="outlined"
                    // onChange={(e) => {
                    //   handleSearchField(e);
                    // }}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl
                    style={{ width: "100%" }}
                    variant="outlined"
                  >
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      onChange={handleSearchField}
                      value={this.state.status}
                      name="status"
                      label="Status"
                      MenuProps={{style:{marginTop:'40px'}}}
                    >
                      {statusFilters.map((statusFilter, index) => {
                        return (
                          <MenuItem
                            key={index}
                            style={{ width: "100%" }}
                            value={statusFilter.value}
                          >
                            {statusFilter.option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <button
                    className="searchBtn"
                    type="submit"
                    // onClick={() => {
                    //   searchOrderHandle();
                    // }}
                  >
                    Search Store
                  </button>
                </Grid>
              </Grid>
            </ul>
            </form>
                );
              }}
            </Formik>


          {this.props.searching && loadingModal}
          {this.props.searchSuccess ? (
            <>
              <SearchResults stores={this.props.Stores} criteria={this.state} {...this.props} />
              <div style={{ display: 'flex', width: '100%',padding:'10px' }}>
                {this.state.offset > 0 &&
                  <span style={{ float: 'right', width: '100%', justifyContent: 'right', textAlign: 'right' }}>
                    <button
                    className="but"
                      // style={{
                      //   padding: '10px',
                      //   color: 'rgb(255, 255, 255)',
                      //   backgroundImage: 'linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)',
                      //   boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
                      //   height: '100%',
                      //   fontSize: '18px',
                      //   fontWeight: 600,
                      //   borderRadius: '5px',
                      //   borderWidth: '1px',
                      //   borderStyle: 'solid',
                      //   borderColor: 'transparent',
                      //   overflow: 'hidden',
                      //   outline: 'none',
                      //   fontFamily: ' Varela Round, sans-serif',
                      //   textTransform: 'none',
                      //   textAign: 'center',
                      //   width: '25%',
                      //   cursor: 'pointer',
                      // }}
                      onClick={() => searchPrevious()
                      }
                    >
                      Prev
                    </button>
                  </span>
                }
                {this.props.searchData && this.props.searchData.searchstore &&
                  this.props.searchData.searchstore.hasNext === true &&
                  <span style={{ float: 'right', width: '100%', justifyContent: 'right', textAlign: 'right' }}>
                    <button
                    className="but"
                      // style={{
                      //   padding: '10px',
                      //   color: 'rgb(255, 255, 255)',
                      //   backgroundImage: 'linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)',
                      //   boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
                      //   height: '100%',
                      //   fontSize: '18px',
                      //   fontWeight: 600,
                      //   borderRadius: '5px',
                      //   borderWidth: '1px',
                      //   borderStyle: 'solid',
                      //   borderColor: 'transparent',
                      //   overflow: 'hidden',
                      //   outline: 'none',
                      //   fontFamily: ' Varela Round, sans-serif',
                      //   textTransform: 'none',
                      //   textAign: 'center',
                      //   width: '25%',
                      //   cursor: 'pointer',
                      // }} 
                      onClick={() => searchOrder()
                      }
                    >
                      Next
                    </button>
                  </span>
                }
              </div>
            </>)
            : null
          }
        </div>

      </>
    );
  }
}
export default StoreSearch;
