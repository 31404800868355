import React from 'react';
import './index.css';
// import NoSearchResult from '../NoSearchResult';
// import { Badge } from 'antd';
import {NoImage}  from '../../utils/image';
import { NO_RESULT } from "../../utils/image";
import ProductToggle from './ProductToggle';
// import EditPopper from './EditPopper';
import EditIcon from '@mui/icons-material/Edit';

const ProductCard = (props) => {
   
    
  
    // const open = Boolean(anchorEl);
  
    const EditClick = (id,uom,status,depid,img) => {
		props.history.push({
            pathname: "/home/EditMasterProducts/details",
            state: {
                Productid:id,
                images:props.images,
                status:status,
                uom:uom,
                masterdepartment:depid,
                img:img
			},
        });  
    }
    
   
        
    

    return (
              <>
                {props.products&&props.products.newProducts&&
                props.products.newProducts.params&&
                props.products.newProducts.params[0]&&
                props.products.newProducts.params[0].length!==0? (
                    props.products.newProducts.params.map((p,index)=>
							p.map((source) => (
                                
                               
                <div   style={{paddingTop:'10px',paddingLeft:'15px'}} key={source.id}>
                     
                    
                <div 
                 className="Card" role="button"
                //   onClick={() => 
                //  getDetails(source.id,source.uom,
                //      source.status,
                //      source.masterdepartment&&source.masterdepartment.id,
                //      )}
                    >
                 
<div className='EditDiv'>
    <span style={{float:'left'}}><ProductToggle id={source.id} status={source.status==='inactive'?false:true} {...props}/> </span>
    <span style={{float:'right'}}>
        <EditIcon onClick={()=>EditClick(source.id,source.uom,
                     source.status,
                     source.masterdepartment&&source.masterdepartment,
                     source.image.primary
                     )}/>
        {/* <EditPopper onClick={()=>EditClick(source.id,source.uom,
                     source.status,
                     source.masterdepartment&&source.masterdepartment.id,
                     )} {...props}/> */}
                     </span>
                   
                   
      </div>
                      <div  className="text-center">
 {/* {source.image.map(({primary}) => */}
 

<div className="product-padding">

          <img  src={((source.image&&source.image.primary===null)||
            (source.image&&source.image.primary==="null"))?NoImage:source.image.primary} className="product-image" alt="product" />


 {/* <EditOutlinedIcon/> */}
</div>
{/* )
} */}
<div className="product-padding">
 <h1>₹ {source.price}</h1>
</div>
<div  className="product-name">
 <p>
     {source.productname}
 </p>
</div>
</div>

</div>
</div>
                            )
           
                        ))
                        ):
                          <div style={{   
                            width:'75vw',
                            paddingTop:'20px',
                                         display:'flex',
                                         justifyContent:'center'}}>
                             		<img  className="noresult"   src={NO_RESULT} alt="noresult"></img>
                              </div>
                              
                            }
          
 </>
    )
}
export default ProductCard;
