import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from 'react-bootstrap';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const useStyles = makeStyles(() => ({
    card: {
        boxShadow: 'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        padding: '15px',
        borderRadius: '14px'
    },
    col: {
        padding: '8px',
        color: 'rgb(99, 115, 129)',
        fontWeight: 600,
        fontSize: '14px',
    },
}));

const PreviousComplaints = (props) => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [listindex, setListIndex] = React.useState(0);

    const handleClick = (i) => {
        setListIndex(i);
        setOpen(!open);
    };

    return (
        <>
            {console.log("ORDERCOMPLAINTS", props.OrderComplaint)}
            {((props.OrderComplaint && props.OrderComplaint !== null && props.OrderComplaint.length === 0) ||
                (props.OrderComplaint && props.OrderComplaint === null)) ?
                <Row className={classes.col}>
                    <Col sm={12} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                        No Complaints
                    </Col>
                </Row>
                : props.OrderComplaint && props.OrderComplaint !== null && props.OrderComplaint.length > 0 &&
                    props.OrderComplaint.filter((f) => f.id !== (props.Complaint && props.Complaint.id && props.Complaint.id)).length === 0 ?
                    <Row className={classes.col}>
                        <Col sm={12} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                            No Complaints
                        </Col>
                    </Row>
                    : null
            }
            {props.OrderComplaint && props.OrderComplaint !== null && props.OrderComplaint.length > 0 &&
                props.OrderComplaint.filter((f) => f.id !== (props.Complaint && props.Complaint.id && props.Complaint.id))
                    .map((c, i) => (
                        <React.Fragment key={c.id}>
                            <Row className={classes.col}>
                                <Col sm={12}>
                                    <>
                                        <ListItemButton onClick={() => handleClick(i)}>
                                            <ListItemIcon>
                                                <RadioButtonCheckedIcon fontSize='small' />
                                            </ListItemIcon>
                                            <ListItemText primary={c.userMessage && c.userMessage.length > 0 &&
                                                c.userMessage[c.userMessage.length - 1]} />
                                            {open ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                        <Collapse in={open && i === listindex} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {c.itemList && c.itemList !== null && c.itemList.length > 0 &&
                                                    c.itemList.map((item) =>
                                                        <ListItemButton sx={{ pl: 4 }}>
                                                            <ListItemText secondary={item} />
                                                        </ListItemButton>)}
                                            </List>
                                        </Collapse>
                                    </>
                                </Col>
                            </Row>
                        </React.Fragment>
                    ))}
        </>
    )
}

export default PreviousComplaints
