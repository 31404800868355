import PropTypes from 'prop-types';

// material-ui
import { CardContent, Divider, Grid, Typography } from '@mui/material';

// project imports
import MainCard from '../../ui-component/cards/MainCard';
import SkeletonPopularCard from '../../ui-component/cards/Skeleton/PopularCard';
import storeplaceholder from '../../utils/images/storeplaceholder.svg';


// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const PopularCard = (props) => {

    return (
        <>
            {props.loadingdashboard ? (
                <SkeletonPopularCard />
            ) : (props.dashboardsuccess &&
                props.dashboardData &&
                props.dashboardData !== null &&
                props.dashboardData.data &&
                props.dashboardData.data.dashboard &&
                props.dashboardData.data.dashboard !== null &&
                <MainCard content={false} elevation={0} style={{ borderRadius: '24px',}}>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container alignContent="center" style={{justifyContent:"space-between"}}>
                                    <Grid item>
                                        <Typography variant="h6">Popular Stores</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                            {props.dashboardData.data.dashboard.popularStore1 &&
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid container alignItems="center" style={{justifyContent:"space-between"}}>
                                            <Grid item>
                                                <img
                                                    style={{  width:'50px', height:'50px', maxHeight:'100%',maxWidth:'100%',borderRadius:'10px'}}
                                                    src={props.dashboardData.data.dashboard.popularStoreImg1 &&
                                                        props.dashboardData.data.dashboard.popularStoreImg1!==null?
                                                        props.dashboardData.data.dashboard.popularStoreImg1:storeplaceholder} alt="store1" />
                                            </Grid>
                                            <Grid item>
                                                        <Typography variant="subtitle2" color="inherit">
                                                            {props.dashboardData.data.dashboard.popularStore1 &&
                                                                props.dashboardData.data.dashboard.popularStore1}
                                                        </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                                <Divider sx={{ my: 1.5 }} />
                                {props.dashboardData.data.dashboard.popularStore2 &&
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid container alignItems="center" style={{justifyContent:"space-between"}}>
                                            <Grid item>
                                                <img
                                                    style={{  maxHeight:'100%',maxWidth:'100%', width:'50px', height:'50px',borderRadius:'10px'  }}
                                                    src={props.dashboardData.data.dashboard.popularStoreImg2 &&
                                                        props.dashboardData.data.dashboard.popularStoreImg2} alt="store1" />
                                            </Grid>
                                            <Grid item>
                                                        <Typography variant="subtitle2" color="inherit">
                                                            {props.dashboardData.data.dashboard.popularStore2 &&
                                                                props.dashboardData.data.dashboard.popularStore2}
                                                        </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                }
                                <Divider sx={{ my: 1.5 }} />
                                {props.dashboardData.data.dashboard.popularStore3 &&
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid container alignItems="center" style={{justifyContent:"space-between"}}>
                                            <Grid item>
                                                <img
                                                    style={{ maxHeight:'100%',maxWidth:'100%',width:'50px', height:'50px',borderRadius:'10px',  }}
                                                    src={props.dashboardData.data.dashboard.popularStoreImg3 &&
                                                        props.dashboardData.data.dashboard.popularStoreImg3} alt="store1" />
                                            </Grid>
                                            <Grid item>
                                                        <Typography variant="subtitle2" color="inherit">
                                                            {props.dashboardData.data.dashboard.popularStore3 &&
                                                                props.dashboardData.data.dashboard.popularStore3}
                                                        </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                }
                                <Divider sx={{ my: 1.5 }} />
                                {props.dashboardData.data.dashboard.popularStore4 &&
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid container alignItems="center" style={{justifyContent:"space-between"}}>
                                            <Grid item>
                                                <img
                                                    style={{maxHeight:'100%',maxWidth:'100%', width:'50px', height:'50px',borderRadius:'10px' }}
                                                    src={props.dashboardData.data.dashboard.popularStoreImg4 &&
                                                        props.dashboardData.data.dashboard.popularStoreImg4} alt="store1" />
                                            </Grid>
                                            <Grid item>
                                                        <Typography variant="subtitle2" color="inherit">
                                                            {props.dashboardData.data.dashboard.popularStore4 &&
                                                                props.dashboardData.data.dashboard.popularStore4}
                                                        </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                }
                                <Divider sx={{ my: 1.5 }} />
                                {props.dashboardData.data.dashboard.popularStore5 &&
                                <Grid container direction="column">
                                    <Grid item>
                                        <Grid container alignItems="center" style={{justifyContent:"space-between"}}>
                                            <Grid item>
                                                <img
                                                    style={{ maxHeight:'100%',maxWidth:'100%', width:'50px',height:'50px',borderRadius:'10px' }}
                                                    src={props.dashboardData.data.dashboard.popularStoreImg5 &&
                                                        props.dashboardData.data.dashboard.popularStoreImg5} alt="store1" />
                                            </Grid>
                                            <Grid item>
                                                        <Typography variant="subtitle2" color="inherit">
                                                            {props.dashboardData.data.dashboard.popularStore5 &&
                                                                props.dashboardData.data.dashboard.popularStore5}
                                                        </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                }
                                <Divider sx={{ my: 1.5 }} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </MainCard>
            )}
        </>
    );
};

PopularCard.propTypes = {
    isLoading: PropTypes.bool
};

export default PopularCard;