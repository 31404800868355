import client from "../../../Configurations/apollo";
import { BLOCK_USER, GET_USERS_LIST, UNBLOCK_USER, USER_DETAILS } from "./Queries";

export const usersList = (params1,params2,params3,params4) => {	
	return async (dispatch) => {
		dispatch({ type: "LOADING_USERSLIST"});
		function onSuccess(success) {
			dispatch({ type: "USERSLIST_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "USERSLIST_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "USERSLIST_ERROR" });
		}
		const success = await client.query({
			query: GET_USERS_LIST,
			variables: {                
				email:params1,
                phoneNumber:params2,
				status:params3,
                pagination:{
                    first:15,
                    offset:params4
                }
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};

export const userDetails = (params) => {	
	return async (dispatch) => {
		dispatch({ type: "LOADING_USER"});
		function onSuccess(success) {
			dispatch({ type: "USER_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "USER_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "USER_ERROR" });
		}
		const success = await client.query({
			query: USER_DETAILS,
			variables: {                
				userid:`${params}`
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};

export const blockUser = (params) => {
	// let id = params;
	return (dispatch) => {
	  dispatch({
		type: "BLOCKING_USER",
	  });
	  client
		.mutate({
		  mutation: BLOCK_USER,
		  variables: {
			userid:`${params}`
		  },
		})
		.then((res) => {
		  if (res.data) {
			dispatch({
			  type: "BLOCK_USER_SUCCESS",
			  payload: res.data,
			});
		  } else {
			dispatch({
			  type: "BLOCK_USER_FAILED",
			  payload: res.message,
			});
		  }
		})
		.catch((err) => {
		  dispatch({
			type: "BLOCK_USER_FAILED",
			payload: err.message,
		  });
		});
	};
  };

  export const unblockUser = (params) => {
	// let id = params;
	return (dispatch) => {
	  dispatch({
		type: "UNBLOCKING_USER",
	  });
	  client
		.mutate({
		  mutation: UNBLOCK_USER,
		  variables: {
			userid:`${params}`
		  },
		})
		.then((res) => {
		  if (res.data) {
			dispatch({
			  type: "UNBLOCK_USER_SUCCESS",
			  payload: res.data,
			});
		  } else {
			dispatch({
			  type: "UNBLOCK_USER_FAILED",
			  payload: res.message,
			});
		  }
		})
		.catch((err) => {
		  dispatch({
			type: "UNBLOCK_USER_FAILED",
			payload: err.message,
		  });
		});
	};
  };