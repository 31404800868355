import React from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
} from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import Label from '../Label';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { AlertTitle } from "@material-ui/lab";
import producterror from '../../utils/images/producterror.svg';
import { Delete } from '@material-ui/icons';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DialogContent, DialogContentText } from '@material-ui/core';

const TABLE_HEAD = [
  { id: 'ProductDetails', label: 'Product Details', alignRight: false },
  { id: 'Price', label: 'Price', alignRight: false },
  { id: 'Status', label: 'Status', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: false },
  { id: 'Delete', label: ' ', alignRight: false },
];

const Productstable = (props) => {
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = (id) => {
    setId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const getDetails = (id) => {
    props.getNewProductDetails(id);
    props.history.push({
      pathname: "/home/Productdetails",
    });
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const close = () => {
    props.closeSnackBars();
  }

  React.useEffect(() => {
    props.getNewProducts();
  },
    // eslint-disable-next-line
    [])
  return (
    <div
      style={{
        transform: "translateY(100px)",
        width: "90%",
        padding: '15px',
      }}>
      {props.addnewproductsuccess ||
        props.addnewproductfailed ? (
        <Snackbar
          style={{ width: "fit-content" }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={
            props.addnewproductsuccess ||
            props.addnewproductfailed
          }
          autoHideDuration={2000}
          onClose={() => close()}>
          <Alert
            onClose={() => close()}
            severity={
              props.addnewproductsuccess
                ? "success"
                :
                props.addnewproductfailed ? "error"
                  : "warning"
            }>
            <AlertTitle>
              {props.addnewproductsuccess
                ? "Added New Product Successfully"
                : props.addnewproductfailed
                  ? "Add New Prdouct Failed"
                  : null}
            </AlertTitle>
          </Alert>
        </Snackbar>
      ) : null}
      {props.loadingnewproducts && props.loadingnewproducts === true ?
        <CircularProgress /> : props.newproductsfailed === true ?
          "Error" : (props.newproductssuccess === true && props.NewProducts && props.NewProducts !== null &&
            props.NewProducts.length && props.NewProducts.length > 0) ?
            <TableContainer   style={{ width: "100%", overflowX: "auto", marginLeft: "0px" }}>
              <Table stickyHeader>
                <TableRow style={{
                  width: '80%',
                  backgroundColor: 'white',
                  border: '1px solid rgb(229, 234, 242)',
                  borderRadius: '8px',
                  color: 'rgb(148, 164, 196)'
                }}>
                  {TABLE_HEAD.map((row, index) =>
                    <TableCell align={index !== 0 ? "center" : "left"} style={index===0? {borderTopLeftRadius: '10px',borderBottomLeftRadius: '10px',border:'none'}
                  :index===4?{borderTopRightRadius: '10px',borderBottomRightRadius: '10px',border:'none'}:{border:'none'}}>
                      {row.label}
                    </TableCell>
                  )}
                </TableRow>
                <br />
                <TableBody>
                  {props.NewProducts.map((row, index) =>
                  (
                    <React.Fragment key={index}>
                      <TableRow
                        style={{
                          width: '80%',
                          backgroundColor: 'white',
                          border: '1px solid rgb(229, 234, 242)',
                          borderRadius: '8px',
                          color: 'rgb(148, 164, 196)'
                        }}
                        tabIndex={-1}
                      >
                        <TableCell align="left" style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>
                          <div style={{ display: 'flex' }}>
                            <span style={{ paddingRight: '8px' }}>
                              {row.image && row.image.primary && (row.image.primary===null||row.image.primary==='null'||
                              row.image.primary===undefined||row.image.primary==="undefined")?
                              <img
                              style={{ maxWidth: '100%', maxHeight: '100%', width: '60px', height: '40px' }}
                              src={producterror} alt="product" />
                              :
                              <img
                                style={{ maxWidth: '100%', maxHeight: '100%', width: '60px', height: '40px' }}
                                src={row.image && row.image.primary && row.image.primary} alt="product" />
                      }
                            </span>
                            <span style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                              {row.productname && row.productname}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell align="center" >
                          {`₹ ${row.price && parseFloat(row.price).toFixed(2)}`}
                        </TableCell>
                        <TableCell align="center" >
                          {row.status && row.status !== null &&
                            <Label color={row.status && row.status === 'active' ? 'success' : 'error'} variant='ghost'>
                              {row.status && row.status}
                            </Label>
                          }
                        </TableCell>
                        <TableCell align="left" >
                          <div
                           style={{
                            border: '1px solid rgb(229, 234, 242)',
                            borderRadius: '10px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            padding: '8px',
                            width: '100%'
                          }}
                            onClick={() => getDetails(row.id)}
                          >
                            <EditTwoToneIcon />
                          </div>
                        </TableCell>
                        <TableCell align="left" style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
                          <div style={{
                            border: '1px solid rgb(229, 234, 242)',
                            borderRadius: '10px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            padding: '8px',
                            width: '100%'
                          }}
                          onClick={()=>handleClickOpen(row.id)}
                          >
                            <Delete />
                          </div>
                        </TableCell>
                      </TableRow>
                      <br />
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            : null}
             <Dialog
             fullWidth={'true'}
             maxWidth={"sm"}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
          {"Are you sure you want to delete the product ?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{display:'flex',width:'50%',float:'right'}}>
          <button 
          style={{
            marginRight:'8px',
            color: 'rgb(83,170,73)',
            backgroundColor: '#fff',
            boxShadow: '0px 14px 24px 0px rgb(93 183 96 / 38%)',
            height: '100%',
            fontSize: '18px',
            fontWeight: 600,
            borderRadius: '5px',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'transparent',
            overflow: 'hidden',
            outline: 'none',
            fontFamily: 'Varela Round, sans-serif',
            textTransform: 'none',
            textAlign: 'center',
            width: '50%'}}
           onClick={handleClose}>
            Cancel
          </button>
          <button 
          style={{
            color: 'rgb(255, 255, 255)',
            backgroundImage: 'linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)',
            boxShadow: '0px 14px 24px 0px rgb(93 183 96 / 38%)',
            height: '100%',
            fontSize: '18px',
            fontWeight: 600,
            borderRadius: '5px',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'transparent',
            overflow: 'hidden',
            outline: 'none',
            fontFamily: 'Varela Round, sans-serif',
            textTransform: 'none',
            textAlign: 'center',
            width: '50%'}}
          onClick={()=>{props.deleteNewProduct(id);setOpen(false)}} >
            Delete
          </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Productstable