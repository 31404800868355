import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import { NO_RESULT } from "../../utils/image";
import client from "../../Configurations/apollo";
import { STORE_WORKING,STORE_WORKING_SUBSCRIPTION } from "./Query";
 import BootstrapSwitchButton from "bootstrap-switch-button-react";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { AlertTitle } from "@material-ui/lab";
import ShareUrl from './ShareUrl';
import { useMediaQuery, useTheme } from "@mui/material";


const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
});

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SearchResults(props) {
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const [error, setError] = React.useState(null);
	const [Online, setOnline] = React.useState(null);
	const [id, setId] = React.useState(null);
	const handleChange = (checked,id) => {
		client
		.mutate({
		  mutation: STORE_WORKING,
		  variables: {
			storeWorking: Boolean(checked),
			storeid: id,
		  },
		})
		.then((res) => {
		  if (res.data && res.data.updatestore && res.data.updatestore !== null) {
			setId(id);
			setOnline(res.data && res.data.updatestore && res.data.updatestore.storeWorking);
			props.getSearchResult({...props.criteria})
			// console.log("1")
			// let stores = []
			
			// 	 if(id===res.data.updatestore.id)
			// 	 {
			// 		console.log("if")
			// 		const newState = this.props.searchData
			// 		&& this.props.searchData.searchstore
			// 		&& this.props.searchData.searchstore.items.map((p)=>p.id===id? { ...p,storeWorking: Boolean(checked) }
			// 		: p)
			// 		this.props.setStore(newState)
			// 	 }
			// 	 else{
			// 		console.log("else")
			// 	 }
						
					  
					 
					 
			// props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
		  }
		  if (res.data && res.data.updatestore && res.data.updatestore === null) {
			console.log("2")
			// props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
			// setIserror(true);
			setOnline(false);
			setOpen(true);
			setError(res.message);
			
				
			// console.log("error","res.message")
		  }
		})
		.catch((err) => {
		//   props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
		setOnline(false);
		  setOpen(true);
		  // setIserror(true)
		  setError(
			"There is no active products or hierarchy present. so store working cannot be updated"
		  );
		  // console.log("Catcherror","There is no active products or hierarchy present. so store working cannot be updated")
		})
		}  
	

	const getDetails = (storeid) => {
		localStorage.setItem('storeid', storeid.toString());
		props.getStoreDetails(storeid);
		
		props.history.push({
			pathname: "/home/Storedetails",
		});
	};

	const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));


	return (
		<>
				<Snackbar
					style={{ width: "fit-content" }}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={open}
					autoHideDuration={2000}
					onClose={() => setOpen(false)}>
					<Alert
							onClose={() => setOpen(false)}
						severity={
							 "error"
						}>
						<AlertTitle>
						{error}

						</AlertTitle>
						</Alert>
						</Snackbar>
						{((props.searchData &&
			 props.searchData.searchstore &&
			props.searchData.searchstore.items.length !== 0)||
			(
				props.searchData && 
				props.searchData.searchStoreName &&
				props.searchData.searchStoreName.length !== 0 
			)) ?
			<Typography
				variant="h6"
				style={{ color: "black", paddingBottom: '10px' }}
			>
				Search Results
			</Typography>
			:
			null
}
			{((props.searchData &&
			 props.searchData.searchstore &&
			props.searchData.searchstore.items.length !== 0)||
			(
				props.searchData && 
				props.searchData.searchStoreName &&
				props.searchData.searchStoreName.length !== 0 
			)) ?
				<TableContainer
					style={{ width: "100%", overflowX: "auto", marginLeft: "0px" }}
					component={Paper}>
					<Table aria-label="customized table">
						<TableHead>
							<TableRow>
								<TableCell>Seller First Name</TableCell>
								<TableCell align="center">Seller Last Name</TableCell>
								<TableCell align="center">Phone No.</TableCell>
								<TableCell align="center">Store Name</TableCell>
								<TableCell align="center">Store Type</TableCell>
								<TableCell align="center">Status</TableCell>
								<TableCell align="center">Store Working </TableCell>
								<TableCell align="center">Link</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.searchData &&
							props.searchData.searchstore &&
							props.searchData.searchstore.items &&
							props.searchData.searchstore.items.length !== 0? (
								props.searchData.searchstore.items.map((row,index) =>
										<TableRow
											
											key={index}>
											<TableCell 
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
												localStorage.setItem(`retailerid`, row.retailer);
											}}
											component="th" scope="row" align="center">
												{row.forAdmin.firstName && row.forAdmin.firstName}
											</TableCell>
											<TableCell 
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
												localStorage.setItem(`retailerid`, row.retailer);
											}}
											align="center">
												{row.forAdmin.lastName && row.forAdmin.lastName}
											</TableCell>
											<TableCell
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
												localStorage.setItem(`retailerid`, row.retailer);
											}}
											 align="center">
												{row.forAdmin.mobileNumber && row.forAdmin.mobileNumber}
											</TableCell>
											<TableCell
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
												localStorage.setItem(`retailerid`, row.retailer);
											}}
											 align="center"style={{ whiteSpace: 'normal' }}> 
												{row.storeName && row.storeName}
											</TableCell>
											<TableCell
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
												localStorage.setItem(`retailerid`, row.retailer);
											}}
											 align="center" style={{ whiteSpace: 'normal' }}>
												{row.businessType && row.businessType}
											</TableCell>
											<TableCell 
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
												localStorage.setItem(`retailerid`, row.retailer);
											}}align="center">

												{row.storeStatus && row.storeStatus}
											</TableCell>
											<TableCell align="center" component="th" scope="row">
												<BootstrapSwitchButton
											
                checked={Online===null?Boolean(row.storeWorking):(id===row.id?Online:row.storeWorking)}
                onlabel="Store Online"
                onstyle="success"
                offlabel="Store Offline"
                offstyle="danger"
                width={120}
                onChange={(checked) => handleChange(checked,row.id)}
				data-onlabel="Online"
				data-offlabel="Offline"
				style={{ textAlign: 'center' }}
              />
											</TableCell>
											<TableCell align="center">
												<ShareUrl id={row.id} {...props}/>
											</TableCell>
										</TableRow>
									)
							) : (
								props.searchData && props.searchData.searchStoreName &&
									props.searchData.searchStoreName.length !== 0 ? (
									props.searchData.searchStoreName.map((row, index) =>
										// rows.map((row,index)=>(
										<TableRow
											
											key={index}>
											<TableCell component="th" scope="row"
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
												localStorage.setItem(`retailerid`, row.retailer);
											}}>
												{row.forAdmin.firstName && row.forAdmin.firstName}
											</TableCell>
											<TableCell align="center"
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
												localStorage.setItem(`retailerid`, row.retailer);
											}}>
												{row.forAdmin.lastName && row.forAdmin.lastName}
											</TableCell>
											<TableCell align="center"
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
												localStorage.setItem(`retailerid`, row.retailer);
											}}>
												{row.forAdmin.mobileNumber && row.forAdmin.mobileNumber}
											</TableCell>
											<TableCell align="center"
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
												localStorage.setItem(`retailerid`, row.retailer);
											}}>
												{row.storeName && row.storeName}
											</TableCell>
											<TableCell align="center" style={{wordBreak:'break-word'}}
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
												localStorage.setItem(`retailerid`, row.retailer);
											}}>
												{row.businessType && row.businessType}
											</TableCell>
											<TableCell align="center"
											onClick={() => {
												getDetails(row.id);
												localStorage.setItem(`storeid`, row.id);
												localStorage.setItem(`retailerid`, row.retailer);
											}}>
												{row.storeStatus && row.storeStatus}
											</TableCell>
											<TableCell align="center" component="th" scope="row">
												<BootstrapSwitchButton
                 checked={Online===null?Boolean(row.storeWorking):(id===row.id?Online:row.storeWorking)}
				 onlabel="Store Online"
				 onstyle="success"
				 offlabel="Store Offline"
				 offstyle="danger"
				 width={120}
				 onChange={(checked) => handleChange(checked,row.id)}
              />
											</TableCell>
											<TableCell align="center">
												<ShareUrl id={row.id} {...props}/>
											</TableCell>
										</TableRow>
									))
									: (
										null
									))
							}

						</TableBody>
					</Table>
				</TableContainer>
				:
			
				( isSmallScreen?
					<div style={{   
						width:'75vw',
						paddingTop:'20px',
									 display:'flex',
									 justifyContent:'center',
									 position:'relative',
									 right:'6px'}}>
							<h5>{'No Search Results'}</h5>
						  </div>
						  :
				<div style={{   
					width:'75vw',
					paddingTop:'20px',
								 display:'flex',
								 justifyContent:'center'}}>
						 <img style={{ maxWidth: "810px"}} src={NO_RESULT} alt="noresult"></img>
					  </div>)
					  
					  }
		</>
	);
}
