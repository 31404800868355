import React from 'react'
import * as yup from 'yup';
 
export const validationSchema = yup.object({ 
    hour:yup
    .string()
    .trim()
    .matches( /^([+-]?(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?)$/,"Only numbers are allowed")
    .required('Hour is required'),
    minute:yup
       .string()
       .trim()
       .matches( /^([+-]?(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?)$/,"Only numbers are allowed")
       .required('Minute is required'),
  
});