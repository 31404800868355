import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from 'react-bootstrap';

const useStyles = makeStyles(() => ({
    card: {
        boxShadow: 'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        padding: '15px',
        borderRadius: '14px'
    },
    col: {
        padding: '8px',
        color: 'rgb(99, 115, 129)',
        fontWeight: 600,
        fontSize: '14px',
    },
}));

const StoreDetails = (props) => {

    const classes = useStyles();

    return (
        <>
            <Row className={classes.col}>
                <Col sm={6} >
                    Store Name
                </Col>
                <Col sm={6}>
                    {props.Complaint.storeid && props.Complaint.storeid.storeName && props.Complaint.storeid.storeName}
                </Col>
            </Row>
            <Row className={classes.col}>
                <Col sm={6}>
                    Email
                </Col>
                <Col sm={6}>
                    {props.Complaint.storeid && props.Complaint.storeid.forAdmin && props.Complaint.storeid.forAdmin.emailAddress && props.Complaint.storeid.forAdmin.emailAddress}
                </Col>
            </Row>
            <Row className={classes.col}>
                <Col sm={6}>
                    Store Manager
                </Col>
                <Col sm={6}>
                    {props.Complaint.storeid && props.Complaint.storeid.storeManagerFirstName && props.Complaint.storeid.storeManagerFirstName}
                    {' '}
                    {props.Complaint.storeid && props.Complaint.storeid.storeManagerLastName && props.Complaint.storeid.storeManagerLastName}
                </Col>
            </Row>
            <Row className={classes.col}>
                <Col sm={6}>
                    Store Manager Mobile
                </Col>
                <Col sm={6}>
                    {props.Complaint.storeid && props.Complaint.storeid.storeManagerMobile && props.Complaint.storeid.storeManagerMobile}
                </Col>
            </Row>
            <Row className={classes.col}>
                <Col sm={6}>
                    Business Type
                </Col>
                <Col sm={6}>
                    {props.Complaint.storeid && props.Complaint.storeid.businessType && props.Complaint.storeid.businessType}
                </Col>
            </Row>
        </>
    )
}

export default StoreDetails
