import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import EditImageField from "../EditImageLogo";
import TextField from "@material-ui/core/TextField";
import EditLocation from "../EditLocation";
import ImageEditWindow from "../ImageEditModal";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./index.css";

const StoreDetailsNew = (props) => {
  React.useEffect(()=>{
		document.body.scrollTop = 0;
    window.scrollTo(0, 0);
	}
	,[])

  const [state, setState] = React.useState({
    imageEditModal: false,
    initialDetailState: props.retailerDetails && props.retailerDetails,
  });

  const storeLabels = [
    "Store Name",
    "Store Description",
    "Store Manager FirstName",
    "Store Manager LastName",
    "Store Manager Mobile",
    "Store Manager Alternate Contact Number",
  ];

  const validationSchema = Yup.object().shape({
    storeName: Yup.string().required("Please enter Store Name"),
    storeDescription: Yup.string().required("Please enter Store Description"),
    // storeLocation: Yup.string().required("Please enter Store Location"),
    storeManagerFirstName: Yup.string().required("Please enter Store Manager First Name"),
    storeManagerLastName: Yup.string().required("Please enter Store Manager Last Name"),
    storeManagerMobile: Yup.string()
      .trim("Space not allowed")
      .matches(/^[0-9]*$/, "Please enter only numbers")
      .min(10, "Please enter 10 digit Mobile number")
      .max(10, "Please enter 10 digit Mobile number")
      .required("Please enter Mobile number"),
    storeManagerPhone: Yup.string()
      .trim("Space not allowed")
      .matches(/^[0-9]*$/, "Please enter only numbers")
      .min(10, "Please enter 10 digit Mobile number")
      .max(10, "Please enter 10 digit Mobile number"),
     
      
  });

  const formik = useFormik({
    initialValues: {
      storeName: props.searchDetails.store.storeName || "",
      storeDescription: props.searchDetails.store.storeDescription || "",
      storeManagerFirstName: props.searchDetails.store.storeManagerFirstName || "",
      storeManagerLastName: props.searchDetails.store.storeManagerLastName || "",
      storeManagerPhone: props.searchDetails.store.storeManagerPhone || "",
      storeManagerMobile: props.searchDetails.store.storeManagerMobile || "",
      storeLocation:props.searchDetails.store.storeLocation,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if(values.storeManagerPhone===values.storeManagerMobile)
      {
        formik.setFieldError('storeManagerMobile','Alternate number and mobile number should not be the same')
      }
      else{
        props.saveDetails({...props.searchDetails.store,
          storeName:values.storeName,
          storeDescription:values.storeDescription,
          storeManagerFirstName:values.storeManagerFirstName,
          storeManagerLastName:values.storeManagerLastName,
          storeManagerPhone:values.storeManagerPhone,
          storeManagerMobile:values.storeManagerMobile,
          storeLocation:props?.edittedStoreLocation?.params==null?
          props.searchDetails.store.storeLocation:props?.edittedStoreLocation?.params
        });
        props.next();
      }
     
    },
  });

  let storeTextField = [
    "storeName",
    "storeDescription",
    "storeManagerFirstName",
    "storeManagerLastName", 
    "storeManagerMobile",
    "storeManagerPhone"
  ];
  
  const handleImageEdit = () => {
    setState({ imageEditModal: !state.imageEditModal });
    props.newSignedUrl &&
      props.postNewUrl({
        url: props.newSignedUrl,
        details: props.searchDetails.store,
      });
  };

  return (
    <Container>
      <div style={{ fontWeight: 600 }}>
        <h4
          style={{
            paddingTop: "30px",
            fontWeight: 500,
            textAlign: "center",
            color: "black",
            textDecoration: "underline",
          }}
        >
          {"Store Details"}
        </h4>
        {state.imageEditModal ? (
          <ImageEditWindow {...props} handleImageEdit={handleImageEdit} />
        ) : null}
        <form onSubmit={formik.handleSubmit}>
          <ul>
            <Row>
              {storeTextField.map((storeDetail, index) => {
                return index < 7 ? (
                  <Col md={6} key={index} style={{ paddingTop: 30, borderRadius: '15px' }}>
                    <TextField
                      InputProps={{ style: { height: 50 } }}
                      label={storeLabels[index]}
                      name={storeDetail}
                      value={formik.values[storeDetail]}
                      onChange={formik.handleChange}
                      error={
                        formik.touched[storeDetail] && Boolean(formik.errors[storeDetail])
                      }
                      helperText={formik.touched[storeDetail] && formik.errors[storeDetail]}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>
                ) : null;
              })}
            </Row>
            <EditLocation {...props} />
            <Grid item xs={12} style={{ paddingTop: 50 }}>
              <EditImageField {...props} handleImageEdit={handleImageEdit} />
            </Grid>
          </ul>
          <div className='buttons'>
          <button
          type="button"
										onClick={props.back}
                    className='nextbutton'>
										Back
									</button>
          <button type='submit' className='nextbutton' id='nex'>
            {'Save & Next'}
          </button>
          </div>
        </form>
      </div>
    </Container>
  );
};
export default StoreDetailsNew;
