import { connect } from "react-redux";
import { SelectState } from "./Selector";
import { loginAccess } from "../../Redux/Action/LoginActions";
import HomePage from "./home";

const mapStateToProps = (state) => {
	return { ...SelectState(state) };
};

const mapDispatchToProps = (dispatch) => {
	return {
		signInClick: (params) => {
			dispatch(loginAccess(params));
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);

