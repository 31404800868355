import * as yup from 'yup';

export const validationSchema = yup.object({
    Productname: yup
      .string()
      .trim("Space not allowed")
      .required('Product name is required'),
    Description: yup
      .string()
      .trim("Space not allowed")
      .required('Product description is required'),
    barcode: yup
      .string()
      .trim("Space not allowed"),
      // .required('Barcode is required'),
   Price:yup
       .string()
       .trim("Space not allowed")
       .matches( /^([+-]?(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?)$/,"Only numbers are allowed")
       .required('Price is required'),
    PromoPrice:yup
       .string()
       .trim("Space not allowed")
       .matches( /^([+-]?(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?)$/,"Only numbers are allowed"),
    Quantity:yup
       .string()
       .matches(/^([1-9]+[0-9]*|[1-9])$/,"Only positive numbers are allowed")
       .trim("Space not allowed")
       .required('Quantity is required'),
    unit:yup
       .string()
       .oneOf(['No.s','kg','g','gal','l','ml'],'required'),
      //  .required('Unit is required'),
    displayRank:yup
       .string()
       .trim("Space not allowed")
       .matches(/^\d+$/,'Only numbers are allowed')
       .required('Display rank is required'),
    status:yup
       .string()
       .trim("Space not allowed")
       .oneOf(['active','inactive'],'please select a status')
       .required('Status is required'),
   //  department:yup
   //     .string()
   //     .required('Department is required'),
   //  salescategory:yup
   //     .string()
   //     .required('Category is required'),
   //  salescategory1:yup
   //     .string()
   //     .required('Subcategory is required'),
   //  salescategory2:yup
   //     .string()
   //     .required('Subcategory 1 is required'),
  });