import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from "@material-ui/core/TextField";
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Grid from "@material-ui/core/Grid";

export default function ResponsiveDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({ storename: '' });
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setState({
            storename: ''
        })
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        e.preventDefault();
        if(e.key === "enter"){
            props.getSearchResultByName(state.storename); 
            setOpen(false);
             }
        setState({
            ...state.storename,
            storename: e.target.value
        })
    };
//  const handleSearch = (e) =>{
//      e.preventDefault();
//      if(e.key === "enter"){
//     props.getSearchResultByName(state.storename); 
//     setOpen(false);
//      }
//  };
 const handleKeypress = e => {
    
    if (e.keyCode === 13) {

        e.preventDefault();        
        props.getSearchResultByName(state.storename); 
        setOpen(false);
    }
  };
    return (
        <div>
            <button variant="text" onClick={handleClickOpen} style={{ textDecoration: 'line',border:'none',backgroundColor:'white',
            fontSize:'18px',
            color:'rgba(30, 139, 195)',cursor:'pointer' }}>
                Search Store By Name
            </button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                    {'Search Store By Name'}
                </DialogTitle>
                <DialogContent>
                    {/* <form id="editable-form"> */}
                        <ul>
                        <form>
                            <Grid container spacing={3} style={{ padding: '20px' ,position:'relative',right:'17px'}}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="filled-read-only-input"
                                        label="Store Name"
                                        type="text"
                                        name="storename"
                                        value={state.storename}
                                        variant="outlined"
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        onKeyPress={handleKeypress}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <button
                                        className="searchBtn"
                                        // type="button"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            props.getSearchResultByName(state.storename); 
                                            setOpen(false);
                                        }}
                                        // ref={node => (this.btn = node)}
                                        type="submit"
                                        // onKeyPress={(e) => {
                                        //     if (e.keyCode === 13) {
                                        //         props.getSearchResultByName(state.storename); 
                                        //         setOpen(false);
                                        //       }
                                           
                                        // }}
                                    >
                                        Search Store
                                    </button>
                                </Grid>
                            </Grid>
                            </form>
                        </ul>
                    {/* </form> */}
                </DialogContent>
            </Dialog>
        </div>
    );
}
