import React from "react";
import { BrowserRouter as Router , Switch ,Route } from "react-router-dom";
import { routesList } from "./routeList";
import ReduxProvider from "./redux";
import { messaging } from "../firebase";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import notificationsound from "../utils/notification.wav";
import AlertTitle from '@mui/material/AlertTitle';
import PageNotFound from "../Components/PageNotFound/PageNotFound";
import "simplebar/dist/simplebar.min.css";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class App extends React.Component {

	playSound = (url) => {
		let audio = new Audio(url);
		audio.play();
	};

	state = {
		fireToken: null,
		notification: false,
		NotificationMessage: "",
		title: ""
	};

	componentDidMount() {
		messaging
			.requestPermission()
			.then(() => {
				return messaging.getToken();
			})       
			.then((token) => {
				this.setState({ fireToken: token });
				localStorage.setItem("fcmToken", token);
			})
			.catch((err) => {
				console.log("firebase-token-error", err);
			});
		navigator.serviceWorker.addEventListener("message", (message) => {
			this.playSound(notificationsound);

			this.setState({ notification: true });
			this.setState({ NotificationMessage: `${message.data["firebase-messaging-msg-data"].notification.body}` })	  // return( <Alert severity="info">
			this.setState({ title: `${message.data["firebase-messaging-msg-data"].notification.title}` })
		});
	}

	render() {

		const routeComponents = routesList.map(({ path, components, exact }, key) => (
			<Route path={path} exact={exact} key={key} component={components} />
		));
		return (
			<ReduxProvider>
				<Snackbar
					style={{ width: "fit-content" }}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={
						this.state.notification
					}
					autoHideDuration={3000}
					onClose={() => this.setState({ notification: false })}>

					<Alert autoHideDuration={3000}
						onClose={() => this.setState({ notification: false })} severity="info">
						<AlertTitle>{this.state.title}</AlertTitle>
						{this.state.NotificationMessage}
					</Alert>
				</Snackbar>

				{/* <ApolloProvider> */}
				<Router>
					<Switch>
						{routeComponents}
						<Route path="*" component={PageNotFound} />
					</Switch> 
				</Router>
				{/* </ApolloProvider> */} 
			</ReduxProvider>
		);
	}
}
export default App;