import client from "../../../Configurations/apollo";
import { ALL_COMPLAINTS, CLOSE_COMPLAINT, COMPLAINT_ACTION, COMPLAINT_DETAILS, ORDER_COMPLAINTS, SOLVE_COMPLAINT } from "./Queries";

export const allComplaints = (params,params1,params2) => {	
	return async (dispatch) => {
		dispatch({ type: "LOADING_COMPLAINTS"});
		function onSuccess(success) {
			dispatch({ type: "COMPLAINTS_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "COMPLAINTS_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "COMPLAINTS_ERROR" });
		}
		const success = await client.query({
			query: ALL_COMPLAINTS,
			variables: {                
				complaintStatus:Number(params),
                pagination:{
                    first:Number(params1),
                    offset:Number(params2)
                }
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};

export const ComplaintDetails = (params) => {	
	return async (dispatch) => {
		dispatch({ type: "LOADING_COMPLAINT"});
		function onSuccess(success) {
			dispatch({ type: "COMPLAINT_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "COMPLAINT_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "COMPLAINT_ERROR" });
		}
		const success = await client.query({
			query: COMPLAINT_DETAILS,
			variables: {                
				complaintid:`${params}`,
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};

export const orderComplaints = (params) => {	
	return async (dispatch) => {
		dispatch({ type: "LOADING_ORDER_COMPLAINT"});
		function onSuccess(success) {
			dispatch({ type: "ORDER_COMPLAINT_SUCCESS", payload: success });
			return success;
		}
		function onError(error) {
			dispatch({ type: "ORDER_COMPLAINT_FAILED", payload: error });
			return error;
		}
		function onNetworkError() {
			dispatch({ type: "ORDER_COMPLAINT_ERROR" });
		}
		const success = await client.query({
			query: ORDER_COMPLAINTS,
			variables: {                
				orderid:`${params}`,
			},
		});
		if (success) {
			return onSuccess(success);
		} else if (success.errors) {
			return onError(success.errors[0]);
		} else return onNetworkError();
	};
};

export const AdminAction = (params1,params2) => {
	return (dispatch) => {
	  dispatch({
		type: "SENDING_ADMIN_ACTION",
	  });
	  client
		.mutate({
		  mutation: COMPLAINT_ACTION,
		  variables: {
			complaintid:`${params1}`,
			adminAction:[`${params2}`]
		  },
		})
		.then((res) => {
		  if (res.data) {
			dispatch({
			  type: "SEND_ACTION_SUCCESS",
			  payload: res.data,
			});
		  } else {
			dispatch({
			  type: "SEND_ACTION_FAILED",
			  payload: res.message,
			});
		  }
		})
		.catch((err) => {
		  dispatch({
			type: "SEND_ACTION_FAILED",
			payload: err.message,
		  });
		});
	};
  };

  export const CloseComplaint = (params1,params2) => {
	return (dispatch) => {
		dispatch({
		  type: "CLOSING_COMPLAINT",
		});
		client
		  .mutate({
			mutation: CLOSE_COMPLAINT,
			variables: {
			  complaintid:`${params1}`,
			  complaintStatus:Number(params2)
			},
		  })
		  .then((res) => {
			if (res.data) {
			  dispatch({
				type: "CLOSE_COMPLAINT_SUCCESS",
				payload: res.data,
			  });
			} else {
			  dispatch({
				type: "CLOSE_COMPLAINT_FAILED",
				payload: res.message,
			  });
			}
		  })
		  .catch((err) => {
			dispatch({
			  type: "CLOSE_COMPLAINT_FAILED",
			  payload: err.message,
			});
		  });
	  };
  };

export const clearclosedcomplaintprops = () =>{
	return (dispatch) => {
		dispatch({
		  type: "CLEAR_CLOSED_COMPLAINT_PROPS"
		});
	}
};

export const SolveComplaint = (params) => {
	return (dispatch) => {
	  dispatch({
		type: "SOLVING_COMPLAINT",
	  });
	  client
		.mutate({
		  mutation: SOLVE_COMPLAINT,
		  variables: {
			complaintid:`${params.complaintid}`,
			orderid:`${params.orderid}`,
			orderNumber:Number(params.orderNumber),
			refund:params.refund,
			replacement:params.replacement,
			refundStatus:params.refundStatus!==null?`${params.refundStatus}`:null,
			refundAmount:params.refundAmount!==null?Number(params.refundAmount):0,
			paymentId:params.paymentId!==null?`${params.paymentId}`:null,
			replacementStatus:params.replacementStatus!==null?`${params.replacementStatus}`:null
		  },
		})
		.then((res) => {
		  if (res.data) {
			dispatch({
			  type: "SOLVE_COMPLAINT_SUCCESS",
			  payload: res.data,
			});
		  } else {
			dispatch({
			  type: "SOLVE_COMPLAINT_FAILED",
			  payload: res.message,
			});
		  }
		})
		.catch((err) => {
		  dispatch({
			type: "SOLVE_COMPLAINT_FAILED",
			payload: err.message,
		  });
		});
	};
  };