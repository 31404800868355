import React from "react";
import { noDepartments } from "../../utils/image";
import { Card } from 'antd';

const { Meta } = Card;

// 
const NoDepartment = () => {
	return (
		<div style={{justifyContent:'center',
		alignContent:'center',
		alignItems:'center',
		textAlign:'center',
		display:'flex'}}>
			 <Card
    style={{ width: 450 }}
    cover={
      <img
      src={noDepartments} alt="noresult"
      />
    }
   
  >
    <Meta
      title="You need atleast one hierarchy to create a product."
      description="Go to Manage hierarchy -> create hierarchy first"
    />
  </Card>
		
		</div>
	);
};
export default NoDepartment;